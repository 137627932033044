import { getAPICall } from "../shared";


export const calculateAverageBunkerPlan = (rowValue) => {
  let endPrice = 0,
    bunkerExpense = 0,
    totalcons = 0,
    totalqty = 0,
    totalprice = 0;
  let seaCons = rowValue?.["sea_cons"] ?? 0;
  let portCons = rowValue?.["port_cons"] ?? 0;
  let initalPrice = rowValue?.["init_prc"] ?? 0;
  let lastPrice = rowValue?.["last_prc"] ?? 0;
  totalcons = seaCons * 1 + portCons * 1;
  let initqty = rowValue?.["init_qty"] ?? 0;
  let lastqty = rowValue?.["rec_qty"] ?? 0;
  totalqty = initqty * 1 + lastqty * 1;
  totalprice = initqty * initalPrice + lastqty * lastPrice;
  endPrice = (totalprice / totalqty).toFixed(2);
  bunkerExpense = (endPrice * totalcons).toFixed(2);
  endPrice = isNaN(endPrice) ? 0 : parseFloat(endPrice).toFixed(2);
  bunkerExpense = isNaN(bunkerExpense)
    ? 0
    : parseFloat(bunkerExpense).toFixed(2);
  return { endPrice, bunkerExpense };
};

export const calculateLifoBunkerPlan = (rowValue) => {
  let endPrice = 0,
    bunkerExpense = 0,
    totalcons = 0,
    totalqty = 0,
    totalprice = 0;
  let lastinprice = 0;

  let seaCons = rowValue?.["sea_cons"] ?? 0;
  let portCons = rowValue?.["port_cons"] ?? 0;
  let initalPrice = rowValue?.["init_prc"] ?? 0;
  let lastPrice = rowValue?.["last_prc"] ?? 0;
  totalcons = seaCons * 1 + portCons * 1;
  let initqty = rowValue?.["init_qty"] ?? 0;
  let lastqty = rowValue?.["rec_qty"] ?? 0;
  let totalseaportcons = totalcons;
  if (totalcons > lastqty) {
    lastinprice = lastqty * lastPrice;
    totalcons = totalcons - lastqty;
    lastinprice += totalcons * initalPrice;
  } else if (totalcons < lastqty) {
    lastinprice = totalcons * lastPrice;
  }
  lastinprice = isNaN(lastinprice) ? 0 : parseFloat(lastinprice).toFixed(2);
  endPrice = (lastinprice / totalseaportcons).toFixed(2);
  return { lastinprice, endPrice };
};

export const calculateFifoBunkerPlan = (rowValue) => {
  let endPrice = 0,
    bunkerExpense = 0,
    totalcons = 0,
    totalqty = 0,
    totalprice = 0;
  let firstinprice = 0;
  let seaCons = rowValue?.["sea_cons"] ?? 0;
  let portCons = rowValue?.["port_cons"] ?? 0;
  let initalPrice = rowValue?.["init_prc"] ?? 0;
  let lastPrice = rowValue?.["last_prc"] ?? 0;
  totalcons = seaCons * 1 + portCons * 1;
  let initqty = rowValue?.["init_qty"] ?? 0;
  let lastqty = rowValue?.["rec_qty"] ?? 0;
  let totalseaportcons = totalcons;
  if (totalcons > initqty) {
    firstinprice = initqty * initalPrice;
    totalcons = totalcons - initqty;
    firstinprice += totalcons * lastPrice;
  } else if (totalcons < initqty) {
    firstinprice = totalcons * initalPrice;
  }
  firstinprice = isNaN(firstinprice) ? 0 : parseFloat(firstinprice).toFixed(2);
  endPrice = (firstinprice / totalseaportcons).toFixed(2);
  return { firstinprice, endPrice };
};

export const handleBeforePrint = () => {
  const style = document.createElement("style");
  style.type = "text/css";
  style.media = "print";
  style.appendChild(document.createTextNode("@page { size: A4 landscape; }"));
  document.head.appendChild(style);
};


export const getmarinevessel = async (vesselname, vesselid) => {
 //  console.log({vesselname, vesselid })
  if (vesselname) {
    try {
      let url = `${process.env.REACT_APP_MT_URL}marine/get-vessels-name/${vesselname}`;
      let marineData = await getAPICall(url);

      let marinVessel = marineData?.filter(
        (item) => item.SHIPNAME.toLowerCase() === vesselname.toLowerCase()
      );

      if (marinVessel.length > 0) {
        marinVessel = marinVessel[0];
        localStorage.setItem("marinevessel", JSON.stringify(marinVessel));
        localStorage.setItem("showvesselmap", true);
      }

      // console.log("marinVesselmarinVessel", marinVessel);

      return marinVessel;
    } catch (err) {
      console.log("err", err);
    }
  }
};

export const parseDurationToDays = (durationStr) => {
  const regex = /(-?\d+)D:(-?\d+)H:(-?\d+)M/;
  const matches = durationStr.match(regex);

  if (!matches) {
    throw new Error("Invalid duration format");
  }

  const days = parseInt(matches[1], 10);
  const hours = parseInt(matches[2], 10);
  const minutes = parseInt(matches[3], 10);

  // Convert hours and minutes to days
  const totalDays = days + (hours / 24) + (minutes / 1440);

  return totalDays;
}

export function roundUpTwoDecimalValue(value) {
  if (isNaN(value) || value === null || value === undefined) {
      return 0.00;
  }
  return (Math.ceil(parseFloat(value) * 100) / 100).toFixed(2);
}

export const laytimeEvent = {
 
  "nextDateAdd": {
    "getDay": {
      "updateTo": "day",
      "fromField": "from_date"
    },
    "updateTo": "from_date",
    "fromField": "to_date"
  },
  "postAPIList": {
    "apiLink": "/laytime-calculator/time-sorting",
    "postData": {
      "laytime": []
    },
    "postDataKey": "laytime",
    "updateGroup": "---",
    "dynamicGroup": true
  },

   "eventLoops": [
        {
            "f_id": 1585,
        "group": ".",
         "index": "same",
	"dynamic": true,
        "isdnd":true,
        "dyncName": "port_name"
	   
        }
    ]

}

export const voyageEstimatePortSpeedEvent = {
  "formulas": [
      {
          "S": "speed",
          "W": "wf_per",
          "formula": "S*(1-(W/100))",
          "roundOff": 2,
          "updateTo": "eff_speed",
          // "withInReplace": {
          //     "replace": [
          //         "S",             
          //         "W"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      },
      {
          "M": "miles",
          "S": "speed",
          "formula": "M/(S*24)",
          "roundOff": 2,
          "updateTo": "gsd",
          // "withInReplace": {
          //     "replace": [
          //         "M",
          //         "S"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      },
      {
          "M": "miles",
          "ES": "eff_speed",
          "formula": "M/(ES*24)",
          "roundOff": 2,
          "updateTo": "tsd",
          // "withInReplace": {
          //     "replace": [
          //         "M",
          //         "ES"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      },
      {
          "gs": "gsd",
          "ts": "tsd",
          "formula": "ts-gs",
          "roundOff": 2,
          "updateTo": "xsd",
          // "withInReplace": {
          //     "replace": [
          //         "ts",
          //         "gs"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      },
      {
          "ed": "seca_length",
          "es": "eff_speed",
          "formula": "(ed/(es*24))",
          "roundOff": 2,
          "updateTo": "eca_days",
          // "withInReplace": {
          //     "replace": [
          //         "ed",
          //         "es"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      }
  ],
  "totalGSum": {
      "roundOff": 2,
      "to": {
          "fields": [
              "total_distance",
              "total_tsd",
              "total_gsd",
              "total_seca",
              "total_seca_days",
              "total_speed",
              "total_eff_spd",
              "total_xsd",
              "total_lq",
              "total_turntime",
              "totalt_port_days",
              "total_xpd",
              "total_exp",
              "total_est_pdays"
          ],
          "groupKey": "Total Itinerary Summary"
      },
      "from": {
          "fields": [
              "miles",
              "tsd",
              "gsd",
              "seca_length",
              "eca_days",
              "speed",
              "eff_speed",
              "xsd",
              "l_d_qty",
              "turn_time",
              "days",
              "xpd",
              "p_exp",
              "t_port_days"
          ],
          "groupKey": "Port Itinerary"
      }
  },
  "eventLoops":[{
    "f_id":984,
    "group":"portitinerary",
    "index": "SAME"
  }]
}
export const TcEstimatePortSpeedEvent = {
  "formulas": [
      {
          "S": "speed",
          "W": "wf_per",
          "formula": "S*(1-(W/100))",
          "roundOff": 2,
          "updateTo": "eff_speed",
          "withInReplace": {
              "replace": [
                  "S",
                  "W"
              ],
              "groupCode": "portitinerary"
          }
      },
      {
          "M": "miles",
          "S": "speed",
          "formula": "M/(S*24)",
          "roundOff": 2,
          "updateTo": "gsd",
          // "withInReplace": {
          //     "replace": [
          //         "M",
          //         "S"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      },
      {
          "M": "miles",
          "ES": "eff_speed",
          "formula": "M/(ES*24)",
          "roundOff": 2,
          "updateTo": "tsd",
          // "withInReplace": {
          //     "replace": [
          //         "M",
          //         "ES"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      },
      {
          "gs": "gsd",
          "ts": "tsd",
          "formula": "ts-gs",
          "roundOff": 2,
          "updateTo": "xsd",
          // "withInReplace": {
          //     "replace": [
          //         "ts",
          //         "gs"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      },
      {
          "ed": "seca_length",
          "es": "eff_speed",
          "formula": "(ed/(es*24))",
          "roundOff": 2,
          "updateTo": "eca_days",
          // "withInReplace": {
          //     "replace": [
          //         "ed",
          //         "es"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      }
  ],
  "totalGSum": {
      "roundOff": 2,
      "to": {
          "fields": [
              "ttl_eca_miles",
              "ttl_eca_days",
              "ttl_speed",
              "ttl_eff_speed",
              "ttl_xsd",
              "ttl_turntime",
              "ttl_exp",
              "total_distance",
              "ttl_pdays",
              "totalt_port_days",
              "ttl_xpd",
              "total_tsd",
              "total_load",
              "total_gsd"
          ],
          "groupKey": "Total Itinerary Summary"
      },
      "from": {
          "fields": [
              "seca_length",
              "eca_days",
              "speed",
              "eff_speed",
              "xsd",
              "turn_time",
              "p_exp",
              "miles",
              "days",
              "t_port_days",
              "xpd",
              "tsd",
              "l_d_qty",
              "gsd"
          ],
          "groupKey": "Port Itinerary"
      }
  }
}

export const voyReletPortSpeedEvent = {
  "formulas": [
      {
          "S": "speed",
          "W": "wf_per",
          "formula": "S*(1-(W/100))",
          "roundOff": 2,
          "updateTo": "eff_speed",
          "withInReplace": {
              "replace": [
                  "S",
                  "W"
              ],
              "groupCode": "portitinerary"
          }
      },
      {
          "M": "miles",
          "S": "speed",
          "formula": "M/(S*24)",
          "roundOff": 2,
          "updateTo": "gsd",
          // "withInReplace": {
          //     "replace": [
          //         "M",
          //         "S"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      },
      {
          "M": "miles",
          "ES": "eff_speed",
          "formula": "M/(ES*24)",
          "roundOff": 2,
          "updateTo": "tsd",
          // "withInReplace": {
          //     "replace": [
          //         "M",
          //         "ES"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      },
      {
          "gs": "gsd",
          "ts": "tsd",
          "formula": "ts-gs",
          "roundOff": 2,
          "updateTo": "xsd",
          // "withInReplace": {
          //     "replace": [
          //         "ts",
          //         "gs"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      },
      {
          "ed": "seca_length",
          "es": "eff_speed",
          "formula": "(ed/(es*24))",
          "roundOff": 2,
          "updateTo": "eca_days",
          // "withInReplace": {
          //     "replace": [
          //         "ed",
          //         "es"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      }
  ],
  "totalGSum": {
      "to": {
          "fields": [
              "total_seca",
              "total_hra",
              "total_gsd",
              "total_tsd",
              "total_distance",
              "totalt_port_days"
          ],
          "groupKey": "Total Itinerary Summary"
      },
      "from": {
          "fields": [
              "seca_length",
              "hra_length",
              "gsd",
              "tsd",
              "miles",
              "t_port_days"
          ],
          "groupKey": "Port Itinerary"
      }
  }
}

export const voyageManagerPortSpeedEvent = {
  "formulas": [
      {
          "S": "speed",
          "W": "wf_per",
          "formula": "S*(1-(W/100))",
          "roundOff": 2,
          "updateTo": "eff_speed",
          // "withInReplace": {
          //     "replace": [
          //         "S",
          //         "W"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      },
      {
          "M": "miles",
          "S": "speed",
          "formula": "M/(S*24)",
          "roundOff": 2,
          "updateTo": "gsd",
          // "withInReplace": {
          //     "replace": [
          //         "M",
          //         "S"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      },
      {
          "M": "miles",
          "ES": "eff_speed",
          "formula": "M/(ES*24)",
          "roundOff": 2,
          "updateTo": "tsd",
          // "withInReplace": {
          //     "replace": [
          //         "M",
          //         "ES"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      },
      {
          "gs": "gsd",
          "ts": "tsd",
          "formula": "ts-gs",
          "roundOff": 2,
          "updateTo": "xsd",
          // "withInReplace": {
          //     "replace": [
          //         "ts",
          //         "gs"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      },
      {
          "ed": "seca_length",
          "es": "eff_speed",
          "formula": "(ed/(es*24))",
          "roundOff": 2,
          "updateTo": "eca_days",
          // "withInReplace": {
          //     "replace": [
          //         "ed",
          //         "es"
          //     ],
          //     "groupCode": "portitinerary"
          // }
      }
  ],
  "totalGSum": {
      "to": {
          "fields": [
              "total_distance",
              "total_seca",
              "total_seca_days",
              "total_gsd",
              "total_tsd",
              "total_xsd",
              "total_lq",
              "total_turntime",
              "totalt_port_days",
              "total_xpd",
              "total_exp",
              "total_port_pdays",
              "total_speed",
              "total_eff_spd"
          ],
          "groupKey": "Total Itinerary Summary"
      },
      "from": {
          "fields": [
              "miles",
              "seca_length",
              "eca_days",
              "gsd",
              "tsd",
              "xsd",
              "l_d_qty",
              "turn_time",
              "days",
              "xpd",
              "p_exp",
              "t_port_days",
              "speed",
              "eff_speed"
          ],
          "groupKey": "Port Itinerary"
      }
  }
}

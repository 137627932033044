import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import configureStore, { history } from "./store/configureStore";
import { Router } from "./components/Root";
import { Provider, useDispatch, useSelector } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import io from "socket.io-client";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { string } from "yup";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { message } from "antd";
import { setAllEmails } from "./actions/emailActions";
import { openNotificationWithIcon } from "./shared";


const cookies = new Cookies();
// import '../src/styles/bootstrap/bootstrap.scss'
// //import '../src/styles/antd.less'
// import "../src/styles/layout.scss"
// import "../src/styles/theme.scss"
// //import "../src/styles/ui.scss"
// //import "../src/styles/vendors.scss"
// import "../src/styles/custom.scss";
const token = localStorage.getItem("oceanToken");
let socketUrl = "";
if (token) {
  let parsedToken = JSON.parse(
    atob(localStorage.getItem("oceanToken").split(".")[1])
  );
  socketUrl = `${process.env.REACT_APP_SOCKETURL}?jti=${parsedToken?.jti}&email=${parsedToken?.email}&company_name=${parsedToken?.company_name}`;
}
// const socketUrl = "http://3.1.49.38:9000";
const socket = io(socketUrl, {
  transports: ["websocket", "polling"],
});
// import "../src/styles/context-menu.scss";

window.socket = socket;
// window.emailTitltCorrectFunction = (initialPart, variableArray) => {
//   let strTest = initialPart

//   variableArray.map((item) => {
//     if (item) {
//       strTest = strTest + ` || ${item}`;
//     }
//   });

//   return strTest
// }

const websocketMail = () => {
  try {
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("oceanToken");
      const userData = token ? JSON.parse(atob(token.split(".")[1])) : null;
      let attemptCount = 0;

      const createWebSocket = () => {
        if (userData?.company_name && token) {
          const socketUrl = `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/${userData.company_name}/?token=${token}`;
          const socket = new WebSocket(socketUrl);
          const groupSocketUrl = `${process.env.REACT_APP_SOCKET_BASE_URL}/ws/group/${userData.company_name}/?token=${token}`;
          const groupSocket = new WebSocket(groupSocketUrl);

          // Event listener for when the connection is opened
          socket.addEventListener("open", () => {
            console.log("WebSocket connection opened");
            attemptCount = 0; // Reset attempt count on successful connection
          });

          // Event listener for incoming messages

          // Event listener for when the connection is closed
          socket.addEventListener("close", (event) => {
            console.log(
              "WebSocket connection closed:",
              event.code,
              event.reason
            );
            attemptCount++;
            // Retry connection after a short delay (e.g., 10 seconds)
            setTimeout(() => {
              console.log(
                `Attempting to reconnect... (Attempt #${attemptCount})`
              );
              if (attemptCount <= 5) {
                createWebSocket(); // Try to reconnect
              } else {
                // openNotificationWithIcon("info", "Websocket connection failed");
              }
            }, 10000);
          });

          // Event listener for errors
          socket.addEventListener("error", (error) => {
            console.error("WebSocket error:", error);
          });

          // for group chat ------------------------------------------------------------------------------------------------------------

          groupSocket.addEventListener("open", () => {
            console.log("WebSocket connection opened");
            attemptCount = 0; // Reset attempt count on successful connection
          });

          // Event listener for incoming messages

          //Event listener for when the connection is closed
          groupSocket.addEventListener("close", (event) => {
            console.log(
              "WebSocket connection closed:",
              event.code,
              event.reason
            );
            attemptCount++;
            // Retry connection after a short delay (e.g., 10 seconds)
            setTimeout(() => {
              console.log(
                `Attempting to reconnect... (Attempt #${attemptCount})`
              );
              if (attemptCount <= 5) {
                createWebSocket(); // Try to reconnect
              }
            }, 10000);
          });

          // Event listener for errors
          groupSocket.addEventListener("error", (error) => {
            console.error("WebSocket error:", error);
          });

          window.mySocketMail = socket; // Store the socket globally (if needed)
          window.myGroupSocket = groupSocket;
        }
      };

      createWebSocket(); // Create the WebSocket connection
    }
  } catch (error) {
    message.error("Error in socket connection");
    console.error(error);
  }
};

websocketMail();

window.corrector = (inputString) => {
  const partArray = inputString.split("||");
  let initialStr = "";

  if (Array.isArray(partArray[0]) && partArray[0].length > 0) {
    partArray[0][0] =
      partArray[0][0].charAt(0).toUpperCase() + partArray[0][0].slice(1);
    initialStr = partArray[0].join("") + "Invoice";
  } else if (typeof partArray[0] === "string" && partArray[0].trim() !== "") {
    partArray[0] = partArray[0].replace(/_/g, " ");
    partArray[0] = partArray[0].replace(/\b\w/g, (firstLetter) =>
      firstLetter.toUpperCase()
    );

    if (/\w\w+/.test(partArray[0])) {
      initialStr = partArray[0].replace(/([a-z])([A-Z])/g, "$1 $2") + " ";
    } else {
      initialStr = partArray[0] + " ";
    }
  }

  partArray.slice(1).forEach((item) => {
    if (item !== "undefined") {
      item = item.replace(/_/g, " ");
      item = item.replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase());
      initialStr = initialStr + ` || ${item}`;
    }
  });

  return initialStr;
};

window.clickedTab = "Analytical Dashboard";
if (localStorage.getItem("lastClickedItem") === null) {
  localStorage.setItem("lastClickedItem", "Analytical Dashboard");
}

const store = configureStore();

let name = "";
if (localStorage.getItem("oceanToken")) {
  name = JSON.parse(
    atob(localStorage.getItem("oceanToken").split(".")[1])
  ).first_name;
}

if (name) {
  window.userName = name.charAt(0).toUpperCase() + name.slice(1);
}
window.emitNotification = (userInput) => {
  socket.emit("postNotification", userInput);
};

window.notificationMessageCorrector = (inputString) => {
  const stringArray = inputString.split(",");
  let finalString = "";
  stringArray.map((item) => {
    if (!item.includes("undefined")) {
      finalString = finalString + item;
    }
  });
  return finalString;
};

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  // <StrictMode>

  <GoogleOAuthProvider clientId="380315452391-tbvplprrntar187htkon5i03kl21i77j.apps.googleusercontent.com">
    <Provider store={store}>
      <RouterProvider router={Router} history={history} />
    </Provider>
  </GoogleOAuthProvider>
);
serviceWorker.unregister();

// vm logs

const storeLogs = [];
const logKeys = {};

window.logTracker = (obj, row) => {
  if (obj.currentValue.includes("NaN")) {
    obj.currentValue = "";
  }

  FormSubmitTracker();

  obj["popup"] = window.getModelTittle();
  obj["name"] = obj.name + "@" + obj.popup;
  if (obj.name in logKeys) {
    storeLogs[logKeys[obj.name]].currentValue = obj.currentValue;
    // storeLogs[logKeys[obj.name]].previousValue = obj.previousValue;
  } else {
    logKeys[obj.name] = storeLogs.length;
    obj.chnageBy = {
      userName: localStorage.getItem("currentUser"), //getCookie("username"),
      userId: getCookie("user_id"),
      userType: getCookie("user_type"),
      pass: getCookie("encpass"),
    };
    obj.userName = localStorage.getItem("currentUser"); //getCookie("username");
    obj.timeStamp = new Date();
    if (!obj.previousValue) {
      obj.previousValue = obj.previousValue || "";
    }
    // obj.previousValue = obj.previousValue || "";
    obj.VMID = getVmID();
    storeLogs.push(obj);
  }
};
var IsClicked = false;

const OnSubmitFormHandler = () => {
  const location = window.location.href;
  //save log only for voyage-manager
  if (!location.includes("/voyage-manager/")) {
    return;
  }
  // console.log(getLogsFeildsofPopUp())
  // alert("updating logs")
  submitToLogsServer();
  IsClicked = true;
};

const loginterCepter = () => {
  if (IsClicked) {
    IsClicked = false;
  }
  submitToLogsServer();
};

const submitToLogsServer = async () => {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ logs: storeLogs, keys: logKeys }),
    };
    // alert("sengin to the server ")
    const response = await fetch(
      `${process.env.REACT_APP_URL}v1/log/saveVmlogs`,
      requestOptions
    );
    const data = await response.json();
    // for (let i = 0; i < storeLogs.length; i++) {
    //   storeLogs.pop();
    // }
    storeLogs.length = 0;
    Object.keys(logKeys).forEach((key) => {
      delete logKeys[key];
    });
  } catch (err) {
    console.log("err", err);
  }
};

const getLogsFeildsofPopUp = () => {
  let title = window.getModelTittle();
  let logs = [];
  for (let log of storeLogs) {
    if (log.popup == title) {
      logs.push(log);
    }
  }
  return logs;
};

window.getModelTittle = () => {
  let modelCollection = document.getElementsByClassName("ant-modal-title");
  // console.log("model Collection ",modelCollection)
  if (modelCollection.length > 0) {
    return modelCollection[modelCollection.length - 1].textContent;
  }
  return "";
};

window.fetchRowName = (record, row) => {
  if ("port_id" in record) {
    return row.name + "@" + record.port_id;
  }
  if ("cargo_contract_id" in record) {
    return row.name + "@" + record.cargo_contract_id;
  }
  return row.name;
};

window.AbstractInputLogTracker = (row, evt, value, record) => {
  try {
    let obj = {
      name: window.fetchRowName(record, row),
      previousValue: value,
      currentValue: evt.target.value,
      type: "text",
      groupName: row.group_name,
    };
    window.logTracker(obj);
  } catch (e) {
    // console.log("intput Error" ,  e)
  }
};

window.corrector = (inputString) => {
  const partArray = inputString.split("||");
  let initialStr = "";

  if (Array.isArray(partArray[0]) && partArray[0].length > 0) {
    partArray[0][0] =
      partArray[0][0].charAt(0).toUpperCase() + partArray[0][0].slice(1);
    initialStr = partArray[0].join("") + "Invoice";
  } else if (typeof partArray[0] === "string" && partArray[0].trim() !== "") {
    partArray[0] = partArray[0].replace(/_/g, " ");
    partArray[0] = partArray[0].replace(/\b\w/g, (firstLetter) =>
      firstLetter.toUpperCase()
    );

    if (/\w\w+/.test(partArray[0])) {
      initialStr = partArray[0].replace(/([a-z])([A-Z])/g, "$1 $2") + " ";
    } else {
      initialStr = partArray[0] + " ";
    }
  }

  partArray.slice(1).forEach((item) => {
    if (item !== "undefined") {
      item = item.replace(/_/g, " ");
      item = item.replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase());
      initialStr = initialStr + ` || ${item}`;
    }
  });

  return initialStr;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}:${hours}:${minutes}`;
};

window.AbstractDateLogtracker = (row, evt, value, record) => {
  const previousDate = value
    ? formatDate(value)
    : formatDate(new Date().toString());
  const currentDate = formatDate(evt);

  try {
    let obj = {
      name: window.fetchRowName(record, row),
      // previousValue: value._i,
      previousValue: previousDate.toString(),
      //currentValue: evt._i,
      currentValue: currentDate.toString(),
      type: "date",
      groupName: row.group_name,
    };
    window.logTracker(obj);
  } catch (e) {
    console.log("intput Error", e);
  }
};

// 'username=theoceann; user_type=1; user_id=USER-00011; encpass=dGVzdEAxMjM%3D'

function getCookie(cookieName) {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    const [name, value] = cookie.split("=");
    if (name === cookieName) {
      return value;
    }
  }
  return null;
}

function getVmID() {
  const url = window.location.href;

  // Regular expression to extract text after '/voyage-manager/'
  const regex = /\/voyage-manager\/(.*)/;
  const match = url.match(regex);

  // Extracted text
  const extractedText = match ? match[1] : null;

  // console.log(extractedText);
  return extractedText;
}

const FormSubmitTracker = () => {
  var elements = document.getElementsByClassName("anticon anticon-save");

  // Loop through each element
  for (let i = 0; i < elements.length; i++) {
    // Add click event listener to each element
    // console.log("submit form handler")

    elements[i].addEventListener("click", OnSubmitFormHandler);
  }
};

// Override the XMLHttpRequest object
const originalXHR = window.XMLHttpRequest;

function newXHR() {
  const xhr = new originalXHR();

  // Override the open method to log information about the XHR request
  const originalOpen = xhr.open;
  xhr.open = function (method, url, async, user, password) {
    return originalOpen.call(this, method, url, async, user, password);
  };

  // Override the onreadystatechange event to log when the request completes
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      if (xhr.status === 401) {
        // window.location.href='/#/user/login'
        window.myNavigator("/#/user/login");
      }
      if (xhr.status === 403) {
        Swal.fire({
          icon: "error",
          title: "Access Denied",
          text: "You do not have permission to access this resource.",
        });
        // alert("ex")
        // window.myNavigator("/access-denied",{replace:true})
        // return <h1>Hellow</h1>
      }

      // console.log('XHR Response Text:', xhr.responseText);
    }
  };
  return xhr;
}

window.XMLHttpRequest = newXHR;
//    NETWORK INTERCEPTOR FOR THIS TAB WITH ALL MONITERLOGS

// Override the XMLHttpRequest object

import React from "react";
import "./AiButton.scss";

const DynamicAiBtn = ({ text }) => {
  return (
    <button type="button" className="ai-btn">
      <strong>{text}</strong>
      <div id="container-stars">
        <div id="stars"></div>
      </div>

      <div id="glow">
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    </button>
  );
};

export default DynamicAiBtn;

export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const TOGGLE_BOXED_LAYOUT = 'TOGGLE_BOXED_LAYOUT';
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const TOGGLE_OFFCANVAS_NAV = 'TOGGLE_OFFCANVAS_NAV';
export const TOGGLE_FIXED_SIDENAV = 'TOGGLE_FIXED_SIDENAV';
export const TOGGLE_FIXED_HEADER = 'TOGGLE_FIXED_HEADER';
export const CHANGE_SIDENAV_WIDTH = 'CHANGE_SIDENAV_WIDTH';
export const TOGGLE_OFFCANVAS_MOBILE_NAV = 'TOGGLE_OFFCANVAS_MOBILE_NAV';
export const CHANGE_COLOR_OPTION = 'CHANGE_COLOR_OPTION';
export const CHANGE_THEME = 'CHANGE_THEME';
export const SET_CHAT_DATA = 'SET_CHAT_DATA';
export const RESET_CHAT_DATA = 'RESET_CHAT_DATA';

//Mail Types

export const COMPOSE_NEW_MAIL = "COMPOSE_NEW_MAIL";
export const NOTIFICATION_CUSTOM = "NOTIFICATION_CUSTOM";
export const RESET_STATE = "RESET_STATE";
export const DELETE_COMPOSE_MAIL = "DELETE_COMPOSE_MAIL";
export const UPDATE_COMPOSE_MAIL_FIELD = "UPDATE_COMPOSE_MAIL_FIELD";
export const UPDATE_COMPOSE_MAIL_ID = "UPDATE_COMPOSE_MAIL_ID";
export const TOGGLE_COMPOSE_MAIL_MIN = 'TOGGLE_COMPOSE_MAIL_MIN';
export const TOGGLE_COMPOSE_MAIL_MAX = 'TOGGLE_COMPOSE_MAIL_MAX';

// Diffee types of mail List
export const FETCH_ALL_EMAILS = 'FETCH_ALL_EMAILS';
export const RESTRICTION_DATA = 'RESTRICTION_DATA';
export const VM_RESTRICTION_DATA = 'VM_RESTRICTION_DATA';
export const FETCH_GROUP_NAME = 'FETCH_GROUP_NAME';
export const SHOW_AI_MAILS = 'SHOW_AI_MAILS';
export const RESIZE_MAIL = "RESIZE_MAIL";
export const FETCH_ALL_GROUP_EMAILS = 'FETCH_ALL_GROUP_EMAILS';
export const FETCH_SENT_EMAILS = 'FETCH_SENT_EMAILS';
export const FETCH_DRAFT_EMAILS = 'FETCH_DRAFT_EMAILS';
export const FETCH_ARCHIVED_EMAILS = 'FETCH_ARCHIVED_EMAILS';
export const FETCH_BIN_EMAILS = 'FETCH_BIN_EMAILS';
export const SEARCH_CHOICE = 'SEARCH_CHOICE';
export const SEARCH_QUERY = 'SEARCH_QUERY';
export const FETCH_IMP_EMAILS = 'FETCH_IMP_EMAILS';
export const FETCH_SPAM_EMAILS = 'FETCH_SPAM_EMAILS';
export const ALL_MAIL_COUNT = 'ALL_MAIL_COUNT';
export const ALL_ACCOUNT_MAIL = 'ALL_ACCOUNT_MAIL';
export const FILTER_DATA = 'FILTER_DATA';
export const HAS_MORE = 'HAS_MORE';
export const MAIL_CONTAINER = 'MAIL_CONTAINER';
export const FETCH_EMAIL_LABELS = 'FETCH_EMAIL_LABELS';
export const FETCH_EMAIL_GROUPS = 'FETCH_EMAIL_GROUPS';
export const FETCH_EMAIL_FOLDERS = 'FETCH_EMAIL_FOLDERS';
export const FETCH_LABELS_EMAIL = 'FETCH_LABELS_EMAIL';
export const FETCH_FOLDER_EMAILS = 'FETCH_FOLDER_EMAILS';
export const FETCH_IMPORTED_EMAILS_INBOX = 'FETCH_IMPORTED_EMAILS_INBOX';

export const FETCH_CARGO_CHILD = 'FETCH_CARGO_CHILD';
export const FETCH_TONNAGE_CHILD = 'FETCH_TONNAGE_CHILD';
export const FETCH_IMPORTED_MAILS = 'FETCH_IMPORTED_MAILS';

// Difference types of cargo List
export const TONNAGE_LIST = 'TONNAGE_LIST';
export const CARGO_LIST = 'CARGO_LIST';


export const IS_SEARCHING = 'IS_SEARCHING';
export const IS_FILTERING = 'IS_FILTERING';
export const FILTER_PAYLOAD = 'FILTER_PAYLOAD';
export const GET_USER_SIGNATURE = 'GET_USER_SIGNATURE';


export const SET_CURRENT_VESSEL_NAME = 'SET_CURRENT_VESSEL_NAME';
export const SET_REPLY_SUGGESTIONS = 'SET_REPLY_SUGGESTIONS';
export const SET_REPLY_ORIGINAL_BODY = 'SET_REPLY_ORIGINAL_BODY';
export const SHOW_TAG_DATA = 'SHOW_TAG_DATA';
export const RIGHTBAR_NOON="RIGHTBAR_NOON";

import * as types from "../constants/actionTypes";

const initialState = {
  inbox: [],
  draft: [],
  composeMails: [],
  allEmailCount: [],
  AllAccountMail: [],
  isSearching: false,
  isFiltering: false,
  searchChoice: "Everything",
  searchQuery: "",
  filterData: [],
  filterPayload: {},
  hasMore: false,
  mailContainer: [],
  // signature: `<br><br><br><br><br><br><br><br><br><br><br>Best regards,<br><b>Your Name</b><br>Your Company`,
  signature: "",
  // Mail All Listings
  allEmails: [],
  allgroupmails: [],
  draftList: [],
  sentList: [],
  importantList: [],
  spamList: [],
  archivedList: [],
  binList: [],
  labelMailList: [],
  folderMailList: [],
  importedMailList: [],
  labels: [],
  folders: [],
  groups: [],
  cargoChild: [],
  tonnageChild: [],
  tonnageListData: [],
  cargolistdata: [],
  importedMails: [],
  resizeMail: null,
  showAiMails: false,
  groupName: "",
  // vesselName: "",
  vesselInfoDrawer: {
    vesselName: "",
    isVisible: false,
  },
  replySuggestions: [],
  replyOriginalBodyText: "",
  restrictionData: [],
  vmRestrictionData: {},
  showtagdata: [],
  openRightBarNoon: false,
};

let mewComposeMailState = {
  composedData: {
    to: [],
    cc: [],
    bcc: [],
    body: "",
    subject: "",
    attachments: [],
    //   editorStateKey: EditorState.createEmpty(),
    HTMLBody: undefined,
    owner_email: "",
    ThreadID: "",
    GmailMessageId: "",
    listOfFile: [],
    totalSize: 0,
  },
  max: false,
  min: false,
};
const emailReducer = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case types.COMPOSE_NEW_MAIL:
      return {
        ...state,
        composeMails: [...state.composeMails, action.payload],
      };
    case types.RESET_STATE:
      return initialState;
    case types.DELETE_COMPOSE_MAIL:
      return {
        ...state,
        composeMails: state.composeMails.filter(
          (item) => item.composedData.uid !== action.payload
        ),
      };
    case types.UPDATE_COMPOSE_MAIL_FIELD:
      return {
        ...state,
        composeMails: state.composeMails.map((item) =>
          item.composedData._id === action.payload.id
            ? {
                ...item,
                composedData: {
                  ...item.composedData,
                  [action.payload.field]: action.payload.value,
                },
              }
            : item
        ),
      };
    case types.UPDATE_COMPOSE_MAIL_ID:
      return {
        ...state,
        composeMails: state.composeMails.map((item) =>
          item.composedData.uid === action.payload.uid
            ? {
                ...item,
                composedData: {
                  ...item.composedData,
                  [action.payload.field]: action.payload.value,
                },
              }
            : item
        ),
      };
    case types.TOGGLE_COMPOSE_MAIL_MIN:
      return {
        ...state,
        composeMails: state.composeMails.map((item) =>
          item.composedData.uid === action.payload
            ? {
                ...item,
                min: !item.min,
                max: false,
              }
            : item
        ),
      };
    case types.GET_USER_SIGNATURE:
      return {
        ...state,
        signature: action.payload,
      };
    case types.TOGGLE_COMPOSE_MAIL_MAX:
      return {
        ...state,
        composeMails: state.composeMails.map((item) =>
          item.composedData.uid === action.payload
            ? {
                ...item,
                max: !item.max,
                min: false,
              }
            : item
        ),
      };

    case types.FETCH_ALL_EMAILS:
      return {
        ...state,
        allEmails: action.payload.emails,
        // allEmailCount: action.payload.totalCount,
      };
    case types.FETCH_ALL_GROUP_EMAILS:
      // console.log("action payload",action.payload)
      return {
        ...state,
        allgroupmails: action.payload,
        // allEmailCount: action.payload.totalCount,
      };

    case types.FETCH_SENT_EMAILS:
      return {
        ...state,
        sentList: action.payload.emails,
      };

    case types.FETCH_DRAFT_EMAILS:
      return {
        ...state,
        draftList: action.payload.emails,
        // draftList: [...state.draftList, ...action.payload.emails],
      };

    case types.FETCH_BIN_EMAILS:
      return {
        ...state,
        binList: action.payload.emails,
      };
    
    case types.SEARCH_CHOICE:
      return {
        ...state,
        searchChoice: action.payload,
      };
      
    case types.SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
      };

    case types.RESTRICTION_DATA:
      return {
        ...state,
        restrictionData: action.payload,
      };
    case types.VM_RESTRICTION_DATA:
      return {
        ...state,
        vmRestrictionData: action.payload,
      };
    case types.NOTIFICATION_CUSTOM:
      return {
        ...state,
        customNotification: action.payload,
      };

    case types.FETCH_IMP_EMAILS:
      return {
        ...state,
        importantList: action.payload.emails,
      };
    case types.RESIZE_MAIL:
      return {
        ...state,
        resizeMail: action.payload,
      };
    case types.FETCH_SPAM_EMAILS:
      return {
        ...state,
        spamList: action.payload.emails,
      };
    case types.FETCH_LABELS_EMAIL:
      return {
        ...state,
        labelMailList: action.payload.emails,
      };
    case types.FETCH_GROUP_NAME:
      // console.log("action.payload",action.payload)
      return {
        ...state,
        groupName: action.payload,
      };
    case types.SHOW_AI_MAILS:
      // console.log("action.payload",action.payload)
      return {
        ...state,
        showAiMails: action.payload,
      };
    case types.FETCH_IMPORTED_MAILS:
      return {
        ...state,
        importedMails: action.payload,
      };
    case types.FETCH_FOLDER_EMAILS:
      return {
        ...state,
        folderMailList: action.payload.emails,
      };
    case types.FETCH_IMPORTED_EMAILS_INBOX:
      return {
        ...state,
        importedMailList: action.payload.emails,
      };
    case types.FETCH_EMAIL_FOLDERS:
      return {
        ...state,
        folders: action.payload,
      };
    case types.FETCH_EMAIL_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case types.FETCH_CARGO_CHILD:
      return {
        ...state,
        cargoChild: action.payload,
      };
    case types.FETCH_TONNAGE_CHILD:
      // console.log('data tyming', action.payload)
      return {
        ...state,
        tonnageChild: action.payload,
      };

    case types.FETCH_ARCHIVED_EMAILS:
      return {
        ...state,
        archivedList: action.payload.emails,
      };
    case types.FETCH_EMAIL_LABELS:
      return {
        ...state,
        labels: action.payload,
      };

    case types.IS_FILTERING:
      return {
        ...state,
        isFiltering: action.payload,
      };
    case types.ALL_MAIL_COUNT:
      return {
        ...state,
        allEmailCount: action.payload,
      };
    case types.ALL_ACCOUNT_MAIL:
      return {
        ...state,
        AllAccountMail: action.payload,
      };
    case types.FILTER_DATA:
      return {
        ...state,
        // filterData: [...state.filterData, ...action.payload.emails],
        filterData: [...action.payload.emails],
      };
    case types.FILTER_PAYLOAD:
      return {
        ...state,
        filterPayload: action.payload,
      };

    case types.HAS_MORE:
      // console.log(action.payload,"--->>>")
      return {
        ...state,
        hasMore: action.payload,
      };
    case types.MAIL_CONTAINER:
      return {
        ...state,
        mailContainer: action.payload,
      };
    case types.SET_CURRENT_VESSEL_NAME:
      return {
        ...state,
        // currentVessel: action.payload,
        vesselInfoDrawer: {
          vesselName: action.payload?.vesselName,
          isVisible: action.payload?.isVisible,
        },
      };
    case types.SET_REPLY_SUGGESTIONS:
      return {
        ...state,
        replySuggestions: action.payload,
      };
    case types.SET_REPLY_ORIGINAL_BODY:
      return {
        ...state,
        replyOriginalBodyText: action.payload,
      };
    case types.TONNAGE_LIST:
      return {
        ...state,
        tonnageListData: [...state.tonnageListData, ...action.payload],
      };
    case types.CARGO_LIST:
      return {
        ...state,
        cargolistdata: [...state.cargolistdata, ...action.payload],
      };
    case types.SHOW_TAG_DATA:
      return {
        ...state,
        showtagdata: [action.payload],
      };

    case types.RIGHTBAR_NOON:
      return {
        ...state,
        openRightBarNoon: action.payload,
      };

    default:
      return state;
  }
};
export default emailReducer;

import React, { Component } from "react";
import { Dropdown, Menu, Modal, Spin } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import html2canvas from "html2canvas";
import URL_WITH_VERSION from "../../shared";
import jsPDF from "jspdf";
import Email from "../../components/Email";

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formReportdata: this.props.data || {},
    };
  }
  render() {
    const { formReportdata } = this.state;
    return (
      <article className="article toolbaruiWrapper">
        <div className="box box-default" id="divToPrint">
          <div className="box-body">
          <div className="row laytime-heading">
              <div className="col-12 ">
                <h4 className="font-weight-bold tc-sub-header"> VC (PURCHASE) REPORT</h4>

              </div>
            </div>
         
            <div className="invoice-inner-download mt-3">
              <div className="row">
                <div className="col-12 text-center">
                  <img
                    className="reportlogo"
                    src={formReportdata.logo}
                    alt="No Img"
                    crossOrigin="anonymous"
                  />
                  <p className="sub-title m-0">{formReportdata.full_name}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="text-center invoice-top-address">
                  <p>{formReportdata.address}</p>
                </div>
              </div>
            </div>

            <div className="row p10">
              <div className="col-md-12 tc-table">
                <table className="table table-bordered table-invoice-report-colum">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">VC-Purchase ID :</td>
                      <td className="">{formReportdata.vc_purchase_id}</td>

                      <td className="font-weight-bold">Cargo Contract ID :</td>
                      <td className="">{formReportdata.cargo_id_name}</td>

                      <td className="font-weight-bold">Contract Type :</td>
                      <td className="">{formReportdata.contract_type_name}</td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">COA-VC Purch ID :</td>
                      <td className="">{formReportdata.coa_vc_purchase_id}</td>

                      <td className="font-weight-bold">My Company/LOB :</td>
                      <td className="">
                        {formReportdata.my_company_name} /{" "}
                        {formReportdata.lob_name}
                      </td>

                      <td className="font-weight-bold">Status :</td>
                      <td className="">{formReportdata.c_status_name}</td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Cargo Name :</td>
                      <td className="">{formReportdata.cargo_name}</td>

                      <td className="font-weight-bold">Invoice By :</td>
                      <td className="">{formReportdata.invoice_by_name}</td>

                      <td className="font-weight-bold">Trade Area :</td>
                      <td className="">{formReportdata.trade_area_name}</td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Cargo Group :</td>
                      <td className="">{formReportdata.cargo_group_name}</td>

                      <td className="font-weight-bold">Min Inv. Qty/Unit :</td>
                      <td className="">
                        {formReportdata.min_inv_qty} /{" "}
                        {formReportdata.min_inv_unit_name}
                      </td>

                      <td className="font-weight-bold">Voyage No. :</td>
                      <td className="">{formReportdata.voyage_no}</td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Owner :</td>
                      <td className="">{formReportdata.charterer_name}</td>

                      <td className="font-weight-bold">BL Qty/Unit :</td>
                      <td className="">
                        {formReportdata.bl_qty
                          ? parseFloat(
                              formReportdata.bl_qty.replaceAll(",", "")
                            ).toFixed(2)
                          : "N/A"}{" "}
                        / {formReportdata.bl_unit_name}
                      </td>

                      <td className="font-weight-bold">SF(M3/MT, Ft3/MT) :</td>
                      <td className="">
                        {formReportdata.stow_m3_mt
                          ? parseFloat(
                              formReportdata.stow_m3_mt.replaceAll(",", "")
                            ).toFixed(2)
                          : "N/A"}{" "}
                        /{" "}
                        {formReportdata.stow_ft3_mt
                          ? parseFloat(
                              formReportdata.stow_ft3_mt.replaceAll(",", "")
                            ).toFixed(2)
                          : "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">CP Qty/Unit :</td>
                      <td className="">
                        {formReportdata.cp_qty} / {formReportdata.cp_unit_name}
                      </td>

                      <td className="font-weight-bold">Currency :</td>
                      <td className="">{formReportdata.currency_name}</td>

                      <td className="font-weight-bold">Load/Disch Term :</td>
                      <td className="">{formReportdata.load_term_name}</td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Min/Max Qty :</td>
                      <td className="">
                        {formReportdata.min_qty
                          ? parseFloat(
                              formReportdata.min_qty.replaceAll(",", "")
                            ).toFixed(2)
                          : "N/A"}{" "}
                        /{" "}
                        {formReportdata.max_qty
                          ? parseFloat(
                              formReportdata.max_qty.replaceAll(",", "")
                            ).toFixed(2)
                          : "N/A"}
                      </td>

                      <td className="font-weight-bold">Freight Bill Via :</td>
                      <td className="">
                        {formReportdata.freight_bill_via_name}
                      </td>

                      <td className="font-weight-bold">Load/Disch $ :</td>
                      <td className="">
                        {formReportdata.load_disch_currency_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">CP Date/Place :</td>
                      <td className="">
                        {formReportdata.cp_date} / {formReportdata.cp_place}
                      </td>

                      <td className="font-weight-bold">Freight Type :</td>
                      <td className="">{formReportdata.freight_type_name}</td>

                      <td className="font-weight-bold">Load Dem/Dep :</td>
                      <td className="">
                        {formReportdata.load_dem
                          ? parseFloat(
                              formReportdata.load_dem.replaceAll(",", "")
                            ).toFixed(2)
                          : "N/A"}{" "}
                        /{" "}
                        {formReportdata.load_dep
                          ? parseFloat(
                              formReportdata.load_dep.replaceAll(",", "")
                            ).toFixed(2)
                          : "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Laycan From/To :</td>
                      <td className="">
                        {formReportdata.laycan_from} /{" "}
                        {formReportdata.laycan_to}
                      </td>

                      <td className="font-weight-bold">Freight Rate :</td>
                      <td className="">{formReportdata.freight_rate}</td>

                      <td className="font-weight-bold">Discharge Dem/Dep :</td>
                      <td className="">
                        {formReportdata.discharge_dem} /{" "}
                        {formReportdata.discharge_dep}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">CP Draft (M) :</td>
                      <td className="">{formReportdata.cp_draft_m}</td>

                      <td className="font-weight-bold">Nominated Vessel :</td>
                      <td className="">
                        {formReportdata.nomiated_vessel_name}
                      </td>

                      <td className="font-weight-bold">Fixed By :</td>
                      <td className="">{formReportdata.fixed_by}</td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Booking No. :</td>
                      <td className="">{formReportdata.booking_no}</td>

                      <td className="font-weight-bold">Vessel Type :</td>
                      <td className="">{formReportdata.vessel_type_name}</td>

                      <td className="font-weight-bold">Vessel Sub Type :</td>
                      <td className="">
                        {formReportdata.vessel_sub_type_name ?? "N/A"}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table table-invoice-report-colum">
                  <tbody>
                    <td className="border-0">
                      <h4 className="font-weight-bold">Load Options</h4>
                      <table className="table border-table table-invoice-report-colum">
                        <thead>
                          <tr className="HeaderBoxText">
                            <th>Port/Area</th>
                            <th>L/D Rates</th>
                            <th>L/H Rates</th>
                            <th>Terms</th>
                            <th>TT</th>
                            <th>PExp</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formReportdata.loadoptions &&
                          formReportdata.loadoptions &&
                          formReportdata.loadoptions.length > 0
                            ? formReportdata.loadoptions.map((e, idx) => {
                                return (
                                  <>
                                    <tr key={idx}>
                                      <td>{e.port_area_name}</td>
                                      <td>{e.l_d_rates}</td>
                                      <td>{e.ld_ru}</td>
                                      <td>{e.ld_terms_name}</td>
                                      <td>{e.ld_tt}</td>
                                      <td>{e.pexp}</td>
                                    </tr>
                                  </>
                                );
                              })
                            : undefined}
                        </tbody>
                      </table>
                    </td>

                    <td className="border-0">
                      <h4 className="font-weight-bold">Discharge Options</h4>
                      <table className="table border-table table-invoice-report-colum">
                        <thead>
                          <tr className="HeaderBoxText">
                            <th>Port/Area</th>
                            <th>L/D Rates</th>
                            <th>L/H Rates</th>
                            <th>Terms</th>
                            <th>TT</th>
                            <th>PExp</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formReportdata.dischargeoptions &&
                          formReportdata.dischargeoptions &&
                          formReportdata.dischargeoptions.length > 0
                            ? formReportdata.dischargeoptions.map((e, idx) => {
                                return (
                                  <>
                                    <tr key={idx}>
                                      <td>{e.port_area_name}</td>
                                      <td>{e.l_d_rates}</td>
                                      <td>{e.ld_ru}</td>
                                      <td>{e.ld_terms_name}</td>
                                      <td>{e.ld_tt}</td>
                                      <td>{e.pexp}</td>
                                    </tr>
                                  </>
                                );
                              })
                            : undefined}
                        </tbody>
                      </table>
                    </td>
                  </tbody>
                </table>

                <h4 className="font-weight-bold">Cargo Itinerary</h4>

                <table className="table border-table table-invoice-report-colum">
                  <thead>
                    <tr className="HeaderBoxText">
                      <th>Port</th>
                      <th>F</th>
                      <th>Cargo</th>
                      <th>Qty</th>
                      <th>Unit</th>
                      <th>L/D Rate (D)</th>
                      <th>L/H Rate (H)</th>
                      <th>Terms</th>
                      <th>TT</th>
                      <th>P Days</th>
                      <th>Ext Days</th>
                      <th>Port Exp</th>
                      <th>Other Exp. Draft</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata.itineraryoptions &&
                    formReportdata.itineraryoptions &&
                    formReportdata.itineraryoptions.length > 0
                      ? formReportdata.itineraryoptions.map((e, idx) => {
                          return (
                            <>
                              <tr key={idx}>
                                <td>{e.port_area_name}</td>
                                <td>{e.io_f_name}</td>
                                <td>{e.cargo_name}</td>
                                <td>
                                  {parseFloat(
                                    e.io_qty.replaceAll(",", "")
                                  ).toFixed(2)}
                                </td>
                                <td>{e.unit_name}</td>
                                <td>
                                  {parseFloat(
                                    e.l_d_rate.replaceAll(",", "")
                                  ).toFixed(2)}
                                </td>
                                <td>
                                  {parseFloat(
                                    e.l_d_rate_h.replaceAll(",", "")
                                  ).toFixed(2)}
                                </td>
                                <td>{e.io_terms_name}</td>
                                <td>{e.io_tt}</td>
                                <td>{e.io_pd}</td>
                                <td>{e.ext_days}</td>
                                <td>{e.port_exp}</td>
                                <td>{e.o_exp}</td>
                              </tr>
                            </>
                          );
                        })
                      : undefined}
                  </tbody>
                </table>

                <h4 className="font-weight-bold">Cargo Pricing & Billing</h4>
                <table className="table border-table table-invoice-report-colum">
                  <thead>
                    <tr className="HeaderBoxText">
                      <th>Load Port(S)</th>
                      <th>Discharge Port(S)</th>
                      <th>Cargo</th>
                      <th>Frt Type</th>
                      <th>Amount</th>
                      <th>Table</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata &&
                      formReportdata["--"].length > 0 &&
                      formReportdata["--"].map((val, index) => {
                        return (
                          <tr>
                            <td>{val.load_ports_name}</td>
                            <td>{val.ldischarge_ports_name}</td>
                            <td>{val.cargo_name1}</td>
                            <td>{val.freight_type_name}</td>
                            <td>{val.amount}</td>
                            <td>{val.p_table}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                <table className="table table-invoice-report-colum">
                  <tbody>
                    <td className="border-0">
                      <h4 className="font-weight-bold">Broker</h4>
                      <table className="table border-table table-invoice-report-colum">
                        <thead>
                          <tr className="HeaderBoxText">
                            <th>Broker</th>
                            <th>Rate Amount</th>
                            <th>Rate Type</th>
                            <th>Pay Method</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formReportdata &&
                            formReportdata.broker.length > 0 &&
                            formReportdata.broker.map((val, index) => {
                              return (
                                <tr key={index}>
                                  <td>{val.broker_name}</td>
                                  <td>{val.amount}</td>
                                  <td>{val.rate_type_name}</td>
                                  <td>{val.pay_method_name}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </td>

                    <td className="border-0">
                      <h4 className="font-weight-bold">Extra Freight Term</h4>
                      <table className="table border-table table-invoice-report-colum">
                        <thead>
                          <tr className="HeaderBoxText">
                            <th>Extra Freight Term</th>
                            <th>Code</th>

                            <th>Rate/Lump</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formReportdata &&
                            formReportdata.extrafreightterm.length > 0 &&
                            formReportdata.extrafreightterm.map(
                              (val, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{val.extra_freight_term_name}</td>
                                    <td>{val.code}</td>

                                    <td>{val.rate_lump}</td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </td>
                  </tbody>
                </table>

                <h4 className="font-weight-bold">
                  Billing and Banking details
                </h4>
                <table className="table table-bordered table-invoice-report-colum">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Remit Bank :</td>
                      {formReportdata.billingandbankingdetails &&
                      formReportdata.billingandbankingdetails ? (
                        <td className="">
                          {
                            formReportdata.billingandbankingdetails
                              .remittance_bank_name
                          }
                        </td>
                      ) : undefined}
                      <td className="font-weight-bold">Account No :</td>
                      {formReportdata.billingandbankingdetails &&
                      formReportdata.billingandbankingdetails ? (
                        <td className="">
                          {formReportdata.billingandbankingdetails.account_no}
                        </td>
                      ) : undefined}
                      <td className="font-weight-bold">1st Invoice(%) :</td>
                      {/* {formReportdata.billingandbankingdetails && formReportdata.billingandbankingdetails ? (

                        <td className="">{parseFloat(formReportdata.billingandbankingdetails.inv_per.replaceAll(",","")).toFixed(2)}</td>
                      ) : undefined
                      } */}

                      {formReportdata.billingandbankingdetails &&
                      formReportdata.billingandbankingdetails.inv_per ? (
                        <td className="">
                          {parseFloat(
                            formReportdata.billingandbankingdetails.inv_per.replaceAll(
                              ",",
                              ""
                            )
                          ).toFixed(2)}
                        </td>
                      ) : undefined}
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Balance(%) :</td>
                      {/* {formReportdata.billingandbankingdetails && formReportdata.billingandbankingdetails ? (

                        <td className="">{parseFloat(formReportdata.billingandbankingdetails.bal_per.replaceAll(",","")).toFixed(2)}</td>
                      ) : undefined
                      } */}

                      {formReportdata.billingandbankingdetails &&
                      formReportdata.billingandbankingdetails.bal_per !==
                        undefined ? (
                        <td className="">
                          {parseFloat(
                            formReportdata.billingandbankingdetails.bal_per.replaceAll(
                              ",",
                              ""
                            )
                          ).toFixed(2)}
                        </td>
                      ) : undefined}

                      <td className="font-weight-bold">Payment Term :</td>
                      {formReportdata.billingandbankingdetails &&
                      formReportdata.billingandbankingdetails ? (
                        <td className="">
                          {
                            formReportdata.billingandbankingdetails
                              .payment_term_name
                          }
                        </td>
                      ) : undefined}

                      <td className="font-weight-bold">Due Date :</td>
                      {formReportdata.billingandbankingdetails &&
                      formReportdata.billingandbankingdetails ? (
                        <td className="">
                          {formReportdata.billingandbankingdetails.due_date}
                        </td>
                      ) : undefined}
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Billing Days :</td>
                      {formReportdata.billingandbankingdetails &&
                      formReportdata.billingandbankingdetails ? (
                        <td className="">
                          {formReportdata.billingandbankingdetails.billing_days}
                        </td>
                      ) : undefined}
                      <td className="font-weight-bold">Freight Surcharge :</td>
                      {/* {formReportdata.billingandbankingdetails && formReportdata.billingandbankingdetails ? (

                        <td className="">{parseFloat(formReportdata.billingandbankingdetails.freight_surcharge.replaceAll(",","")).toFixed(2)}</td>
                      ) : undefined
                      } */}

                      {formReportdata.billingandbankingdetails &&
                      formReportdata.billingandbankingdetails
                        .freight_surcharge ? (
                        <td className="">
                          {parseFloat(
                            formReportdata.billingandbankingdetails.freight_surcharge.replaceAll(
                              ",",
                              ""
                            )
                          ).toFixed(2)}
                        </td>
                      ) : undefined}

                      <td className="font-weight-bold">Billing Basis :</td>
                      {formReportdata.billingandbankingdetails &&
                      formReportdata.billingandbankingdetails ? (
                        <td className="">
                          {
                            formReportdata.billingandbankingdetails
                              .billing_basis_name
                          }
                        </td>
                      ) : undefined}
                    </tr>
                  </tbody>
                </table>

                <h4 className="font-weight-bold">Supplier/Receiver Info</h4>
                <table className="table table-bordered table-invoice-report-colum">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Final Destination :</td>
                      <td className="">
                        {formReportdata && formReportdata["-------"]
                          ? formReportdata["-------"]["final_destination"]
                          : ""}{" "}
                      </td>

                      <td className="font-weight-bold">Supplier :</td>
                      <td className="">
                        {formReportdata && formReportdata["-------"]
                          ? formReportdata["-------"]["supplier_name"]
                          : ""}
                      </td>

                      <td className="font-weight-bold">Receiver :</td>
                      <td className="">
                        {formReportdata && formReportdata["-------"]
                          ? formReportdata["-------"]["reciever_name"]
                          : ""}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Ref Company :</td>
                      <td className="">
                        {formReportdata && formReportdata["-------"]
                          ? formReportdata["-------"]["ref_company_name"]
                          : ""}
                      </td>

                      <td className="font-weight-bold">VAT No / % :</td>
                      <td className="">
                        {formReportdata && formReportdata["-------"]
                          ? formReportdata["-------"]["vat_no"]
                          : ""}
                      </td>

                      <td className="font-weight-bold">OBL No. :</td>
                      <td className="">
                        {formReportdata && formReportdata["-------"]
                          ? formReportdata["-------"]["obl_no"]
                          : ""}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Ref Contract :</td>
                      <td className="">
                        {formReportdata && formReportdata["-------"]
                          ? formReportdata["-------"]["ref_contract"]
                          : ""}
                      </td>

                      <td className="font-weight-bold">Commingle Code :</td>
                      <td className="">
                        {formReportdata && formReportdata["-------"]
                          ? formReportdata["-------"]["commingle_code"]
                          : ""}
                      </td>

                      <td className="font-weight-bold">Trader :</td>
                      <td className="">
                        {formReportdata && formReportdata["-------"]
                          ? formReportdata["-------"]["trader"]
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h4 className="font-weight-bold">Estimate Rev/Exp Info</h4>
                <table className="table border-table table-invoice-report-colum">
                  <thead>
                    <tr className="HeaderBoxText">
                      <th>Description Type</th>
                      <th>Rev/Exp</th>
                      <th>Type Of Rev/Exp</th>
                      <th>Price</th>
                      <th>Currency</th>
                      <th>Total Amount($)</th>
                      <th>Final Amount</th>
                      <th>Account Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata &&
                      formReportdata.revexpinfo.length > 0 &&
                      formReportdata.revexpinfo.map((val, index) => {
                        return (
                          <tr key={index}>
                            <td>{val.description}</td>
                            <td>{val.rev_exp_name}</td>
                            <td>{val.re_type_name}</td>
                            <td>{val.price}</td>
                            <td>{val.re_currency_name}</td>
                            <td>{val.total_amount}</td>
                            <td>{val.final_amount}</td>
                            <td>{val.account_code}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                <h4 className="font-weight-bold">Rebill</h4>
                <table className="table border-table table-invoice-report-colum">
                  <thead>
                    <tr className="HeaderBoxText">
                      <th>F</th>
                      <th>Port</th>
                      <th>Ledger Code</th>
                      <th>Ledger Code Description</th>
                      <th>Cost Details Description</th>
                      <th>Rebillable</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formReportdata &&
                      formReportdata.rebillsettings.length > 0 &&
                      formReportdata.rebillsettings.map((val, index) => {
                        return (
                          <tr key={index}>
                            <td>{val.cv_f_name}</td>
                            <td>{val.cv_port_name}</td>
                            <td>{val.ledger_code}</td>
                            <td>{val.ledger_code_description}</td>
                            <td>{val.cost_details_description}</td>
                            <td>{val.rebillable == 1 ? "Yes" : "No"}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                <h4 className="font-weight-bold">Dem/Des Term</h4>
                <table className="table table-bordered table-invoice-report-colum">
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Load Dem/Des :</td>
                      <td className="text-right">
                        {formReportdata && formReportdata["-"]
                          ? formReportdata["-"].load_dem
                          : ""}
                        /
                        {formReportdata && formReportdata["-"]
                          ? formReportdata["-"].load_des
                          : ""}
                      </td>

                      <td className="font-weight-bold">Total Time Bar :</td>
                      <td className="text-right">
                        {formReportdata && formReportdata["---"]
                          ? formReportdata["---"].total_time_bar
                          : ""}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Per Day/Per hour :</td>
                      <td className="text-right">
                        {formReportdata && formReportdata["-"]
                          ? formReportdata["-"].per_day == 1
                            ? "Yes"
                            : "No"
                          : ""}
                        /
                        {formReportdata && formReportdata["-"]
                          ? formReportdata["-"].per_hour == 1
                            ? "Yes"
                            : "No"
                          : ""}
                      </td>

                      <td className="font-weight-bold">TT Hours :</td>
                      <td className="text-right">
                        {formReportdata && formReportdata["---"]
                          ? formReportdata["---"].tt_hours
                          : ""}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Disc Dem/Des :</td>
                      <td className="text-right">
                        {formReportdata && formReportdata["-"]
                          ? formReportdata["-"].disch_dem
                          : ""}
                        /
                        {formReportdata && formReportdata["-"]
                          ? formReportdata["-"].disch_des
                          : ""}
                      </td>

                      <td className="font-weight-bold">Freight Surcharge :</td>
                      <td className="text-right">
                        {formReportdata && formReportdata["---"]
                          ? formReportdata["---"].freight_surcharge
                          : ""}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Per Day/Per hour :</td>
                      <td className="text-right">
                        {formReportdata && formReportdata["-"]
                          ? formReportdata["-"].d_per_day == 1
                            ? "Yes"
                            : "NO"
                          : ""}
                        /
                        {formReportdata && formReportdata["-"]
                          ? formReportdata["-"].d_per_hour == 1
                            ? "Yes"
                            : "No"
                          : ""}
                      </td>

                      <td className="font-weight-bold">Bunker Surcharge :</td>
                      <td className="text-right">
                        {formReportdata && formReportdata["---"]
                          ? formReportdata["---"].bunker_surcharge
                          : ""}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Dem/Des Curr :</td>
                      <td className="text-right">
                        {formReportdata && formReportdata["-"]
                          ? formReportdata["-"].demurrage
                          : ""}
                        /
                        {formReportdata && formReportdata["-"]
                          ? formReportdata["-"].dem_des_curr_name
                          : ""}
                      </td>

                      <td className="font-weight-bold">Admin Charges :</td>
                      <td className="text-right">
                        {formReportdata && formReportdata["---"]
                          ? formReportdata["---"].admin_charges
                          : ""}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Demurrage :</td>
                      <td className="text-right">
                        {formReportdata && formReportdata["-"]
                          ? formReportdata["-"].demurrage
                          : ""}
                      </td>

                      <td className="font-weight-bold">Dem/Des Commitable :</td>
                      <td className="text-right">
                        {formReportdata && formReportdata["---"]
                          ? formReportdata["---"].dem_des_commitable == 1
                            ? "Yes"
                            : "No"
                          : ""}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Laytime :</td>
                      <td className="text-right">
                        {formReportdata && formReportdata["-"]
                          ? formReportdata["-"].laytime
                          : ""}
                      </td>

                      <td className="font-weight-bold">
                        Reversible All Port :
                      </td>
                      <td className="text-right">
                        {formReportdata && formReportdata["---"]
                          ? formReportdata["---"].reversible_all_port == 1
                            ? "Yes"
                            : "No"
                          : ""}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Laytime Allowed :</td>
                      <td className="text-right">
                        {formReportdata && formReportdata["-"]
                          ? formReportdata["-"].laytime_allowed == 1
                            ? "Yes"
                            : "No"
                          : ""}
                      </td>

                      <td className="font-weight-bold">Non Rev. All Port :</td>
                      <td className="text-right">
                        {formReportdata && formReportdata["---"]
                          ? formReportdata["---"].non_rev_all_port == 1
                            ? "Yes"
                            : "No"
                          : ""}
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Days / Hours :</td>
                      <td className="text-right">
                        {formReportdata && formReportdata["-"]
                          ? formReportdata["-"].days == 1
                            ? "Yes"
                            : "No"
                          : ""}
                        /
                        {formReportdata && formReportdata["-"]
                          ? formReportdata["-"].hours == 1
                            ? "Yes"
                            : "No"
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

class VcPurchageReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Printer",
      pdfData: "",
      userInput: "",
      emailModal: false,
      loading: false,
      mailTitlePayload: {},
    };
  }

  printReceipt() {
    window.print();
  }

  printDocument() {
    var quotes = document.getElementById("divToPrint");

    html2canvas(quotes, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
      scale: 2,
    }).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "html-to-img.png";
      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 25;
      var pageData = canvas.toDataURL("image/jpeg", 1.0);
      var imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          imgData,
          "PNG",
          5,
          position + 25,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      doc.save("VC (Purchase) Report.pdf");
    });
  }

  onActionDonwload = (downType, pageType) => {
    let params = `t=${pageType}`,
      cols = [];
    const { columns } = this.state;

    columns.map((e) =>
      e.invisible === "false" && e.key !== "action"
        ? cols.push(e.dataIndex)
        : false
    );
    // if (cols && cols.length > 0) {
    //   params = params + '&c=' + cols.join(',')
    // }

    window.open(
      `${process.env.REACT_APP_ATTACHMENT}/download/file/${downType}?${params}`,
      "_blank"
    );
  };

  sendEmailReportModal = async () => {
    try {
      this.setState({ loading: true });
      const quotes = document.getElementById("divToPrint");
      const canvas = await html2canvas(quotes, {
        logging: true,
        letterRendering: 1,
        useCORS: true,
        allowTaint: true,
        scale: 2,
      });
      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF("p", "mm");
      let position = 25;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      doc.addImage(pageData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          pageData,
          "PNG",
          5,
          position + 25,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      const blob = doc.output("blob");
      this.setState({
        loading: false,
        pdfData: blob,
        emailModal: true,
      });
    } catch (error) {
      console.error("Error:", error);
      this.setState({ loading: false });
      // Handle errors here
    }
  };

  render() {
    const { cargo_name, charterer_name, full_name, c_status_name } =
      this.props.data;
    //  console.log('propss',this.props.data)
    const menu = (
      <Menu>
        <Menu.Item>
          <a onClick={() => this.onActionDonwload("pdf", "VcPurchaseReport")}>
            PDF
          </a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.onActionDonwload("csv", "VcPurchaseReport")}>
            CSV
          </a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.onActionDonwload("xls", "VcPurchaseReport")}>
            XLS
          </a>
        </Menu.Item>
      </Menu>
    );
    const {
      columns,
      loading,
      responseData,
      pageOptions,
      search,
      isVisible,
      sidebarVisible,
    } = this.state;

    return (
      <div className="body-wrapper modalWrapper">
        <article className="article toolbaruiWrapper">
          <div className="box box-default">
            <div className="box-body">
              <div className="toolbar-ui-wrapper">
                <div className="leftsection"></div>
                <div className="rightsection">
                  <span className="wrap-bar-menu">
                    <ul className="wrap-bar-ul">
                      <li
                        onClick={this.sendEmailReportModal}
                        style={{
                          cursor: this.state.loading
                            ? "not-allowed"
                            : "pointer",
                        }}
                      >
                        Send Email
                      </li>
                      <li onClick={this.printDocument}>Download</li>
                      {/* <li>
                        <Dropdown overlay={menu}>
                          <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            Download <DownOutlined />
                          </a>
                        </Dropdown>
                      </li> */}
                      <li>
                        <ReactToPrint
                          trigger={() => (
                            <span className="text-bt">
                              <PrinterOutlined /> Print
                            </span>
                          )}
                          content={() => this.componentRef}
                        />
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </article>

        <article className="article">
          <div className="box box-default">
            <div className="box-body">
              <ComponentToPrint
                ref={(el) => (this.componentRef = el)}
                data={this.props.data}
              />
            </div>
          </div>
        </article>

        {this.state.emailModal && (
          <Modal
            title="New Message"
            visible={this.state.emailModal}
            onOk={() => {
              // Instead of calling this.state.emailModal, set it to false
              this.setState({ emailModal: false });
            }}
            onCancel={() => {
              // Instead of calling this.state.emailModal, set it to false
              this.setState({ emailModal: false });
            }}
            footer={null}
          >
            {this.state.pdfData && (
              <Email
                handleClose={() => {
                  this.setState({ emailModal: false });
                }}
                attachmentFile={this.state.pdfData}
                title={window.corrector(
                  `VC_Purchase_Report||${cargo_name}||${charterer_name}||${full_name}||${c_status_name}`
                )}
              />
            )}
          </Modal>
        )}

        {this.state.loading && (
          <div
            style={{
              position: "absolute",
              top: "10%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </div>
    );
  }
}

export default VcPurchageReports;

import dayjs from "dayjs";

export const findTheNextDate = (currentdate, days = 0) => {
  try {
    let sf = dayjs(currentdate);
    let ad = null;

    if (days === undefined || days === "N/A") {
      days = 0;
    }
    if (sf.isValid()) {
      ad = sf.add(days * 24, "hour"); // Convert days to hours
    }
    return dayjs(ad).format("YYYY-MM-DD HH:mm");
  } catch (err) {
    console.log("Error in findTheNextDate:", err);
  }
};

export const updateThetableBYDate = (
  tabledata,
  date,
  index,
  gpkey,
  isDepartureClicked = false,
  isArrivalClicked = false,
  isTsdChange = false
) => {
  // console.log("Updating table:", tabledata, "Date:", date, "GPKey:", gpkey, "Index:", index, "isDepartureClicked:", isDepartureClicked, "(((((999)))",tabledata[index]?.arrival_date_time);

  let _tabledata = [...tabledata];
  let firstdate = dayjs(date).isValid() && (isArrivalClicked || isDepartureClicked)
    ? date
    : tabledata[index]?.arrival_date_time;

  _tabledata.forEach((ele, ind) => {
    let obj = { ...ele };

    if (ind === index && isDepartureClicked) {
      // If Departure is clicked, update t_port_days / pdays instead of modifying dates
      if (gpkey === "portitinerary" || gpkey === "bunkerdetails") {
        obj["t_port_days"] =
          (Number(
            dayjs(firstdate).diff(dayjs(obj["arrival_date_time"]), "day", true)
          ) + Number(obj["xpd"]))?.toFixed(2) || 0;
      } else {
        obj["pdays"] =
          (dayjs(firstdate).diff(dayjs(obj["arrival_date_time"]), "day", true) +
            obj["xpd"])?.toFixed(2) || 0;
      }
    } else if (ind === index && isArrivalClicked && index !== 0) {
      let arrivalMoment = dayjs(obj["arrival_date_time"]);
      let prevobj =
        ind - 1 < _tabledata.length ? { ..._tabledata[ind - 1] } : {};
      if (arrivalMoment.isValid()) {
        // Only update tsd if firstdate is a valid date
        const daysDiff = arrivalMoment.diff(dayjs(prevobj["departure"]), "day", true) || 0;
        obj["tsd"] = daysDiff?.toFixed(2)
          
        // Update departure only if arrival date is valid
        obj["departure"] = findTheNextDate(
          firstdate,
          obj["t_port_days"] || obj["pdays"] || 0
        );
      } else {
        console.log("Invalid arrival date, tsd and departure not updated");
      }
    } else if (ind >= index) {
      // Normal date calculation logic
      let nextobj =
        ind + 1 < _tabledata.length ? { ..._tabledata[ind + 1] } : {};

      let arrivaldatetime = dayjs(firstdate).isValid()
        ? dayjs(firstdate)
        : null;

      let currentdeparture = findTheNextDate(
        arrivaldatetime,
        parseFloat(
          gpkey === "portitinerary" || gpkey === "bunkerdetails"
            ? obj["t_port_days"] || 0
            : obj["pdays"] || 0
        )
      );

      let nextarrival = isDepartureClicked
        ? findTheNextDate(arrivaldatetime, parseFloat(obj["tsd"] || 0))
        : findTheNextDate(currentdeparture, parseFloat(nextobj["tsd"] || 0));

      obj["day"] = arrivaldatetime ? arrivaldatetime.format("dddd") : "";
      obj["arrival_date_time"] = isDepartureClicked
        ? nextarrival
        : arrivaldatetime
        ? arrivaldatetime.format("YYYY-MM-DD HH:mm")
        : "";

      // obj["departure"] = isDepartureClicked
      // ? (gpkey === "portitinerary" ? obj.hasOwnProperty("t_port_days") : obj.hasOwnProperty("tdays") ? nextarrival : obj["departure"])
      // : dayjs(currentdeparture).isValid()
      //   ? dayjs(currentdeparture).format("YYYY-MM-DD HH:mm")
      //   : "";
      obj["departure"] = isDepartureClicked
        ? gpkey === "portitinerary" || gpkey === "bunkerdetails"
          ? obj.hasOwnProperty("t_port_days")
            ? findTheNextDate(nextarrival, obj["t_port_days"] || 0)
            : nextarrival
          : obj.hasOwnProperty("pdays")
          ? findTheNextDate(nextarrival, obj["pdays"] || 0)
          : obj["departure"]
        : dayjs(currentdeparture).isValid()
        ? dayjs(currentdeparture).format("YYYY-MM-DD HH:mm")
        : "";
      firstdate = nextarrival;
    }
    // else {
    //   console.log("============>>>>", obj)
    //   if (gpkey === "portitinerary" || gpkey === "bunkerdetails") {
    //     obj["tsd"] =
    //       +dayjs(firstdate).diff(dayjs(obj["arrival_date_time"]), "day") || 0;
    //   } else {
    //     obj["tsd"] =
    //       dayjs(firstdate).diff(dayjs(obj["arrival_date_time"]), "day") +
    //         obj["xpd"] || 0;
    //   }
    // }

    _tabledata[ind] = obj;
  });

  // console.log("upDatedTableData----->>>>",_tabledata)
  // const updatedTable = updateTableDates(_tabledata);
  // console.log("updatedTable=====>>>>----->>>>",updatedTable)

  return _tabledata;
};

// function updateTableDates(itinerary) {
//   for (let i = 1; i < itinerary.length; i++) {
//       let prevPort = itinerary[i - 1]; // Previous row
//       let currPort = itinerary[i]; // Current row
//       let nextPort = itinerary[i + 1]; // Next row (if exists)

//       // Calculate arrival date as previous departure + tsd from next object (if exists)
//       if (prevPort.departure && nextPort?.tsd) {
//           currPort.arrival_date_time = dayjs(prevPort.departure)
//               .add(parseFloat(nextPort.tsd || 0), "day")
//               .format("YYYY-MM-DD HH:mm");
//       } else {
//           currPort.arrival_date_time = prevPort.departure; // Default to previous departure if no tsd
//       }

//       // Calculate departure date as arrival date + t_port_days
//       currPort.departure = dayjs(currPort.arrival_date_time)
//           .add(parseFloat(currPort.t_port_days || 0), "day")
//           .format("YYYY-MM-DD HH:mm");
//   }
//   return itinerary;
// }
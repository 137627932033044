import { Icon } from "@iconify/react/dist/iconify.js";
import {
  AutoComplete,
  Avatar,
  Checkbox,
  DatePicker,
  Drawer,
  Dropdown,
  Input,
  List,
  Menu,
  message,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import {
  composeNewMail,
  setAllEmails,
  setIsSearcihng,
  setSearchChoice,
  setSearchQuery,
  setVesselName,
} from "../../actions/emailActions";
import {
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
  postAPICallService,
} from "../../shared";
import {
  capitaliseFirstLetter,
  chipStyles,
  disabledDate,
  EMAILS_PER_PAGE,
  events,
  formatNumber,
  formatTimestamp,
  getAvtarColors,
  getInitial,
  highlightSearchKeywords,
  returnccbcc,
  sendWebSocketMessage,
  timeHandler,
} from "./constants";
import CurrentMail from "./CurrentMail";

import MailRightbar from "./mailRightbar/MailRightbar";
import SearchFilters from "./SearchFilter";
import { debounce } from "lodash";
import SuggestionBox from "./SuggestionBox";
import "./styles.scss";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";

const Listview = ({
  page,
  setPage,
  handleSearch,
  searchData,
  mailType,
  setRefreshCount,
  refreshCount,
  mailData,
  setMailData,
  totalMailCount,
  paginationHandler,
  setTotalMailCount,
  fetchEmails,
  fetchEmailCount,
  setFilterPayload,
  isFilters,
  setIsfilters,
  setFilterData,
  filterData,
  handleDateSearch,
  setFilterSpin,
  filterSpin,
  isDateFilterActive,
  setIsDateFilterActive,
  setDateRange,
  readUnreadCount,
  setReadUnreadCount,
  resetFilter,
}) => {
  // console.log({ isFilters, filterData });
  const allMails = useSelector((state) => state.email.allEmails);
  const sentMails = useSelector((state) => state.email.sentList);
  const draftMails = useSelector((state) => state.email.draftList);
  const binMails = useSelector((state) => state.email.binList);
  const archivedMails = useSelector((state) => state.email.archivedList);
  const importantMails = useSelector((state) => state.email.importantList);
  const spamMails = useSelector((state) => state.email.spamList);
  const labelMails = useSelector((state) => state.email.labelMailList);
  const importedMailList = useSelector((state) => state.email.importedMailList);
  const filteredData = useSelector((state) => state.email.filterData);
  const composeMails = useSelector((state) => state.email.composeMails);
  const filterPayload = useSelector((state) => state.email.filterPayload);
  const restrictionData = useSelector((state) => state.email.restrictionData);

  const [modalOpen, setModalOpen] = useState(false);
  const [openSearchBox, setOpenSearchBox] = useState(false)
  const [advancedSearchChoices, setAdvancedSearchChoices] = useState(["Everything", "Attachments", "Subjects", "From", "Receiver", "Body", "Vessels", "Cargos", "Tags", "Load Port", "Discharge Port", "Categories"])
  const [advancedSearchValues, setAdvancedSearchValues] = useState({});
  const [senderSuggestions, setSenderSuggestions] = useState([])
  const [advancedSearchData, setAdvancedSearchData] = useState([]);
  const reduxSearcChoice = useSelector((state) => state.email.searchChoice);
  const reducSeachQuery = useSelector((state) => state.email.searchQuery);
  const [selectedChoice, setSelectedChoice] = useState(reduxSearcChoice || 'Everything');
  const [selectedQuery, setSelectedQuery] = useState(reducSeachQuery);
  const [suggestionList, setSuggestionList] = useState([]);
  const [visible, setVisible] = useState(false);
  // console.log("searchData0000000000000", isFiltering);
  // console.log(allMails,'lkhbnm')
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedMail, setSelectedMail] = useState({});
  const [currentMail, setCurrentMail] = useState({});
  const [openCurrentMail, setOpenCurrentMail] = useState(false);
  // const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [unique, setUnique] = useState([]);
  const [sub, setSub] = useState([]);
  // const [mailData, setMailData] = useState(allMails);
  // const [filterData, setFilterData] = useState({ emails: [] });
  const avtarColorObject = getAvtarColors();
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  // const [isFilters, setIsfilters] = useState(false);
  // const [filterSpin, setFilterSpin] = useState(false);
  const [query, setQuery] = useState("");
  const [notMore, setNotMore] = useState(true);
  const [isRemainsSelected, setIsRemainsSelected] = useState(false);
  const [filterPagination, setFilterPagination] = useState({
    page: 1,
    pageSize: 50,
  });
  const [dateRange, setDateRanger] = useState(null);
  const [filterText, setFilterText] = useState({
    subject: "",
    sender: "",
  });
  // const [readUnreadCount, setReadUnreadCount] = useState({});
  const hasMounted = useRef(false);
  const [viwedBy, setViwedBy] = useState([]);
  const [assignTo, setAssignTo] = useState([]);

  const textColors = [
    '#432548', '#1f2d3a', '#c0392b', '#1e8141', '#1f6a8e', '#732b77',
    '#e67e22', '#137c6b', '#b84c00', '#2a2e2e', '#2c3e50', '#3c3333'
  ];;
  const backgroundColors = [
    '#ccebff30', '#ecf4f5', '#ffe6e617', '#c8e6c933', '#d3e1f352', '#f9e1f93b',
    '#fff2b21c', '#d6e1f040', '#ffe1f259', '#f8f8f8', '#e1d7f245', '#cbbeb62e'
  ];

  // console.log({ mailData });

  const location = useLocation();
  useEffect(() => {
    setSelectedItems([]);
    setSelectAll(false);
    handleCurrentMailClose();
    setFilters({
      Invoice: [],
      Sender: [],
      attach: [],
      comments: [],
      date: [],
      mailType: [],
      reciver: [],
      subject: [],
      text: [],
      viewedBy: [],
      tags: [],
      date: [],
    });
  }, [location]);

  const [filters, setFilters] = useState({
    Invoice: [],
    Sender: [],
    attach: [],
    comments: [],
    date: [],
    mailType: [],
    reciver: [],
    subject: [],
    text: [],
    viewedBy: [],
    tags: [],
    date: [],
    users: [],
  });

  const { label_name, folder_name, imported_mail_identifier } = useParams();

  // const handleDateSearch = async (range) => {
  //   try {
  //     setFilterSpin(true);
  //     const url = `${process.env.REACT_APP_MT_URL}mail/get-date-filter?page=${filterPagination.page}&pageSize=${filterPagination.pageSize}`;
  //     const payload = {
  //       date_range: range,
  //       owner_email: "*",
  //     };

  //     const resp = await postAPICall(url, payload, "POST", (value) => {
  //       if (value?.data?.length > 0) {
  //         // console.log(value);
  //         setIsfilters(false);
  //         setMailData(value?.data);
  //         console.log("MAILCOUNT-VALUE", value);

  //         // setFilterData(value?.data);
  //         setTotalMailCount(value?.count_after_filter);

  //         setFilterSpin(false);
  //       } else {
  //         openNotificationWithIcon(
  //           "info",
  //           "No data found for the choosen date."
  //         );
  //         setFilterSpin(false);
  //       }
  //     });
  //   } catch (err) {
  //     console.log(err);
  //     setFilterSpin(false);
  //   }
  // };

  const handleOpenSearchBox = () => {
    setOpenSearchBox(true)
  }
  const handleCloseSearchBox = () => {
    setOpenSearchBox(false)
  }
  const handleSearchGetAPI = async () => {
    try {
      
   
      // dispatch(setSpinner(true))
      const url = `${process.env.REACT_APP_MT_URL}analytic/get-keys-values`
      const isToken = true
      const response = await getAPICall( url, isToken )
      if (response) {
        const responseData = response?.data
        setAdvancedSearchChoices(Object.keys(responseData))
        setAdvancedSearchValues(responseData)
        // dispatch(setSpinner(false))
      }
      else{
        // dispatch(setSpinner(false))
      }
    } catch (error) {
      // dispatch(setSpinner(false))
      console.error(error)
    }
  }

  const handleSearchPostAPI = async (item) => {
    // dispatch(setSpinner(true))
    const url = `${process.env.REACT_APP_MT_URL}analytic/advance-search?page=${1}&pageSize=20`

    const payload = {
      "choice": selectedChoice,
      "query": item
    }
    const isToken = true;
    const showMessage = false;
    const response = await postAPICallService( {url, payload} );
    // if(response?.data?.data?.length === 0 || !response){
    //   toast.error('No Data Found..')
    // }
    if (response && response?.data?.length > 0) {
      // console.log(response?.data?.data[0])
      // setAdvancedData(response?.data?.data)
      // dispatch(setPage(1))
      dispatch(setAllEmails({ emails: response?.data }));
      // dispatch(setSpinner(false))
      handleCloseSearchBox();
      // const element = document.querySelector('.scrollIt'); // Get the first element with the class 'scrollIt'
      // if (element) {
      //   element.scrollTo({ behavior: 'smooth', top:0 }); // Scroll to the element smoothly
      // }

    } else {
      message.error('No Data Found..');

    }
    // dispatch(setSpinner(false))

  }
  const handleSearchPostAPIId = async (id) => {
    if (id) {
      // dispatch(setSpinner(true))

      const url = `${process.env.REACT_APP_MT_URL}analytic/advance-search?id=${id}`
      const payload = {
        "choice": selectedChoice,
        "query": selectedQuery
      }
      const isToken = true;
      const showMessage = false;
      const response = await postAPICallService({ url, isToken, payload, showMessage });
      const responseData = await response?.data?.data[0];
      if (responseData) {
        // console.log(responseData,'srsrsrrssrs')
        dispatch(setCurrentMail(responseData));
        setCurrentMail(true);
        setOpenSearchBox(false);
        // dispatch(setSpinner(false));

      }
    }
  }
  

  const handleSolrSuggestion = async (value,newChoice) => {
    let val=value.replace(/<\/?b>/g, '')?.trim()
    
    if(val){
    const url = `${process.env.REACT_APP_MT_URL}solr/getSuggestions?q=${val?.trim()}&choice=${newChoice}`

    const isToken = true;
    const showMessage = false;
    const response = await getAPICall( url, isToken );
    let responseArray = [];
    setAdvancedSearchData([])
    // Collect all suggestion arrays
    // console.log("response",response);
    
    const suggestions = [
      response?.suggest?.AttachmentsSuggester?.[val]?.suggestions,
      response?.suggest?.SubjectSuggester?.[val]?.suggestions,
      response?.suggest?.tagsSuggester?.[val]?.suggestions,
      response?.suggest?.fromSuggester?.[val]?.suggestions,
      response?.suggest?.snippetSuggester?.[val]?.suggestions,
      response?.suggest?.toRecipientsSuggester?.[val]?.suggestions,
      response?.suggest?.vesselNameSuggester?.[val]?.suggestions,
      response?.suggest?.ccRecipientsSuggester?.[val]?.suggestions,
      response?.suggest?.loadPortSuggester?.[val]?.suggestions,
      response?.suggest?.dischargePortSuggester?.[val]?.suggestions,
      response?.suggest?.cargoSuggester?.[val]?.suggestions,
      response?.suggest?.Everything?.[val]?.suggestions,
    ];

    // console.log("handleDebouncedSearch",response);
    
    // Filter out undefined or empty arrays and flatten into one array
    responseArray = suggestions.filter(Boolean).flat();
    const fileObjects = responseArray.map(file => ({...file, value: file.term, label: (
      
        <div
          dangerouslySetInnerHTML={{
            __html: file.term,
          }}
        />
    ), }));
    setAdvancedSearchData(fileObjects);
    if(newChoice === 'tags') {
      const fileObjects = responseArray.map(file => (file.term.replace(/<\/?b>/g, '')));
      setUnique({unique_tags_mails: fileObjects})
    }
    if(newChoice === 'subject'){
      const fileObjects = responseArray.map(file => (file.term.replace(/<\/?b>/g, '')));
      setSub({unique_subjects: fileObjects})
    }
    if(newChoice === 'From') {
      const responseArray = response?.suggest?.fromSuggester?.[val]?.suggestions;
      const fileObjects = responseArray.map(file => ({...file, value: file.term.replace(/<\/?b>/g, ''), label: (
      
        <div
          dangerouslySetInnerHTML={{
            __html: file.term,
          }}
        />
    ), }));
      setSenderSuggestions(fileObjects)
    }
    value = "";

  }

  }

  const handleSolrSuggestionClick = async(id) => {
    try {
      // dispatch(setSpinner(true))
      const i = id.payload;
      const url = `${process.env.REACT_APP_MT_URL}mail/get-mail/${i}`
      const requestData = {
        url: url,
        isToken: true, // Assuming this is the default value you want
        payload: {},
        header: {},
        showMessage: false
      }
      try {
        const term = id?.term?.replace(/<\/?b>/g, '');
        let response = await getAPICall(requestData)
        let responseData = response?.data?.[0]
        dispatch(setAllEmails([responseData]))
        setFilters(prevData =>
          prevData.map(item => ({
            ...item,
            Sender: [term]
          }))
        )
        // setMailDetailsOpen(true)
        // handleClose()
      } catch (error) {
        console.error('Error:', error)
        // Handle error appropriately
      } finally{
        // dispatch(setSpinner(false))
      }
    } catch (error) {
      console.error(error)
    } finally {
      // dispatch(setSpinner(false))
    }
  }

  const handleDebouncedSearch = useCallback(
 
    debounce(async (query,selectedChoice) => {
      // console.log(selectedChoice,'in maildjnhehf')
      if (!query.trim()) return; // Prevent empty searches
      handleSolrSuggestion(query,selectedChoice)

    }, 50), // Delay of 500ms
    []
  );

  const handleViewMailClick = async (id) => {

    const url = `${process.env.REACT_APP_MT_URL}mail/get-mail/${id}`
    // const isToken = true
    
    try {
      let response = await getAPICall(url)
      let responseData = response?.data?.[0]
      handleMailClick(responseData)
      handleClose()
    } catch (error) {
      console.error('Error:', error)
      // Handle error appropriately
    }
  }
  const handleDateFilters = (date) => {
    setDateRanger(date);
    if (!date || !date[0] || !date[1]) {
      setIsDateFilterActive(false);
      setPage(1);
      fetchEmails(mailType);
      fetchEmailCount(mailType);
      return;
    }
    setDateRange(
      dayjs(date?.[0]).format("YYYY/MM/DD") +
        "-" +
        dayjs(date?.[1]).format("YYYY/MM/DD")
    );
    handleDateSearch(
      dayjs(date?.[0]).format("YYYY/MM/DD") +
        "-" +
        dayjs(date?.[1]).format("YYYY/MM/DD")
    );
  };

  const menu = (
    <Menu>
      <RangePicker
        onChange={(value) => handleDateFilters(value)}
        allowClear
        showTime
        showSecond={false}
        disabledDate={disabledDate}
        value={dateRange}
      />
    </Menu>
  );

  useEffect(() => {
    findUniqueData();
    getAssignedMailOptions();
  }, []);

  const handleMailClick = async (mail) => {
    // console.log("mailll",mail)
    setModalOpen(false);
    setSuggestionList([]);
    if (mailType === "draft" && !mail.labelIds?.includes("INBOX")) {
      const data = {
        composedData: {
          Date: mail.Date || "",
          to: mail.to || [],
          cc: mail.cc || [],
          bcc: mail.bcc || [],
          body: mail.body || "",
          subject: mail.subject || "",
          attachments: mail.attachments || [],
          //   editorStateKey: EditorState.createEmpty(),
          HTMLBody: undefined,
          owner_email: mail.owner_email || "",
          ThreadID: mail.ThreadID || "",
          GmailMessageId: mail.GmailMessageId || "",
          listOfFile: mail.listOfFile || [],
          totalSize: 0,
          uid: uuidv4(),
          _id: mail._id || null,
        },
        max: false,
        min: false,
      };
      const isPresent = composeMails?.some(
        (item) => item.composedData._id === mail._id
      );
      if (!isPresent) {
        dispatch(composeNewMail(data));
      }
    } else {
      setOpenCurrentMail(true);
      if (query?.length > 0) {
        let helightedData = await highlightSearchKeywords(query, mail);
        // setSelectedMail(mail);
        // setCurrentMail(mail);
        setSelectedMail(helightedData);
        setCurrentMail(helightedData);
        setQuery("");
      } else {
        setSelectedMail(mail);
        setCurrentMail(mail);
      }

      dispatch(setVesselName({ vesselName: "", isVisible: false }));
      if (!mail?.isRead) {
        const payload = {
          GmailMessageId: mail?.GmailMessageId,
          _id: mail?._id,
          context: mail?.client,
          isRead: true,
          event: "markRead",
        };
        handleMarkMailIsRead(payload);
        const updatedMail = {
          ...mail,
          isRead: true,
        };

        // {markedAsRead: 17602, markedAsUnread: 140682, status: true}
        let mailCount = {
          markedAsRead: readUnreadCount?.markedAsRead + 1,
          markedAsUnread:
            readUnreadCount?.markedAsUnread == 0
              ? 0
              : readUnreadCount?.markedAsUnread - 1,
          status: true,
        };
        setReadUnreadCount(mailCount);

        setCurrentMail(updatedMail);
        // mail.isRead=true;
      }
    }
  };

  const handleCurrentMailClose = () => {
    setOpenCurrentMail(false);
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    // setIsRemainsSelected(!isRemainsSelected);
    if (e.target.checked) {
      const dataToMap = isFilters || isDateFilterActive ? filterData : mailData;
      setSelectedItems(dataToMap?.map((item) => item));
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (e, email) => {
    // console.log(email);
    // console.log(selectedItems.some((selectedItem) => ((selectedItem._id ||selectedItem.id) === (email._id || email.id))))
    e.stopPropagation();
    if (selectedItems.length === 1) {
      setSelectAll(false);
    }
    // Use a functional update to ensure you're working with the latest state.
    let index = selectedItems?.findIndex((item) => item?._id === email?._id);
    if (index === -1) {
      setSelectedItems([...selectedItems, email]);
    } else {
      let filter = selectedItems.filter((item) => item?._id !== email?._id);
      setSelectedItems(filter);
    }
    // setSelectedItems((prevSelectedItems) => {

    //   // console.log(prevSelectedItems.includes(item?._id !== email?._id))
    //   if (prevSelectedItems.filter((item) => item?._id !== email?._id))) {
    //     // Remove the email if it's already selected

    //     return prevSelectedItems.filter((item) => item?._id !== email?._id);
    //   } else {
    //     // Add the email if it's not selected
    //     return [...prevSelectedItems, email];
    //   }
    // });
  };
  //   const handleSelectItem = (e, email) => {
  //   e.stopPropagation();
  //     if(selectedItems.length === 1) {
  //         setSelectAll(false)
  //     }
  //   // Update the selected items by checking if the item is already selected
  //   setSelectedItems((prevSelectedItems) => {
  //     // Check if the email is already in the selected items
  //     const isSelected = prevSelectedItems.some(
  //       (selectedItem) => (selectedItem._id || selectedItem.id) === (email._id || email.id)
  //     );
  //     if (isSelected) {
  //       // If already selected, remove the item
  //       return prevSelectedItems.filter(
  //         (item) => (item._id || item.id) !== (email._id || email.id)
  //       );
  //     } else {
  //       // If not selected, add the item
  //       return [...prevSelectedItems, email];
  //     }
  //   });
  // };

  const markImportant = (userInpu1) => {
    // Destructure the userInput and event from the input object
    let action = true;
    const { userInput, event } = userInpu1;
    event.stopPropagation();

    const { _id, important, client } = userInput;

    // Prepare the payload to be sent via WebSocket
    const payload = {
      context: client,
      _id,
      important: !important, // Toggle the important value
      event: "markImportant",
    };

    // Send the event to the WebSocket
    events.markImportant(payload);
    // Listening for WebSocket messages
    window.mySocketMail.onmessage = (event) => {
      events.handleAcknowledgment(event, (isSuccess) => {
        if (isSuccess) {
          // openNotificationWithIcon("success", "Marked as Important!");
          const dataToMap =
            isFilters || isDateFilterActive ? filterData : mailData;
          const updatedMails = dataToMap.map((mail) => {
            if (mail._id === _id) {
              !mail.important &&
                action &&
                openNotificationWithIcon("success", "Marked as Important!");
              mail.important &&
                action &&
                openNotificationWithIcon("success", "Marked as Unimportant!");
              action = false;
              return {
                ...mail,
                important: !mail.important, // Toggle the important value
              };
            }
            return mail;
          });

          if (isFilters || isDateFilterActive) {
            setFilterData(updatedMails);
          } else {
            setMailData(updatedMails);
          }
        } else {
          message.error("Something went wrong. Please try again!");
        }
      });
    };
  };

  const markRead = (userInpu1) => {
    // Destructure the userInput and event from the input object
    const { userInput, event } = userInpu1;
    event.stopPropagation();
    let action = true;
    const { _id, isRead, GmailMessageId, client } = userInput;

    // Prepare the payload to be sent via WebSocket
    const payload = {
      GmailMessageId: GmailMessageId,
      _id,
      context: client,
      isRead: !isRead, // Toggle the important value
      event: "markRead",
    };

    // Send the event to the WebSocket
    events.markIsRead(payload);
    // Listening for WebSocket messages
    window.mySocketMail.onmessage = (event) => {
      events.handleAcknowledgment(event, (isSuccess) => {
        if (isSuccess) {
          const dataToMap =
            isFilters || isDateFilterActive ? filterData : mailData;
          // console.log("datatomap", dataToMap);
          // openNotificationWithIcon("success", "Marked as Important!");
          const updatedMails = dataToMap?.map((mail) => {
            if (mail._id === _id) {
              !mail.isRead &&
                action &&
                openNotificationWithIcon("success", "Marked as Read!");
              mail.isRead &&
                action &&
                openNotificationWithIcon("success", "Marked as UnRead!");
              action = false;
              return {
                ...mail,
                isRead: !mail.isRead, // Toggle the important value
              };
            }
            return mail;
          });
          if (isFilters || isDateFilterActive) {
            setFilterData(updatedMails);
          } else {
            setMailData(updatedMails);
          }
        } else {
          message.error("Something went wrong. Please try again!");
        }
      });
    };
  };

  const markSpam = (userInpu1) => {
    // Destructure the userInput and event from the input object
    const { userInput, event } = userInpu1;
    event.stopPropagation();
    let action = true;
    const { _id, GmailMessageId, client } = userInput;

    // Prepare the payload to be sent via WebSocket
    const payload = {
      GmailMessageId,
      _id,
      Spam: true, // Mark as spam
      event: "spamMail",
    };

    // Send the event to the WebSocket and wait for acknowledgment
    events.markSpam(payload);

    // Listening for WebSocket messages
    window.mySocketMail.onmessage = (event) => {
      events.handleAcknowledgment(event, (isSuccess) => {
        if (isSuccess) {
          const dataToMap =
            isFilters || isDateFilterActive ? filterData : mailData;
          const updatedMails = dataToMap.filter((mail) => mail._id !== _id);
          if (isFilters || isDateFilterActive) {
            setFilterData(updatedMails);
          } else {
            setMailData(updatedMails);
          }
          isSuccess &&
            action &&
            openNotificationWithIcon("success", "Moved to Spam!");
          action = false;
        } else {
          message.error("Something went wrong. Please try again!");
        }
      });
    };
  };

  const handleDeleteMail = async (GmailMessageId, _id, client) => {
    try {
      let action = true;
      const payLoad = {
        GmailMessageId,
        _id,
        context: client,
        trashed: true,
        permanent: false,
        event: "deleteMail",
      };

      // Check if webSocketManager is available and WebSocket is open
      const webSocketManager = window?.webSocketManager;
      if (
        webSocketManager &&
        webSocketManager.socket.readyState === WebSocket.OPEN
      ) {
        // Create a unique responseId to track this request
        const responseId = Date.now();

        // Create a promise to handle the delete response
        const responsePromise = new Promise((resolve, reject) => {
          // Define a temporary event handler
          const handleResponse = (response) => {
            // console.log({ response });
            // if (response?.responseId === responseId) {
            if (response?.content?.AcknowledgmentID) {
              action &&
                openNotificationWithIcon(
                  "success",
                  "Mail successfully deleted"
                );
              setCurrentMail({});
              handleCurrentMailClose();
              const dataToMap =
                isFilters || isDateFilterActive ? filterData : mailData;
              const filteredMails = dataToMap?.filter(
                (mail) => mail?._id !== _id
              );
              if (isFilters || isDateFilterActive) {
                setFilterData(filteredMails);
              } else {
                setMailData(filteredMails);
              }
              action = false;
            } else {
              message.error("Failed to delete mail");
            }
            resolve(response);
            // Remove the event listener after handling the response
            webSocketManager.off("deleteMail", handleResponse);
            // }
          };

          // Register the temporary event handler
          webSocketManager.on("deleteMail", handleResponse);
        });

        // Send payload with the unique responseId
        await webSocketManager.send({ ...payLoad, responseId });

        // Await the response promise
        await responsePromise;
      } else {
        message.error("Please try again.");
        console.error("WebSocket is not open or not initialized.");
      }
    } catch (error) {
      console.error("WebSocket error:", error);
      message.error("Failed to delete mail due to WebSocket error");
    }
  };

  const handleDeleteConfirmation = (mailDetail) => {
    // console.log({mailDetail})
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteMail(
          mailDetail.GmailMessageId,
          mailDetail._id,
          mailDetail.client
        );
        // handleDeleteMail(mailDetail: {})
      }
    });
  };

  const findUniqueData = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}mail/find-unique`;
      const payload = { owner_email: "*" };
      await postAPICall(url, payload, "post", (data) => {
        if (data.data) {
          // console.log(data?.data)
          setUnique(data?.data);
          setViwedBy(data?.data?.unique_viewed_users);
        } else {
          openNotificationWithIcon("info", data?.msg);
        }
      });
    } catch (e) {
      console.error("Error fetching unique data:", e);
    }
  };

  // const handleViewedBy = async (value) => {
  //   try {
  //     const trimmedValue = value.trim();

  //     if (trimmedValue.length > 1) {
  //       const url = `${process.env.REACT_APP_MT_URL}mail/all-mails?query=${trimmedValue}`;

  //       const response = await postAPICall(url, {}, "get", (data) => {
  //         const options = data?.emails?.map((mail) => ({
  //           label: mail,
  //           value: mail,
  //         }));
  //         setUnique((prev) => ({
  //           ...prev,
  //           viewMails: options,
  //         }));
  //       });
  //     }
  //   } catch (e) {
  //     console.error("Error fetching data:", e);
  //   }
  // };

  const handleViewedBy = debounce(async (value) => {
    try {
      const trimmedValue = value.trim();
      const url = `${process.env.REACT_APP_MT_URL}mail/all-mails?query=${trimmedValue}`;
      if (trimmedValue.length > 1) {
        const response = await postAPICall(url, {}, "get", (data) => {
          const options = data?.emails?.map((mail) => ({
            label: mail,
            value: mail,
          }));
          setUnique((prev) => ({
            ...prev,
            unique_emails: data?.emails,
          }));
        });
      } else {
        const response = await postAPICall(url, {}, "get");
        // console.log("response", response);
        if (response?.emails) {
          setUnique((prev) => ({
            ...prev,
            unique_emails: response?.emails,
          }));
        }
      }
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  }, 400);

  const seacrViwedByMail = (value) => {
    const allOptions = unique?.unique_viewed_users;
    const filteredOptions = allOptions.filter((item) =>
      item.toLowerCase().includes(value.toLowerCase())
    );

    setViwedBy(filteredOptions);
  };

  useEffect(() => {
    return () => {
      handleViewedBy.cancel();
    };
  }, []);

  const handleChange = (name, value) => {
    if (value) {
      setPage(1);
      // setFilters((prevFilters) => ({
      //   ...prevFilters,
      //   [name]: [...prevFilters[name], value], // Update the specific filter dynamically
      // }));
      if (filters[name].includes(value)) {
        openNotificationWithIcon("info", "Value already exists in the filter.");
        return;
      } else {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: [...prevFilters[name], value], // Update the specific filter dynamically
        }));
        setIsfilters(true);
      }
      // setFilters((prevFilters) => {
      // //   // Check if the value already exists in the specific filter array
      // //   if (prevFilters[name].includes(value)) {
      // //     openNotificationWithIcon("info", "Value already exists in the filter.");
      // //     return prevFilters;
      // //   }

      // return {
      //   ...prevFilters,
      //   [name]: [...prevFilters[name], value],
      // };
      // // });
    } else {
      openNotificationWithIcon("info", "Put Some Value For Filter.");
    }
  };

  // const handleChipClose = (item, name) => {

  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     [name]: prevFilters[name].filter((val) => val !== item), // Remove the specific filter value
  //   }));
  //   if (isFilters) {
  //     checkFilterIsEmpty(filters);
  //   }
  // };

  const handleChipClose = (item, name) => {
    if (name == "users") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: prevFilters[name].filter((val) => val.id !== item.id), // Remove the specific filter value
      }));
      const updatedFilterValues = {
        ...(filterPayload?.query?.[0] || []),
        [name]: filterPayload?.query?.[0]?.[name]?.filter(
          (val) => val.id !== item.id
        ),
      };
      dispatch(setFilterPayload({ owner_email: "*", updatedFilterValues }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: prevFilters[name].filter((val) => val !== item), // Remove the specific filter value
      }));
      const updatedFilterValues = {
        ...(filterPayload?.query?.[0] || []),
        [name]: filterPayload?.query?.[0]?.[name]?.filter(
          (val) => val !== item
        ),
      };
      dispatch(setFilterPayload({ owner_email: "*", updatedFilterValues }));
    }
  };

  const mergeAndRemoveDuplicates = (arr1, arr2) => {
    return [...new Set([...(arr1 || []), ...(arr2 || [])])];
  };

  const checkFilterIsEmpty = (obj) => {
    const allEmpty = Object.values(obj).every(
      (arr) => Array.isArray(arr) && arr.length === 0
    );

    if (allEmpty) {
      setPage(1);
      setIsfilters(false);
      dispatch(setFilterPayload({}));
      if (mailType?.trim().length > 0) {
        fetchEmails(mailType);
        fetchEmailCount(mailType);
      }
    } else {
      // const updatedFilters = { ...filters };
      const updatedFilters = {
        ...filterPayload?.query?.[0],
        ...filters,
        Sender: mergeAndRemoveDuplicates(
          filters.Sender,
          filterPayload?.query?.[0]?.Sender || []
        ),
        date: mergeAndRemoveDuplicates(
          filters.date,
          filterPayload?.query?.[0]?.date || []
        ),
      };
      const payload = {
        owner_email: "*",
        query: [updatedFilters], // Use the cloned filters object
      };
      dispatch(setFilterPayload(payload));
      // handleFilterSubmit();
    }
  };

  useEffect(() => {
    if (isFilters) {
      checkFilterIsEmpty(filters);
    }
  }, [isFilters, filters]);

  const onChange = (dates, value, dateString) => {
    setPage(1);
    if (dates && dates[0] && dates[1]) {
      const formattedStartDate = dayjs(new Date(dates[0])).format("YYYY/MM/DD");
      const formattedEndDate = dayjs(new Date(dates[1])).format("YYYY/MM/DD");

      const dateRangeString = `${formattedStartDate}-${formattedEndDate}`;
      if (value) {
        setFilters((prev) => ({
          ...prev,
          [value]: [...(prev[value] || []), dateRangeString],
        }));
        setIsfilters(true);
      }
    }
  };

  const renderSelectedChips = (filterKey, filterValues) => {
    if (filterKey === "users") {
      // console.log(filterValues);
      return filterValues?.length ? (
        filterValues.map((item) => (
          <Tag
            color="processing"
            key={item?.id || item} // Use a unique key, such as `item.id`
            closable
            onClose={() => handleChipClose(item, filterKey)}
          >
            {item?.name}
          </Tag>
        ))
      ) : (
        <span style={{ fontSize: "12px", color: "lightgray" }}>
          No Active Filter
        </span>
      );
    } else {
      return filterValues?.length ? (
        filterValues.map((item) => (
          <Tag
            color="processing"
            key={item} // Ensure a unique key
            closable
            onClose={() => handleChipClose(item, filterKey)}
          >
            {item}
          </Tag>
        ))
      ) : (
        <span style={{ fontSize: "12px", color: "lightgray" }}>
          No Active Filter
        </span>
      );
    }
  };

  const handleSelectChange = (value, field) => {
    setPage(1);
    if (field === "users") {
      setFilters((prev) => ({
        ...prev,
        [field]: value.map((user) => ({
          name: user.first_name,
          id: user.id,
        })),
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        [field]: Array.isArray(value) ? value : [value], // Always store as array
      }));
    }

    setIsfilters(true);
  };

  const TagsPopover = ({ item }) => {
    const content = (
      <div
        className="color-even-odd"
        style={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: "3px",
          maxWidth: "500px",
        }}
      >
        {item?.tags?.length > 0
          ? item?.tags?.map((tag, index) => (
              <span
                style={{
                  textAlign: "center",
                  marginBottom: "7px",
                  backgroundColor:
                    chipStyles[index % chipStyles.length].backgroundColor,
                  color: chipStyles[index % chipStyles.length].color,
                  borderColor: chipStyles[index % chipStyles.length].color,
                }}
                className="list_view_tags"
                key={index}
              >
                {tag}
              </span>
            ))
          : "No Tags Available"}
      </div>
    );

    return (
      <Popover
        content={content}
        title="Tags"
        arrow
        trigger={["click", "hover"]}
      >
        {item?.tags?.length > 0 ? (
          <>
            {item?.tags.slice(0, 2).map((tag, index) => (
              <span
                className="list_view_tags"
                key={index}
                style={{
                  backgroundColor:
                    chipStyles[index % chipStyles.length].backgroundColor,
                  color: chipStyles[index % chipStyles.length].color,
                  borderColor: chipStyles[index % chipStyles.length].color,
                }}
              >
                {tag}
              </span>
            ))}
            {item?.tags.length > 2 && (
              <span style={{ color: "#12406a", fontWeight: "500" }}>
                +{item.tags.length - 2}{" "}
              </span>
            )}
          </>
        ) : (
          "No Tag"
        )}
      </Popover>
    );
  };

  const getRandomColor = (index) => {
    const colors = [
      "#fde4cf",
      "#bde0fe",
      "#caffbf",
      "#ffc8dd",
      "#a0c4ff",
      "#ffafcc",
      "#d4a373",
      "#c3f3ed",
      "#ffe6e6",
      "#ffd6a5",
    ];
    return colors[index % colors.length]; // Cycle through colors based on index
  };
  

  const AssignToByPopover = ({ item }) => {
    const userCount = item?.users?.length || 0;
  
    const renderUserList = () => (
      <div style={{ maxHeight: "200px", overflowY: "auto", padding: "8px" }}>
        {item?.users?.map((user, idx) => (
          <div
            key={idx}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "4px",
              fontSize: "14px",
            }}
          >
            <Avatar
              size="small"
              style={{
                backgroundColor: getRandomColor(idx),
                color: "#000", // Black text for visibility
                marginRight: "8px",
              }}
            >
              {user?.first_name?.charAt(0).toUpperCase()}
            </Avatar>
            {user?.first_name}
          </div>
        ))}
      </div>
    );
  
    return (
      <div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
        {userCount > 0 ? (
          <>
            {item?.users?.slice(0, 2).map((user, index) => (
              <Tooltip key={index} title={`${user?.first_name}`}>
                <Avatar
                  style={{
                    backgroundColor: getRandomColor(index), // Assign pastel color
                    color: "#000", // Black text
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {user?.first_name?.charAt(0).toUpperCase()}
                </Avatar>
              </Tooltip>
            ))}
            {userCount > 2 && (
              <Popover
                content={renderUserList()} // Render all users in the popover
                title="Assigned Users"
                trigger="hover"
                placement="top"
              >
                <Avatar
                  style={{
                    backgroundColor: "#fde4cf",
                    color: "#000", // Black text
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  +{userCount - 2}
                </Avatar>
              </Popover>
            )}
          </>
        ) : (
          "N/A"
        )}
      </div>
    );
  };

  const EmailViewedByPopover = ({ item }) => {
    const content = (
      <div
        className="color-even-odd"
        style={{
          columnGap: "3px",
          maxWidth: "500px",
          minWidth: "200px",
          maxHeight: "300px",
          overflow: "auto",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {Array.isArray(item?.readBy) && item?.readBy?.length > 0 ? (
          item?.readBy?.map((email, index) => (
            <div
              style={{
                textAlign: "start",
                marginBottom: "7px",
              }}
              className="list_view_tags"
              key={index}
            >
              {index + 1}. {email}
            </div>
          ))
        ) : item?.readBy ? (
          <div className="list_view_tags">1. {item?.readBy}</div>
        ) : (
          "N/A"
        )}
      </div>
    );

    return (
      <Popover
        content={content}
        title="Email Read By"
        arrow
        trigger={["click", "hover"]}
        onClick={(e) => e.stopPropagation()}
      >
        {Array.isArray(item?.readBy) && item?.readBy?.length > 0 ? (
          <span style={{ display: "flex", justifyContent: "center" }}>
            {item?.readBy?.slice(0, 2).map((email, index) => (
              <span
                className="list_view_tags"
                style={{ maxWidth: "125px" }}
                key={index}
              >
                {email}
              </span>
            ))}
            {item?.readBy.length > 1 && (
              <span style={{ color: "#12406a", fontWeight: "500" }}>
                +{item.readBy.length - 1}{" "}
              </span>
            )}
          </span>
        ) : item?.readBy ? (
          <span className="list_view_tags" style={{ maxWidth: "125px" }}>
            {String(item?.readBy)}
          </span>
        ) : (
          <span className="ReadBy-text">N/A</span>
        )}
      </Popover>
    );
  };

  const ListCheckbox = ({ item }) => {
    const content = <div>HIIII</div>;
    return (
      <Popover content={content} trigger={["click"]}>
        <div>
          <Checkbox
            key={item["_id"] || item?.id}
            // checked={selectedItems.includes(item)}
            checked={selectedItems.some(
              (selectedItem) =>
                (selectedItem._id || selectedItem.id) === (item._id || item.id)
            )}
            // onChange={(e) => {
            //   e.stopPropagation();
            // }}
            onClick={(e) => {
              e.stopPropagation();
              handleSelectItem(e, item);
            }}
            style={{ marginRight: "10px" }}
          />
        </div>
      </Popover>
    );
  };

  const handleBulkAction = (eventType) => {
    let payload = [];
    let Action = true;
    if (
      (eventType === "markRead" && Action === true) ||
      (eventType === "markAsUnread" && Action === true)
    ) {
      // console.log("selecteditems", selectedItems);
      payload = selectedItems?.map((email) => ({
        context: "fix@theoceann.ai", // This vlaue is static here because Backend dev(ARUN) Told me that it will not be a problem.
        event: "markRead",
        isRead: eventType === "markRead" ? true : false,
        _id: email["_id"] || email["id"],
      }));
      eventType === "markRead" && Action === true
        ? openNotificationWithIcon("success", "Marked as Read")
        : openNotificationWithIcon("success", "Marked as Unread");
      Action = false;
    } else if (eventType === "deleteMail") {
      payload = selectedItems?.map((email) => ({
        GmailMessageId: email?.GmailMessageId,
        context: "inbox",
        event: eventType,
        inbox: "fix@theoceann.ai",
        _id: email["_id"] || email["id"],
      }));
      Action &&
        selectedItems.length === 1 &&
        openNotificationWithIcon("success", "Email Deleted");
      Action &&
        selectedItems.length > 1 &&
        openNotificationWithIcon("success", "Emails are Deleted");
      Action = false;
    } else if (eventType === "spamMail") {
      payload = selectedItems?.map((email) => ({
        GmailMessageId: email?.GmailMessageId,
        Spam: true,
        context: "fix@theoceann.ai",
        event: eventType,
        _id: email["_id"] || email["id"],
      }));
      Action && openNotificationWithIcon("success", "Marked as Spam");
      Action = false;
    } else if (
      eventType === "markImportant" ||
      eventType === "markAsUnimportant"
    ) {
      payload = selectedItems?.map((email) => ({
        important: eventType === "markImportant" ? true : false,
        context: "fix@theoceann.ai",
        event: "markImportant",
        _id: email["_id"] || email["id"],
      }));
      eventType === "markImportant" &&
        Action &&
        openNotificationWithIcon("success", "Marked as Important");
      !(eventType === "markImportant") &&
        Action &&
        openNotificationWithIcon("success", "Marked as UnImportant");
      // setIsRemainsSelected(true);
    }

    // console.log({payload})
    if (
      window.mySocketMail &&
      window.mySocketMail.readyState === WebSocket.OPEN
    ) {
      // Send the payload via WebSocket
      setFilterSpin(true);
      window.mySocketMail.send(JSON.stringify(payload));

      // Update state based on eventType
      if (eventType === "deleteMail") {
        const selectedIds = new Set(
          selectedItems.map((selected) => selected._id)
        );

        // Define the updateEmails function for filtering out selected emails
        const updateEmails = (emails) => {
          return emails.filter((email) => !selectedIds.has(email._id));
        };
        // setMailData(updatedEmails);
        if (isFilters || isDateFilterActive) {
          let data = updateEmails(filterData);
          // console.log({ data });
          dispatch(setFilterData({ emails: data }));
        } else {
          setMailData((prevMailData) => updateEmails(prevMailData));
        }
        setSelectedItems([]);
      } else if (eventType === "spamMail") {
        // Remove spammed emails from the state
        // setMailData((prevEmails) =>
        //   prevEmails.filter(
        //     (email) =>
        //       !selectedItems.some((selected) => selected._id === email._id)
        //   )
        // );
        const updateEmails = (emails) =>
          emails.filter(
            (email) =>
              !selectedItems.some((selected) => selected._id === email._id)
          );
        if (isFilters || isDateFilterActive) {
          let data = updateEmails(filterData);
          // console.log({ data });
          dispatch(setFilterData({ emails: data }));
        } else {
          setMailData((prevMailData) => updateEmails(prevMailData));
        }
        setSelectedItems([]);
      } else if (eventType === "markRead" || eventType === "markAsUnread") {
        const updateEmails = (emails) =>
          emails.map((email) =>
            selectedItems.some((selected) => selected._id === email._id)
              ? { ...email, isRead: eventType === "markRead" }
              : email
          );
        if (isFilters || isDateFilterActive) {
          let data = updateEmails(filterData);
          // console.log({ data });
          dispatch(setFilterData({ emails: data }));
        } else {
          setMailData((prevMailData) => updateEmails(prevMailData));
        }
      } else if (eventType === "markImportant") {
        const updateEmails = (emails) => {
          return emails.map((email) => {
            // Check if the email's _id is in the selectedItems array
            const isSelected = selectedItems.some(
              (selected) => selected._id === email._id
            );

            // If the email is selected, mark it as important, otherwise return the original email
            return isSelected ? { ...email, important: true } : email;
          });
        };
        if (isFilters || isDateFilterActive) {
          let data = updateEmails(filterData);
          // console.log({ data });
          dispatch(setFilterData({ emails: data }));
        } else {
          setMailData((prevMailData) => updateEmails(prevMailData));
        }
      } else if (eventType === "markAsUnimportant") {
        const updateEmails = (emails) => {
          return emails.map((email) => {
            // Check if the email's _id is in the selectedItems array
            const isSelected = selectedItems.some(
              (selected) => selected._id === email._id
            );

            // If the email is selected, mark it as important, otherwise return the original email
            return isSelected ? { ...email, important: false } : email;
          });
        };
        if (isFilters || isDateFilterActive) {
          let data = updateEmails(filterData);
          // console.log({ data });
          dispatch(setFilterData({ emails: data }));
        } else {
          setMailData((prevMailData) => updateEmails(prevMailData));
        }
      }

      // setSelectedItems();
      setFilterSpin(false);
      Action = false;

      // } else {
      //   console.error("Acknowledgment not received.");
      //   setFilterSpin(false);
      // }
    }
    // } else {
    //   console.error("WebSocket connection is not open");
    //   setFilterSpin(false);
    // }
  };

  // Handler functions for different actions
  const handleMarkAsRead = () => handleBulkAction("markRead");
  const handleMarkAsUnread = () => handleBulkAction("markAsUnread");
  const handleDeleteMails = () => handleBulkAction("deleteMail");
  const handleMoveToSpam = () => handleBulkAction("spamMail");
  const handleMarkAsImportant = () => handleBulkAction("markImportant");
  const handleMarkAsUnimportant = () => handleBulkAction("markAsUnimportant");

  const handleFilterSearch = (query) => {
    const trimmedQuery = query.replace(/^\s+/, "");
    setQuery(trimmedQuery);
    // if (trimmedQuery.length > 0) {
    // handleSearch(trimmedQuery);
    //   dispatch(setIsSearcihng(true));
    // } else {
    //   openNotificationWithIcon("info", "Please Enter some text to search.");
    //   setMailData(allMails);
    // }
  };

  // const handleSearchingTyping = (query) => {
  //   const trimmedQuery = query.trim();
  //   console.log({trimmedQuery})
  //   if (trimmedQuery.length > 3) {
  //     dispatch(setIsSearcihng(true));
  //     debouncedSearch(trimmedQuery);
  //   } else {
  //     setSuggestionList([]);
  //     setModalOpen(false)
  //     setMailData(allMails);
  //   }
  // };

  const handleKeyDown = (event) => {
    const query = event.target.value.trim();
    if (event.key === "Enter" && query.length >= 3) {
      // dispatch(setIsSearcihng(true));
      suggestionApiCall(query);
    } else if (query.length < 3) {
      setSuggestionList([]);
      setModalOpen(false);
      setMailData(allMails);
    }
  };

  // const debouncedSearch = useCallback(
  //   debounce((value) => {
  //     if (value?.trim()?.length > 0) {
  //       suggestionApiCall(value);
  //       // handleSearch(value);
  //     }else{
  //       setSuggestionList([]);
  //       setModalOpen(false)

  //     }
  //   }, 300),
  //   []
  // );

  const handleMarkMailIsRead = async (payload) => {
    const handleAcknowledgment = (response) => {
      if (response?.content?.AcknowledgmentID) {
        // message.success("Email Marked as Read.");
        const userData = localStorage.getItem("oceanToken")
          ? JSON.parse(
              atob(localStorage.getItem("oceanToken")?.split(".")[1] ?? "")
            )
          : null;
        const dataToMap =
          isFilters || isDateFilterActive ? filterData : mailData;
        const updatedMails = dataToMap.map((mail) => {
          if (mail._id === response?.content?.AcknowledgmentID) {
            return {
              ...mail,
              isRead: !mail.isRead, // Toggle the important value
              readBy: mail?.readBy
                ? [...mail?.readBy, userData?.email]
                : [userData?.email],
            };
          }
          return mail;
        });
        if (isFilters || isDateFilterActive) {
          dispatch(setFilterData({ emails: updatedMails }));
        } else {
          setMailData(updatedMails);
        }

        // return response;
        return true;
      } else {
        // message.error("Failed to send mail");
        return false;
      }
    };

    await sendWebSocketMessage("markRead", payload, handleAcknowledgment);
  };
  const getTimestamp = (item) => {
    if (item.Threads && item.Threads.length > 0) {
      return item.Threads[item.Threads.length - 1].TIMESTAMP;
    }
    return item.Formatted_Date || item.TIMESTAMP;
  };

  const displayTime = (item) => {
    const timestamp = getTimestamp(item);
    // return timestamp ? formatTimestamp(timestamp) : timeHandler(item);
    return timeHandler(item)
  };

  // const paginationHandler = (action) => {
  //   if (action === "next" && page < totalMailCount) {
  //     setPage(page+1)
  //   } else if (action === "prev" && page > 1) {
  //     setPage(page-1)
  //   }
  // };

  // function checkFilterIsEmpty(obj) {
  //   const allEmpty = Object.values(obj).every(
  //     (arr) => Array.isArray(arr) && arr.length === 0
  //   );

  //   if (allEmpty) {
  //     setPage(1);
  //     setIsfilters(false);
  //     dispatch(setFilterPayload([]));
  //     if (mailType?.trim().length > 0) {
  //       fetchEmails(mailType);
  //       fetchEmailCount(mailType);
  //     }
  //   } else {
  //     handleFilterSubmit();
  //   }
  // }

  // useEffect(() => {
  //   if (hasMounted.current) {
  //     checkFilterIsEmpty(filters);
  //   } else {
  //     hasMounted.current = true;
  //   }
  // }, [filters]);
  // console.log(unique,' jgdytdeufjmdkm d')
  const checkRestrictedTask = (email, type) => {
    // console.log("restrictiondataa",restrictionData)
    const user = restrictionData?.filter(
      (ele) => ele.emailAccount?.import_email === email
    );
    // console.log("isallowed", user)
    // console.log("type", type)
    if (type == "delete" && user?.[0]?.deleteEmail == false) {
      Swal.fire({
        title: "User doesn't have access to delete mails",
        text: "Please contact the administrator",
      });
      return false;
    } else {
      return true;
    }
  };

  const suggestionApiCall = async (reqBody) => {
    setFilterSpin(true);
    const url = `${process.env.REACT_APP_MT_URL}analytic/advance-search`;
    const payload = { query: reqBody };

    try {
      const res = await postAPICall(url, payload, "post");
      // console.log("reshjhj", res);

      if (res?.status == true) {
        setSuggestionList(res?.data);
        setFilterSpin(false);
        // Debounced function to open the modal
        debounce(() => {
          handleOpen();
        }, 1000)();
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  // console.log(selectedItems, "selected ");

  const getAssignedMailOptions = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}accounts/get-users`;
      const response = await getAPICall(url);
      // console.log("response", response);
      if (response && Array.isArray(response)) {
        setAssignTo(response || []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="list-view">
      {!openCurrentMail ? (
        <>
          {selectedItems?.length > 0 && (
            <div className="selected_items_box">
              <Tooltip title="Marked Read.">
                <div onClick={handleMarkAsRead}>
                  <Icon icon={"tabler:mail-opened"} />
                </div>
              </Tooltip>

              <Tooltip title="Marked as Unread.">
                <div onClick={handleMarkAsUnread}>
                  <Icon icon={"tabler:mail"} />
                </div>
              </Tooltip>

              <Tooltip title="Delete Selected Mails.">
                <Popconfirm
                  title="Delete Selected Mails"
                  description="Are you sure to delete these mail?"
                  onConfirm={handleDeleteMails}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <div>
                    <Icon
                      icon={"tabler:trash"}
                      style={{ color: "red" }}
                      // onClick={handleDeleteMails}
                    />
                  </div>
                </Popconfirm>
              </Tooltip>

              <Tooltip title="Move to Spam.">
                <div onClick={handleMoveToSpam}>
                  <Icon
                    icon={"tabler:alert-octagon"}
                    style={{ color: "gray" }}
                  />
                </div>
              </Tooltip>

              <Tooltip title="Mark as Important.">
                <div onClick={handleMarkAsImportant}>
                  <Icon
                    icon={"tabler:star-filled"}
                    style={{ color: "goldenrod" }}
                  />
                </div>
              </Tooltip>

              <Tooltip title="Mark as Unimportant.">
                <div onClick={handleMarkAsUnimportant}>
                  <Icon icon={"tabler:star"} style={{ color: "goldenrod" }} />
                </div>
              </Tooltip>
            </div>
          )}

          <div className="list-view-filter-table">
            <table>
              <tbody>
                <tr>
                  <td colSpan={8}>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div className="mail_filter_row_box">
                        <div className="mail_filter_row">
                          <div
                            className="filter_icons"
                            onClick={() => setIsFilterOpen(!isFilterOpen)}
                          >
                            <Icon icon={"tabler:filter"} />
                          </div>

                          <Drawer
                            mask={false}
                            className="mail_filter_drawer"
                            title="Search Filters :"
                            onClose={() => setIsFilterOpen(false)}
                            open={isFilterOpen}
                          >
                            <SearchFilters />
                          </Drawer>
                          <div style={{ width: "50%" }}>
                            <Popover
                              content={
                                <>
                                  {suggestionList?.length > 0 && (
                                    <SuggestionBox
                                      suggestionLists={suggestionList}
                                      close={handleClose}
                                      setMailDetailsOpen={handleMailClick}
                                    />
                                  )}
                                </>
                              }
                              open={modalOpen && suggestionList?.length > 0}
                              destroyTooltipOnHide={true}
                              onOpenChange={(visible) => setModalOpen(visible)}
                              trigger="click" // You can change the trigger as needed (e.g., hover)
                              placement="bottomLeft" // Position the popover below the input
                            >
                              <Input
                                type="text"
                                placeholder="Search for emails..."
                                // onPressEnter={(e) =>
                                //   handleFilterSearch(e.target.value)
                                // }
                                value={reducSeachQuery}
                                onKeyDown={(e) => e.preventDefault()}

                                // onChange={(e) =>
                                //   handleSearchingTyping(e.target.value)
                                // }
                                // onKeyDown={handleKeyDown}
                                onClick={()=> {
                                  handleOpenSearchBox()
                                  handleSearchGetAPI()
                                }}
                              />
                            </Popover>
                          </div>
                          <Modal
                          open={openSearchBox}
                          onCancel={handleCloseSearchBox}
                          footer={null}
                          closable={false}
                          mask={false}
                          style={{ top: "3rem", transform: "translateX(-50%)", width:"500px" }}
                          bodyStyle={{
                            width: "480px",
                            padding: "1rem",
                            backgroundColor: "#fafafa",
                            border: "1px solid black",
                            borderRadius: "8px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          {/* Search Bar */}
                          
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap:4 }}>
                          <AutoComplete
                            style={{ width: '500px' }}
                            options={advancedSearchData?.term || advancedSearchData || []}
                            filterOption={false}
                            onSearch={(val) => {
                              if (!val) {
                                setSelectedQuery(val);
                                dispatch(setSearchQuery(val));
                                return;
                              }

                              handleDebouncedSearch(val,selectedChoice);
                            }}
                            onSelect={(val, option) => {
                              if (!val) {
                                setSelectedQuery(val);
                                dispatch(setSearchQuery(val));
                                return;
                              }

                              if (option.payload) {
                                const hasAtAndDot = /.*@.*\..*/.test(option.term);
                                if (hasAtAndDot) {
                                  const output = option.term.replace(/<[^>]*>/g, '');
                                  handleSearchPostAPI(output);
                                  setSelectedQuery(output);
                                  dispatch(setSearchQuery(output));
                                } else {
                                  handleViewMailClick(option.payload);
                                  const output = option.term.replace(/<[^>]*>/g, '');
                                  if(output){
                                    dispatch(setSearchQuery(output));
                                  }
                                  handleCloseSearchBox()
                                }
                              } else {
                                const output = val.replace(/<[^>]*>/g, '');
                                if(output){
                                  setSelectedQuery(output);
                                  dispatch(setSearchQuery(output));
                                  handleSearchPostAPI(output);
                                }
                              }
                            }}
                          >
                            <Input
                              placeholder="Search..."
                              autoFocus
                              value={selectedQuery}
                              onChange={(e) => {
                                setSelectedQuery(e.target.value);
                                dispatch(setSearchQuery(e.target.value));
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  if (!e.target.value.trim()) {
                                    message.info('Enter any query...');
                                    return;
                                  }
                                  handleSearchPostAPI(e.target.value);
                                  dispatch(setPage(1));
                                    setSelectedQuery('');
                                  }
                                }}
                              />
                            </AutoComplete>
                            <CloseOutlined
                              style={{
                                fontSize: "24px",
                                color: "#003e78",
                                padding: "4px",
                                border: "1px solid #003e78",
                                borderRadius: "50%",
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              }}
                              onClick={handleCloseSearchBox}
                            />
                          </div>

                          {/* Search Choices */}
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "8px",
                              padding: "8px",
                              alignItems: "center",
                            }}
                          >
                            {advancedSearchChoices.map((item, index) => (
                              <div
                                key={item}
                                onClick={() => {
                                  setSelectedChoice(item);
                                  dispatch(setSearchChoice(item));
                                  const fileObjects = advancedSearchValues[item].map(file => ({ value: file, label: (
      
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: file,
                                      }}
                                    />
                                ), }));
                                  setAdvancedSearchData(fileObjects);
                                }}
                                style={{
                                  fontSize: "12px",
                                  cursor: "pointer",
                                  textAlign: "center",
                                  border: selectedChoice === item ? "1px solid #000000" : `1px solid ${textColors[index % textColors.length]}`,
                                  color: selectedChoice === item ? "#ffffff" : textColors[index % textColors.length],
                                  backgroundColor: selectedChoice === item ? "#003e78" : backgroundColors[index % backgroundColors.length],
                                  borderRadius: "50px",
                                  padding: "4px 8px",
                                  whiteSpace: "nowrap",
                                  transition: "transform 0.3s ease",
                                  boxShadow: selectedChoice === item ? "1px 1px 1px black" : "none",
                                }}
                              >
                                {item}
                              </div>
                            ))}
                          </div>
                        </Modal>

                          <Dropdown
                            overlay={menu}
                            trigger={["click"]}
                            placement="bottomLeft"
                            arrow
                          >
                            <div className="filter_icons">
                              <Icon icon={"tabler:calendar"} />
                            </div>
                          </Dropdown>
                          {readUnreadCount && readUnreadCount?.status && (
                            <div className="read-unread-count">
                              <div className="read-section">
                                <span>
                                  <Icon
                                    icon={"mingcute:mail-open-line"}
                                    className="icon"
                                  />
                                </span>
                                Read Emails :{" "}
                                {readUnreadCount?.markedAsRead || 0}
                              </div>

                              <div className="unread-section">
                                <span>
                                  <Icon
                                    icon={"ic:outline-mail"}
                                    className="icon"
                                  />
                                </span>
                                Unread Emails :{" "}
                                {readUnreadCount?.markedAsUnread || 0}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="mail-pagination">
                        <span>
                          {Math.min(
                            (page - 1) * EMAILS_PER_PAGE + 1,
                            totalMailCount
                          )}{" "}
                          to {Math.min(page * EMAILS_PER_PAGE, totalMailCount)}{" "}
                          of {formatNumber(totalMailCount || 0)}
                        </span>
                        <span
                          // className="mr-2"
                          style={{
                            // border: "1px solid gray",
                            // borderRadius: "10px",
                            // padding: "2px 5px",
                            cursor: "pointer",
                          }}
                          onClick={() => paginationHandler("prev")}
                        >
                          <Icon
                            style={{ color: "#12406a", fontSize: "29px" }}
                            className=""
                            icon={"ion:chevron-back-circle"}
                          />
                        </span>
                        <span
                          style={{
                            // border: "1px solid gray",
                            // borderRadius: "10px",
                            // padding: "2px 5px",
                            cursor: "pointer",
                          }}
                          onClick={() => paginationHandler("next")}
                        >
                          <Icon
                            style={{ color: "#12406a", fontSize: "29px" }}
                            className=""
                            icon={"fluent:chevron-circle-right-48-filled"}
                          />
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr style={{ borderRight: "1px solid black" }}>
                  <td
                    style={{
                      borderRight: "1px solid black",
                      paddingTop: "25px",
                      paddingLeft: "15px",
                      width: "3%",
                    }}
                  >
                    <Checkbox
                      id="selectall"
                      onChange={handleSelectAll}
                      checked={selectAll}
                    />
                  </td>
                  <td style={{ width: "8%", borderRight: "1px solid black" }}>
                    <div>
                      <b>Received Date</b>
                    </div>
                    <RangePicker
                      value={null}
                      disabledDate={disabledDate}
                      onChange={(event, dateString) =>
                        onChange(event, "date", dateString)
                      }
                    />
                  </td>
                  <td style={{ width: "13%", borderRight: "1px solid black" }}>
                    <div>
                      <b>Sender</b>
                    </div>
                    <AutoComplete
                      placeholder="Search Sender"
                      style={{ width: 200 }}
                      onSearch={(text)=> {
                        handleViewedBy()
                        
                        handleDebouncedSearch(text,"From")
                      }}
                      value={filters?.Sender?.[0]}
                      onSelect={(value) => handleChange("Sender", value)}
                      allowClear
                      // options={unique?.viewMails || []}
                      options={
                        senderSuggestions.length > 0 ? senderSuggestions.map((user) => ({
                          value: user.value , // Ensure it's a valid string
                          label: user.label, // Ensure it's a valid string
                        })) : unique?.unique_emails?.length > 0
                          ? unique?.unique_emails?.map((user) => ({
                              value: user,
                              label: `${
                                user?.charAt(0)?.toUpperCase() + user?.slice(1)
                              } User`,
                            }))
                          : []
                      }
                      // onChange={(value)=>setFilterText({...filterText,sender:value})}
                      // value={filterText?.sender}
                    />
                  </td>
                  <td style={{ width: "21%", borderRight: "1px solid black" }}>
                    <div>
                      <b>Subject</b>
                    </div>

                    <Select
                      allowClear
                      showSearch
                      // maxTagCount={0}
                      mode="multiple"
                      placeholder="Subject"
                      value={filters?.subject?.[0]}
                      onPressEnter={(e) =>
                        handleChange("subject", e.target?.value)
                      }
                      onSearch={(text)=> {
                        
                        handleDebouncedSearch(text,"subject")
                      }}
                      // onChange={(value) => handleSelectChange(value, "tags")}
                    >
                      {sub?.unique_subjects?.map((item) => (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td style={{ width: "14%", borderRight: "1px solid black" }}>
                    <div>
                      <b>Viewed By</b>
                    </div>
                    <AutoComplete
                      placeholder="Search Viewed by"
                      onSearch={seacrViwedByMail}
                      value={filters?.viewedBy?.[0]}
                      allowClear
                      // value={filters?.viewedBy[0]}
                      onSelect={(value) => handleChange("viewedBy", value)}
                      // options={[]}
                      options={
                        viwedBy?.length > 0
                          ? viwedBy?.map((user) => ({
                              value: user,
                              label: `${
                                user?.charAt(0)?.toUpperCase() + user?.slice(1)
                              }`,
                            }))
                          : []
                      }
                    />
                  </td>

                  <td style={{ width: "10%", borderRight: "1px solid black" }}>
                    <div>
                      <b>Assign To</b>
                    </div>
                    <Select
                      allowClear
                      showSearch
                      maxTagCount={0}
                      mode="multiple"
                      placeholder="Choose Assign To"
                      value={filters?.users?.map((item) => item.id)}
                      onChange={(selectedIds) => {
                        const selectedItems = assignTo.filter((item) =>
                          selectedIds.includes(item.id)
                        );
                        handleSelectChange(selectedItems, "users");
                      }}
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {assignTo?.map((item) => (
                        <Select.Option
                          value={item.id}
                          key={item.id}
                          label={item.first_name}
                        >
                          {item?.first_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>

                  <td style={{ width: "15%", borderRight: "1px solid black" }}>
                    <div>
                      <b>Tags</b>
                    </div>
                    <Select
                      allowClear
                      showSearch
                      maxTagCount={0}
                      mode="multiple"
                      placeholder="Choose Tags"
                      value={filters?.tags}
                      onSearch={(text)=> {
                        
                        handleDebouncedSearch(text,"tags")
                      }}
                      onChange={(value) => handleSelectChange(value, "tags")}
                    >
                      {unique?.unique_tags_mails?.map((item) => (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td style={{ width: "12%", borderRight: "1px solid black" }}>
                    <div>
                      <b>Mail type</b>
                    </div>
                    <Select
                      placeholder="Mail Type"
                      mode="multiple"
                      maxTagCount={0}
                      value={filters?.mailType}
                      onChange={(value) =>
                        handleSelectChange(value, "mailType")
                      }
                    >
                      <Select.Option value="cargo">Cargo</Select.Option>
                      <Select.Option value="tonnage">Tonnage</Select.Option>
                      <Select.Option value="fixture">Fixture</Select.Option>
                      <Select.Option value="other">Other</Select.Option>
                    </Select>
                  </td>
                  {/* <td style={{ width: "12%", borderRight: "1px solid black" }}>
                    <div>
                      <b>Receivers</b>
                    </div>
                    <Select
                      allowClear
                      maxTagCount={0}
                      value={filters?.reciver}
                      showSearch
                      mode="multiple"
                      placeholder="Choose Receivers"
                      onChange={(value) => handleSelectChange(value, "reciver")}
                    >
                      {unique?.unique_receiver_mails?.map((item) => (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </td> */}
                </tr>

                {/* Second row for displaying selected filters */}
                <tr className="chips-table-row">
                  <td style={{ width: "89px" }}></td>
                  <td>{renderSelectedChips("date", filters.date)}</td>
                  <td>{renderSelectedChips("Sender", filters.Sender)}</td>
                  <td>{renderSelectedChips("subject", filters.subject)}</td>
                  <td>{renderSelectedChips("viewedBy", filters.viewedBy)}</td>
                  <td>{renderSelectedChips("users", filters.users)}</td>
                  <td>{renderSelectedChips("tags", filters.tags)}</td>
                  <td>{renderSelectedChips("mailType", filters.mailType)}</td>
                  {/* <td>{renderSelectedChips("reciver", filters.reciver)}</td> */}
                </tr>
              </tbody>
            </table>
          </div>

          {/* <div
            className="list-view-title-row-box"
            style={{ position: "sticky", top: "0" }}
          >
            <table className="list-view-title-row">
              <tbody>
                <tr>
                  <td style={{ width: "75px" }}>Select</td>
                  <td style={{ width: "95px" }}>Timestamp</td>
                  <td style={{ width: "250px" }}>Sender</td>
                  <td style={{ width: "300px" }}>Subject</td>

                  <td style={{ width: "199px" }}>Viewed By</td>
                  <td>Tags</td>
                  <td style={{ width: "199px" }}>Mail type/Vessels</td>
                  <td>Receivers</td>
                </tr>
              </tbody>
            </table>
          </div> */}

          <div
            id="scrollableDiv"
            className="mail_scrolls"
            style={{
              height: "85vh",
              overflowY: "scroll",
              //padding: "0 16px",
              //border: "1px solid rgba(140, 140, 140, 0.35)",
            }}
          >
            {/* <InfiniteScroll
              dataLength={mailData?.length||0}
              next={loadMoreData}
              hasMore={notMore}
              // loader={
              //   <>
              //     {[...Array(5).keys()].map((item) => (
              //       <Skeleton
              //         avatar
              //         paragraph={{
              //           rows: 1,
              //         }}
              //         // active
              //       />
              //     ))}
              //   </>
              // }
              endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
              scrollableTarget="scrollableDiv"
            > */}
            <List
              dataSource={
                isFilters || isDateFilterActive ? filterData : mailData
              }
              split
              renderItem={(item, index) => {
                // console.log(item)
                const ccBccList = returnccbcc(item);
                const colors = [
                  "tomato",
                  "violet",
                  "orange",
                  "blue",
                  "green",
                  "yellow",
                  "pink",
                ];
                return (
                  <List.Item
                    key={item.email}
                    onClick={() => handleMailClick(item)}
                    className={`list-item-hover-${(index % 3) + 1}`}
                    style={{
                      backgroundColor: item?.isRead
                        ? "#ecf2f54d"
                        : "hsl(204deg 91.42% 90.82% / 30%)",
                    }}
                  >
                    <div style={{ width: "3%" }}>
                      <ListCheckbox item={item} />
                    </div>
                    <List.Item.Meta
                      avatar={
                        <div
                          style={{
                            width: "80px",
                            fontWeight: "600",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: item?.isRead ? "normal" : "600",
                            }}
                          >
                            {/* {item.Formatted_Date || item.TIMESTAMP
                              ? formatTimestamp(
                                  item.Formatted_Date || item.TIMESTAMP
                                )
                              : timeHandler(item)} */}
                            {displayTime(item)}
                          </div>
                          {/* Uncomment if you need to show sender initials */}
                          {/* <div className="user_mail_profile">
                          {getInitial(item?.Sender)}
                        </div> */}
                        </div>
                      }
                      // title={item.Sender}
                      description={
                        <>
                          <div className="mail_list_view_container">
                            <div className="mail_list_description">
                              <div className="mail_list_sub">
                                <Tooltip
                                  title={
                                    <div className="sender-tool-tip">
                                      <div>
                                        <Avatar
                                          size="large"
                                          style={{
                                            background:
                                              avtarColorObject[
                                                item?.sender?.name
                                                  ?.replace(/[^a-zA-Z0-9]/g, "")
                                                  .slice(0, 1)
                                                  .toUpperCase() ||
                                                  item?.sender?.emailAddress?.name
                                                    ?.replace(
                                                      /[^a-zA-Z0-9]/g,
                                                      ""
                                                    )
                                                    .slice(0, 1)
                                                    .toUpperCase()
                                              ],
                                          }}
                                        >
                                          {getInitial(
                                            item.Sender ||
                                              item?.sender?.emailAddress
                                                ?.name ||
                                              item?.owner_email
                                          )}
                                        </Avatar>
                                      </div>
                                      <div className="sub-details">
                                        <span
                                        // style={{
                                        //   fontWeight: item?.isRead
                                        //     ? "normal"
                                        //     : "600",
                                        // }}
                                        >
                                          {capitaliseFirstLetter(
                                            item.Sender ||
                                              item?.sender?.emailAddress
                                                ?.name ||
                                              item?.owner_email ||
                                              "N/A"
                                          )}
                                        </span>
                                        <span>
                                          {capitaliseFirstLetter(
                                            item.Sender ||
                                              item?.sender?.emailAddress
                                                ?.address ||
                                              item?.owner_email ||
                                              "N/A"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  }
                                >
                                  <span style={{ margin: "5px" }}>
                                    <Avatar
                                      size="small"
                                      style={{
                                        background:
                                          avtarColorObject[
                                            item?.sender?.name
                                              ?.replace(/[^a-zA-Z0-9]/g, "")
                                              .slice(0, 1)
                                              .toUpperCase() ||
                                              item?.sender?.emailAddress?.name
                                                ?.replace(/[^a-zA-Z0-9]/g, "")
                                                .slice(0, 1)
                                                .toUpperCase()
                                          ],
                                        // colors[index % colors.length], // Assign color based on index
                                      }}
                                    >
                                      {getInitial(
                                        item.Sender ||
                                          item?.sender?.emailAddress?.name ||
                                          item?.owner_email
                                      )}
                                    </Avatar>
                                  </span>
                                  <span
                                    style={{
                                      fontWeight: item?.isRead
                                        ? "normal"
                                        : "600",
                                    }}
                                  >
                                    {capitaliseFirstLetter(
                                      item.Sender ||
                                        item?.sender?.emailAddress?.name ||
                                        item?.owner_email ||
                                        "N/A"
                                    )}
                                  </span>
                                </Tooltip>
                                {item?.Threads && item?.Threads.length > 0 ? (
                                  <Tooltip
                                    title={`Thread count ${
                                      item?.Threads ? item?.Threads.length : ""
                                    }`}
                                  >
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        padding: "2px 6px",
                                        color: "green",
                                      }}
                                    >
                                      {item?.Threads
                                        ? item?.Threads.length
                                        : ""}
                                    </span>
                                  </Tooltip>
                                ) : null}
                              </div>
                              <Tooltip
                                title={
                                  Array.isArray(item?.readBy)
                                    ? item.readBy.map((data, index) => (
                                        <div key={index}>{String(data)}</div>
                                      ))
                                    : item?.readBy
                                    ? String(item.readBy)
                                    : "unread email"
                                }
                              >
                                <div style={{ marginLeft: "4px" }}>
                                  <Icon
                                    icon="mdi:email-outline"
                                    fontSize={14}
                                    style={{ color: "#1C5CE9" }}
                                  />
                                </div>
                              </Tooltip>
                              <div style={{ marginLeft: "4px" }}>
                                <Tooltip
                                  title={
                                    ccBccList.length > 0
                                      ? ccBccList?.map((ccItem, i) => (
                                          <div key={i}>
                                            {ccItem?.name || ccItem?.email}
                                          </div>
                                        ))
                                      : "N/A"
                                  }
                                >
                                  <div>
                                    <Icon
                                      icon="ph:users"
                                      fontSize={14}
                                      style={{ color: "#00712D" }}
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                              {item?.labelIds?.includes("DRAFT") && (
                                <span style={{ color: "#dd4b39" }}>
                                  (Draft)
                                </span>
                              )}
                              {item.Subject || item.subject ? (
                                <div
                                  className="mail_list_snippet"
                                  style={{
                                    fontWeight: item?.isRead
                                      ? "normal"
                                      : "bold",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: item?.subject || item?.Subject,
                                  }}
                                >
                                  {/* {item.subject || item.Subject} */}
                                </div>
                              ) : (
                                <div
                                  className="mail_list_snippet"
                                  style={{
                                    fontWeight: item?.isRead
                                      ? "normal"
                                      : "bold",
                                  }}
                                  // dangerouslySetInnerHTML={{ __html: item?.subject || item?.Subject }}
                                >
                                  {/* {item.subject || item.Subject} */}
                                  (no subject)
                                </div>
                              )}

                              <div
                                style={{ width: "15%", textAlign: "center" }}
                              >
                                <EmailViewedByPopover item={item} />
                                {/* {item?.readBy && Array.isArray(item?.readBy) ? (
                                  item?.readBy?.map((readByItem, i) => (
                                    <span key={i} className="ReadBy-text">
                                      {String(readByItem)}
                                    </span> // Convert to string
                                  ))
                                ) : item?.readBy ? (
                                  <span className="ReadBy-text">
                                    {String(item?.readBy)}
                                  </span> // Convert to string
                                ) : (
                                  <span className="ReadBy-text">N/A</span>
                                )} */}
                              </div>

                              <div
                                style={{ width: "10%", textAlign: "center" }}
                              >
                                <AssignToByPopover item={item} />
                              </div>

                              <div className="list_view_tags_box">
                                <TagsPopover item={item} />
                              </div>

                              <div className="mail_list_labels mailType">
                                <Tooltip title="Mail Type">
                                  {item?.Processing ? (
                                    <span
                                      className={`mail_list_badge ${item.MailType}`}
                                      style={{
                                        backgroundColor: "rgb(255, 227, 200)",
                                        color: "rgb(141, 75, 13)",
                                        borderColor:
                                          chipStyles[index % chipStyles.length]
                                            .color,
                                      }}
                                    >
                                      Analysing
                                    </span>
                                  ) : (
                                    <span
                                      className={`mail_list_badge ${item.MailType}`}
                                      style={{
                                        backgroundColor:
                                          chipStyles[index % chipStyles.length]
                                            .backgroundColor,
                                        color:
                                          chipStyles[index % chipStyles.length]
                                            .color,
                                        borderColor:
                                          chipStyles[index % chipStyles.length]
                                            .color,
                                      }}
                                    >
                                      {item.MailType}
                                    </span>
                                  )}
                                </Tooltip>

                                <div className="mail_list_attach_icon">
                                {item?.hasAttachments && (
                                  <Tooltip title="This email has Attachments.">
                                    <div>
                                      <Icon
                                        fontSize={14}
                                        icon="mdi:attachment"
                                        style={{ color: "white" }}
                                      />
                                    </div>
                                  </Tooltip>
                                )}

                                <Tooltip title="Click to Delete this Mail.">
                                  <div
                                    onClick={(e) => {
                                      if (
                                        !checkRestrictedTask(
                                          item?.client,
                                          "delete"
                                        )
                                      ) {
                                        return;
                                      }

                                      e.stopPropagation();
                                      handleDeleteConfirmation({
                                        GmailMessageId: item?.GmailMessageId,
                                        _id: item?._id,
                                        client: item?.client,
                                      });
                                    }}
                                  >
                                    <Icon
                                      fontSize={14}
                                      style={{ color: "red" }}
                                      icon="tabler:trash"
                                    />
                                  </div>
                                </Tooltip>
                                <Tooltip title="Report as Spam or Unsubscribe">
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <Popconfirm
                                    title="Report as Spam or Unsubscribe"
                                    onConfirm={(event) => {
                                      event.stopPropagation();
                                      markSpam({ event, userInput: item });
                                    }}
                                    okText="OK"
                                    cancelText="No"
                                  >
                                    <Icon
                                      fontSize={14}
                                      style={{ color: "gray" }}
                                      icon={"tabler:alert-octagon"}
                                    />
                                  </Popconfirm>
                                </div>
                                </Tooltip>
                                <Tooltip
                                  placement="top"
                                  title={
                                    item?.isRead ? "Mark Read" : "Mark unread"
                                  }
                                >
                                  <div
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      markRead({ event, userInput: item });
                                    }}
                                  >
                                    <Icon
                                      icon={
                                        item?.isRead
                                          ? "tabler:mail-opened"
                                          : "tabler:mail"
                                      }
                                      fontSize={18}
                                      color="#125B9A"
                                    />
                                  </div>
                                </Tooltip>

                                <Tooltip title="Click to mark as important or unimportant.">
                                  <div
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      markImportant({ event, userInput: item });
                                    }}
                                  >
                                    <Icon
                                      fontSize={14}
                                      icon={
                                        item?.important
                                          ? "tabler:star-filled"
                                          : "tabler:star"
                                      }
                                      style={{ color: "orange" }}
                                    />
                                  </div>
                                </Tooltip>
                                <Tooltip title="Mail Type">
                                  {item?.Processing ? (
                                    <span
                                      className={`mail_list_badge ${item.MailType}`}
                                      style={{
                                        backgroundColor: "rgb(255, 227, 200)",
                                        color: "rgb(141, 75, 13)",
                                        borderColor:
                                          chipStyles[index % chipStyles.length]
                                            .color,
                                      }}
                                    >
                                      Analysing
                                    </span>
                                  ) : (
                                    <span
                                      className={`mail_list_badge ${item.MailType}`}
                                      style={{
                                        backgroundColor:
                                          chipStyles[index % chipStyles.length]
                                            .backgroundColor,
                                        color:
                                          chipStyles[index % chipStyles.length]
                                            .color,
                                        borderColor:
                                          chipStyles[index % chipStyles.length]
                                            .color,
                                      }}
                                    >
                                      {item.MailType}
                                    </span>
                                  )}
                                </Tooltip>

                               
                              </div>
                              </div>
                              

                             
                            </div>
                          </div>
                        </>
                      }
                    />
                  </List.Item>
                );
              }}
            />
            {/* </InfiniteScroll> */}
          </div>
        </>
      ) : (
        <>
          <div style={{ padding: "0 55px 0 24px", minHeight: 280 }}>
            <CurrentMail
              mail={currentMail}
              setCurrentMail={setCurrentMail}
              threads={false}
              threadData={currentMail?.Threads}
              allMails={mailData}
              setMailData={setMailData}
              handleDeleteMail={handleDeleteConfirmation}
              handleCurrentMailClose={handleCurrentMailClose}
              openCurrentMail={openCurrentMail}
              totalMailCount={totalMailCount}
              page={page}
              paginationHandler={paginationHandler}
              setReadUnreadCount={setReadUnreadCount}
              readUnreadCount={readUnreadCount}
            />

            {(currentMail["_id"] ||
              currentMail["id"] ||
              currentMail?.GmailMessageId) && (
              <MailRightbar currentMail={currentMail} />
            )}
          </div>
        </>
      )}

      <Spin fullscreen spinning={filterSpin} />
    </div>
  );
};

export default Listview;

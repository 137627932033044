import moment from "moment";
import {
  europeanCountryCodes,
  europeanCountryCodesWithName,
} from "../constants/countrycode";

export const CIIACValue = (ship_type, dwt) => {
  let a = 0,
    c = 0;
  let _dwt = dwt;

  switch (ship_type) {
    case "B":
      {
        if (_dwt >= 279000) {
          _dwt = 279000;
          a = 4745;
          c = 0.622;
        } else if (dwt <= 279000) {
          a = 4745;
          c = 0.622;
        }
      }
      break;
    case "GCS":
      {
        if (dwt >= 20000) {
          a = 31948;
          c = 0.792;
        } else if (dwt < 20000) {
          a = 588;
          c = 0.3885;
        }
      }
      break;
    case "T":
      {
        a = 5247;
        c = 0.61;
      }
      break;
    case "C":
      {
        a = 1984;
        c = 0.489;
      }
      break;
    case "G":
      {
        if (dwt >= 65000) {
          a = 1.441e11;
          c = 2.071;
        } else if (dwt < 65000) {
          a = 8104;
          c = 0.639;
        }
      }
      break;
    case "refregetated_cargo_carrier":
      {
        a = 4600;
        c = 0.557;
      }
      break;

    case "combination_carrier":
      {
        a = 40853;
        c = 0.812;
      }
      break;

    case "combination_carrier":
      {
        a = 40853;
        c = 0.812;
      }
      break;
    case "lng_carrier":
      {
        if (_dwt < 65000) {
          _dwt = 65000;
          a = 1.448e14;
          c = 2.673;
        } else if (_dwt >= 65000 && _dwt < 100000) {
          a = 1.448e14;
          c = 2.673;
        } else if (_dwt >= 1000000) {
          a = 9.827;
          c = 0;
        }
      }
      break;
    default:
      a = 1;
      c = 1;
  }

  return { _dwt, a, c };
};

export const CIIRefCalculation = (ship_type, DWT) => {
  const { _dwt, a, c } = CIIACValue(ship_type, DWT);
  return a * Math.pow(Number(_dwt), -c);
};

export const Co2FuelEmmission = (fuel_consumption, multiplier) => {
  return fuel_consumption * multiplier;
};

export const CIIRequiredCalculation = (ciiref, Z) => {
  return ciiref * (100 - Z) * 0.01;
};

export const CIIAttained = (total_co2_emmission, dwt, distance) => {
  let total_co2_emmission_gram = total_co2_emmission * 1_000_000;
  return dwt * distance > 0 ? total_co2_emmission_gram / (dwt * distance) : 0;
};

export const CIIBand = (ciirating, dwt, ship_type) => {
  // "options": [
  //   {
  //     "id": "B",
  //     "name": "B - Dry Bulk"
  //   },
  //   {
  //     "id": "BG",
  //     "name": "BG - Barge"
  //   },
  //   {
  //     "id": "C",
  //     "name": "C - Container"
  //   },
  //   {
  //     "id": "G",
  //     "name": "G - Gas"
  //   },
  //   {
  //     "id": "T",
  //     "name": "T - Tanker"
  //   },
  //   {
  //     "id": "TG",
  //     "name": "TG - Tow"
  //   }
  // ]

  let d1,
    d2,
    d3,
    d4 = 0;
  switch (ship_type) {
    case "B": {
      d1 = 0.86;
      d2 = 0.94;
      d3 = 1.06;
      d4 = 1.18;
      let band = CIIBandCalculator(d1, d2, d3, d4, ciirating);
      return band;
    }

    case "G": {
      if (dwt >= 65000) {
        d1 = 0.81;
        d2 = 0.91;
        d3 = 1.12;
        d4 = 1.44;
      } else {
        d1 = 0.85;
        d2 = 0.95;
        d3 = 1.06;
        d4 = 1.25;
      }

      let band = CIIBandCalculator(d1, d2, d3, d4, ciirating);
      return band;
    }

    case "T": {
      d1 = 0.82;
      d2 = 0.93;
      d3 = 1.08;
      d4 = 1.28;

      let band = CIIBandCalculator(d1, d2, d3, d4, ciirating);
      return band;
    }

    case "C": {
      d1 = 0.83;
      d2 = 0.94;
      d3 = 1.07;
      d4 = 1.19;

      let band = CIIBandCalculator(d1, d2, d3, d4, ciirating);
      return band;
    }

    case "GCS": {
      d1 = 0.83;
      d2 = 0.94;
      d3 = 1.06;
      d4 = 1.19;
      let band = CIIBandCalculator(d1, d2, d3, d4, ciirating);
      return band;
    }

    case "RCC": {
      d1 = 0.78;
      d2 = 0.91;
      d3 = 1.07;
      d4 = 1.2;
      let band = CIIBandCalculator(d1, d2, d3, d4, ciirating);
      return band;
    }

    case "combination_carrier": {
      d1 = 0.87;
      d2 = 0.96;
      d3 = 1.06;
      d4 = 1.14;
      let band = CIIBandCalculator(d1, d2, d3, d4, ciirating);
      return band;
    }

    case "LC": {
      if (dwt >= 100000) {
        d1 = 0.89;
        d2 = 0.98;
        d3 = 1.06;
        d4 = 1.13;
      } else {
        d1 = 0.78;
        d2 = 0.92;
        d3 = 1.1;
        d4 = 1.37;
      }

      let band = CIIBandCalculator(d1, d2, d3, d4, ciirating);
      return band;
    }

    case "ro-ro-cargoship_vc": {
      d1 = 0.86;
      d2 = 0.94;
      d3 = 1.06;
      d4 = 1.16;

      let band = CIIBandCalculator(d1, d2, d3, d4, ciirating);
      return band;
    }

    case "ro-ro-cargoship": {
      d1 = 0.66;
      d2 = 0.9;
      d3 = 1.11;
      d4 = 1.37;

      let band = CIIBandCalculator(d1, d2, d3, d4, ciirating);
      return band;
    }

    case "ro-ro-passenger_ship": {
      d1 = 0.72;
      d2 = 0.9;
      d3 = 1.12;
      d4 = 1.41;

      let band = CIIBandCalculator(d1, d2, d3, d4, ciirating);
      return band;
    }

    case "cruise-passenger_ship": {
      d1 = 0.87;
      d2 = 0.95;
      d3 = 1.06;
      d4 = 1.16;

      let band = CIIBandCalculator(d1, d2, d3, d4, ciirating);
      return band;
    }
  }
};

export const CIIBandCalculator = (d1, d2, d3, d4, ciirating) => {
  if (ciirating > 0 && ciirating <= d1) return "A";
  else if (ciirating > d1 && ciirating <= d2) return "B";
  else if (ciirating > d2 && ciirating <= d3) return "C";
  else if (ciirating > d3 && ciirating <= d4) return "D";
  else if (ciirating > d4) {
    return "E";
  } else {
    return "-";
  }
};

export const VoyageCIIDynamicsCalculation = (
  ship_type = null,
  dwt = 0,
  ciiObj
) => {
  const {
    pc_ulsfo,
    pc_vlsfo,
    pc_mgo,
    pc_lsmgo,
    pc_ifo,
    ifo,
    mgo,
    lsmgo,
    vlsfo,
    ulsfo,
    dst_sailed,
  } = ciiObj;

  let vlsfo_sea_cons = isNaN(parseFloat(vlsfo)) ? 0 : vlsfo,
    ulsfo_sea_cons = isNaN(parseFloat(ulsfo)) ? 0 : ulsfo,
    lsmgo_sea_cons = isNaN(parseFloat(lsmgo)) ? 0 : lsmgo,
    mgo_sea_cons = isNaN(parseFloat(mgo)) ? 0 : mgo,
    ifo_sea_cons = isNaN(parseFloat(ifo)) ? 0 : ifo;
  let vlsfo_port_cons = isNaN(parseFloat(pc_vlsfo)) ? 0 : pc_vlsfo,
    ulsfo_port_cons = isNaN(parseFloat(pc_ulsfo)) ? 0 : pc_ulsfo,
    lsmgo_port_cons = isNaN(parseFloat(pc_lsmgo)) ? 0 : pc_lsmgo,
    mgo_port_cons = isNaN(parseFloat(pc_mgo)) ? 0 : pc_mgo,
    ifo_port_cons = isNaN(parseFloat(pc_ifo)) ? 0 : pc_ifo;
  let distance = isNaN(parseFloat(dst_sailed)) ? 0 : dst_sailed;

  let co2emmission_vlsfo_ulsfo =
    Co2FuelEmmission(vlsfo_sea_cons, 3.15) +
    Co2FuelEmmission(vlsfo_port_cons, 3.15) +
    Co2FuelEmmission(ulsfo_sea_cons, 3.15) +
    Co2FuelEmmission(ulsfo_port_cons, 3.15);

  let co2emmission_lsmgo_mgo =
    Co2FuelEmmission(lsmgo_sea_cons, 3.2) +
    Co2FuelEmmission(lsmgo_port_cons, 3.2) +
    Co2FuelEmmission(mgo_sea_cons, 3.2) +
    Co2FuelEmmission(mgo_port_cons, 3.2);

  let co2emission_ifo =
    Co2FuelEmmission(ifo_port_cons, 3.114) +
    Co2FuelEmmission(ifo_sea_cons, 3.114);

  let totalco2emission =
    co2emmission_vlsfo_ulsfo * 1 +
    co2emmission_lsmgo_mgo * 1 +
    co2emission_ifo * 1;
   
  let cii_ref = CIIRefCalculation(ship_type, dwt);

  let cii_required = CIIRequiredCalculation(cii_ref, 7);
  let cii_attained = CIIAttained(totalco2emission, dwt, distance);
  let cii_rating =
    cii_required && parseFloat(cii_required) > 0
      ? cii_attained / cii_required
      : 0;
  let cii_band = CIIBand(cii_rating, dwt, ship_type);
  let predicted_req_cii = CIIRequiredCalculation(cii_ref, 9);
  let predicted_req_cii_rating =
    predicted_req_cii && parseFloat(predicted_req_cii) > 0
      ? cii_attained / predicted_req_cii
      : 0;

  return {
    co2_emission_vu: isNaN(co2emmission_vlsfo_ulsfo)
      ? 0
      : co2emmission_vlsfo_ulsfo.toFixed(2),
    co2_emission_ml: isNaN(co2emmission_lsmgo_mgo)
      ? 0
      : co2emmission_lsmgo_mgo.toFixed(2),
    co2_emission_ifo: isNaN(co2emission_ifo) ? 0 : co2emission_ifo.toFixed(2),
    co2_emission_total: isNaN(totalco2emission)
      ? 0
      : totalco2emission.toFixed(2),
    cii_ref: isNaN(cii_ref) && !isFinite(cii_ref) ? 0 : cii_ref.toFixed(2),
    cii_req: isNaN(cii_required) ? 0 : cii_required.toFixed(2),
    cii_att: isNaN(cii_attained) ? 0 : cii_attained.toFixed(2),
    cii_ret: isNaN(cii_rating) ? 0 : cii_rating.toFixed(2),
    cii_band: cii_band ? cii_band : "",
    cii_pred: predicted_req_cii ? predicted_req_cii.toFixed(2) : 0,
    cii_pred_ret: isNaN(predicted_req_cii_rating)
      ? 0
      : predicted_req_cii_rating.toFixed(2),
  };
};

export const phasePer = () => {
  let date = new Date();
  let currentYear = date.getFullYear();
  let phase = 0;
  if (currentYear <= 2024) {
    phase = 40;
  } else if (currentYear == 2025) {
    phase = 70;
  } else if (currentYear > 2026) {
    phase = 100;
  }
  return phase;
};

//--------------------------Euets calculation ----------------------

export const VoyageEuetsCalculation = (
  prevPort = 0,
  currentPort = 0,
  eutsObj
) => {
  const {
    pc_vlsfo,
    pc_ulsfo,
    pc_lsmgo,
    pc_mgo,
    pc_ifo,
    ifo,
    mgo,
    lsmgo,
    vlsfo,
    ulsfo,
    dst_sailed,
    co2_price_per,
  } = eutsObj;

  let vlsfo_sea_cons = isNaN(parseFloat(vlsfo)) ? 0 : vlsfo,
    ulsfo_sea_cons = isNaN(parseFloat(ulsfo)) ? 0 : ulsfo,
    lsmgo_sea_cons = isNaN(parseFloat(lsmgo)) ? 0 : lsmgo,
    mgo_sea_cons = isNaN(parseFloat(mgo)) ? 0 : mgo,
    ifo_sea_cons = isNaN(parseFloat(ifo)) ? 0 : ifo;
  let vlsfo_port_cons = isNaN(parseFloat(pc_vlsfo)) ? 0 : pc_vlsfo,
    ulsfo_port_cons = isNaN(parseFloat(pc_ulsfo)) ? 0 : pc_ulsfo,
    lsmgo_port_cons = isNaN(parseFloat(pc_lsmgo)) ? 0 : pc_lsmgo,
    mgo_port_cons = isNaN(parseFloat(pc_mgo)) ? 0 : pc_mgo,
    ifo_port_cons = isNaN(parseFloat(pc_ifo)) ? 0 : pc_ifo;
  let distance = isNaN(parseFloat(dst_sailed)) ? 0 : dst_sailed;

  let sea_emmission = 0,
    port_emmission = 0,
    total_emmission = 0,
    co2price_mt = isNaN(parseFloat(co2_price_per)) ? 0 : co2_price_per;
   
    

  let port_ets_emission = 0,
    total_euets = 0,
    total_euets_expense = 0;

  sea_emmission =
    Co2FuelEmmission(vlsfo_sea_cons, 3.15) +
    Co2FuelEmmission(ulsfo_sea_cons, 3.15) +
    Co2FuelEmmission(lsmgo_sea_cons, 3.2) +
    Co2FuelEmmission(mgo_sea_cons, 3.2) +
    Co2FuelEmmission(ifo_sea_cons, 3.114);

  port_emmission =
    Co2FuelEmmission(vlsfo_port_cons, 3.15) +
    Co2FuelEmmission(ulsfo_port_cons, 3.15) +
    Co2FuelEmmission(lsmgo_port_cons, 3.2) +
    Co2FuelEmmission(mgo_port_cons, 3.2) +
    Co2FuelEmmission(ifo_port_cons, 3.114);
  
  total_emmission = sea_emmission + port_emmission;
  let phase_per = phasePer();
  let sea_ets = seaEts(prevPort, currentPort);
  let sea_ets_emmission = sea_emmission * sea_ets * 0.01 * phase_per * 0.01;
  let port_ets = currentPort ? 100 : 0;
  port_ets_emission = port_emmission * port_ets * 0.01 * phase_per * 0.01;
  total_euets = Number(port_ets_emission) + Number(sea_ets_emmission);
  total_euets_expense = total_euets * co2price_mt;

  return {
    sea_ems: isNaN(sea_emmission) ? 0 : sea_emmission.toFixed(2),
    port_ems: isNaN(port_emmission) ? 0 : port_emmission.toFixed(2),
    ttl_ems: isNaN(total_emmission) ? 0 : total_emmission.toFixed(2),
    phase_pre: isNaN(phase_per) ? 0 : phase_per.toFixed(2),
    sea_pre_ets: isNaN(sea_ets) ? 0 : sea_ets.toFixed(2),
    sea_ets_ems: isNaN(sea_ets_emmission) ? 0 : sea_ets_emmission.toFixed(2),
    port_pre_ems: isNaN(port_ets) ? 0 : port_ets.toFixed(2),
    port_ets_ems: isNaN(port_ets_emission) ? 0 : port_ets_emission.toFixed(2),
    ttl_eu_ets: isNaN(total_euets) ? 0 : total_euets.toFixed(2),
    ttl_eu_ets_exp: isNaN(total_euets_expense)
      ? 0
      : total_euets_expense.toFixed(2),
  };
};

export const seaEts = (prevPort, currentPort) => {
  let seaEts = 0;
  if (prevPort === "") {
    return 0;
  }
  seaEts = prevPort ? (currentPort ? 100 : 50) : currentPort ? 50 : 0;
  return seaEts;
};

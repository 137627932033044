import React, { useEffect, useState } from "react";
import { getAPICall } from "../../shared";
import { Table, Button, Spin, DatePicker } from 'antd';
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
const { RangePicker } = DatePicker;


const VesselHistory = ({ currentVesseldata }) => {
    //    console.log('current Vessel Data-----',currentVesseldata)
    const { 
        vessel_name
        ,SHIPNAME, IMO, ship_id,SHIP_ID } = currentVesseldata;
    const [shipData, setShipData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dateRange, setDateRange] = useState([]);

    const getDetails = async (filterByDate = false) => {
        try {
            setLoading(true);
            setShipData([]); 
            const date_range = dateRange.length
            ? `${dayjs(dateRange[0]).format("YYYY-MM-DD")}/${dayjs(dateRange[1]).format("YYYY-MM-DD")}`
            : "";
            // const date_range = dateRange.length
            // ? `${dayjs(dateRange[0]).format("YYYY-MM-DD")}/${dayjs(dateRange[1]).format("YYYY-MM-DD")}`
            // : `${dayjs().subtract(3, "month").format("YYYY-MM-DD")}/${dayjs().format("YYYY-MM-DD")}`;
        


            // console.log("------>>>>>>",date_range);
            // let url = `${process.env.REACT_APP_MT_URL}marine/ship-history?ship_name=${SHIPNAME !== undefined ? SHIPNAME :
            //     vessel_name
            //     }&month=${monthFilter}`;
            // let url = `${process.env.REACT_APP_MT_URL}marine/ship-history?ship_name=${SHIPNAME !== undefined ? SHIPNAME :
            //     vessel_name
            //     }&date_range=${date_range}`;
          let url;
            if(ship_id){
                url = `${process.env.REACT_APP_MT_URL}marine/ship-history?ship_name=${ship_id}`
            } 
            // else if(SHIPNAME){
            //     url = `${process.env.REACT_APP_MT_URL}marine/ship-history?SHIPNAME=${SHIPNAME}`
            // } 
            else if(SHIP_ID){
                url=`${process.env.REACT_APP_MT_URL}marine/ship-history?ship_name=${SHIP_ID}`
            } 
            // else if(vessel_name){
            //     url=`${process.env.REACT_APP_MT_URL}marine/ship-history?vessel_name=${vessel_name}`
            // } 


            // let url = `${process.env.REACT_APP_MT_URL}marine/ship-history?ship_name=${ship_id ? ship_id : SHIPNAME ? SHIPNAME : SHIP_ID || vessel_name }`;

            if (filterByDate && dateRange.length) {
                const date_range = `${dayjs(dateRange[0]).format("YYYY-MM-DD")}/${dayjs(dateRange[1]).format("YYYY-MM-DD")}`;
                url += `&date_range=${date_range}`;
            }
           

            const response = await getAPICall(url);
            const cleanData = response?.data.map(item => ({
                ...item,
                TIMESTAMP: item?.TIMESTAMP || 'N/A',
                SPEED: item?.SPEED ?? 'N/A',
                LAT: item?.LAT ?? 'N/A',
                LON: item?.LON ?? 'N/A',
                DWT: item?.DWT ?? 'N/A',
                LAST_PORT: item?.LAST_PORT ?? 'N/A',
                NEXT_PORT_NAME: item?.NEXT_PORT_NAME ?? 'N/A',
                SHIP_CLASS: item?.SHIP_CLASS ?? 'N/A',
                YEAR_BUILT: item?.YEAR_BUILT ?? 'N/A',
            }));
            setShipData(cleanData);
        } catch (err) {
            console.log("Error fetching data:", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setDateRange([]); // Reset date range on vessel change
        setShipData([]);
        getDetails(false);
    }, [SHIPNAME,vessel_name]);

    const columns = [
        // {
        //     title: 'Ship Name',
        //     dataIndex: 'SHIPNAME',
        //     key: 'SHIPNAME',
        //     align: 'center',
        //     render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        // },
        // {
        //     title: 'Ship Type',
        //     dataIndex: 'SHIPTYPE',
        //     key: 'SHIPTYPE',
        //     align: 'center',
        //     render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        // },
        // {
        //     title: 'Time Stamp',
        //     dataIndex: 'TIMESTAMP',
        //     key: 'TIMESTAMP',
        //     align: 'center',
        //     render: (text) => (
        //         <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>
        //             {text ? new Date(text).toISOString().slice(0, 19).replace('T', ' ') : 'N/A'}
        //         </div>
        //     )
        //     // render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        // },
        // {
        //     title: 'MMSI',
        //     dataIndex: 'MMSI',
        //     key: 'MMSI',
        //     align: 'center',
        //     render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        // },
        // {
        //     title: 'IMO',
        //     dataIndex: 'IMO',
        //     key: 'IMO',
        //     align: 'center',
        //     render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        // },
        {
            title: 'Speed',
            dataIndex: 'SPEED',
            key: 'SPEED',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        // {
        //     title: 'Max Speed',
        //     dataIndex: 'MAX_SPEED',
        //     key: 'MAX_SPEED',
        //     align: 'center',
        //     render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        // },
        // {
        //     title: 'AVG Speed',
        //     dataIndex: 'AVG_SPEED',
        //     key: 'AVG_SPEED',
        //     align: 'center',
        //     render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        // },
        {
            title: 'DWT',
            dataIndex: 'DWT',
            key: 'DWT',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        {
            title: 'Longitude',
            dataIndex: 'LON',
            key: 'LON',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        {
            title: 'Latitude',
            dataIndex: 'LAT',
            key: 'LAT',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        // {
        //     title: 'Last Port',
        //     dataIndex: 'LAST_PORT',
        //     key: 'LAST_PORT',
        //     align: 'center',
        //     render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        // },
        // {
        //     title: 'Ship Class',
        //     dataIndex: 'SHIP_CLASS',
        //     key: 'SHIP_CLASS',
        //     align: 'center',
        //     render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        // },
        {
            title: "Course",
            dataIndex: "COURSE",
            key: "COURSE",
            align: 'center',
            render: (text) => <div style={{paddingLeft: '0.8rem',paddingRight: '0.8rem'}}>{text || 'N/A'}</div>
        },
        {
            title: "Heading",
            dataIndex: "HEADING",
            key: "HEADING",
            align: 'center',
            render: (text) => <div style={{paddingLeft: '0.8rem',paddingRight: '0.8rem'}}>{text || 'N/A'}</div>
        },

        {
            title: "Time Stamp",
            dataIndex: "TIMESTAMP",
            key: "TIMESTAMP",
            align: 'center',
            render: (text) => <div style={{paddingLeft: '0.8rem',paddingRight: '0.8rem'}}> {text ? moment(text).format("DD-MM-YYYY HH:mm:ss") : 'N/A'}</div>
        },
        // {
        //     title: 'Next Port',
        //     dataIndex: 'NEXT_PORT_NAME',
        //     key: 'NEXT_PORT_NAME',
        //     align: 'center',
        //     render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        // },
        // {
        //     title: 'Year Built',
        //     dataIndex: 'YEAR_BUILT',
        //     key: 'YEAR_BUILT',
        //     align: 'center',
        //     render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        // },
    ];

    const rowClassName = (record, index) => {
        return index % 2 === 0 ? 'row1-white' : 'row1-grey';
    };

    if (loading) {
        return <Spin size="large" />;
    }


    const handleDateChange = (dates) => {
        setDateRange(dates);

        if (!dates || dates.length === 0) {
            getDetails(false); // Reset data when clearing the date filter
        }

    };

    const applyDateFilter = () => {
        getDetails(true);
    };

    const disabledDate = (current) => {
        const sixMonthsAgo = dayjs().subtract(6, "months").startOf("day");
        const today = dayjs().endOf("day");
        return current && (current < sixMonthsAgo || current > today);
    };

    // const disabledDate = (current) => {
    //     return current && current < dayjs().subtract(3, "months").startOf("day");
    // };

    const handleClearFilter=()=>{
        setDateRange([]);
        getDetails(false);
    }

    const printDocument = () => {
        const quotes = document.getElementById("documentdownload");
    
        // Hide pagination before generating the PDF
        const pagination = document.getElementsByClassName('ant-pagination'); // Removed the dot (.) as it's not needed here
        if (pagination.length > 0) {
            // Loop through the collection and hide each pagination element
            for (let i = 0; i < pagination.length; i++) {
                pagination[i].style.visibility = 'hidden';  
                pagination[i].style.position = 'absolute'; 
            }
        }
    
        quotes.style.pageBreakBefore = "auto";
        quotes.style.pageBreakAfter = "auto";
        quotes.style.pageBreakInside = "auto";
        quotes.style.width = "100%";
    
        const heading = "Vessel History Data";
    
        html2canvas(quotes, {
            logging: true,
            letterRendering: 1,
            useCORS: true,
            allowTaint: true,
            scale: 2,
            width: quotes.scrollWidth,
            height: quotes.scrollHeight,
            x: window.scrollX,
            y: window.scrollY,
        }).then(function (canvas) {
            const link = document.createElement("a");
            link.download = "html-to-img.png";
            const imgWidth = 210;
            const pageHeight = 290;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            const doc = new jsPDF("p", "mm");
    
            // Add the heading
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(15);
            doc.text(heading, 105, 20, { align: 'center' });
    
            let position = 30;
    
            const pageData = canvas.toDataURL("image/jpeg", 1.0);
            const imgData = encodeURIComponent(pageData);
            doc.addImage(imgData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
            doc.setLineWidth(5);
            doc.setDrawColor(255, 255, 255);
            doc.rect(0, 0, 210, 295);
            heightLeft -= pageHeight;
    
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(
                    imgData,
                    "PNG",
                    5,
                    position + 30,
                    imgWidth - 8,
                    imgHeight - 7
                );
                doc.setLineWidth(5);
                doc.setDrawColor(255, 255, 255);
                doc.rect(0, 0, 210, 295);
                heightLeft -= pageHeight;
            }
    
            doc.save("Vessel-History-Data.pdf");
    
            // Restore pagination after generating the PDF
            if (pagination.length > 0) {
                for (let i = 0; i < pagination.length; i++) {
                    pagination[i].style.visibility = ''; // Reset visibility back to normal
                    pagination[i].style.position = ''; // Reset position to default
                }
            }
        });
    };  

    return (
        <div>
            {/* <h4 style={{ color: "#003366" }}>{vessel_name}</h4> */}

            <div   style={{
    display: 'flex',
    // flexDirection: 'row',
    // gap: '87px', // Gap between elements
    backgroundColor: '#f0f4f8', // Light background color
    padding: '10px',
    borderRadius: '8px',
    marginBottom: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  }}>
    <p style={{ color: '#1f2937', fontWeight: 'bold',marginBottom:'0rem' }}>Vessel Name : {currentVesseldata?.SHIPNAME || vessel_name}</p>
    <p style={{ color: '#1f2937', fontWeight: 'bold',marginBottom: '0rem' }}>IMO No. : {currentVesseldata?.imo_number ? currentVesseldata?.imo_number : currentVesseldata?.IMO  ||"N/A"}</p>
    <p style={{ color: '#1f2937', fontWeight: 'bold',marginBottom: '0rem' }}>MMSI No. : {currentVesseldata?.mmsi_number? currentVesseldata?.mmsi_number  : currentVesseldata?.MMSI || "N/A"}</p>
    <p style={{ color: '#1f2937', fontWeight: 'bold',marginBottom: '0rem' }}>Next Port : {currentVesseldata?.next_port?.name ? currentVesseldata?.next_port?.name : currentVesseldata?.NEXT_PORT_NAME || "N/A"}</p>
    <p style={{ color: '#1f2937', fontWeight: 'bold',marginBottom: '0rem' }}>Last Port: {currentVesseldata?.last_port?.name ? currentVesseldata?.last_port?.name : currentVesseldata?.LAST_PORT  || "N/A"}</p>
</div>
            
            <div style={{ display: "flex",justifyContent:"space-between", marginBottom: "1rem" }}>
                <div>
                <RangePicker 
                    onChange={handleDateChange} 
                    disabledDate={disabledDate} 
                    format="YYYY-MM-DD"
                    allowClear
                />
                <Button type="primary" onClick={applyDateFilter}>Search</Button>
                <Button type="default" onClick={handleClearFilter}>Clear Filter</Button>
                </div>
                <div>
                <Button type="primary" onClick={printDocument}><DownloadOutlined/>Download</Button>
                </div>
            </div>

            <div id="documentdownload">
                <Table
                    columns={columns}
                    dataSource={shipData}
                    rowKey="IMO"
                    pagination={true}
                    bordered
                    rowClassName={rowClassName}
                />
            </div>
        </div>
    );
};

export default VesselHistory;

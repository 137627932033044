import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import URL_WITH_VERSION, { getAPICall, openNotificationWithIcon, postAPICall } from "../../../../shared";
import { Spin } from "antd";

const VesselPerformance = (props) => {
  const { formData } = props;
  const [chartData, setChartData] = useState([]);
  const [otherData, setOtherData] = useState([])

  console.log({props});
  useEffect(() => {
      fetchShipId();
  }, []);

  const fetchShipId = async () => {
    try {
      let url;
      if(props?.customKey){
        url = `${process.env.REACT_APP_MT_URL}marine/get-vessels-name/${props?.vesselName}`;

      }else{
        let qparams= formData?.vesselInfo?.vessel_name ? formData?.vesselInfo?.vessel_name: formData?.vessel_name
        url = `${process.env.REACT_APP_MT_URL}marine/get-vessels-name/${qparams}`;

      }
      const resp = await getAPICall(url);
      if (Array.isArray(resp) && resp?.length > 0) {
        callGraphApi(resp[0]);
      }
    } catch (error) {
      console.error("Error fetching ship ID:", error);
    }
  };

  const callGraphApi = async (data) => {
    try {
      const payload = {
        vessel_name: data?.SHIPNAME,
        ship_id: data?.SHIP_ID,
      };

      const url = `${URL_WITH_VERSION}/recommendation_bp/vessel_performance`;

      const resp = await postAPICall(url, payload, "post");

      setOtherData(resp)
      // console.log(resp);
      if(resp?.error=="Failed to retrieve data from MarineTraffic API"){
        openNotificationWithIcon("info","Limit reached for vessel performance hits")
        return;
      }

      if (resp && resp?.analysis_results) {
        setChartData(resp.analysis_results);
      }
    } catch (error) {
      console.error("Error fetching graph data:", error);
    }
  };

  const chartOptions = {
    title: {
      //   text: "Vessel Performance Analysis",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: [
        "Average Speed (knots)",
        "Fuel Consumption (tons)",
        "Performance Index",
        "Speed",
      ],
      top: 0,
    },
    xAxis: {
      type: "category",
      data: Array.isArray(chartData) && chartData?.map((item) => item.date),
      axisLabel: {
        rotate: 45,
      },
    },
    yAxis: {
      type: "value",
      name: "Values",
    },
    series: [
      {
        name: "Average Speed (knots)",
        type: "line",
        data: Array.isArray(chartData) && chartData?.map((item) => item.average_speed_knots),
      },
      {
        name: "Fuel Consumption (tons)",
        type: "line",
        data: Array.isArray(chartData) && chartData?.map((item) => item.fuel_consumption_tons),
      },
      {
        name: "Performance Index",
        type: "line",
        data: Array.isArray(chartData) && chartData?.map((item) => item.performance_index),
      },
      {
        name: "Speed",
        type: "line",
        data: Array.isArray(chartData) && chartData?.map((item) => item.speed),
      },
    ],
  };
  // console.log("chartdataa", chartData);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
        Vessel Performance
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1.5rem",
        }}
      >
        <h4 style={{ textAlign: "center" }}>
          Speed : <span style={{fontSize: "1rem"}}>{otherData.speed?.toFixed(2) || "N/A"}N</span>
        </h4>

        {/* Consumption Section */}
        <span
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <h4>Consumption = </h4>
          <span style={{ display: "flex", flexDirection: "column"}}>
            <h4>
              Ballast : <span style={{fontSize: "1rem"}}>{otherData?.consumption?.ballast?.toFixed(2) || "N/A"}MT</span>  /  
              Laden : <span style={{fontSize: "1rem"}}>{otherData?.consumption?.laden?.toFixed(2) || "N/A"}MT</span>
            </h4>
          </span>
        </span>

        <h4 style={{ textAlign: "center" }}>
          Wind Speed : <span style={{fontSize: "1rem"}}>{otherData?.wind_speed || "N/A"}N</span>
        </h4>
        <h4 style={{ textAlign: "center" }}>
          Weather : <span style={{fontSize: "1rem"}}>{otherData?.weather_condition?.toUpperCase() || "N/A"}</span>
        </h4>
      </div>

      <div>
        { Array.isArray(chartData) && chartData?.length > 0 ? (
          <ReactECharts option={chartOptions} style={{ height: 400 }} />
        ) : (
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p>Loading...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default VesselPerformance;

import { Tabs, Timeline, Card, Spin, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { openNotificationWithIcon, postAPICall } from "../../shared";
import Item from "antd/es/list/Item";
import dayjs from "dayjs";
import { Icon } from '@iconify/react';

const BestMatchDrawerContent = ({ currentMatch, cargo }) => {

  const [activeKey, setActiveKey] = useState("1");
  const [events, setEvents] = useState([]);
  const [orderData, setOrderData] = useState([]);

  const [loadingNew, setLoadingNew] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);

  const handleBestMatch = async () => {
    try {
      setLoadingNew(true);
      let url;
      let payload = {};

      if (cargo) {
        url = `${process.env.REACT_APP_MT_URL}mail/best-match-cargo`;
        payload = {
          cargo_size: currentMatch.cargo_size,
          cargo_type: currentMatch.cargo_type,
          change_tab: "global",
          laycan: currentMatch.laycan
            ? currentMatch.laycan.includes("/") // Check if it's a date range
              ? dayjs(
                currentMatch.laycan.split("/")[0],
                ["DD-MM-YYYY", "YYYY-MM-DD"],
                true
              ).format("YYYY-MM-DD") // Format only the first date in the range
              : dayjs(
                currentMatch.laycan,
                ["DD-MM-YYYY", "YYYY-MM-DD"],
                true
              ).format("YYYY-MM-DD") // Format the single date
            : null,

          load_port: currentMatch.load_port.port,
        };
      } else {
        payload = {
          change_tab: "global",
          dwt: currentMatch?.dwt,
          open_area: currentMatch?.open_area,
          vessel_type: currentMatch?.vessel_type,
          sub_vessel_type:currentMatch?.sub_vessel_type
        };
        url = `${process.env.REACT_APP_MT_URL}mail/best-match-tonnage`;
      }

      const response = await postAPICall(url, payload, "post", (data) => {
        if (data.matched_documents && data.matched_documents.length > 0) {
          let events = [];
          // Map each item in the data to an object with necessary details
          if (cargo) {
            events = data?.matched_documents?.map((item, index) => ({
              draft: item?.draft || "N/A",
              speed: item?.speed_and_consumption || "N/A",
              vessel_name: item?.vessel_name || "N/A",
              open_area: item?.open_area || "N/A",
              open_date: item?.open_date || "N/A",
              dwt: item?.dwt || "N/A",
              vessel_type: item?.vessel_type || "N/A",
              sub_vessel_type: item?.sub_vessel_type || "N/A",
              build_year:
                item?.build_year && Array.isArray(item?.build_year)
                  ? item?.build_year.join(", ")
                  : item?.build_year || "N/A",
            }));
          } else {
            events = data?.matched_documents?.map((item, index) => ({
              cargo_type:item?.cargo_type || "N/A",
              company: item?.company || "N/A",
              tct: item?.tct || "N/A",
              load_port: item?.load_port?.port || "N/A",
              discharge_port: item?.discharge_port?.port || "N/A",
              laycan: item?.laycan || "N/A",
              cargo: item?.cargo || "N/A",
              cargo_size: item?.cargo_size ? item?.cargo_size + ' MT' : "N/A",
            }));
          }

          setEvents(events);
          setLoadingNew(false);
        } else {
          openNotificationWithIcon("info", "No Best Match Found!");
          setLoadingNew(false);
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    handleBestMatch();
  }, []);

  const getMyCargoOrder = async () => {
    try {
      setOrderLoading(true);
      let url;
      let payload = {};

      if (cargo) {
        payload = {
          cargo_size: currentMatch.cargo_size,
          cargo_type: currentMatch.cargo_type,
          change_tab: "not global",
          laycan: currentMatch.laycan
            ? currentMatch.laycan.includes("/") // Check if it's a date range
              ? dayjs(
                currentMatch.laycan.split("/")[0],
                ["DD-MM-YYYY", "YYYY-MM-DD"],
                true
              ).format("YYYY-MM-DD") // Format only the first date in the range
              : dayjs(
                currentMatch.laycan,
                ["DD-MM-YYYY", "YYYY-MM-DD"],
                true
              ).format("YYYY-MM-DD") // Format the single date
            : null,

          load_port: currentMatch.load_port.port,
        };
        url = `${process.env.REACT_APP_MT_URL}mail/best-match-cargo`;
      } else {
        payload = {
          change_tab: "not global",
          dwt: currentMatch?.dwt,
          open_area: currentMatch?.open_area,
          vessel_type: currentMatch?.vessel_type,
        };
        url = `${process.env.REACT_APP_MT_URL}mail/best-match-tonnage`;
      }

      const response = await postAPICall(url, payload, "POST", (value) => {
        if (value.matched_documents && value.matched_documents.length > 0) {
          setOrderData(value.matched_documents);
          setOrderLoading(false);
        } else {
          openNotificationWithIcon("info", "No Data found for My Order Cargo");
          setOrderLoading(false);
        }
      });
    } catch (error) {
      console.error("Error fetching my cargo order data:", error);
    }
  };

  // Timeline component to display data as cards
  const TimelineBestMatch = () => (
    <div className="mail_scrolls" style={{ height: "75vh", overflowY: "auto" }}>
      {loadingNew ? (
        <div
          style={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <Spin />
        </div>
      ) : (
        <Timeline className="TimelineBestMatch">
          {cargo
            ? events?.map((event, index) => (
              <Item key={index}>
                <div style={{ padding: "7px" }}>
                  <Card
                    title={event?.vessel_name?.toUpperCase() || "N/A"}
                    bordered={false}
                    style={{ marginBottom: 16 }}
                    className="timeline-item-card"
                  >
                    <div className="timeline-item-details">

                      <table style={{ width: '100%' }}>
                        <tbody>
                          <tr>
                            <td><strong>Open Area :</strong></td>
                            <td><strong>----</strong></td>
                            <td>{event.open_area || "N/A"}</td>
                          </tr>
                          <tr>
                            <td><strong>Open Date :</strong></td>
                            <td><strong>----</strong></td>
                            <td>{event.open_date || "N/A"}</td>
                          </tr>
                          <tr>
                            <td><strong>Dead Weight :</strong></td>
                            <td><strong>----</strong></td>
                            <td>{event.dwt ? (event?.dwt + ' MT') : 'N/A'}</td>
                          </tr>
                          <tr>
                            <td><strong>Build Year :</strong></td>
                            <td><strong>----</strong></td>
                            <td>{event.build_year || "N/A"}</td>
                          </tr>
                          <tr>
                            <td><strong>Vessel Type :</strong></td>
                            <td><strong>----</strong></td>
                            <td>{event.vessel_type || "N/A"}</td>
                          </tr>
                          <tr>
                            <td><strong>Vessel Sub Type :</strong></td>
                            <td><strong>----</strong></td>
                            <td>{event.sub_vessel_type || "N/A"}</td>
                          </tr>
                          <tr>
                            <td><strong>Draft :</strong></td>
                            <td><strong>----</strong></td>
                            <td>{event.draft || "N/A"}</td>
                          </tr>
                          <tr>
                            <td><strong>Speed :</strong></td>
                            <td><strong>----</strong></td>
                            <td style={{ maxWidth: '200px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                              {event.speed || "N/A"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </div>
              </Item>
            ))
            : events?.map((event, index) => {
              // console.log('===>>>>', event)
              return (
                <Item key={index}>
                  <div style={{ padding: "7px" }}>
                    <Card
                      title={
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            lineHeight: '1'
                          }}>
                            <Tooltip title={event?.company?.toUpperCase()}>
                              <span style={{ color: "rgba(47, 43, 61, 0.78)", fontWeight: "700" }} className="vessel-name-ellipsis">
                                {event?.company?.toUpperCase()}
                              </span>{" "}
                            </Tooltip>
                            <b  className="bstyle">.{event.tct.toUpperCase() || "N/A"}</b>
                          </span>
                        </span>
                      }
                      bordered={false}
                      style={{ border: "1px solid #1b6f14", borderRadius: "10px",marginBottom:"16" }}
                      className="timeline-item-card"
                    >
                      <Tag className="Tagcss">
                        {event?.cargo.toUpperCase() || "No Cargo"}
                      </Tag>
                      <div className="timeline-item-details">

                        <table style={{ width: '100%' }}>
                          <tbody style={{color:"rgba(47, 43, 61, 0.78)"}}>
                            <tr>
                              <td><strong>Load Port:</strong></td>
                              <td><strong>----</strong></td>
                              <td>{event.load_port || "N/A"}</td>
                            </tr>
                            <tr>
                              <td><strong>Discharge Port:</strong></td>
                              <td><strong>----</strong></td>
                              <td>{event.discharge_port || "N/A"}</td>
                            </tr>
                            <tr>
                              <td><strong>Laycan:</strong></td>
                              <td><strong>----</strong></td>
                              <td>{event.laycan || "N/A"}</td>
                            </tr>
                            <tr>
                              <td><strong>Cargo Qty:</strong></td>
                              <td><strong>----</strong></td>
                              <td>{event.cargo_size || "N/A"}</td>
                            </tr>
                            <tr>
                              <td><strong>Cargo Type:</strong></td>
                              <td><strong>----</strong></td>
                              <td>{event.cargo_type || "N/A"}</td>
                            </tr>
                            
                            
                          </tbody>
                        </table>
                      </div>
                    </Card>
                  </div>
                </Item>)
            }
            )}
        </Timeline>
      )}
    </div>
  );

  const MyOrderCargo = () => (
    <div
      className="mail_scrolls myOrderBox"
      style={{ height: "75vh", overflowY: "auto" }}
    >
      {orderLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <Spin />
        </div>
      ) : (
        <Timeline mode="right" className="TimelineBestMatch cargo">
          {cargo
            ? orderData?.map((event, index) => (
              <Item key={index}>
                <div style={{ padding: "7px" }}>
                  <Card
                    title={
                      <span style={{ display: "block", textAlign: "left" }}>
                        {event?.vessel_name?.toUpperCase() || "N/A"}
                      </span>
                    }
                    bordered={false}
                    style={{ marginBottom: 16 }}
                    className="timeline-item-card"
                  >
                    <div className="timeline-item-details">

                      <table style={{ width: '100%' }}>
                        <tbody>
                          <tr>
                            <td><strong>Open Area :</strong></td>
                            <td><strong>----</strong></td>
                            <td>{event.open_area || "N/A"}</td>
                          </tr>
                          <tr>
                            <td><strong>Open Date :</strong></td>
                            <td><strong>----</strong></td>
                            <td>{event.open_date || "N/A"}</td>
                          </tr>
                          <tr>
                            <td><strong>Dead Weight :</strong></td>
                            <td><strong>----</strong></td>
                            <td>{event.dwt ? (event?.dwt + ' MT') : 'N/A'}</td>
                          </tr>
                          <tr>
                            <td><strong>Build Year :</strong></td>
                            <td><strong>----</strong></td>
                            <td>{event.build_year || "N/A"}</td>
                          </tr>
                          <tr>
                            <td><strong>Vessel Type :</strong></td>
                            <td><strong>----</strong></td>
                            <td>{event.vessel_type || "N/A"}</td>
                          </tr>
                          <tr>
                            <td><strong>Vessel Sub Type :</strong></td>
                            <td><strong>----</strong></td>
                            <td>{event.sub_vessel_type || "N/A"}</td>
                          </tr>
                          <tr>
                            <td><strong>Draft :</strong></td>
                            <td><strong>----</strong></td>
                            <td>{event.draft || "N/A"}</td>
                          </tr>
                          <tr>
                            <td><strong>Speed :</strong></td>
                            <td><strong>----</strong></td>
                            <td style={{ maxWidth: '200px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                              {event.speed_and_consumption || "N/A"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </div>
              </Item>
            ))
            : orderData?.map((event, index) => {
              return (
                <Item key={index}>
                  <div style={{ padding: "7px" }}>
                    <Card
                      title={
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            lineHeight: '1'
                          }}>
                            <Tooltip title={event?.company?.toUpperCase() || "N/A"}>
                              <span style={{ color: "rgba(47, 43, 61, 0.78)", fontWeight: "700" }} className="vessel-name-ellipsis">
                                {event?.company?.toUpperCase() || "N/A"}
                              </span>{" "}
                            </Tooltip>
                            - <b  className="bstyle">.{event.tct.toUpperCase() || "N/A"}</b>
                          </span>
                        </span>
                      }
                      bordered={false}
                      // style={{ marginBottom: 16 }}
                      className="timeline-item-card myOrder"
                      style={{ border: "1px solid #1b6f14", borderRadius: "10px",marginBottom:"16" }}
                    >
                      <Tag className="Tagcss" color="processing">
                            {event?.cargo.toUpperCase() || "N/A"}
                          </Tag>
                      <div className="timeline-item-details">
                        <table style={{ width: '100%',color:"rgba(47, 43, 61, 0.78)" }}>
                          <tbody>
                            <tr>
                              <td><strong>Load Port:</strong></td>
                              <td><strong>----</strong></td>
                              <td>{event?.load_port?.port  || "N/A"}</td>
                            </tr>
                            <tr>
                              <td><strong>Discharge Port:</strong></td>
                              <td><strong>----</strong></td>
                              <td>{event?.discharge_port?.port  || "N/A"}</td>
                            </tr>
                            <tr>
                              <td><strong>Laycan:</strong></td>
                              <td><strong>----</strong></td>
                              <td>{event?.laycan || "N/A"}</td>
                            </tr>
                            <tr>
                              <td><strong>Cargo Size:</strong></td>
                              <td><strong>----</strong></td>
                              <td>{event?.cargo_size || "N/A"}</td>
                            </tr>
                            <tr>
                              <td><strong>Cargo Type:</strong></td>
                              <td><strong>----</strong></td>
                              <td>{event?.cargo_type || "N/A"}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Card>
                  </div>
                </Item>
              )
            }

            )}
        </Timeline>
      )}
    </div>
  );
  // Define the items for the Tabs component
  const items = [
    {
      key: "1",
      label: cargo ? "Global Tonnage" : 'Global Cargo',
      children: <TimelineBestMatch />,
    },
    {
      key: "2",
      label: cargo ? "My Order Tonnage" : ' My Order Cargo',
      children: <MyOrderCargo />,
    },
  ];

  const handleTabChange = (tab) => {
    setActiveKey(tab);
    if (tab === "2" && orderData.length === 0) {
      getMyCargoOrder();
    }
  };

  return (
    <>
      <div style={{ paddingBottom: "10px" }}>
        <table className="bestMatchUpperTable">
          {cargo ? (
            <tbody>
              <tr>
                <td>Cargo Name</td>
                <td>---</td>
                <td style={{ fontWeight: "bold" }}>
                  {currentMatch?.cargo?.toUpperCase() || "N/A"}
                </td>
              </tr>
              <tr>
                <td>Voyage Type</td>
                <td>---</td>
                <td>{currentMatch?.tct.toUpperCase() || "N/A"}</td>
              </tr>
              <tr>
                <td>Cargo Type</td>
                <td>---</td>
                <td>{currentMatch?.cargo_type ? currentMatch?.cargo_type.toUpperCase() : "N/A"}</td>
              </tr>
              <tr>
                <td>Load/Disc Port</td>
                <td>---</td>
                <td style={{ fontWeight: "bold" }}>
                  {(currentMatch.load_port.port || "N/A") +
                    "/" +
                    (currentMatch?.discharge_port.port || "N/A")}
                </td>
              </tr>
              <tr>
                <td>Laycan Date</td>
                <td>---</td>
                <td>{currentMatch?.laycan ? currentMatch?.laycan : "N/A"}</td>
              </tr>
            </tbody>
          ) : (
            <tbody style={{color:"rgba(47, 43, 61, 0.78)"}}>
              <tr>
                <td>Vessel Name <Icon icon="lucide:ship" style={{color:"#1b6f14",fontSize:"1rem"}} /></td>
                <td>---</td>
                <td  style={{ fontWeight: "bold" }}>
                  {Array.isArray(currentMatch?.vessel_name) ? currentMatch?.vessel_name[0]?.toUpperCase() : currentMatch?.vessel_name?.toUpperCase() || "N/A"}
                </td>
              </tr>
              <tr>
                <td>Dead Weight</td>
                <td>---</td>
                <td>{currentMatch?.dwt ? currentMatch?.dwt + " MT" : "N/A"}</td>
              </tr>
              <tr>
                <td>Build Year</td>
                <td>---</td>
                <td>
                    {Array.isArray(currentMatch?.build_year)
                      ? currentMatch?.build_year?.join(", ")
                      : currentMatch?.build_year || "N/A"}
                </td>
              </tr>
              <tr>
                <td>Open Area <Icon icon="material-symbols:anchor"  style={{color: "1b6f14",fontSize:"1rem"}} /></td>
                <td>---</td>
                <td style={{ fontWeight: "bold" }}>
                  {currentMatch?.open_area
                    ? currentMatch?.open_area?.toUpperCase()
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td>Open Dates</td>
                <td>---</td>
                <td>
                  {currentMatch?.open_date ? currentMatch?.open_date : "N/A"}
                </td>
              </tr>
              <tr>
                <td>Vessel Type</td>
                <td>---</td>
                <td>
                  {currentMatch?.vessel_type?.toUpperCase() ? currentMatch?.vessel_type?.toUpperCase() : "N/A"}
                </td>
              </tr>
              <tr>
                <td>Vessel Sub Type</td>
                <td>---</td>
                <td>
                  {currentMatch?.sub_vessel_type?.toUpperCase() ? currentMatch?.sub_vessel_type?.toUpperCase() : "N/A"}
                </td>
              </tr>
            </tbody>
          )}
        </table>
        <hr style={{ display: "block", 
                     width: "100%",
                     height: "1px", 
                     backgroundColor: "grey",
                     margin: "10px 0" }} />
        <p style={{color:"rgba(47, 43, 61, 0.78)"}}> <Icon icon="bi:lightning-charge"  style={{color: "blue"}} />Best Matches</p>
      </div>
      <Tabs
        animated
        centered
        defaultActiveKey={activeKey}
        onChange={handleTabChange}
        items={items}
      />
    </>
  );
};

export default BestMatchDrawerContent;

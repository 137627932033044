import React, { useEffect, useRef, useState } from "react";
import NormalFormIndex from "../../../../shared/NormalForm/normal_from_index";
import { Col, Layout, Modal, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";

import Remarks from "../../../../shared/components/Remarks";
import URL_WITH_VERSION, {
  apiDeleteCall,
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
} from "../../../../shared";
import Attachment from "../../../../shared/components/Attachment";
import {
  deleteAttachment,
  getAttachments,
  uploadAttachment,
} from "../../../../shared/attachments";
import TDE from "../../../tde";

const MakeOtherPayment = (props) => {
  const [state, setState] = useState({
    formData: props.formData,
    frmName: "head_fixture_make_payment_form",
    frmVisible: true,
    openRemarksModal: false,
    showAttachment: false,
    loadComponent: undefined,
    isShowTde: false,
    openReport: false,
    reportData: null,
  });

//   console.log("propsss", props);

  const isFirstLoad = useRef(true);
  useEffect(() => {
    if (isFirstLoad.current) {
      getinvoicedata();
      firstLoadForm();
      isFirstLoad.current = false; // Set to false after first call
    }
  }, []);

  const ShowAttachment = async (isShowAttachment) => {
    let loadComponent = undefined;
    const { id } = state.formData;
    if (id && isShowAttachment) {
      const attachments = await getAttachments(id, "EST");
      const callback = (fileArr) =>
        uploadAttachment(fileArr, id, "EST", "port-expense");
      loadComponent = (
        <Attachment
          uploadType="Estimates"
          attachments={attachments}
          onCloseUploadFileArray={callback}
          deleteAttachment={(file) =>
            deleteAttachment(file.url, file.name, "EST", "port-expense")
          }
          tableId={0}
        />
      );
      setState((prevState) => ({
        ...prevState,
        showAttachment: isShowAttachment,
        loadComponent: loadComponent,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        showAttachment: isShowAttachment,
        loadComponent: undefined,
      }));
    }
  };

  const getinvoicedata = async () => {
    try {
      const url = `${URL_WITH_VERSION}/head-fixture/hf-lifting-payment?e=${formData.id}`;
      const response = await getAPICall(url);
      // console.log("response",response)
      if (response?.data) {
        const newData = response?.data?.["-"];
        const invoice_total = response?.data?.invoice_total
        setState((prev) => ({
          ...prev,
          formData: {
            ...prev.formData,
            invoice_total: invoice_total,
          }
        }));

        if (newData !== state?.formData?.["-"]) {
          setState((prev) => ({
            ...prev,
            formData: {
              ...prev.formData,
              "-": newData,
            }
          }));
        }
      } else {
        console.log("No data found in response");
      }
    } catch (error) {
      console.error("No data found in response");
    }
  }

  const firstLoadForm = () => {
    const { formData, type } = props;
    // console.log("formdata",formData)
    if (formData && type == "createForm") {
      let formObj = {
        vessel: formData?.vessel ? formData?.vessel : "",
        head_fixture_code: formData?.head_fix_code
          ? formData?.head_fix_code
          : "",
        my_company: formData?.my_company ? formData?.my_company : "",
        reg_desp_owner: formData?.owner || "",
        "-" : formData?.["-"] || [],
        // reg_desp_owner:
      };

      setState((prev) => ({ ...prev, formData: formObj }));
    } else {
      //   editFormData(state.formData);
    }
  };

  const saveFormData = async (data) => {
    try {
      // if (!data?.amount || data?.amount == "0.00") {
      //   openNotificationWithIcon("info", "Please fill total amount");
      //   return;
      // }
      setState((prev) => ({ ...prev, frmVisible: false }));
      let param;
      let url;
      const updatedData = { ...data, hf_id: props.formData.id };

      if (state?.formData?.invoice_no) {
        param = "update";
        url = `${URL_WITH_VERSION}/head-fixture/hf-payment/${param}?frm=head_fixture_make_payment_form`;
        const resp = await postAPICall(url, updatedData, "put");
        // console.log("resppppp", resp);
        if (resp.data == true) {
          openNotificationWithIcon("success", "Updated successfully");
          editFormData(resp.row);
        }
      } else {
        param = "save";
        url = `${URL_WITH_VERSION}/head-fixture/hf-payment/${param}?frm=head_fixture_make_payment_form`;
        const resp = await postAPICall(url, updatedData, "post");
        // console.log("resppppp", resp);
        if (resp.data == true) {
          openNotificationWithIcon("success", "Saved successfully");
          editFormData(resp.row);
        }
      }

      setState((prev) => ({ ...prev, frmVisible: true }));
    } catch (error) {
      console.log(error);
    }
  };

  const editFormData = async (editData) => {
    try {
      const url = `${URL_WITH_VERSION}/head-fixture/hf_payment_edit?e=${editData.payment_id}`;
      const resp = await getAPICall(url);

      if (resp?.data) {
        setState((prev) => ({
          ...prev,
          formData: { ...resp.data }, // Ensure a new object reference
        }));
        // console.log("Updated Form Data:", resp.data); // Debugging log
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteFormData = (postData) => {
    try {
      if (postData && postData.hasOwnProperty("id") && postData["id"] > 0) {
        Modal.confirm({
          title: "Confirm",
          content: "Are you sure, you want to delete it?",
          onOk: () => _onDelete(postData),
        });
      } else {
        openNotificationWithIcon("error", "Please generate Invoice No!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _onDelete = async (postData) => {
    try {
      let _url = `${URL_WITH_VERSION}/head-fixture/hf-payment/delete?e=${postData?.id}`;
      const resp = await postAPICall(_url, {}, "delete");
    //   console.log("respp989898", resp);
      if (resp?.data == true) {
        openNotificationWithIcon("success", "Deleted successfully");
        props.close();
        props.getTableData();
      } else {
        console.log("some error occured");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getReportData=()=>{
    try {


        
    } catch (error) {
        console.log(error)
        
    }
  }

  const { formData, frmName, frmVisible } = state;

//   console.log("formdataa65656", formData);
  return (
    <div className="tcov-wrapper full-wraps voyage-fix-form-wrap">
      <Layout className="layout-wrapper">
        <Layout>
          <Content className="content-wrapper">
            <Row gutter={16} style={{ marginRight: 0 }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="body-wrapper">
                  <article className="article toolbaruiWrapper">
                    <div className="box box-default">
                      <div className="box-body">
                        {frmVisible ? (
                          <NormalFormIndex
                            key={"key_" + frmName + "_0"}
                            formClass="label-min-height"
                            formData={formData}
                            showForm={true}
                            frmCode={frmName}
                            addForm={true}
                            // frmOptions={frmOptions}
                            showToolbar={[
                              {
                                isLeftBtn: [
                                  {
                                    isSets: [
                                      {
                                        id: "3",
                                        key: "save",
                                        type: <SaveOutlined />,
                                        withText: "Save",
                                        showToolTip: true,
                                        event: (key, data) => {
                                          saveFormData(data);
                                        },
                                      },
                                      {
                                        id: "4",
                                        key: "delete",
                                        type: <DeleteOutlined />,
                                        withText: "Delete",
                                        showToolTip: true,
                                        event: (key, data) => {
                                          deleteFormData(data);
                                        },
                                      },
                                      {
                                        id: "21",
                                        key: "edit",
                                        type: <EditOutlined />,
                                        withText: "Remark",
                                        showToolTip: true,
                                        event: (key, data) => {
                                          if (data?.id) {
                                            setState((prev) => ({
                                              ...prev,
                                              openRemarksModal: true,
                                            }));
                                          } else {
                                            openNotificationWithIcon(
                                              "info",
                                              "Please save make other payment first.",
                                              3
                                            );
                                          }
                                        },
                                      },
                                    ],
                                  },
                                ],
                                isRightBtn: [
                                  {
                                    isSets: [
                                      {
                                        key: "attachment",
                                        isDropdown: 0,
                                        withText: "Attachment",
                                        type: "",
                                        menus: null,
                                        event: (key, data) => {
                                          data &&
                                          data.hasOwnProperty("id") &&
                                          data["id"] > 0
                                            ? ShowAttachment(true)
                                            : openNotificationWithIcon(
                                                "info",
                                                "Please save make other payment invoice First.",
                                                3
                                              );
                                        },
                                      },
                                      formData?.invoice_no && {
                                        key: "tde",
                                        isDropdown: 0,
                                        withText: "TDE",
                                        type: "",
                                        menus: null,
                                        event: (key) => {
                                          if (formData?.payment_status == 241) {
                                            openNotificationWithIcon(
                                              "info",
                                              'Invoice with status "PREPARED" can not access TDE. Please change the status'
                                            );
                                          } else {
                                            if (formData?.invoice_no) {
                                              setState((prevState) => ({
                                                ...prevState,
                                                isShowTde: true,
                                              }));
                                            } else {
                                              openNotificationWithIcon("tde");
                                            }
                                          }
                                        },
                                      },
                                      {
                                        key: "report",
                                        isDropdown: 0,
                                        withText: "Report",
                                        type: "",
                                        menus: null,
                                        event: (key, data) => {
                                          if (data.id) {
                                            setState((prev) => ({
                                              ...prev,
                                              openReport: true,
                                            }));
                                          } else {
                                            openNotificationWithIcon(
                                              "info",
                                              "Please save make other payment first."
                                            );
                                          }
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ]}
                            inlineLayout={true}
                            isShowFixedColumn={"...."}
                            // showSideListBar={null}
                            // tableRowDeleteAction={(action, data) =>
                            //   onClickExtraIcon(action, data)
                            // }
                          />
                        ) : undefined}
                      </div>
                    </div>{" "}
                  </article>
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>

      {state.openRemarksModal && (
        <Modal
          width={600}
          title="Remark"
          open={state.openRemarksModal}
          onOk={() => {
            setState({ openRemarksModal: true });
          }}
          onCancel={() =>
            setState((prevState) => ({ ...prevState, openRemarksModal: false }))
          }
          footer={false}
        >
          <Remarks
            remarksID={
              props.formData.head_fix_code || formData?.head_fixture_code
            }
            remarkType="head-fixture"
            remarkVoyId={props.formData.id}
            remarkInvNo={formData.invoice_no}
          />
        </Modal>
      )}

      {state.showAttachment ? (
        <Modal
          style={{ top: "2%" }}
          title="Upload Attachment"
          open={state.showAttachment}
          onCancel={() => ShowAttachment(false)}
          width="50%"
          footer={null}
        >
          {state.loadComponent}
        </Modal>
      ) : undefined}

      {state.isShowTde ? (
        <Modal
          title="TDE"
          open={state.isShowTde}
          width="90%"
          onCancel={() =>
            setState((prevState) => ({ ...prevState, isShowTde: false }))
          }
          style={{ top: "10px" }}
          bodyStyle={{ maxHeight: 790, overflowY: "auto", padding: "0.5rem" }}
          footer={null}
        >
          <TDE
            invoiceType="makeOtherPayment"
            invoiceNo={formData["invoice_no"]}
            formData={formData}
            // modalCloseEvent={closeTde}
          />
        </Modal>
      ) : undefined}
    </div>
  );
};

export default MakeOtherPayment;

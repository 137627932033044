import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "./constants";
import { setAccountMail, setAllMailCount } from "../../actions/emailActions";
import { getAPICall, postAPICall } from "../../shared";
import { useEffect, useState } from "react";
import { Card, Row, Col, Spin } from "antd"; // Updated imports
import { Icon } from "@iconify/react/dist/iconify.js";

export let allMailsCounts;

const MailCount = ({ isShow = false,menuClicked }) => {
  const dispatch = useDispatch();
  const [mailData, setMailData] = useState([]);
  const [userMailwithLabel, setuserMailWithLabel] = useState([]);
  const mailCount = useSelector((state) => state.email.allEmailCount);
  allMailsCounts = mailCount;
  const [isLoading, setLoading] = useState(false);

  if (mailCount && Array.isArray(mailCount)) {
    localStorage.setItem("AllMailCounts", JSON.stringify(mailCount));
  }

  const countCard = [
    {
      title: "Total Received Emails",
      backgroundColor: "rgb(255, 226, 229)",
      iconColor: "rgb(250, 90, 125)",
      icon: "fluent:mail-arrow-down-20-filled",
      key: "All Mails",
    },
    {
      title: "Total Sent Emails",
      backgroundColor: "rgb(255, 244, 222)",
      iconColor: "rgb(255, 148, 122)",
      icon: "fluent:mail-arrow-up-16-filled",
      key: "Sent",
    },
    {
      title: "Total Drafts",
      backgroundColor: "rgb(220, 252, 231)",
      iconColor: "rgb(60, 216, 86)",
      icon: "fluent:mail-edit-32-filled",
      key: "Draft",
    },
    {
      title: "Total Important",
      backgroundColor: "rgb(243, 232, 255)",
      iconColor: "rgb(191, 131, 255)",
      icon: "material-symbols:star",
      key: "Important",
    },
  ];

  const AllMailWithLabel = async () => {
    try {
      // accounts/company-wise-all-oceannmailaccount

      const url = `${process.env.REACT_APP_MT_URL}accounts/company-wise-all-oceannmailaccount`;
      const isToken = true;
      const response = await getAPICall(url);
      if (response) {
        let content = response.data;

        //  dispatch(setallAccountMail(content));
        setuserMailWithLabel(content);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    AllMailWithLabel();
  }, [menuClicked]);

  const getAllMailCount = async (mail) => {
    try {
      const isToken = true;
      let payload = {};

      if (mail?.type) {
        payload[mail.type] = "*";
      } else {
        payload = { inbox: mail?.import_email };
      }
      const url = `${process.env.REACT_APP_MT_URL}mail/getCounts`;
      const response = await postAPICall(url, payload, "POST");
      // console.log("=-----", response);
      if (response) {
        if (response?.totalCount) {
          if (response?.totalCount) {
            let formate = formatNumber(response?.totalCount);
            return {
              label: mail.MailLabel,
              email: mail.import_email,
              totalCount: formate ? formate : 0,
            };
          } else {
            return {
              label: mail.MailLabel,
              email: mail.import_email,
              totalCount: 0,
            };
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMailCounts = async () => {
    let countarr = [];
    const staticMenu = [
      {
        MailLabel: "All Mails",
        import_mail: "inbox",
        type: "inbox",
      },
      {
        MailLabel: "Sent",
        import_mail: "sent",
        type: "sent",
      },
      {
        MailLabel: "Draft",
        import_mail: "Draft",
        type: "draft",
      },
      {
        MailLabel: "Important",
        import_mail: "important",
        type: "important",
      },

      {
        MailLabel: "Archived",
        import_mail: "archived",
        type: "archived",
      },

      {
        MailLabel: "Bin",
        import_mail: "bin",
        type: "trash",
      },
      {
        MailLabel: "Spam",
        import_mail: "spam",
        type: "spam",
      },
    ];

    let mapArray = [...staticMenu, ...userMailwithLabel];

    if (staticMenu.length > 0) {
      const mailCountsPromises = mapArray.map((mailData) =>
        getAllMailCount(mailData)
      );
      setLoading(true);
      const resolvedMailCounts = await Promise.all(mailCountsPromises);
      countarr = resolvedMailCounts?.filter((mailCount) => mailCount !== null);
      setLoading(false);
      dispatch(setAllMailCount(countarr));
    }
  };

  useEffect(() => {
    if(menuClicked=="oceannMail"){
      fetchMailCounts();
    }
    
  }, [menuClicked]);
 
  useEffect(() => {
    const labelsToSelect = ["All Mails", "Sent", "Draft", "Important"];
    
    if (mailCount && Array.isArray(mailCount) && mailCount?.length > 0) {
      const selectedMailData = mailCount?.filter((ele) =>
        labelsToSelect.includes(ele?.label)
      );

      const mergedData = countCard.map((card) => {
        const matchingMail = selectedMailData?.find((mail) => {
          if (card.key.includes(mail.label)) {
            return true;
          }
          return false;
        });

        return {
          ...card,
          count: matchingMail ? matchingMail.totalCount : 0,
        };
      });

      setMailData(mergedData);
    }
  }, [mailCount]);

  return (
    isShow && (
      <Row gutter={[16, 16]}>
        {isLoading ? (
          <Col
            span={24}
            style={{ textAlign: "center", alignContent: "center" }}
          >
            <Spin size="large" />
          </Col>
        ) : (
          mailData?.map((ele, index) => (
            <Col xs={24} sm={12} lg={12} key={index}>
              <Card
                className="dashboard-card"
                style={{
                  textAlign: "center",
                  // padding: "1px",
                  backgroundColor: ele.backgroundColor,
                  border: `1px solid ${ele.iconColor}`,
                  width: "100%",
                  height: "150px",
                  margin: "0 auto",
                }}
              >
                <div>
                  <Icon
                    icon={ele.icon}
                    style={{ fontSize: "40px", color: ele.iconColor }}
                  />
                </div>

                <span
                  style={{
                    fontSize: "24px",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  {ele.count}
                </span>

                <h4 style={{ color: "#817373" }}>{ele.title}</h4>
              </Card>
            </Col>
          ))
        )}
      </Row>
    )
  );
};

export default MailCount;

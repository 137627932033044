export const claimBrokerRow = {
  collapse_group: 0,
  f_alignement: 1,
  f_classname: "",
  f_default: "0.00",
  f_evt:
    '{\r\n\t"formulas": [\r\n\t  \r\n\t  {\r\n\t\t"A": "broker_commission",\r\n\t\t"Q": "address_commission",\r\n\t\t"F": "final_total_claim",\r\n\t\t"formula": "(A+Q+F)",\r\n\t\t"roundOff": 2,\r\n\t\t"updateTo": "final_amt_us",\r\n\t\t"externalReplace": {\r\n\t\t\t"replace": ["A", "Q","F"]\r\n\t\t}\r\n\t},\r\n\t   {\r\n\t\t"F": "final_amt_us",\r\n\t\t"E": "exchange",\r\n\t\t"formula": "(F*E)",\r\n\t\t"roundOff": 2,\r\n\t\t"updateTo": "final_amt_loc",\r\n\t\t"externalReplace": {\r\n\t\t\t"replace": ["F", "E"]\r\n\t\t}\r\n\t}\r\n\t\r\n\t],\r\n\r\n"eventLoops": [\r\n\t{\r\n\t    "f_id": 6121\r\n\t\r\n\t}\r\n    ]\r\n\r\n}',
  f_id: 5633,
  f_max_len: 0,
  f_min_len: 0,
  f_name: "broker_commission",
  f_placeholder: "Enter value for broker commission",
  f_readonly: 0,
  f_req: 0,
  f_so: 35,
  f_type: "number",
  f_v_fn: "validateDecimal",
  frm_type: "Normal",
  id: "claim_invoice_form",
  name: "Broker Commission",
  status: 1,
};

import { Modal, Table, Tooltip } from "antd";
import React, { useEffect } from "react";
// import ToolbarUI from "../../../../components/ToolbarUI";
import ToolbarUI from "../../../../components/CommonToolbarUI/toolbar_index";
import SidebarColumnFilter from "../../../../shared/SidebarColumnFilter";
import URL_WITH_VERSION, {
  getAPICall,
  objectToQueryStringFunc,
  useStateCallback,
} from "../../../../shared";
import { EditOutlined } from "@ant-design/icons";
import { FIELDS } from "../../../../shared/tableFields";
import MakeOtherPayment from "./MakeOtherPayment";

const MakeOtherPaymentList = (props) => {
  // console.log("props",props)
  const [state, setState] = useStateCallback({
    frmName: "tcto_form",
    loading: false,
    columns: [],
    responseData: [],
    pageOptions: { pageIndex: 1, pageLimit: 10, totalRows: 0 },
    isAdd: true,
    isVisible: false,
    sidebarVisible: false,
    formDataValues: {},
    showModal: false,
    formData: props.formData || {},
    typesearch: {},
    donloadArray: [],
  });

  useEffect(() => {
    const tableAction = {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 70,
      render: (text, record) => {
        return (
          <div className="editable-row-operations">
            <Tooltip title="Edit">
              <span
                className="iconWrapper"
                onClick={() => onRowClick(text, "1")}
              >
                <EditOutlined />
              </span>
            </Tooltip>
          </div>
        );
      },
    };

    let tableHeaders = Object.assign(
      [],
      FIELDS && FIELDS["make-other-payment-list"]
        ? FIELDS["make-other-payment-list"]["tableheads"]
        : []
    );
    tableHeaders.push(tableAction);
    tableHeaders.forEach((el) => (el["invisible"] = "false"));

    setState(
      (prevState) => ({ ...prevState, columns: tableHeaders }),
      () => {
        getTableData();
      }
    );
  }, []);

  const onRowClick = async (record, type) => {
    if (type == 1) {
      let _url = `${URL_WITH_VERSION}/head-fixture/hf_payment_edit?e=${record.id}`;
      const response = await getAPICall(_url);
      const respData = await response["data"];
      respData["type"] = "edit";

      setState(
        (prevState) => ({ ...prevState, formDataValues: respData }),
        () => {
          setState((prevState) => ({ ...prevState, showModal: true }));
        }
      );
    }
  };

  const getTableData = async (searchtype = {}) => {
    const { pageOptions, formData } = state;
    let qParams = { p: pageOptions.pageIndex, l: pageOptions.pageLimit };
    let headers = { order_by: { id: "desc" } };
    let _search =
      searchtype &&
      searchtype.hasOwnProperty("searchOptions") &&
      searchtype.hasOwnProperty("searchValue")
        ? searchtype
        : state.typesearch;

    if (
      _search &&
      _search.hasOwnProperty("searchValue") &&
      _search.hasOwnProperty("searchOptions") &&
      _search["searchOptions"] !== "" &&
      _search["searchValue"] !== ""
    ) {
      let wc = {};
      _search["searchValue"] = _search["searchValue"].trim();

      if (_search["searchOptions"].indexOf(";") > 0) {
        let so = _search["searchOptions"].split(";");
        wc = { OR: {} };
        so.map((e) => (wc["OR"][e] = { l: _search["searchValue"] }));
      } else {
        wc = {
          OR: { [_search["searchOptions"]]: { l: _search["searchValue"] } },
        };
      }

      if (headers.hasOwnProperty("where")) {
        // If "where" property already exists, merge the conditions
        headers["where"] = { ...headers["where"], ...wc };
      } else {
        // If "where" property doesn't exist, set it to the new condition
        headers["where"] = wc;
      }
      state.typesearch = {
        searchOptions: _search.searchOptions,
        searchValue: _search.searchValue,
      };
    }

    setState((prevState) => ({
      ...prevState,
      loading: true,
      responseData: [],
    }));

    let qParamString = objectToQueryStringFunc(qParams);
    let _state = {},
      dataArr = [],
      totalRows = 0;

    let _url = "";

    _url = `${URL_WITH_VERSION}/head-fixture/hf-payment/list?e=${formData.id}`;

    const response = await getAPICall(_url, headers);
    const data = await response;
    totalRows = data && data.total_rows ? data.total_rows : 0;
    dataArr = data && data.data ? data.data : [];
    let donloadArr = [];

    if (dataArr.length > 0) {
      dataArr.forEach((d) => donloadArr.push(d["id"]));
      _state["responseData"] = dataArr;
    }

    setState((prevState) => ({
      ...prevState,
      ..._state,
      donloadArray: donloadArr,
      pageOptions: {
        pageIndex: pageOptions.pageIndex,
        pageLimit: pageOptions.pageLimit,
        totalRows: totalRows,
      },
      loading: false,
    }));
  };

  const callOptions = (evt) => {
    let _search = {
      searchOptions: evt["searchOptions"],
      searchValue: evt["searchValue"],
    };
    if (
      evt.hasOwnProperty("searchOptions") &&
      evt.hasOwnProperty("searchValue")
    ) {
      let pageOptions = state.pageOptions;

      pageOptions["pageIndex"] = 1;
      setState(
        (prevState) => ({
          ...prevState,
          search: _search,
          pageOptions: pageOptions,
        }),
        () => {
          getTableData(_search);
        }
      );
    } else if (
      evt &&
      evt.hasOwnProperty("actionName") &&
      evt["actionName"] === "reset-serach"
    ) {
      let pageOptions = state.pageOptions;
      pageOptions["pageIndex"] = 1;

      setState(
        (prevState) => ({ ...prevState, search: {}, pageOptions: pageOptions }),
        () => {
          getTableData();
        }
      );
    } else if (
      evt &&
      evt.hasOwnProperty("actionName") &&
      evt["actionName"] === "column-filter"
    ) {
      // column filtering show/hide
      let responseData = state.responseData;
      let columns = Object.assign([], state.columns);

      if (responseData.length > 0) {
        for (var k in responseData[0]) {
          let index = columns.some(
            (item) =>
              (item.hasOwnProperty("dataIndex") && item.dataIndex === k) ||
              (item.hasOwnProperty("key") && item.key === k)
          );
          if (!index) {
            let title = k
              .split("_")
              .map((snip) => {
                return snip[0].toUpperCase() + snip.substring(1);
              })
              .join(" ");
            let col = Object.assign(
              {},
              {
                title: title,
                dataIndex: k,
                key: k,
                invisible: "true",
                isReset: true,
              }
            );
            columns.splice(columns.length - 1, 0, col);
          }
        }
      }

      setState((prevState) => ({
        ...prevState,
        sidebarVisible: evt.hasOwnProperty("sidebarVisible")
          ? evt.sidebarVisible
          : !prevState.sidebarVisible,
        columns: evt.hasOwnProperty("columns") ? evt.columns : columns,
      }));
    } else {
      let pageOptions = state.pageOptions;
      pageOptions[evt["actionName"]] = evt["actionVal"];

      if (evt["actionName"] === "pageLimit") {
        pageOptions["pageIndex"] = 1;
      }

      setState(
        (prevState) => ({ ...prevState, pageOptions: pageOptions }),
        () => {
          getTableData();
        }
      );
    }
  };

  const handleResize =
  (index) =>
  (e, { size }) => {
    setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };
  const onActionDonwload = async (downType, pageType) => {
    const token = localStorage.getItem("oceanToken");
    let params = `t=${pageType}`,
      cols = [],
      titles = [];
    const { columns, pageOptions, donloadArray } = state;

    let qParams = { p: pageOptions.pageIndex, l: pageOptions.pageLimit };

    columns.forEach((e) => {    
      if (e.invisible === "false" && e.key !== "action") {
        cols.push(e.dataIndex);
        titles.push(e.title);
      }
    });

    if (cols && cols.length > 0) {
      params = params + "&c=" + cols.join(",");
    }
    const filter = donloadArray.join(",");
    const url = `${process.env.REACT_APP_ATTACHMENT}/download/file/${downType}?${params}&l=${qParams.l}&ids=${filter}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: JSON.stringify({ data: titles }),
      });
      const blob = await response.blob();
      const link = document.createElement("a");
      const urlBlob = window.URL.createObjectURL(blob);
      link.href = urlBlob;
      link.download = `Make other payment Listing.${downType}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.error("There was an error with the fetch operation:", error);
    }
  };

  const closeModal=()=>{
    setState((prev)=>({...prev,showModal:false}))
  }

  const tableColumns = state.columns
    ?.filter((col) => (col && col.invisible !== "true" ? true : false))
    .map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize(index),
      }),
    }));
  return (
    <div className="body-wrapper">
      <article className="article">
        <div className="box box-default">
          <div className="box-body">
            {!props.title && (
              <div className="form-wrapper">
                <div className="form-heading">
                  <h4 className="title">
                    {/* <span>Make other payment summary</span> */}
                  </h4>
                </div>
              </div>
            )}
            <div
              className="section"
              style={{
                width: "100%",
                marginBottom: "10px",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
            >
              {state.loading === false ? (
              <ToolbarUI
                routeUrl={"make_other_payment"}
                optionValue={{
                  pageOptions: state.pageOptions,
                  columns: state.columns,
                  search: state.search,
                }}
                callback={(e) => callOptions(e)}
                dowloadOptions={[
                  {
                    title: "CSV",
                    event: () => onActionDonwload("csv", "hf-makepayment"),
                  },
                  {
                    title: "PDF",
                    event: () => onActionDonwload("pdf", "hf-makepayment"),
                  },
                  {
                    title: "XLS",
                    event: () => onActionDonwload("xlsx", "hf-makepayment"),
                  },
                ]}
              />
               ) : undefined}
            </div>
            <div>
              <Table
                // rowKey={record => record.id}
                className="inlineTable editableFixedHeader resizeableTable"
                bordered
                scroll={{ x: "max-content" }}
                // scroll={{ x: 1200, y: 370 }}
                columns={tableColumns}
                size="small"
                // components={components}
                dataSource={state.responseData}
                loading={state.loading}
                pagination={false}
                rowClassName={(r, i) =>
                  i % 2 === 0
                    ? "table-striped-listing"
                    : "dull-color table-striped-listing"
                }
              />
            </div>
          </div>
        </div>
      </article>

      {state.sidebarVisible ? (
        <SidebarColumnFilter
          columns={state.columns}
          sidebarVisible={state.sidebarVisible}
          callback={(e) => callOptions(e)}
        />
      ) : null}

      {state.showModal ? (
        <Modal
          className="page-container"
          style={{ top: "2%" }}
          title="Make other payment"
          open={state.showModal}
          onCancel={closeModal}
          width="90%"
          footer={null}
        >
          <MakeOtherPayment
            close={closeModal}
            formData={state.formDataValues}
            frmData={state.formData}
            getTableData={getTableData}
          />
        </Modal>
      ) : undefined}
    </div>
  );
};

export default MakeOtherPaymentList;

import React, { useEffect, useState } from "react";

import {
  Tabs,
  Card,
  Grid,
  Typography,
  Accordion,
  Row,
  Col,
  Collapse,
  Spin,
  Button,
  Popconfirm,
} from "antd";
import {
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
} from "../../../shared";
import VesselMap from "../../voyage-list/components/VesselMap";
import moment from "moment";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router";
const { TabPane } = Tabs;
const { Panel } = Collapse;
const NewVesselInfoDrawer = ({ marineData }) => {
  let { SHIPNAME, IMO } = marineData;
  const [activeTab, setActiveTab] = useState("vessel_information");
  const [center, setCenter] = useState(null);
  const [allData, setAllData] = useState([]);
  const [isLatest, setLatest] = useState(false);
  const [portCallLoading, setPortCallLoading] = useState(false);
  const [AddMyFleettract, setAddMyFleettract] = useState(false);
  const navigate = useNavigate();
  const [vesseldata, setVesselData] = useState({
    vesselinfo: {},
    vessel_particulars: {
      //   shipname: "N/A",
      //   dwt: "N/A",
      //   mmsi: "N/A",
      //   imo: "2020-10-14T12:00:00.000Z",
      //   ship_id: "N/A",
      //   speed: "N/A",
      //   status: "N/A",
      //   ship_type: "N/A",
      //   call_sign: "N/A",
      //   flag: "N/A",
      //   length: "N/A",
      //   breadth: "N/A",
      //   grt: "N/A",
      //   year_built: "N/A",
      //   ship_yard: "N/A",
      //   eedi: "N/A",
      //   rightship_safety_score: "N/A",
      //   rightship_ghg_rating: "N/A",
    },
    live_position: {
      latitude: "N/A",
      longitude: "N/A",
      speed: "N/A",
      avg_speed: "N/A",
      max_speed: "N/A",
      heading: "N/A",
      course: "N/A",
      status: "N/A",
      destination: "N/A",
      eta: "N/A",
      distance_to_go: "N/A",
      distance_travelled: "N/A",
      lastPort: "N/A",
      nextPort: "N/A",
    },
    port_congestion: {
      port: "N/A",
      country: "N/A",
      un_locode: "N/A",
      vessels_in_port: "N/A",
      departures_last_24_hrs: "N/A",
      arrivals_last_24_hrs: "N/A",
      global_area: "N/A",
      time_anch: "N/A",
      time_port: "N/A",
      vessels: "N/A",
      calls: "N/A",
      time_anch_stdev: "N/A",
      time_anch_diff: "N/A",
      time_port_stdev: "N/A",
      time_port_diff: "N/A",
      shipname: "N/A",
      ship_type: "N/A",
      capacity: "N/A",
      reported_eta: "N/A",
    },
  });

  const handleChange = async (newValue) => {
    let newVessel = vesseldata.vesselinfo;
    const shipId = vesseldata?.vesselinfo?.SHIP_ID;
    if (newValue === "vessel_particulars") {
      // getVesselParticular(marineData.MMSI)
    }
    setActiveTab(newValue);
    try {
      if (newValue == "port_calls") {
        setPortCallLoading(true);
        const url = `${process.env.REACT_APP_MT_URL}marine/single-vessel-port-call-db?shipid=${shipId}`;
        const vesselsinglportltUrl = `${process.env.REACT_APP_MT_URL}marine/single-vessel-port-call?v=6&shipid=${shipId}&msgtype=extended`;
        const vesselparticalRes = await getAPICall(url);
        //   console.log("vesselresponse", vesselparticalRes);
        if (vesselparticalRes?.data.length == 0) {
          const vesselparticalRes = await getAPICall(vesselsinglportltUrl);
          // console.log("vesselresponse222", vesselparticalRes);
          setAllData(vesselparticalRes?.data);
          setLatest(true);
          setPortCallLoading(false);
        } else {
          setAllData(vesselparticalRes?.data);
          setPortCallLoading(false);
        }
      } else if (newValue == "vessel_particulars") {
        const vesselUrl = `${process.env.REACT_APP_MT_URL}marine/get-vessel-particulars/${newVessel.MMSI}/${newVessel.IMO}/${newVessel.SHIP_ID}/${newVessel.SHIPNAME}`;
        const response = await getAPICall(vesselUrl);
        //   console.log("responsee", response);
        setVesselData((prev) => ({
          ...prev,
          vessel_particulars: response?.data[0],
        }));
      }
      
    } catch (error) {
      
    }
    
  };

  useEffect(() => {
    const token = localStorage.getItem("oceanToken");
    let vesselParticulars = {
      shipname: marineData.SHIPNAME,
      dwt: marineData.DWT,
      mmsi: marineData.MMSI,
      imo: marineData.IMO,
      ship_id: marineData.SHIP_ID,
      speed: "N/A",
      status: "N/A",
      ship_type: "N/A",
      call_sign: marineData.CALLSIGN,
      flag: marineData.FLAG,
      length: "N/A",
      breadth: "N/A",
      grt: "N/A",
      year_built: marineData.YEAR_BUILT,
      ship_yard: "N/A",
      eedi: "N/A",
      rightship_safety_score: "N/A",
      distance_travelled: "N/A",
    };
    let vesselPosition = {
      latitude: "N/A",
      longitude: "N/A",
      speed: "N/A",
      avg_speed: "N/A",
      max_speed: "N/A",
      heading: "N/A",
      course: "N/A",
      status: "N/A",
      destination: "N/A",
      eta: "N/A",
      distance_to_go: "N/A",
      distance_travelled: "N/A",
    };

    getLivePosition(marineData.MMSI, marineData.SHIP_ID, vesselParticulars);
  }, [marineData.MMSI, marineData.SHIP_ID]);

  const getLivePosition = async (mmsi, shipId, vesselParticularData) => {
    const token = localStorage.getItem("oceanToken");
    const urlVesselPosition = `${process.env.REACT_APP_MT_URL}marine/vessel-position-imo/${mmsi}/${shipId}/${SHIPNAME}/${IMO}`;

    try {
      const vesselPositionResponse = await fetch(urlVesselPosition, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      const vesselPositionDataFetched = await vesselPositionResponse.json();
      //   console.log("resp55", vesselPositionDataFetched);
      setAddMyFleettract(vesselPositionDataFetched?.Marked);
      if (!vesselPositionResponse.ok) {
        openNotificationWithIcon("info", vesselPositionDataFetched?.error);
        throw new Error(`Error: ${vesselPositionResponse.status}`);
      }

      const {
        SPEED,
        LENGTH,
        WIDTH,
        GRT,
        SHIPTYPE,
        LAT,
        LON,
        AVG_SPEED,
        MAX_SPEED,
        HEADING,
        COURSE,
        STATUS,
        DESTINATION,
        ETA,
        DISTANCE_TO_GO,
        DISTANCE_TRAVELLED,
        SHIPNAME,
        DWT,
        IMO,
        NEXT_PORT_NAME,
        LAST_PORT,
      } = vesselPositionDataFetched.data;

      // Update vessel particular data
      vesselParticularData.speed = SPEED;
      vesselParticularData.length = LENGTH;
      vesselParticularData.breadth = WIDTH;
      vesselParticularData.grt = GRT;
      vesselParticularData.ship_type = SHIPTYPE;

      // Create vessel position object
      const vesselPosition = {
        latitude:
          LAT[LAT.length - 1] === "N" ? LAT.substring(0, LAT.length - 1) : LAT,

        longitude:
          LON[LON.length - 1] === "E" ? LON.substring(0, LON.length - 1) : LON,
        speed: SPEED,
        avg_speed: AVG_SPEED,
        max_speed: MAX_SPEED,
        heading: HEADING,
        course: COURSE,
        status: STATUS,
        destination: DESTINATION,
        eta: ETA,
        distance_to_go: DISTANCE_TO_GO,
        distance_travelled: DISTANCE_TRAVELLED,
        lastPort: LAST_PORT,
        nextPort: NEXT_PORT_NAME,
      };

      // Update the map center position
      setCenter([LON, LAT]);

      // Update vessel data
      setVesselData((prev) => ({
        ...prev,
        vesselinfo: vesselPositionDataFetched.data,
        live_position: vesselPosition,
        vessel_particulars: vesselParticularData,
      }));
    } catch (error) {
      console.error("Failed to fetch vessel position data:", error);
    }
  };

  const latestPortcall = async () => {
    try {
      if (isLatest) {
        openNotificationWithIcon("success", "Data is already updated");
        return;
      }

      const shipID = vesseldata?.vesselinfo?.SHIP_ID;

      const url = `${process.env.REACT_APP_MT_URL}marine/single-vessel-port-call-db?shipid=${shipID}`;
      const vesselparticalRes = await getAPICall(url);
      //   console.log("response888",vesselparticalRes)
      if (vesselparticalRes?.data?.length !== 0) {
        setLatest(true);
        openNotificationWithIcon("success", "Port call data is updated");
      } else {
        console.log("some error occured");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const addMyFleet = async () => {
    // console.log(vesseldata, "vessel data")

    try {
      const url = `${process.env.REACT_APP_MT_URL}marine/add-to-fleet`;
      let payloadDetails = vesseldata?.live_position;
      let vesselPaylod = vesseldata?.vesselinfo;
      let payload = {
        imo_number: vesselPaylod?.IMO || "",
        mmsi_number: vesselPaylod?.MMSI || "",
        ship_id: vesselPaylod?.SHIP_ID || "",
        vessel_name: vesselPaylod?.SHIPNAME || "",
        next_port: {
          name: payloadDetails?.NEXT_PORT_NAME || "",
          locode: payloadDetails?.NEXT_PORT_UNLOCODE || "",
          travel_distance_nm: payloadDetails?.DISTANCE_TRAVELLED || "",
          travel_time_h: "",
        },
        last_port: {
          name: payloadDetails?.LAST_PORT || "",
          locode: payloadDetails?.LAST_PORT_UNLOCODE || "",
          ata: "",
          atd: "",
        },
        voyage: {
          DRAUGHT: payloadDetails?.DRAUGHT || "",
        },
        NEXT_PORT_NAME: payloadDetails?.NEXT_PORT_NAME || "",
        position: {
          speed: payloadDetails?.SPEED || "",
          draft: payloadDetails?.DRAFT || "",
          heading: payloadDetails?.HEADING || "lack of data",
          location: null,
          destination: payloadDetails?.DESTINATION || "",
          latitude: payloadDetails?.LAT || "",
          longitude: payloadDetails?.LON || "",
          nav_status: payloadDetails?.STATUS || "",
        },
      };
      const response = await postAPICall(url, payload, "POST");
      //   console.log("responsee33",response)
      if (response) {
        openNotificationWithIcon("success", "Added successfully");
        setAddMyFleettract(true);
      } else {
        console.log("error");
      }
    } catch (err) {
      console.log("Error:", err);
    }
  };

  const removeFromFleet = async () => {
    const shipId = vesseldata?.vesselinfo?.SHIP_ID;
    try {
      if (shipId) {
        try {
          const url = `${process.env.REACT_APP_MT_URL}marine/delete-from-fleet/${shipId}`;
          const payload = {};
          const response = await postAPICall(url, payload, "DELETE");
          //   console.log("response77",response)
          if (response) {
            openNotificationWithIcon("success", "Deleted successfully");
            setAddMyFleettract(false);
          } else {
            console.log("error");
          }
        } catch (error) {
          openNotificationWithIcon("info", "Something went wrong");
        }
      } else {
        // dispatch(setSpinner(false))
        // toast.error('Ship id not found')
      }
    } catch (error) {}
  };
  // console.log('marineData----',marineData);
  // console.log('vesselData----',vesseldata);

  const objTosend={
    SHIP_ID:marineData?.SHIP_ID,
    SHIPNAME:marineData?.SHIPNAME,
    MMSI:marineData?.MMSI,
    CALLSIGN:marineData?.CALLSIGN,
    DWT:marineData?.DWT,
    FLAG:marineData?.FLAG,
    IMO:marineData?.IMO,
    LAT:vesseldata?.live_position?.latitude,
    LON:vesseldata?.live_position?.longitude,
     CURRENT_PORT_COUNTRY:vesseldata?.vesselinfo?.CURRENT_PORT_COUNTRY,
     CURRENT_PORT:vesseldata?.vesselinfo?.CURRENT_PORT,
  }

  return (
    <>
      {vesseldata?.vesselinfo?.SHIPNAME && (
        <div style={{ display: "flex" }}>
          <div style={{ position: "absolute", top: "6.5px", right: "185px" }}>
            <Popconfirm
              title={AddMyFleettract ? "Remove from fleet" : "Add to fleet"}
              description={
                AddMyFleettract
                  ? "Do you want to remove this from fleet?"
                  : "Do you want to add this to fleet?"
              }
              onConfirm={AddMyFleettract ? removeFromFleet : addMyFleet}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                title="Add Vessel To My Fleet"
                // onClick={handleClickOpen}
                variant="outlined"
                size="small"
                //   style={{padding:"10px"}}
              >
                <Icon
                  icon={AddMyFleettract ? "tabler:star-filled" : "tabler:star"}
                  color="orange"
                  fontSize={24}
                  style={{ marginRight: "10px" }}
                />
                {AddMyFleettract ? "Remove Fleet" : "Add Fleet"}
              </Button>
            </Popconfirm>
          </div>

          <div style={{ position: "absolute", top: "6.5px", right: "50px" }}>
            <Button
              onClick={() =>
                navigate("/show-all-vessel-details", {
                  state: { vesselPositionDetails: objTosend },
                })
              }
            >
              More details
            </Button>
          </div>
        </div>
      )}

      <div
        style={{
          background: "inherit",
          height: "400px",
          overflow: "hidden",
          width: "100%",
        }}
      >
        {center ? (
          <VesselMap
            center={center}
            shipName={vesseldata?.vessel_particulars?.shipname}
            log={vesseldata?.live_position?.longitude}
            lat={vesseldata?.live_position?.latitude}
            Imo={vesseldata?.vessel_particulars?.imo}
            vesselData={vesseldata}
          />
        ) : (
          <Spin />
        )}

        <hr />
      </div>

      <Tabs
        activeKey={activeTab}
        onChange={handleChange}
        tabBarGutter={50}
        tabBarStyle={{ fontWeight: "bold" }}
      >
        <TabPane tab="Vessel Information" key="vessel_information">
          <div style={{ background: "inherit" }}>
            <div>
              <Card style={{ maxWidth: "100%", marginTop: "20px" }}>
                <h3>Vessel Information</h3>
                <div>
                  <table
                    className="portcalls-history-table"
                    style={{ width: "100%" }}
                  >
                    <tr>
                      <td>Vessel Name</td>
                      <td>{vesseldata?.vesselinfo?.SHIPNAME ?? "N/A"}</td>
                      <td style={{ width: "20px", textAlign: "center" }}>|</td>
                      <td>DWT</td>
                      <td>{vesseldata?.vesselinfo?.DWT ?? "N/A"}</td>
                    </tr>
                    <tr>
                      <td>IMO</td>
                      <td>{vesseldata?.vesselinfo?.IMO ?? "N/A"}</td>
                      <td style={{ width: "20px", textAlign: "center" }}>|</td>
                      <td>MMSI</td>
                      <td>{vesseldata?.vesselinfo?.MMSI ?? "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Ship ID</td>
                      <td>{vesseldata?.vesselinfo?.SHIP_ID ?? "N/A"}</td>
                      <td style={{ width: "20px", textAlign: "center" }}>|</td>
                      <td>Country</td>
                      <td>{vesseldata?.vesselinfo?.COUNTRY ?? "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Year Built</td>
                      <td>{vesseldata?.vesselinfo?.YEAR_BUILT ?? "N/A"}</td>
                      <td style={{ width: "20px", textAlign: "center" }}>|</td>
                      <td>Call sign</td>
                      <td>{vesseldata?.vesselinfo?.CALLSIGN ?? "N/A"}</td>
                    </tr>
                    <tr>
                      <td>FLAG</td>
                      <td>{vesseldata?.vesselinfo?.FLAG ?? "N/A"}</td>
                      <td style={{ width: "20px", textAlign: "center" }}>|</td>
                      <td>Passage Type</td>
                      <td>{vesseldata?.vesselinfo?.TYPE_NAME ?? "N/A"}</td>
                    </tr>
                  </table>
                </div>
              </Card>
            </div>
          </div>
        </TabPane>

        <TabPane tab="Particulars" key="vessel_particulars">
          <table className="portcalls-history-table" style={{ width: "100%" }}>
            {/* done  */}
            <tr>
              <td>Vessel Name</td>
              <td>{vesseldata?.vesselinfo?.SHIPNAME ?? "N/A"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>DWT</td>
              <td>{vesseldata?.vesselinfo?.DWT ?? "N/A"}</td>
            </tr>
            <tr>
              <td>MMSI</td>
              <td>{vesseldata?.vesselinfo?.MMSI ?? "N/A"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>IMO</td>
              <td>{vesseldata?.vesselinfo?.IMO ?? "N/A"}</td>
            </tr>
            <tr>
              <td>Ship Id</td>
              <td>{vesseldata?.vesselinfo?.SHIP_ID ?? "N/A"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>Speed</td>
              <td>{vesseldata?.vessel_particulars?.SPEED_SERVICE ?? "N/A"}</td>
            </tr>
            <tr>
              <td>Ship Type</td>
              <td>{vesseldata?.vessel_particulars?.VESSEL_TYPE ?? "N/A"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>Call Sign</td>
              <td>{vesseldata?.vessel_particulars?.CALLSIGN ?? "N/A"}</td>
            </tr>
            <tr>
              <td>Flag</td>
              <td>{vesseldata?.vessel_particulars?.FLAG ?? "N/A"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>Length overall</td>
              <td>{vesseldata?.vessel_particulars?.LENGTH_OVERALL ?? "N/A"}</td>
            </tr>
            <tr>
              <td>Breadth</td>
              <td>
                {vesseldata?.vessel_particulars?.BREADTH_MOULDED ?? "N/A"}
              </td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>GRT</td>
              <td>{vesseldata?.vessel_particulars?.DRAUGHT ?? "N/A"}</td>
            </tr>
            <tr>
              <td>Year Built</td>
              <td>{vesseldata?.vessel_particulars?.BUILD ?? "N/A"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>Summer Dwt</td>

              <td>{vesseldata?.vessel_particulars?.SUMMER_DWT ?? "N/A"}</td>
            </tr>
            <tr>
              <td>Ship Yard</td>
              <td>{vesseldata?.vessel_particulars?.YARD_NUMBER ?? "N/A"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              {/* done  */}
              <td>Depth</td>
              <td>{vesseldata?.vessel_particulars?.DEPTH ?? "N/A"}</td>
            </tr>
            <tr>
              <td>L FORE</td>
              <td>{vesseldata?.vessel_particulars?.L_FORE ?? "N/A"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>W LEFT</td>
              <td>{vesseldata?.vessel_particulars?.W_LEFT ?? "N/A"}</td>
            </tr>
            <tr>
              <td>Gross tonnage</td>
              <td>{vesseldata?.vessel_particulars?.GROSS_TONNAGE ?? "N/A"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>Net tonnage</td>
              <td>{vesseldata?.vessel_particulars?.NET_TONNAGE ?? "N/A"}</td>
            </tr>
            <tr>
              <td>Owner</td>
              <td>{vesseldata?.vessel_particulars?.OWNER ?? "N/A"}</td>
              {/* <td>
                      Manager
                    </td> */}
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
            </tr>
            <tr>
              <td>Manager Country</td>
              <td>{vesseldata?.vessel_particulars?.MANAGER_COUNTRY ?? "--"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>Manager Town</td>
              <td>{vesseldata?.vessel_particulars?.MANAGER_TOWN ?? "--"}</td>
            </tr>

            <tr>
              <td>Manager Website</td>
              <td>{vesseldata?.vessel_particulars?.MANAGER_WEBSITE ?? "--"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>Manager Town</td>
              <td>{vesseldata?.vessel_particulars?.MANAGER_TOWN ?? "--"}</td>
            </tr>

            <tr>
              <td>Engine Rpm</td>
              <td>{vesseldata?.vessel_particulars?.ENGINE_RPM ?? "--"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>Hall Material</td>
              <td>{vesseldata?.vessel_particulars?.HULL_MATERIAL ?? "--"}</td>
            </tr>

            <tr>
              <td>Insurer</td>
              <td>{vesseldata?.vessel_particulars?.INSURER ?? "--"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>Engine Power</td>
              <td>{vesseldata?.vessel_particulars?.ENGINE_POWER ?? "--"}</td>
            </tr>
            <tr>
              <td>Propeller</td>
              <td>{vesseldata?.vessel_particulars?.PROPELLER ?? "--"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>Deckes Number</td>
              <td>{vesseldata?.vessel_particulars?.DECKS_NUMBER ?? "--"}</td>
            </tr>
          </table>
        </TabPane>
        <TabPane tab="Live Position" key="live_position">
          <table className="portcalls-history-table" style={{ width: "100%" }}>
            <tr>
              <td>Latitude</td>
              <td>{vesseldata?.live_position?.latitude ?? "N/A"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>Longitude</td>
              <td>{vesseldata?.live_position?.LON ?? "N/A"}</td>
            </tr>
            <tr>
              <td>Speed</td>
              <td>
                {vesseldata?.live_position?.speed
                  ? (Number(vesseldata?.live_position?.speed) * 0.1).toFixed(
                      2
                    ) + " knots"
                  : "N/A"}
              </td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>Avg Speed</td>
              <td>
                {(vesseldata?.live_position?.avg_speed &&
                  vesseldata?.live_position?.avg_speed + " knots") ||
                  "N/A"}
              </td>
            </tr>
            <tr>
              <td>Max Speed</td>
              <td>
                {(vesseldata?.live_position?.max_speed &&
                  vesseldata?.live_position?.max_speed + " knots") ||
                  "N/A"}
              </td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>Heading</td>
              <td>{vesseldata?.live_position?.heading ?? "N/A"}</td>
            </tr>
            <tr>
              <td>Course</td>
              <td>{vesseldata?.live_position?.course ?? "N/A"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>Status</td>
              <td>
                <p
                  title={vesseldata?.live_position?.status}
                  style={{
                    width: "99%",
                    display: "block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {vesseldata?.live_position?.status ?? "N/A"}
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ background: "rgb(191 124 219)", color: "black" }}>
                Destination
              </td>
              <td style={{ background: "rgb(191 124 219)", color: "black" }}>
                {vesseldata?.live_position?.destination ?? "N/A"}
              </td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td style={{ background: "#dbb97c", color: "black" }}>ETA</td>
              <td style={{ background: "#dbb97c", color: "black" }}>
                {/* {formatETA(vesseldata?.live_position?.eta)} */}
              </td>
            </tr>
            <tr>
              <td>Distance To Go</td>
              <td>{vesseldata?.live_position?.distance_to_go ?? "N/A"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>Distance Travelled</td>
              <td>{vesseldata?.live_position?.distance_travelled ?? "N/A"}</td>
            </tr>
            <tr>
              <td style={{ background: "#7cdb7c", color: "black" }}>
                Last port
              </td>
              <td style={{ background: "#7cdb7c", color: "black" }}>
                {vesseldata?.live_position?.lastPort ?? "N/A"}
              </td>
              <td
                style={{
                  width: "20px",
                  textAlign: "center",
                  background: "white",
                }}
              >
                |
              </td>
              <td style={{ background: "#709f81b8", color: "black" }}>
                Current port
              </td>
              <td style={{ background: "#709f81b8", color: "black" }}>
                {vesseldata?.live_position.nextPort ?? "N/A"}
              </td>
            </tr>
            <tr>
              <td style={{ background: "#acaeeb", color: "black" }}>
                Next port ID
              </td>
              <td style={{ background: "#acaeeb", color: "black" }}>
                {vesseldata?.live_position?.nextPort ?? "N/A"}
              </td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td>W_LEFT</td>
              <td>{vesseldata?.live_position?.W_LEFT ?? "N/A"}</td>
            </tr>
            <tr>
              <td>L_FORE</td>
              <td>{vesseldata?.live_position?.L_FORE ?? "N/A"}</td>
              <td style={{ width: "20px", textAlign: "center" }}>|</td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </TabPane>

        <TabPane tab="Port Calls" key="port_calls">
          {portCallLoading ? (
            <Spin />
          ) : (
            <>
              <div
                style={{
                  textAlign: "right",
                  paddingBlock: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>
                  {isLatest ? "Latest Port call Data" : "Port call Details"}
                </span>
                <Button
                  className="latest-port-call"
                  onClick={() => latestPortcall()}
                >
                  Latest
                </Button>
              </div>
              <table className="port_call_table">
                <thead>
                  <tr>
                    <th>Port</th>
                    <th>Move Type</th>
                    <th>Time(UTC)</th>
                  </tr>
                </thead>
                <tbody>
                  {allData?.length > 0 &&
                    allData.map((item, index) => (
                      <tr>
                        <td>{item.PORT_NAME}</td>
                        <td>{item.MOVE_TYPE}</td>
                        <td style={{ background: "#5dc93aab", color: "black" }}>
                          {moment(item.TIMESTAMP_UTC)
                            .utc()
                            .format("YYYY-MM-DD - HH:mm")}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
        </TabPane>
      </Tabs>
    </>
  );
};

export default NewVesselInfoDrawer;

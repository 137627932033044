import { Modal } from 'antd';
import React from 'react'
import VesselSchedule from '../../../components/vessel-form';
import Chat from '../../Chat/internalChat';

const ChatModal = ({ showAddChatlModal, setShowAddChatModal }) => {
    // console.log(showAddChatlModal, "chat")
    return (
        <Modal
            title="Add Vessel Form"
            open={showAddChatlModal}
            closable={true}
            onCancel={() => setShowAddChatModal(false)}
            footer={null}
            style={{
                top: 20,
            }}
            width={"90%"}
            bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
        >
            <Chat />
        </Modal>
    );
};

export default ChatModal;


import React, { useState } from 'react';
import { Tooltip, Button, Modal, Spin, message } from 'antd';
import { Icon } from '@iconify/react/dist/iconify.js';
import { SearchOutlined, TagOutlined, FileOutlined, EnvironmentOutlined, DollarOutlined, CloseOutlined } from '@ant-design/icons';
import { openNotificationWithIcon, postAPICall, getAPICall } from '../../../shared';
import { useSelector } from 'react-redux';
import QuickVessel from './quickvessel';
import VesselSchedule from '../../../components/vessel-form';
import AddAddressBook from '../../../components/AddAddressBook';
import BunkerPriceModal from './BunkerPriceModal';
import { highlightSearchKeywords } from '../constants';

const SelectText = props => {
    const [showToolTip, setShowToolTip] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [isOpencontactFull, setIsOpencontactFull] = useState(false);
    const [isContact, setIsContact] = useState(false);
    const [isOpenvessel, setIsOpenvessel] = useState(false); // For QuickVessel modal
    const [isFullDetailsOpenvessel, setIsFullDetailsOpenvessel] = useState(false);
    const currentMail = props?.mail
    const [year, setYear] = useState(new Date())
    const [Name, setName] = useState(props.selectedText)
    const [marineData, setMarinData] = useState({})
    const [showBunkerPrice, setShowBunkerPrice] = useState(false)
    const [bunkerDetails, setBunkerDetails] = useState([])
    let checkCheckContackValidation = false
    const [formData, setFormData] = useState({
        quickVessel: {
            vessel_name: props.selectedText,
            imo_no: null,
            summer_dwt: '',
            vessel_code: '',
            GRT: 0,
            NRT: 0,
            LOA: 0,
            TPC: 0,
            built_year: year, depth: 0
        },
        quickCon: {
            person_name: '',
            company_short_name: '',
            mobile_number: '',
            company_name: props.selectedText,
            email_id: '',
            sender_email: '',

        }
    })
    const handleSubmit = (formData) => {
        console.log('Submitted Vessel Form:', formData);
    };

    const handleSubmitCon = (formData) => {
        setIsOpencontactFull(true)
    };


    //  create label 
    const handleAddLabel = async () => {
        if (props.selectedText?.trim() && props.selectedText?.trim()?.length < 25) {
            let labelName = props.selectedText;
            try {
                const url = `${process.env.REACT_APP_MT_URL}mail/addLabel`;

                const payload = {
                    name: labelName?.trim(),
                    key: labelName?.trim()?.replace(/\s+/g, "")?.toLowerCase(),
                };
                const response = await postAPICall(url, payload, "post");
                if (response?.id) {
                    openNotificationWithIcon("success", response?.msg);
                } else {
                    openNotificationWithIcon("error", response?.msg || "Some error occcured");
                }
            } catch (err) {
                console.log(err);
                openNotificationWithIcon("error", err?.message);
            }
        }
    };
    const openFullDetailsModal = () => {

        setIsFullDetailsOpenvessel(true);
        setIsOpenvessel(false); // Close the QuickVessel modal
    };
    const findMatch = (text, e) => {

        try {
            const cleanText = text.replace(/[\n,:-]/g, ' ')
            if (currentMail && currentMail?.vessel_name && currentMail?.vessel_name.length > 0) {
                const vesselArray = currentMail.vessel_name
                const textArray = cleanText.split(' ')

                // Create a regular expression pattern from vesselArray
                const pattern = new RegExp(vesselArray.join('|'), 'gi')

                // Find all matches in the text using the regular expression pattern
                const matches = cleanText.match(pattern)
                const uniqueMatches = matches ? Array.from(new Set(matches)) : []

                if (uniqueMatches.length > 0) {
                    // Assuming the first match is the vessel name to search for
                    const vesselName = uniqueMatches[0]
                    vesselSearch(vesselName, e)

                } else {
                    openNotificationWithIcon("success", 'No vessel Found')
                }
            } else {
                if (currentMail.Processing == true) {
                    openNotificationWithIcon("success", "Please wait ! can't create vessel in processing")
                    // props.handlecloseTextSelectPopUp({ spinner: false, popUpclose: true });
                    // handleCloseAllPopup()
                } else {
                    openNotificationWithIcon("success", 'No vessel name provided')
                    // handleCloseAllPopup()
                    // props.handlecloseTextSelectPopUp({ spinner: false, popUpclose: true });
                }
                // setshowToolTip(false)
            }
            // props.handlecloseTextSelectPopUp(false);
        } catch (error) {
            console.log(error)
            openNotificationWithIcon("info", 'An error occurred while searching for vessel')
            //   handleCloseAllPopup()
        }
    }
    const formData1 = {
        id: 0,
        "portconsp.tableperday": [
            {
                editable: true,
                index: 0,
                con_type: 3,
                con_g: 2,
                id: -9e6 + 0,
                con_unit: 2,
                con_loading: "0.00",
                con_disch: "0.00",
                con_ideal_on: "0.00",
                con_capacity: "0.00",
                con_margin: "0.00",
                con_heat: "0.00",
                con_heat_p: "0.00",
                con_heat_pp: "0.00",
                con_igs: "0.00",
                con_clean: "0.00",
                con_maneuv: "0.00",
                con_av: "0.00",
            },
            {
                editable: true,
                index: 1,
                con_type: 10,
                con_g: 4,
                id: -9e6 + 1,
                con_unit: 2,
                con_loading: "0.00",
                con_disch: "0.00",
                con_ideal_on: "0.00",
                con_capacity: "0.00",
                con_margin: "0.00",
                con_heat: "0.00",
                con_heat_p: "0.00",
                con_heat_pp: "0.00",
                con_igs: "0.00",
                con_clean: "0.00",
                con_maneuv: "0.00",
                // con_av: "0.00",
            },
            {
                editable: true,
                index: 2,
                con_type: 5,
                con_g: 3,
                id: -9e6 + 2,
                con_unit: 2,
                con_loading: "0.00",
                con_disch: "0.00",
                con_ideal_on: "0.00",
                con_capacity: "0.00",
                con_margin: "0.00",
                con_heat: "0.00",
                con_heat_p: "0.00",
                con_heat_pp: "0.00",
                con_igs: "0.00",
                con_clean: "0.00",
                con_maneuv: "0.00",
                con_av: "0.00",
            },
            {
                editable: true,
                index: 3,
                con_type: 7,
                con_g: 4,
                id: -9e6 + 3,
                con_unit: 2,
                con_loading: "0.00",
                con_disch: "0.00",
                con_ideal_on: "0.00",
                con_capacity: "0.00",
                con_margin: "0.00",
                con_heat: "0.00",
                con_heat_p: "0.00",
                con_heat_pp: "0.00",
                con_igs: "0.00",
                con_clean: "0.00",
                con_maneuv: "0.00",
                con_av: "0.00",
            },
            {
                editable: true,
                index: 4,
                con_type: 4,
                con_g: 3,
                id: -9e6 + 4,
                con_unit: 2,
                con_loading: "0.00",
                con_disch: "0.00",
                con_ideal_on: "0.00",
                con_capacity: "0.00",
                con_margin: "0.00",
                con_heat: "0.00",
                con_heat_p: "0.00",
                con_heat_pp: "0.00",
                con_igs: "0.00",
                con_clean: "0.00",
                con_maneuv: "0.00",
                con_av: "0.00",
            },
        ],
        "seaspdconsp.tableperday": [
            {
                editable: true,
                index: 1,
                speed_type: 1,
                ballast_laden: 1,
                id: -9e6 + 10,
                engine_load: 85,
                ifo: "0.00",
                vlsfo: "0.00",
                ulsfo: "0.00",
                lsmgo: "0.00",
                mgo: "0.00",
            },
            {
                editable: true,
                index: 2,
                speed_type: 1,
                ballast_laden: 2,
                id: -9e6 + 11,
                engine_load: 85,
                ifo: "0.00",
                vlsfo: "0.00",
                ulsfo: "0.00",
                lsmgo: "0.00",
                mgo: "0.00",
            },
        ],
    }
    const vesselSearch = async (selectedText, event) => {
        try {
            // props.handlecloseTextSelectPopUp({ spinner: true, popUpclose: false })
            if (selectedText?.length > 3) {
                let vesselName = selectedText.trim()
                let vesselsearchVesselName = vesselName?.replace(/^\s*(MV|M\/V)\s*/i, '')
                const replacedString = vesselsearchVesselName?.replace(/\//g, '');
                if (replacedString.length !== 0) {
                    let url = `${process.env.REACT_APP_MT_URL}marine/get-vessels-name/${replacedString}`;
                    let marineData = await getAPICall(url);
                    // console.log(marineData[0], 'marineData')
                    if (marineData) {
                        handleSaveVessel(marineData[0])
                        setMarinData(marineData[0])
                    }
                }
            }
            // setIsOpenvessel(true);
            //   props.handlecloseTextSelectPopUp({ spinner: false, popUpclose: false })
        } catch (error) {
            // setRefresh(false)
            //   props.handlecloseTextSelectPopUp({ spinner: false, popUpclose: false })
            console.error('Error fetching data:', error)
        }
    }
    const handleShortName = async (value, type) => {
        try {
            setName(value)
            // const uuid = uuidv4()
            const sanitizedValue = value.replace(/[^\w\s]/g, '').replace(/\s/g, '')
            const vessel = sanitizedValue.substring(0, 3).toUpperCase()
            const shortUuid = sanitizedValue.replace(/-/g, '').substring(0, 6).toUpperCase()
            const newVesselCode = vessel.length !== 0 ? `${vessel}${shortUuid}` : ''
            const { phones, emails, skypeIds } = extractDataForContact(value)

            if (type === 'vessel') {
                setFormData(prev => ({
                    ...prev,
                    quickVessel: {
                        ...prev.quickVessel,
                        vessel_name: value.slice(0, 10),
                        vessel_code: ''
                    }
                }))
            } else if (type === 'contact') {
                // setIsContact(true)
                // props.handlecloseTextSelectPopUp({ spinner: true, popUpclose: false });
                if (!currentMail.sender_full_details) {
                    const isToken = true
                    const payload = { value }
                    const url = `${process.env.REACT_APP_MT_URL}marine/contact_info`
                    // const response = await postApiService({
                    //     url,
                    //     isToken,
                    //     payload,
                    //     showMessage: true
                    // })
                    const response = await postAPICall(url, payload, "post");
                    if (response) {

                        checkCheckContackValidation = true
                        let responseData = response.data
                        setFormData(prev => ({
                            ...prev,
                            quickCon: {
                                ...prev.quickCon,
                                company_name: responseData?.company_name || '',
                                person_name: responseData.name || '',
                                company_short_name: shortUuid,
                                skype_id: responseData?.skype?.length > 0 ? responseData.skype[0] : null,
                                mobile_number: responseData?.phone_numbers?.length > 0 ? responseData?.phone_numbers[0] : null,
                                email_id: responseData?.emails?.length > 0 ? responseData?.emails[0] : null,
                                sender_email: currentMail?.New_From?.length > 0 ? currentMail?.New_From[0] : null,
                                // website: responseData?.websites?.length > 0 ? responseData?.websites[0] : null
                            }
                        }))
                        setIsContact(true)
                    }
                    else {
                        if (phones.length == 0 && emails.length == 0) {
                            openNotificationWithIcon('info', 'Please select valid characters .')
                            checkCheckContackValidation = false
                        } else {
                            checkCheckContackValidation = true
                            setFormData(prev => ({
                                ...prev,
                                quickCon: {
                                    ...prev.quickCon,
                                    company_name: '',
                                    person_name: '',
                                    company_short_name: '',
                                    skype_id: skypeIds[0],
                                    mobile_number: phones[0],
                                    email_id: emails[0],
                                    sender_email: currentMail?.New_From?.length > 0 ? currentMail?.New_From[0] : null,
                                }
                            }))
                            setIsContact(true)
                        }
                    }

                }
                else if (currentMail?.sender_full_details) {
                    checkCheckContackValidation = true
                    setFormData(prev => ({
                        ...prev,
                        quickCon: {
                            ...prev.quickCon,
                            company_name: currentMail.sender_full_details?.company_name,
                            person_name: currentMail.sender_full_details?.name,
                            company_short_name: shortUuid,
                            skype_id: currentMail.sender_full_details?.skype?.length > 0 ? currentMail.sender_full_details.skype[0] : null,
                            mobile_number: currentMail.sender_full_details?.phone_numbers?.length > 0 ? currentMail.sender_full_details.phone_numbers[0] : null,
                            email_id: currentMail.sender_full_details?.emails?.length > 0 ? currentMail.sender_full_details.emails[0] : null,
                            sender_email: currentMail.sender_full_details?.emails?.length > 0 ? currentMail.sender_full_details.emails[0] : null,
                        }
                    }))
                    setIsContact(true)
                }
                else {
                    if (phones.length == 0 && emails.length == 0) {
                        openNotificationWithIcon("success", 'Please select valid characters .')
                        checkCheckContackValidation = false
                    } else {
                        checkCheckContackValidation = true
                        setFormData(prev => ({
                            ...prev,
                            quickCon: {
                                ...prev.quickCon,
                                company_name: '',
                                person_name: '',
                                company_short_name: '',
                                skype_id: skypeIds[0],
                                mobile_number: phones[0],
                                email_id: emails[0],
                                sender_email: emails[0]
                            }
                        }))
                        setIsContact(true)
                    }
                }
                setIsContact(true)
                // props.handlecloseTextSelectPopUp({ spinner: false, popUpclose: false });
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = (value, key, group) => {
        try {
            if (key === 'company_name') {
                // const uuid = uuidv4()
                const companyName = value.substring(0, 3).toUpperCase()
                const shortUuid = value.replace(/-/g, '').substring(0, 6).toUpperCase()
                const companyCode = companyName.length !== 0 ? `${companyName}${shortUuid}` : ''

                setFormData(prev => ({
                    ...prev,
                    [group]: {
                        ...prev[group],
                        ['company_short_name']: companyCode
                    }
                }))
            }

            setFormData(prev => ({
                ...prev,
                [group]: {
                    ...prev[group],
                    [key]: value
                }
            }))
        } catch (error) {
            console.log(error)
        }
    }

    const handleYearChange = (date) => {
        try {
            setYear(date)
            setFormData(prev => ({
                ...prev,
                built_year: date // Update the built_year when the year is changed
            }))
        } catch (error) {
            console.log(error)
        }
    }
    const extractDataForContact = (text) => {
        const skypeRegex = /\bSkype(?:\s+ID)?\s*:\s*(\S+)/gi
        const phoneRegex = /\+?\d{1,3}[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,4}/g
        const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g

        const skypeIds = []
        let match
        while ((match = skypeRegex.exec(text)) !== null) {
            skypeIds.push(match[1])
        }

        const phones = text.match(phoneRegex) || []
        const emails = text.match(emailRegex) || []

        return { skypeIds, phones, emails }
    }
    const handleSaveVessel = async vesseldetails => {

        try {
            if (vesseldetails) {
                // console.log(vesseldetails)
                let vesselResponse = null
                if (vesseldetails.MMSI) {
                    const isToken = true
                    const vesselUrl = `${process.env.REACT_APP_MT_URL}marine/get-vessel-particulars/${vesseldetails.MMSI}/${vesseldetails.IMO}/${vesseldetails.SHIP_ID}/${vesseldetails.SHIPNAME}`;
                    const vesselResponse = await getAPICall(vesselUrl);
                    if (vesselResponse) {
                        setMarinData(vesselResponse?.data[0])
                        setIsOpenvessel(true);
                    }
                    //   const vesselUrl = `${process.env.NEXT_PUBLIC_BASE_URL}/marine/get-vessel-particulars/${vesseldetails.MMSI}/${vesseldetails.IMO}/${vesseldetails.SHIP_ID}/${vesseldetails.SHIPNAME}`
                    //   vesselResponse = await getApiService({
                    //     url: vesselUrl,
                    //     isToken,
                    //     showMessage: false,
                    //     payload: {},
                    //     header: {}
                    //   })
                    // }

                    let vesselInfo = vesseldetails
                    const vesselDetail = vesselResponse?.data?.length > 0 ? vesselResponse?.data[0] : null
                    setIsOpenvessel(true);
                    // setIsopenVessel(false)
                    // setAnchorEl(null)

                    setFormData(prev => ({
                        ...prev,
                        quickVessel: {
                            ...prev.quickVessel,
                            vessel_name: vesselInfo?.SHIPNAME,
                            vessel_code: vesselInfo?.MMSI,
                            built_year: vesselInfo?.YEAR_BUILT,
                            imo_no: vesselInfo?.IMO,
                            TPC: vesselDetail?.VESSEL_TYPE ? vesselDetail?.VESSEL_TYPE : '',
                            summer_dwt: vesselDetail?.SUMMER_DWT ? vesselDetail?.SUMMER_DWT : vesselInfo?.DWT,
                            GRT: vesselDetail?.GROSS_TONNAGE ? parseFloat(vesselDetail?.GROSS_TONNAGE) : 0,
                            NRT: vesselDetail?.NET_TONNAGE ? parseFloat(vesselDetail?.NET_TONNAGE) : 0,
                            LOA: vesselDetail?.LENGTH_OVERALL ? parseFloat(vesselDetail?.LENGTH_OVERALL) : 0,
                            depth: vesselDetail?.DEPTH ? parseFloat(vesselDetail?.DEPTH) : 0,
                            // "GROSS_TONNAGE": "36353",
                            // "NET_TONNAGE": "21576",



                        }
                    }))
                    // setIsopen(true)
                    // } else {
                    //   toast.error('Please select a valid vessel name')
                    // }
                } else {
                    // toast.error('Please select a valid vessel name')
                }
            }
        } catch (error) {
            console.error('Error:', error)

        }
    }
    //  save vessel or contact for small modal 
    const saveFormData = async (formData, type) => {
        try {
            const url = `${process.env.REACT_APP_MT_URL}${type === 'vessel' ? 'vessel/add-vessel' : 'contact/add-contact'
                }`
            if (type === 'vessel') {
                const payload = formData
                if (formData.vessel_name && formData.summer_dwt) {
                    const response = await postAPICall(
                        url,
                        payload,
                        "post"
                    )
                    openNotificationWithIcon('success', response?.msg)
                    setIsOpenvessel(false)

                } else {
                    openNotificationWithIcon('error', 'Please fill the mandatory fields')
                }
            } else {

                const payload = formData
                if (formData.company_name.length !== 0) {
                    const response = await postAPICall(
                        url,
                        payload,
                        "post"
                    )
                    if (response.status !== 200) {
                        openNotificationWithIcon('success', response.msg)
                    }
                } else {
                    openNotificationWithIcon('info', 'Please fill the mandatory fields')
                }

            }

            // handleCloseAllPopup()
            // }
        } catch (error) {
            console.log(error)
            // handleCloseAllPopup()
        }
    }

    const fetchPortDetails = async (selectedText) => {
        try {
            const url = `${process.env.REACT_APP_MT_URL}marine/spotprice/${selectedText}`
            const responseData = await getAPICall(url)
            if (responseData) {
                setBunkerDetails(responseData.data)
                setShowBunkerPrice(true)
            } else if (responseData.status === 404) {
                openNotificationWithIcon('error', 'Please select a valid port')
            }
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    const searchfuncationality = async (query, mail) => {
        if (query?.length > 0) {
            let helightedData = await highlightSearchKeywords(query, currentMail)
            props.handlecloseTextSelectPopUp(helightedData)

            // setSelectedMail(mail);
            // setCurrentMail(mail);
            // setSelectedMail(helightedData);
            // setCurrentMail(helightedData);

        }
    }

    // console.log("propss.formdataa", props)

    return (
        showToolTip ?
            <>
                {showToolTip && (
                    <div className="mailTooltip">
                        <Tooltip title="Search">
                            <Button
                                style={{ border: 'none', background: 'inherit' }}
                                icon={<Icon icon={'oui:search'} fontSize={'18'} />}
                                size="small"
                                onClick={() => {
                                    searchfuncationality(props?.selectedText)

                                    setShowToolTip(false);
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Create Label">
                            <Button
                                style={{ border: 'none', background: 'inherit' }}
                                className='button-suggestion'
                                icon={<Icon icon="tabler:tag" fontSize={'18'} />}
                                size="small"
                                onClick={() => {
                                    if (props.selectedText.trim().length > 2) {
                                        handleAddLabel()
                                        // Your label creation logic
                                        setShowToolTip(false);
                                    } else {
                                        message.error('Please select more than 1 character');
                                        props.handleCloseTextSelectPopUp({ spinner: false, popUpClose: false });
                                    }
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Create Vessel Form">
                            <Button
                                style={{ border: 'none', background: 'inherit' }}
                                className='button-suggestion'
                                icon={<Icon icon='tabler:ship' fontSize={'18'} />}
                                size="small"
                                onClick={() => {
                                    if (props.selectedText.length > 2) {
                                        findMatch(props.selectedText)
                                        // Open the QuickVessel modal
                                        // setShowToolTip(false);
                                    } else {
                                        message.error('Please select more than 10 characters');
                                        props.handleCloseTextSelectPopUp({ spinner: false, popUpClose: false });
                                    }
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Create Contact Form">
                            <Button
                                style={{ border: 'none', background: 'inherit' }}
                                className='button-suggestion'
                                icon={<Icon icon='tabler:file' fontSize={'18'} />}
                                size="small"
                                onClick={() => {
                                    if (props.selectedText.length >= 10) {
                                        handleShortName(props.selectedText, 'contact');
                                    } else {
                                        message.error('Please select more than 10 characters');
                                    }
                                }}
                            />
                        </Tooltip>

                        <Tooltip title="Bunker Price">
                            <Button
                                style={{ border: 'none', background: 'inherit' }}
                                className='button-suggestion'
                                icon={<Icon icon='tabler:droplet-dollar' fontSize={'18'} />}
                                size="small"
                                onClick={() => {
                                    fetchPortDetails(props?.selectedText)
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Port Congestion">
                            <Button
                                style={{ border: 'none', background: 'inherit' }}
                                className='button-suggestion'
                                icon={<Icon icon='tabler:map-pin' fontSize={'18px'} />}
                                size="small"
                                onClick={() => {

                                    openNotificationWithIcon('info', 'Coming soon');
                                }}
                            />
                        </Tooltip>
                    </div>
                )}

                {refresh && (
                    <Modal
                        visible={refresh}
                        onCancel={() => setRefresh(false)}
                        footer={null}
                        closable={false}
                    >
                        <Spin size="large" tip="Loading..." />
                    </Modal>
                )}


                {setIsContact &&
                    <Modal
                        visible={isContact}
                        title="Add Contact"
                        onCancel={() => setIsContact(false)}
                        footer={[
                            <Button key="detailed" onClick={() => handleSubmitCon(formData.quickCon)}>
                                Fill Detailed Form
                            </Button>,
                            <Button
                                key="save"
                                type="primary"
                                onClick={() => saveFormData(formData.quickCon, 'contact')}
                            >
                                Save
                            </Button>
                        ]}
                    >

                        <QuickVessel
                            handleShortName={handleShortName}
                            selectedText={props.selectedText}
                            handleChange={handleChange}
                            handleYearChange={handleYearChange}
                            data={formData}
                            type='contact'
                        />
                    </Modal>
                }

                {isOpenvessel ? (
                    <Modal
                        open={isOpenvessel}
                        title="Add Vessel"
                        onCancel={() => setIsOpenvessel(false)}
                        footer={[
                            <Button key="detailed" onClick={openFullDetailsModal}>
                                Fill Detailed Form
                            </Button>,
                            <Button
                                key="save"
                                type="primary"
                                onClick={() => saveFormData(formData?.quickVessel, 'vessel')}
                            >
                                Save
                            </Button>
                        ]}
                    >
                        <QuickVessel
                            handleShortName={handleShortName}
                            selectedText={props.selectedText}
                            handleChange={handleChange}
                            handleYearChange={handleYearChange}
                            data={formData}
                            type='vessel'
                        />
                    </Modal>
                ) : null}


                {/* Full Details Modal */}
                {isFullDetailsOpenvessel &&

                    <Modal
                        visible={isFullDetailsOpenvessel}
                        title="Detailed Vessel Form"
                        onCancel={() => setIsFullDetailsOpenvessel(false)}
                        footer={null}
                        style={{ top: 10 }}
                        bodyStyle={{ height: 790, overflowY: 'auto', padding: '0.5rem' }}
                        maskClosable={false}
                        width={1350}
                    >
                        <VesselSchedule isShowRightBar={true}
                            formData={{
                                vessel_name: formData?.quickVessel?.vessel_name,
                                vessel_code: formData?.quickVessel?.vessel_code,
                                vessel_dwt: formData?.quickVessel?.DWT,
                                // type_code:formData?.quickVessel?.d
                                year_built: formData?.quickVessel?.built_year,
                                imo_no: formData?.quickVessel?.imo_no,

                            }}
                            // marineData={marineData}
                            onEdit={true}
                            showSideListBar={false}
                            showDeleteIcon={"noShow"} />


                    </Modal>}

                {isOpencontactFull &&
                    <Modal
                        visible={isOpencontactFull}
                        title="Detailed Vessel Form"
                        onCancel={() => { setIsOpencontactFull(false); setIsContact(false) }}
                        footer={null}
                        style={{ top: 10 }}
                        bodyStyle={{ height: 790, overflowY: 'auto', padding: '0.5rem' }}
                        maskClosable={false}
                        width={1350}
                    >

                        <AddAddressBook
                            formDataValues={{
                                full_name: formData?.quickCon?.company_name,
                                short_name: formData?.quickCon?.company_short_name,
                                email: formData?.quickCon?.email_id
                            }}

                        />

                    </Modal>}
                {/*  Details form of vessel */}
                {/*  bunker price */}

                {(bunkerDetails?.length > 0 && showBunkerPrice) &&
                    <Modal
                        title="Bunker Price"
                        visible={showBunkerPrice}
                        onCancel={() => setShowBunkerPrice(false)}
                        footer={null} // Remove default footer
                        width={800} // Adjust the width as needed
                    >

                        <BunkerPriceModal bunkerDetails={bunkerDetails} />
                    </Modal>
                }

            </> : ''
    );
};

export default SelectText;

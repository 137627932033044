import React, { useEffect, useState } from "react";

export const VoyageEstimateCalcArr = [
  {
    title: "Freight",
    formula: "Sum of ((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum",
  },
  {
    title: "Freight Commission",
    formula: "Sum of ((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum",
  },
  {
    title: "Misc. Revenue",
    formula: "Sum of (Extra Rev)",
  },
  {
    title: "Demurrage Amt",
    formula: "Sum of (Demurrage in Port Itinerary)",
  },
  {
    title: "Demm Comm Amt",
    formula: "Sum of (Total Demurrage* B.comm%)",
  },
  {
    title: "Despatch Amt",
    formula: "Sum of (Despatch in Port Itinerary)",
  },
  {
    title: "Gross Revenue",
    formula: "Freight + Misc. Revenue + Despatch - Demurrage",
  },

  {
    title: "Net Revenue",
    formula: "Gross Revenue - Freight Commission - Demm Comm Amt",
  },

  {
    title: "Revenue",
    formula: `Gross Revenue  - Freight Commission - Demm Comm Amt`,
  },

  {
    title: "Expenses",
    formula: "Gross. Expenses - (TCI Address Commission + TCI Bro Commission)",
  },

  {
    title: "Vessel expenses",
    formula: "TCI Hire Rate * Total Voyage Days",
  },

  {
    title: "TCI Address Commission",
    formula: "Vessel Expenses *Add Comm.%",
  },

  {
    title: "TCI Bro Commission",
    formula: "Vessel Expenses *Broker Comm.%",
  },

  {
    title: "Port Expenses",
    formula: "Sum of (Port Expense in Port Itinerary)",
  },

  {
    title: "Emmission Expenses",
    formula: "Sum Of (Total Eu Ets (in Mt) * Co2 Price/Mt)",
  },

  {
    title: "Misc. Expenses (Other Cost + Ballast Bonus)",
    formula: "(Sum of Othercost) + Ballast Bonus",
  },

  //Bunker Expenses

  {
    title: "Bunker Expenses",
    formula:
      "Sea Consp Non ECA + Sea Consp ECA + Port Consp +TC Bunker Adjustment",
  },

  {
    title: "Gross Expenses",
    formula:
      "Vessel Expenses + Misc. Expenses + Port Expenses + Bunker Expenses +Emmission Expenses",
  },

  {
    title: "Net Expenses",
    formula: "Gross. Expenses - (TCI Address Commission + TCI Bro Commission)",
  },

  {
    title: "Voyage Result",
    formula: "Net Revenue - Net Expenses",
  },

  {
    title: "Profit (Loss)",
    formula: "Net Revenue - Net Expenses",
  },

  {
    title: "Daily Profit (Loss)",
    formula: "(Net Revenue - Net Expenses)/Total Voyage Days ",
  },

  {
    title: "TCE Hire ( Net Daily )",
    formula:
      "(Net Revenue - Gross Expenses + Vessel Expenses + Misc. Expenses + TCI Add. Comm.) / Total Voyage Days",
  },

  {
    title: "Gross TCE",
    formula: "TCE Hire/(1-Add Comm.%)",
  },

  {
    title: "Average Freight rate ($/T)",
    formula: "Net Expenses/ sum of (Cp qty + cp qty* opt %)",
  },

  {
    title: "Breakeven & Freight rate ($/T)",
    formula: "Net Expenses/ sum of (Cp qty + cp qty* opt %)",
  },

  // Co2 Cost

  {
    title: "Co2 Cost",
    formula: "Sum Of (Total Eu Ets (in Mt) * Co2 Price/Mt)",
  },

  {
    title: "CO2 Adjusted Profit (Loss)",
    formula: "Profit (Loss)- Co2 Cost",
  },

  {
    title: "CO2 Adjusted (Net) TCE",
    formula: "CO2 Adjusted Profit (Loss)/ Total Voyage Days",
  },
  {
    title: "Sea Consp Non ECA",
    formula: "Sum of(IFO + MGO + LSMGO + VLSFO + ULSFO)",
  },
  {
    title: "IFO",
    formula: "Sum of(non eca sea consp of IFO) * cp$",
  },
  {
    title: "Sea Consp ECA",
    formula: "Sum of(eca sea consp of all five fuels)",
  },
  {
    title: "Port Consp",
    formula: "Sum of(eca port consp of all five fuels)",
  },
];

export const TCEstimateCalcArr = [
  {
    title: "Revenue",
    formula:
      "Gross Revenue -(Gross TCO Hire add Com +Gross TCO Hire B. Comm +Gross TCO BB Comm);",
  },

  {
    title: "Gross TCO Hire $",
    formula: "Sum of (Daily Hire * Duration) in TCO Term",
  },

  {
    title: "Gross TCO Hire B. Comm",
    formula: "Sum of (Daily Hire * Duration*B.comm.%) in TCO Term",
  },

  {
    title: "Gross TCO Hire Add Comm",
    formula: "Sum of (Daily Hire * Duration*A.comm.%) in TCO Term",
  },

  {
    title: "Gross TCO BB",
    formula: "Sum of (Ballast Bonus) in TCO Term",
  },

  {
    title: "Gross TCO BB Comm",
    formula: "Sum of (Ballast Bonus*(A.comm% + B.Comm%)) in TCO Term",
  },

  {
    title: "Net Ballast Bonus",
    formula: "Gross TCO BB-Gross TCO BB Comm",
  },

  {
    title: "Total TCO Net Hire",
    formula: "Sum of (Daily Hire * Duration) in TCO Term",
  },

  {
    title: "Total Net TCO BB",
    formula: "Sum of (Ballast Bonus) in TCO Term",
  },

  {
    title: "Gross Revenue",
    formula: "Gross TCO Hire $ + Gross TCO BB + Misc. Revenue",
  },

  {
    title: "Net Revenue",
    formula:
      "Gross Revenue -(Gross TCO Hire add Com +Gross TCO Hire B. Comm +Gross TCO BB Comm);",
  },

  /// expenses---

  {
    title: "Expenses",
    formula: "Gross Expenses - (TCI Add. Comm. + TCI Bro. Comm.)",
  },

  {
    title: "Vessel expenses",
    formula: "TCI Hire Rate *Total Voyage Days",
  },

  {
    title: "TCI Hire Expenses",
    formula: "TCI Hire Rate *Total Voyage Days",
  },

  {
    title: "TCI Add. Comm.",
    formula: "TCI Hire Expenses *Add Comm.% ",
  },

  {
    title: "TCI Bro. Comm.",
    formula: "TCI Hire Expenses *Broker% ",
  },

  {
    title: "TCI BB Comm",
    formula: "Ballast Bonus*Broker%",
  },

  {
    title: "TCI Ballst Bonus",
    formula: "Ballast Bonus",
  },

  {
    title: "Port Expenses",
    formula: "Sum of (P.EXP) in Port Itinerary",
  },

  {
    title: "Emission Expense",
    formula: "Sum of (Co2 Price Per MT * Total EU ETS (MT)) in Euets",
  },

  {
    title: "Other Expenses",
    formula: "Other Cost",
  },

  {
    title: "Bunker Expenses",
    formula:
      "Sea Consp Non ECA + Sea Consp ECA +Port Consp +TC Bunker Adjustment",
  },

  // Gross Expenses

  {
    title: "Gross Expenses",
    formula:
      "Vessel expenses +Port Expenses +Bunker Expenses +Other Expenses + Emission Expense",
  },

  {
    title: "Net Expenses",
    formula: "Gross Expenses-(TCI Add. Comm. + TCI Bro. Comm.)",
  },

  //

  {
    title: "Voyage Result",
    formula: "Net Revenue - Net Expenses",
  },

  {
    title: "Profit (Loss)",
    formula: "Net Revenue - Net Expenses",
  },

  {
    title: "Daily Profit (Loss)",
    formula: "(Net Revenue - Net Expenses)/Total Voyage Days ",
  },

  // Co2 Cost

  {
    title: "Co2 Cost",
    formula: "Sum Of (Total Eu Ets (in Mt) * Co2 Price/Mt)",
  },

  {
    title: "CO2 Adjusted Profit (Loss)",
    formula: "Profit (Loss)- Co2 Cost",
  },

  {
    title: "CO2 Adjusted (Net) TCE",
    formula: "CO2 Adjusted Profit (Loss)/ Total Voyage Days",
  },
];

export const VoyageReletCalcArr = [
  {
    title: "Revenue",
    formula: "Freight (Cargo ) + Other Revenue + (Demurrage - Despatch)",
  },

  {
    title: "Freight (Cargo )",
    formula:
      "Sum of ((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum in sell Cargo",
  },

  {
    title: "VC Cargo Commision",
    formula:
      "Sum of ((((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum)*B.Comm %) in sell Cargo",
  },

  {
    title: "Other Revenue",
    formula: "Sum of (Extra Rev) in sell Cargo",
  },

  {
    title: "Gross Revenue",
    formula: "Freight (Cargo ) + Other Revenue + (Demurrage - Despatch)",
  },

  {
    title: "Net Revenue",
    formula: "Freight (Cargo ) + Other Revenue + (Demurrage - Despatch)",
  },

  // expenses:Expenses

  {
    title: "Expenses",
    formula:
      "Sum of ((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum in Purchase Cargo",
  },

  {
    title: "VC Frieght",
    formula:
      "Sum of ((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum in Purchase Cargo",
  },

  {
    title: "VC Frieght Commission",
    formula:
      "Sum of ((((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum)*B.Comm %) in Purchase Cargo",
  },

  {
    title: "Other Expenses",
    formula: "Other Cost  + Ballast Bonus",
  },

  {
    title: "Gross Expenses",
    formula: "VC Frieght + (Other Cost  + Ballast Bonus)",
  },

  {
    title: "Net Expenses",
    formula: " Gross Expenses - VC Frieght Commission;",
  },

  // yoyage result

  {
    title: "Voyage Result",
    formula: "Net Revenue- Net Expenses",
  },
  {
    title: "Profit (Loss)",
    formula: "Net Revenue- Net Expenses",
  },

  {
    title: "Daily Profit (Loss)",
    formula: "(Net Revenue- Net Expenses)/Total Voyage Days",
  },
];

const ViewCalculation = ({ desc, tooltipData, title, formData }) => {
  // console.log("tooltipdataa66", tooltipData);
  // console.log("formdataaa", formData);
  const formatNumber = (num, decimals = 2) => {
    if (typeof num === "string") {
      num = num.replace(/[^0-9.-]/g, ""); // Remove any non-numeric characters
    }

    const parsed = parseFloat(num);
    return isNaN(parsed) ? "0.00" : parsed.toFixed(decimals);
  };

  const [caluculation, setCalculation] = useState("");
  useEffect(() => {
    // console.log("tooltipdataaa",tooltipData)
    if (title === "Revenue") {
      setCalculation(
        `${tooltipData?.grossRevenue} - ${tooltipData?.freightCommission} - ${tooltipData?.demcommissionamt} = ${tooltipData.netRevenue}`
      );
    } else if (title == "Freight") {
      const freightCalculations = formData?.cargos?.map((cargo) => {
        const cpQty = parseFloat(cargo.cp_qty) || 0;
        const optPercentage = parseFloat(cargo.opt_percentage) || 0;
        const fratRate = parseFloat(cargo.frat_rate) || 0;
        const worldScale = parseFloat(cargo.world_scale) || 0;
        const lumsum = parseFloat(cargo.lumsum) || 0;

        let calculationDetails;

        if (cargo.frt_type == "38") {
          // Calculation using `frat_rate`
          const optValue = cpQty * (optPercentage / 100);
          const totalQty = cpQty + optValue;
          const freightValue = totalQty * fratRate;
          calculationDetails = `(${cpQty} * ${optPercentage}% + ${cpQty}) * ${fratRate} = ${freightValue.toFixed(
            2
          )} -- Flat Rate`;
        } else if (cargo.frt_type == "259") {
          // Calculation using `world_scale`
          const optValue = cpQty * (optPercentage / 100);
          const totalQty = cpQty + optValue;
          const freightValue = totalQty * worldScale;
          calculationDetails = `(${cpQty} * ${optPercentage}% + ${cpQty}) * ${worldScale} = ${freightValue.toFixed(
            2
          )}  -- World Scale`;
        } else {
          // Use `lumsum` directly
          calculationDetails = `${lumsum.toFixed(2)}  -- Lumpsum`;
        }

        return `${calculationDetails}`;
      });
      freightCalculations?.push(`Total Freight = ${tooltipData?.fr}`);
      setCalculation(freightCalculations?.join("\n"));
    } else if (title == "Freight Commission") {
      const freightCalculations = formData?.cargos?.map((cargo) => {
        const cpQty = parseFloat(cargo.cp_qty) || 0;
        const optPercentage = parseFloat(cargo.opt_percentage) || 0;
        const fratRate = parseFloat(cargo.frat_rate) || 0;
        const worldScale = parseFloat(cargo.world_scale) || 0;
        const lumsum = parseFloat(cargo.lumsum) || 0;
        let bComm = parseFloat(cargo.b_commission) || 0;

        let calculationDetails;

        if (cargo.frt_type == "38") {
          // Calculation using `frat_rate`
          const optValue = cpQty * (optPercentage / 100);
          const totalQty = cpQty + optValue;
          const freightValue = totalQty * fratRate;
          const frRow = (freightValue * bComm) / 100;
          calculationDetails = `(${freightValue} % ${bComm} = ${frRow.toFixed(
            2
          )} -- Flat Rate`;
        } else if (cargo.frt_type == "259") {
          // Calculation using `world_scale`
          const optValue = cpQty * (optPercentage / 100);
          const totalQty = cpQty + optValue;
          const freightValue = totalQty * worldScale;
          const frRow = (freightValue * bComm) / 100;
          calculationDetails = `(${freightValue} % ${bComm} } = ${frRow.toFixed(
            2
          )}  -- World Scale`;
        } else {
          // Use `lumsum` directly
          calculationDetails = `${
            (lumsum.toFixed(2) * bComm) / 100
          }  -- Lumpsum`;
        }

        return `${calculationDetails}`;
      });
      freightCalculations?.push(
        `Total Freight Commission = ${tooltipData?.freightCommission}`
      );
      setCalculation(freightCalculations?.join("\n"));
    } else if (title == "Misc. Revenue") {
      const miscreveCalculations = formData?.cargos?.map((ele) => {
        const extra_rev = parseFloat(ele?.extra_rev) || 0;
        const calculationDetails = `${extra_rev}`;

        return calculationDetails;
      });
      setCalculation(
        `${miscreveCalculations?.join(" + ")} = ${tooltipData?.mr}`
      );
    } else if (title == "Demurrage Amt") {
      let demmCalculations;

      // Filter for entries with `dem_disp` equal to "Demmurage" and then map to get the amount
      demmCalculations = formData?.portitinerary
        ?.filter((ele) => ele?.dem_disp === "Demmurage")
        ?.map((ele) => {
          const demmAmt = parseFloat(ele?.dem_disp_amt) || 0;
          return `${demmAmt}`;
        });

      setCalculation(
        demmCalculations && demmCalculations?.length > 1
          ? `${demmCalculations?.join(" + ")} = ${tooltipData?.demurrage * -1}`
          : `${demmCalculations?.[0] || 0} = ${tooltipData?.demurrage * -1}`
      );
    } else if (title == "Demm Comm Amt") {
      const demmCalculations = formData?.portitinerary
        ?.filter((ele) => ele?.dem_disp === "Demmurage")
        ?.reduce((total, ele) => {
          const demmAmt = parseFloat(ele?.dem_disp_amt) || 0;
          return total + demmAmt;
        }, 0);

      const total2 = formData?.cargos?.map((ele) => {
        const calculationDetails = `${demmCalculations} * ${
          ele?.b_commission || 0
        }`;
        return calculationDetails;
      });
      setCalculation(`${total2?.join(" + ")} = ${tooltipData?.demurrage}`);
    } else if (title == "Despatch Amt") {
      let demmCalculations;

      // Filter for entries with `dem_disp` equal to "Demmurage" and then map to get the amount
      demmCalculations = formData?.portitinerary
        ?.filter((ele) => ele?.dem_disp === "Despatch")
        ?.map((ele) => {
          const demmAmt = parseFloat(ele?.dem_disp_amt) || 0;
          return `${demmAmt}`;
        });

      setCalculation(
        demmCalculations?.length > 1
          ? `${demmCalculations?.join(" + ")} = ${tooltipData?.despatch}`
          : `${demmCalculations?.[0] || 0} = ${tooltipData?.despatch}`
      );
    } else if (title == "Gross Revenue") {
      setCalculation(
        `${tooltipData?.fr?.toFixed(2)} + ${tooltipData?.mr?.toFixed(
          2
        )} + ${tooltipData?.despatch?.toFixed(
          2
        )} + ${tooltipData?.demurrage?.toFixed(
          2
        )} = ${tooltipData?.grossRevenue?.toFixed(2)}`
      );
    } else if (title == "Net Revenue") {
      setCalculation(
        `${tooltipData?.grossRevenue?.toFixed(
          2
        )} - ${tooltipData?.freightCommission?.toFixed(
          2
        )} - ${tooltipData?.demcommissionamt?.toFixed(
          2
        )} = ${tooltipData?.netRevenue?.toFixed(2)}`
      );
    } else if (title == "Expenses") {
      setCalculation(
        `${tooltipData?.GrossExpenses?.toFixed(
          2
        )} - (${tooltipData?.amt_add_percentage?.toFixed(
          2
        )} + ${tooltipData?.amt_bro_percentage?.toFixed(
          2
        )}) = ${tooltipData?.netExpenses?.toFixed(2)}`
      );
    } else if (title == "Vessel expenses") {
      setCalculation(
        `${tooltipData?.hire?.toFixed(
          2
        )} * ${tooltipData?.totalVoyageDays?.toFixed(
          2
        )} = ${tooltipData?.vesselHire?.toFixed(2)}`
      );
    } else if (title == "TCI Address Commission") {
      setCalculation(
        `(${tooltipData?.vesselHire?.toFixed(2)} * ${
          tooltipData?.addPercentage
        })/100 = ${tooltipData?.amt_add_percentage?.toFixed(2)}`
      );
    } else if (title == "TCI Bro Commission") {
      setCalculation(
        `(${tooltipData?.vesselHire?.toFixed(2)} * ${
          tooltipData?.broPercentage
        })/100 = ${tooltipData?.amt_bro_percentage?.toFixed(2)}`
      );
    } else if (title == "Port Expenses") {
      const portExpenses = formData?.portitinerary?.map((ele) => {
        let p_exp = parseFloat(ele?.p_exp) || 0;
        return `${p_exp}`;
      });
      setCalculation(
        portExpenses?.length > 1
          ? `${portExpenses?.join(" + ")} = ${tooltipData?.pi}`
          : `${portExpenses?.[0]} = ${tooltipData?.pi}`
      );
    } else if (title == "Emmission Expenses" || title == "Co2 Cost") {
      let co2Price = parseFloat(formData["-----"]?.co2_price_per || 0);

      // Sum of ttl_eu_ets values
      let sumEuEts = formData?.euets?.reduce(
        (sum, ele) => sum + parseFloat(ele.ttl_eu_ets || 0),
        0
      );

      // Apply inc_pnl condition
      let total = formData?.["-----"]?.inc_pnl ? sumEuEts * co2Price : 0.0;

      const calculationDetails = formData?.euets?.map((ele) => {
        return `${ele.ttl_eu_ets || 0}`;
      });

      setCalculation(
        `(${calculationDetails?.join(" + ") || 0}) * ${
          co2Price?.toFixed(2) || 0
        } = ${total?.toFixed(2) || 0}`
      );
    } else if (title == "Misc. Expenses (Other Cost + Ballast Bonus)") {
      setCalculation(
        `${tooltipData?.misCost?.toFixed(2)} + ${tooltipData?.bb?.toFixed(
          2
        )} = ${tooltipData?.miscExpense?.toFixed(2)}`
      );
    } else if (title == "Bunker Expenses") {
      setCalculation(
        `${tooltipData?.totalecaSeacons?.toFixed(
          2
        )} + ${tooltipData?.totalnonEcaSeacons?.toFixed(
          2
        )} + ${tooltipData?.totalecaPortCons?.toFixed(
          2
        )} + ${tooltipData?.totalnonecaPortcons?.toFixed(
          2
        )} = ${tooltipData?.bunkerExpense?.toFixed(2)}`
      );
    } else if (title == "Gross Expenses") {
      // let total = parseFloat(formData["-----"]?.ttl_co2_cost);
      setCalculation(
        `${tooltipData?.vesselHire?.toFixed(
          2
        )} + ${tooltipData?.miscExpense?.toFixed(
          2
        )} + ${tooltipData?.pi?.toFixed(
          2
        )} +  ${tooltipData?.bunkerExpense?.toFixed(
          2
        )} = ${tooltipData?.GrossExpenses?.toFixed(2)}`
      );
    } else if (title == "Net Expenses") {
      setCalculation(
        `${tooltipData?.GrossExpenses?.toFixed(
          2
        )} - (${tooltipData?.amt_add_percentage?.toFixed(
          2
        )} + ${tooltipData?.amt_bro_percentage?.toFixed(
          2
        )}) = ${tooltipData?.netExpenses?.toFixed(2)}`
      );
    } else if (title == "Voyage Result") {
      setCalculation(
        `${tooltipData?.netRevenue?.toFixed(
          2
        )} - ${tooltipData?.netExpenses?.toFixed(
          2
        )} = ${tooltipData?.profitLoss?.toFixed(2)}`
      );
    } else if (title == "Profit (Loss)") {
      setCalculation(
        `${tooltipData?.netRevenue.toFixed(
          2
        )} - ${tooltipData?.netExpenses?.toFixed(
          2
        )} = ${tooltipData?.profitLoss?.toFixed(2)}`
      );
    } else if (title == "Daily Profit (Loss)") {
      let total =
        (tooltipData?.netRevenue - tooltipData?.netExpenses) /
          tooltipData?.totalVoyageDays?.toFixed(2) || 0;
      setCalculation(`
        (${tooltipData?.netRevenue?.toFixed(
          2
        )} - ${tooltipData?.netExpenses?.toFixed(
        2
      )})/${tooltipData?.totalVoyageDays?.toFixed(2)} = ${
        tooltipData?.totalVoyageDays > 0 ? total?.toFixed(2) : "0.00"
      }
      `);
    } else if (title == "TCE Hire ( Net Daily )") {
      setCalculation(
        `(${tooltipData?.netRevenue?.toFixed(
          2
        )} - ${tooltipData?.GrossExpenses?.toFixed(
          2
        )} + ${tooltipData?.vesselHire?.toFixed(
          2
        )} + ${tooltipData?.miscExpense?.toFixed(
          2
        )} - ${tooltipData?.amt_add_percentage?.toFixed(
          2
        )})/${tooltipData?.totalVoyageDays?.toFixed(2)} = ${
          tooltipData?.totalVoyageDays > 0
            ? tooltipData?.tceHire?.toFixed(2)
            : "0.00"
        }`
      );
    } else if (title == "Gross TCE") {
      let total =
        tooltipData?.tceHire / (1 - tooltipData?.addPercentage * 0.01);
      setCalculation(
        `${
          tooltipData?.tceHire > 0 ? tooltipData?.tceHire?.toFixed(2) : 0
        }/(1-${tooltipData?.addPercentage?.toFixed(2)}%) = ${total>0? total?.toFixed(2):"0.00"}`
      );
    } else if (
      title == "Average Freight rate ($/T)" ||
      title == "Breakeven & Freight rate ($/T)"
    ) {
      const calculationDetails = formData?.cargos?.map((ele) => {
        return `(${ele.cp_qty || 0} + ${ele.cp_qty || 0} * ${
          ele.opt_percentage || 0
        })`;
      });
      setCalculation(
        `${tooltipData?.netExpenses?.toFixed(2)}/${calculationDetails?.join(
          " + "
        )} = ${tooltipData?.break_even_frtrate?.toFixed(2)}`
      );
    } else if (title == "CO2 Adjusted Profit (Loss)") {
      setCalculation(
        `${formatNumber(tooltipData?.profitLoss)} - ${formatNumber(
          tooltipData?.co2expense
        )} = ${formatNumber(tooltipData?.co2_adjusted_profit)}`
      );
    } else if (title == "CO2 Adjusted (Net) TCE") {
      setCalculation(
        `${tooltipData?.co2_adjusted_profit?.toFixed(
          2
        )}/${tooltipData?.totalVoyageDays?.toFixed(
          2
        )} = ${tooltipData?.co2_adjusted_tce?.toFixed(2)}`
      );
    } else if (title == "Sea Consp Non ECA") {
      // console.log("data56", tooltipData?.nonEcaSeacons);
      const data = tooltipData?.nonEcaSeacons;
      setCalculation(
        `${data?.ifo} + ${data?.mgo} + ${data?.vlsfo} + ${data?.ulsfo} + ${data?.lsmgo} = ${tooltipData?.totalnonEcaSeacons}`
      );
    } else if (title === "IFO") {
      const data = formData?.["."]?.filter((ele) => ele.fuel_code === "IFO");
      const cpPrice = data?.[0]?.cp_price || 0; // Assume cp_price is in the first object or default to 0

      // Sum up all 'ifo' values from bunkerdetails
      const totalIfo = formData?.bunkerdetails?.reduce((acc, item) => {
        return acc + parseFloat(item.ifo || "0"); // Parse each ifo value as a float and add it to accumulator
      }, 0);

      // Calculate the final result
      const calculation = totalIfo * cpPrice;
      setCalculation(`${totalIfo} * ${cpPrice} = ${calculation}`);
    } else if (title == "Sea Consp ECA") {
      const data = tooltipData?.ecaSeacons;
      setCalculation(
        `${data?.ifo} + ${data.mgo} + ${data.vlsfo} + ${data.ulsfo} + ${
          data.lsmgo
        } = ${tooltipData?.totalecaSeacons?.toFixed(2)}`
      );
    } else if (title == "Port Consp") {
      const data = tooltipData?.nonecaPortcons;
      setCalculation(
        `${data?.ifo} + ${data?.mgo} + ${data?.vlsfo?.toFixed(2)} + ${
          data?.ulsfo
        } + ${data?.lsmgo?.toFixed(
          2
        )} = ${tooltipData?.totalnonecaPortcons?.toFixed(2)}`
      );
    }
  }, [title]);
  return (
    <div style={{ top: 100 }}>
      {/* <p>{desc}</p> */}
      <pre>{caluculation}</pre>
    </div>
  );
};

export default ViewCalculation;

export const ReturnTemplateTonnageOrder = (formData) => {
  return `
    <div>
    <p style="margin-bottom:10px; color: red;"><i>Please propose suitable cargo for below tonnage.</i></p>
    <br>
        <div>
            <div style="color:red !important;"><b>Open Date</b>: ${
              formData.open_from ? formData.open_from : "NA"
            }</div>
            <div><b>Open Area</b>: ${
              formData.open_position ? formData.open_position : "NA"
            }</div>
            <div><b>Vessel Name</b>: ${
              formData.vessel_name ? formData.vessel_name : "NA"
            }</div>
            <div><b>DWT</b>: ${formData.dwt ? formData.dwt : "NA"}</div>
            <div><b>IMO</b>: ${formData.imo_no ? formData.imo_no : "NA"}</div>
            <div><b>Flag</b>: ${formData.flag ? formData.flag : "NA"}</div>
            <div><b>Built Year</b>: ${
              formData.built_year ? formData.built_year : "NA"
            }</div>
            <div><b>Bunker On Delivery</b>: ${
              formData.bunker_on_delivery ? formData.bunker_on_delivery : "NA"
            }</div>
            <div><b>LOA</b>: ${formData.loa ? formData.loa : "NA"}</div>
            <div><b>Beam</b>: ${formData.beam ? formData.beam : "NA"}</div>
            <div><b>Hatches</b>: ${
              formData.hatches ? formData.hatches : "NA"
            }</div>
            <div><b>Speed</b>: ${formData.speed ? formData.speed : "NA"}</div>
            <div><b>Cons</b>: ${formData.cons ? formData.cons : "NA"}</div>
            <div><b>Other Vessel Details</b>: ${
              formData.vessel_other_details
                ? formData.vessel_other_details
                : "NA"
            }</div>
        </div>
    </div>
    `;
};

export const ReturnTemplateTonnageFirm = (formData) => {
  return `
    <html>
    <head><meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="Generator" content="Microsoft Word 15 (filtered medium)"></head>
        <div>
        <p><i>Please propose suitable cargo for below tonnage.</i></p>
        <br >
        
          <div>
         <div><b>Vessel Name</b>: ${
            formData.vesselName ? formData.vesselName : "NA"
          }</div>
         <div><b>Open Date</b>: ${
            formData.openDate ? formData.openDate : "NA"
          }</div>
         <div><b>Open Area</b>: ${
            formData.openArea ? formData.openArea : "NA"
          }</div>
         <div><b>BOD</b>: ${
            formData.bod ? formData.bod : "NA"
          }</div>
         <div><b>Last 5 Cargo</b>: ${
            formData.last5Cargo ? formData.last5Cargo : "NA"
          }</div>
         <div><b>ETA</b>: ${
            formData.eta ? formData.eta : "NA"
          }</div>
         <br>
         <div><i>Vessel Particulars</i>: &nbsp;</div>
         <div><b>LOA</b>: ${
            formData.loa ? formData.loa : "NA"
          }</div>
         <div><b>BEAM</b>: ${
            formData.beam ? formData.beam : "NA"
          }</div>
         <div><b>DWT</b>: ${
            formData.dwt ? formData.dwt : "NA"
          }</div>
         <div><b>Hatches</b>: ${
            formData.hatches ? formData.hatches : "NA"
          }</div>
         <div><b>GRT</b>: ${
            formData.grt ? formData.grt : "NA"
          }</div>
         <div><b>NRT</b>: ${
            formData.nrt ? formData.nrt : "NA"
          }</div>
         <div><b>Sea Consumption</b>: ${
            formData.seaConsumption ? formData.seaConsumption : "NA"
          }</div>
         <div><b>Port Consumption</b>: ${
            formData.portConsumption ? formData.portConsumption : "NA"
          }</div>
         <div><b>Holds Cubic Feet Capacity</b>: ${
            formData.holdsCubicFeetCapacity
              ? formData.holdsCubicFeetCapacity
              : "NA"
          }</div>
         <div><b>Sizes of Hatches LxB</b>: ${
            formData.sizesOfHatches ? formData.sizesOfHatches : "NA"
          }</div>
         <div><b>Numbers of Cranes</b>: ${
            formData.numOfCranes ? formData.numOfCranes : "NA"
          }</div>
         <div><b>Numbers of Grabs</b>: ${
            formData.numOfGrabs ? formData.numOfGrabs : "NA"
          }</div>
         <div><b>Agent Details</b>: ${
            formData.agentDetails ? formData.agentDetails : "NA"
          }</div>
         <div><b>Account Details</b>: ${
            formData.accountDetails ? formData.accountDetails : "NA"
          }</div>
         <div><b>Hire Rate</b>: ${
            formData.hireRate ? formData.hireRate : "NA"
          }</div>
         <div><b>Bunker Rates</b>: ${
            formData.bunkerRates ? formData.bunkerRates : "NA"
          }</div>
         <div><b>ILOHC Rates</b>: ${
            formData.ilohcRates ? formData.ilohcRates : "NA"
          }</div>
         <div><b>Other Rates</b>: ${
            formData.otherRates ? formData.otherRates : "NA"
          }</div>
         <div><b>Address Commission</b>: ${
            formData.addressCommission ? formData.addressCommission : "NA"
          }</div>
         <div><b>Broker Commission</b>: ${
            formData.brokerCommission ? formData.brokerCommission : "NA"
          }</div>
         <div><b>Last 3 Cargo</b>: ${
            formData.last3Cargo ? formData.last3Cargo : "NA"
          }</div>
         <div><b>Last 3 Charterers</b>: ${
            formData.last3Charterers ? formData.last3Charterers : "NA"
          }</div>
         <div><b>Last 3 Ports</b>: ${
            formData.last3Ports ? formData.last3Ports : "NA"
          }</div>
         <div><b>Name of Hull or Hull No</b>: ${
            formData.hullOrHullNo ? formData.hullOrHullNo : "NA"
          }</div>
         <div><b>Class</b>: ${
            formData.Class ? formData.Class : "NA"
          }</div>
         <div><b>Last Drydock</b>: ${
            formData.lastDrydock ? formData.lastDrydock : "NA"
          }</div>
         <div><b>Last Special Survey</b>: ${
            formData.lastSpecialSurvey ? formData.lastSpecialSurvey : "NA"
          }</div>
         <div><b>Next DD</b>: ${
            formData.nextDD ? formData.nextDD : "NA"
          }</div>
         <div><b>SS DUE</b>: ${
            formData.ssDue ? formData.ssDue : "NA"
          }</div>
         <div><b>Collisions in the last 12 months</b>: ${
            formData.collisionsLast12Months
              ? formData.collisionsLast12Months
              : "NA"
          }</div>
         <div><b>Details of any PSC detention in the last 12 months</b>: ${
            formData.pscDetentionLast12Months
              ? formData.pscDetentionLast12Months
              : "NA"
          }</div>
         <div><b>Other Details</b>: ${
            formData.otherDetails ? formData.otherDetails : "NA"
          }</div>
          </div>
          
     
        </div>
        </html>
    `;
};

export const ReturnTemplateCargoOrder = (formData) => {
  // console.log("formData cargo", formData);
  return `
    <html>
    <head><meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="Generator" content="Microsoft Word 15 (filtered medium)">
   </head>
        <div>
            <div>
            <p><i>Please propose suitable tonnage for below cargo.</i></p>
            <br >
          

            <div>
                   <div><b>Cargo Name</b>${
                      formData?.cargo_name ? formData.cargo_name : "NA"
                    } </div>
                   <div><b>Account For</b>:  ${
                      formData?.account_for ? formData.account_for : "NA"
                    }</div>
                   <div><b>Cargo Details</b>:  ${
                      formData?.cargo_details ? formData.cargo_details : "NA"
                    }</div>
                   <div><b>CP Qty</b>: ${
                      formData?.cp_qty ? formData.cp_qty : "NA"
                    } mt </div>
                   <div><b>Min Qty</b>: ${
                      formData?.min_qty ? formData.min_qty : "NA"
                    } mt </div>
                   <div><b>Max Qty</b>:  ${
                      formData?.max_qty ? formData.max_qty : "NA"
                    } mt</div>
                   <div><b>Freight Rate</b>:  ${
                      formData?.fright_rate ? formData.fright_rate : "NA"
                    }</div>
                   <div><b>Broker Commission %</b>:  ${
                      formData?.broker_commission
                        ? formData.broker_commission
                        : "NA"
                    } %</div>
                   <div><b>Load Port Options</b>:  ${
                      formData?.load_port_options
                        ? formData.load_port_options
                        : "NA"
                    }</div>
                   <div><b>Discharge Port Options</b>:  ${
                      formData?.discharge_port_options
                        ? formData.discharge_port_options
                        : "NA"
                    }</div>
                   <div><b>Load Term</b>:  ${
                      formData?.load_term ? formData.load_term : "NA"
                    }</div>
                   <div><b>Discharge Load Term</b> ${
                      formData?.discharge_load_term
                        ? formData.discharge_load_term
                        : "NA"
                    }</div>
                   <div><b>DEM</b>:  ${
                      formData?.dem ? formData.dem : "NA"
                    }</div>
                   <div><b>DES</b>:  ${
                      formData?.des ? formData.des : "NA"
                    }</div>
                   <div><b>Other Details</b></td>  <td> ${
                      formData?.other_details ? formData.other_details : "NA"
                    }</div>
                    </div>

                
            </div>
        </div>
        </html>
    `;
};

export const ReturnTemplateCargoFirm = (formData) => {
  return `
    <html>
    <head><meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="Generator" content="Microsoft Word 15 (filtered medium)"></head>
        <div>
        <div style={{display:"flex"}}>
      <p>  <i>Please propose suitable Tonnage for below cargo.</i></p>
      <br >
              
               <div><b>Cargo Quantity</b>: ${
                  formData.cargoQuantity ? formData.cargoQuantity : "NA"
                }</div>
               <div><b>Load Port</b>: ${
                  formData.loadPort ? formData.loadPort : "NA"
                }</div>
               <div><b>Discharge Port</b>: ${
                  formData.dischargePort ? formData.dischargePort : "NA"
                }</div>
               <div><b>Laycan</b>: ${
                  formData.laycan ? formData.laycan : "NA"
                }</div>
               <div><b>Load Rate</b>: ${
                  formData.loadRate ? formData.loadRate : "NA"
                }</div>
               <div><b>Discharge Rate</b>: ${
                  formData.dischargeRate ? formData.dischargeRate : "NA"
                }</div>
                <br>
               <div><b>Owner</b>: ${
                  formData.owner ? formData.owner : "NA"
                }</div>
               <div><b>Disponent Owner</b>: ${
                  formData.disponentOwner ? formData.disponentOwner : "NA"
                }</div>
                <br>
               <div><i>Vessel Particulars</i>: &nbsp;</div>
               <div><b>LOA</b>: ${
                  formData.loa ? formData.loa : "NA"
                }</div>
               <div><b>BEAM</b>: ${
                  formData.beam ? formData.beam : "NA"
                }</div>
               <div><b>DWT</b>: ${
                  formData.dwt ? formData.dwt : "NA"
                } mt</div>
               <div><b>Hatches</b>: ${
                  formData.hatches ? formData.hatches : "NA"
                }</div>
               <div><b>GRT</b>: ${
                  formData.grt ? formData.grt : "NA"
                }</div>
               <div><b>NRT</b>: ${
                  formData.nrt ? formData.nrt : "NA"
                }</div>
               <div><b>Sea Consumption</b>: ${
                  formData.seaConsumption ? formData.seaConsumption : "NA"
                }</div>
               <div><b>Port Consumption</b>: ${
                  formData.portConsumption ? formData.portConsumption : "NA"
                }</div>
               <br>
               <div><b>Beneficiary Account</b>: ${
                  formData.beneficiaryAccount
                    ? formData.beneficiaryAccount
                    : "NA"
                }</div>
               <div><b>Beneficiary Bank Details</b>: ${
                  formData.beneficiaryBankDetails
                    ? formData.beneficiaryBankDetails
                    : "NA"
                }</div>
               <br>
               <div><b>Charterers</b>: ${
                  formData.charterers ? formData.charterers : "NA"
                }</div>
               <div><b>Freight Rate</b>: ${
                  formData.freightRate ? formData.freightRate : "NA"
                }</div>
               <div><b>Demurage Rate</b>: ${
                  formData.demurrageRate ? formData.demurrageRate : "NA"
                }</div>
               <div><b>Despatch Rate</b>: ${
                  formData.despatchRate ? formData.despatchRate : "NA"
                }</div>
               <div><b>Address Commm</b>: ${
                  formData.addressComm ? formData.addressComm : "NA"
                }</div>
               <div><b>Broker Comm</b>: ${
                  formData.brokerComm ? formData.brokerComm : "NA"
                }</div>
               <div><b>Other Rates</b>: ${
                  formData.otherRates ? formData.otherRates : "NA"
                }</div>                
                
            </div>
        </div>
      
  `;
};

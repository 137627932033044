import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import React, { Component } from "react";

import {
  BarChartOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import moment from "moment";
import URL_WITH_VERSION, {
  getAPICall,
  objectToQueryStringFunc,
  openNotificationWithIcon,
} from "../../..";
import ToolbarUI from "../../../../components/ToolbarUI";
import { BunkerDetailsCalculation } from "../../../NormalForm/normalhelper";
import TcovVmPnl, { VoyageEstimateCalcArr } from "../plcomponents/TcovVmPnl";
import PnlGraph from "./PnlGraph";
import AiButton from "../../AiButton/AiButton";
import AiPLComparator from "./AiPLComparator";
import { Icon } from "@iconify/react/dist/iconify.js";
import redux_state from "../../../../services/redux_state";

const TabPane = Tabs.TabPane;
const { TextArea } = Input;
const FormItem = Form.Item;

const columns = [
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "20%",
    ellipsis: true,
  },

  {
    title: "Estimated",
    dataIndex: "estimate",
    key: "estimate",
    width: "8%",
  },
  {
    title: "Actual",
    dataIndex: "actual",
    key: "actual",
    width: "8%",
  },
  {
    title: "Posted",
    dataIndex: "posted",
    key: "posted",
    width: "8%",
  },
  {
    title: "Cash In",
    dataIndex: "cash_in",
    key: "cash_in",
    width: "8%",
  },
  {
    title: `Diff(Act.-Est.)`,
    dataIndex: "Diff",
    key: "Diff",
    width: "8%",
  },
  {
    title: "%Diff",
    dataIndex: "perDiff",
    key: "perDiff",
    width: "8%",
  },
  {
    title: "Post Vs Cash",
    dataIndex: "sec_variance",
    key: "sec_variance",
    width: "8%",
  },
  {
    title: "% Post Vs Cash",
    dataIndex: "sec_per",
    key: "sec_per",
    width: "8%",
  },
];

const columns2 = [
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "30%",
    ellipsis: true,
    
  },
  {
    title: "Estimated",
    dataIndex: "estimate",
    key: "estimate",
    width: "20%",
  },
  {
    title: "Actual",
    dataIndex: "actual",
    key: "actual",
    width: "20%",
  },
  // { title: "", dataIndex: "", key: "blank", width: "68%" },
];

class PL extends Component {
  callback = (evt) => {};
  constructor(props) {
    super(props);
    this.state = {
      dollarUSLocale: Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      openGraphModal: false,
      openAICompModal: false,
      formData: this.props.formData || {},
      estimateDatavalue: this.props.estimateData || {},
      showPL: false,
      showEstimatePl: this.props.showEstimatePl,
      vesselAmount: 0,
      tooltipData: {},
      viewTabs: this.props.viewTabs,
      estimateData: [
        {
          key: "revenue",
          description: "Revenue",
          estimate: 0,
          children: [
            { key: "rev00", description: "Freight", estimate: 0, children: [] },
            { key: "rev01", description: "Freight Commission", estimate: 0 },
            { key: "rev02", description: "Misc. Revenue", estimate: 0 },
            { key: "rev03", description: "Demurrage Amt", estimate: 0 },
            { key: "rev04", description: "Demm Comm Amt", estimate: 0 },
            { key: "rev05", description: "Despatch Amt", estimate: 0 },
            { key: "rev06", description: "Gross Revenue", estimate: 0 },
            { key: "rev07", description: "Net Revenue", estimate: 0 },
          ],
        },
        {
          key: "expenses",
          description: "Expenses",
          estimate: 0,
          children: [
            { key: "ex10", description: "Vessel expenses", estimate: 0 },
            { key: "ex11", description: "TCI Address Commission", estimate: 0 },
            { key: "ex12", description: "TCI Bro Commission", estimate: 0 },
            { key: "ex13", description: "Port Expenses", estimate: 0 },
            { key: "ex14", description: "Emmission Expenses", estimate: 0 },
            {
              key: "ex15",
              description: "Misc. Expenses",
              estimate: 0,
              children: [{}],
            },

            {
              key: "ex16",
              description: "Bunker Expenses",
              estimate: 0,
              children: [
                {
                  key: "ex160",
                  description: "Sea Consp Non ECA",
                  estimate: 0,
                  children: [
                    { key: "ex1600", description: "IFO", estimate: 0 },
                    { key: "ex1601", description: "MGO", estimate: 0 },
                    { key: "ex1602", description: "VLSFO", estimate: 0 },
                    { key: "ex1603", description: "LSMGO", estimate: 0 },
                    { key: "ex1604", description: "ULSFO", estimate: 0 },
                  ],
                },

                {
                  key: "ex161",
                  description: "Sea Consp ECA",
                  estimate: 0,
                  children: [
                    { key: "ex1610", description: "IFO", estimate: 0 },
                    { key: "ex1611", description: "MGO", estimate: 0 },
                    { key: "ex1612", description: "VLSFO", estimate: 0 },
                    { key: "ex1613", description: "LSMGO", estimate: 0 },
                    { key: "ex1614", description: "ULSFO", estimate: 0 },
                  ],
                },

                {
                  key: "ex162",
                  description: "Port Consp",
                  estimate: 0,
                  children: [
                    { key: "ex1620", description: "IFO", estimate: 0 },
                    { key: "ex1621", description: "MGO", estimate: 0 },
                    { key: "ex1622", description: "VLSFO", estimate: 0 },
                    { key: "ex1623", description: "LSMGO", estimate: 0 },
                    { key: "ex1624", description: "ULSFO", estimate: 0 },
                  ],
                },

                {
                  key: "ex163",
                  description: "TC Bunker Adjustment",
                  actual: parseFloat(
                    props?.formData?.tci_pl_tc_bunker || 0
                  )?.toFixed(2),
                  estimate: 0,
                  posted: 0,
                  cash_in: 0,
                  Diff: 0,
                  perDiff: 0,
                  sec_variance: 0,
                  sec_per: 0,
                  children: [
                    {
                      key: "ex1630",
                      description: "TCI Bunker Adjustment",
                      actual: props?.formData?.tci_pl_tc_bunker || "0.00",
                    },
                  ],
                },
              ],
            },

            { key: "ex17", description: "Gross Expenses", estimate: 0 },
            { key: "ex18", description: "Net Expenses", estimate: 0 },
          ],
        },
        {
          key: "voyage-result",
          description: "Voyage Result",
          estimate: 0,
          children: [
            { key: "vr20", description: "Profit (Loss)", estimate: 0 },
            { key: "vr21", description: "Daily Profit (Loss)", estimate: 0 },
            { key: "vr22", description: "TCE Hire ( Net Daily )", estimate: 0 },
            { key: "vr23", description: "Gross TCE", estimate: 0 },
            {
              key: "vr24",
              description: "Average Freight rate ($/T)",
              estimate: 0,
            },
            {
              key: "vr25",
              description: "Breakeven & Freight rate ($/T)",
              estimate: 0,
            },
            { key: "vr26", description: "Co2 Cost", estimate: 0 },

            {
              key: "vr27",
              description: "CO2 Adjusted Profit (Loss)",
              estimate: 0,
            },
            { key: "vr28", description: "CO2 Adjusted (Net) TCE", estimate: 0 },

            { key: "vr29", description: "Total Sea Days", estimate: 0 },
            { key: "vr210", description: "Total Port Days", estimate: 0 },
            { key: "vr211", description: "Gross Voyage Days", estimate: 0 },
            { key: "vr212", description: "Off Hire Days", estimate: 0 },
            { key: "vr213", description: "Net Voyage Days", estimate: 0 },
          ],
        },
      ],
      selectOptions: [
        {
          value: "actual",
          label: "Actual",
        },
      ],
      actualSelection: false,
      selectedOption: "Actual",
      remark: "",
    };
    this.elementRef = React.createRef();
    this.loading = false;
    this.saveLoading = false;
  }
  dollarFormatedValue = (value) => {
    const { dollarUSLocale } = this.state;
    return isNaN(value) || !isFinite(value)
      ? "0.00"
      : dollarUSLocale.format(parseFloat(value).toFixed(2));
  };

  DemurrageDespatchCalculation = (itenaryarr) => {
    let demurrage = 0;
    let despatch = 0;
    itenaryarr?.forEach((el) => {
      if (el?.dem_disp === "Despatch") {
        despatch += parseFloat(el.dem_disp_amt);
      } else if (el?.dem_disp === "Demmurage") {
        demurrage += parseFloat(el.dem_disp_amt);
      }
    });
    return { demurrage, despatch };
  };

  checkNanAndFormat = (value) => {
    return !value || isNaN(value) ? 0 : value?.replaceAll(",", "") * 1;
  };

  sumFromObj = (obj) => {
    let sum = 0;
    let arr = Object.values(obj);
    if (arr.length > 0) {
      sum = arr.reduce((ac, el) => {
        if (typeof el === "string") {
          return ac + parseFloat(el.replaceAll(",", ""));
        } else {
          return ac + parseFloat(el);
        }
      }, 0);
    }
    return sum;
  };

  findCpPassage = (fuelType, passageType, consArr) => {
    let cp_price = 0;
    let fuel_cons = 0;
    consArr?.map((el) => {
      const { fuel_type, fuel_code } = el;
      if (fuel_type == fuelType) {
        cp_price = parseFloat(el.cp_price);
        fuel_cons = passageType === "1" ? el?.ballast_value : el.laden_value;
      }
    });
    cp_price = isNaN(cp_price) ? 0 : parseFloat(cp_price);
    fuel_cons = isNaN(fuel_cons) || !fuel_cons ? 0 : parseFloat(fuel_cons);
    return { cp_price, fuel_cons };
  };
  EcaSeaconsCalculation = (formdata) => {
    let ecaSeaCons = {
      ifo: 0,
      mgo: 0,
      lsmgo: 0,
      vlsfo: 0,
      ulsfo: 0,
    };

    let fuelType = formdata?.["eca_fuel_grade"] ?? 7;
    let ecafuelConsPrice = 0;

    formdata?.portitinerary?.map((el) => {
      const { eca_days = 0, passage } = el;
      const { cp_price, fuel_cons } = this.findCpPassage(
        fuelType,
        passage,
        formdata["."]
      );

      ecafuelConsPrice += cp_price * (eca_days || 0) * fuel_cons;
    });

    switch (fuelType) {
      case "3":
        ecaSeaCons["ifo"] = ecafuelConsPrice;
        break;

      case "4":
        ecaSeaCons["mgo"] = ecafuelConsPrice;
        break;

      case "5":
        ecaSeaCons["vlsfo"] = ecafuelConsPrice;
        break;

      case "7":
        ecaSeaCons["lsmgo"] = ecafuelConsPrice;
        break;

      case "10":
        ecaSeaCons["ulsfo"] = ecafuelConsPrice;
        break;
      case "11":
        ecaSeaCons["hfo"] = ecafuelConsPrice;
        break;
    }

    return ecaSeaCons;
  };

  nonEcaSeaconsCalculation = (formdata) => {
    let nonEcaSeaCons = {
      ifo: 0,
      mgo: 0,
      lsmgo: 0,
      vlsfo: 0,
      ulsfo: 0,
    };

    let nonifo;
    let nonmgo;
    let nonlsmgo;
    let nonvlsfo;
    let nonulsfo;

    let ecafuelType = formdata?.["eca_fuel_grade"];
    formdata?.portitinerary?.map((el) => {
      const { eca_days = 0, passage, tsd = 0 } = el;
      let nonEcadays = tsd - eca_days;
      nonifo = this.findCpPassage(3, passage, formdata["."]);
      nonmgo = this.findCpPassage(4, passage, formdata["."]);
      nonlsmgo = this.findCpPassage(7, passage, formdata["."]);
      nonvlsfo = this.findCpPassage(5, passage, formdata["."]);
      nonulsfo = this.findCpPassage(10, passage, formdata["."]);

      if (ecafuelType == 7) {
        if (nonvlsfo.fuel_cons > 0 && nonifo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonvlsfo;
          nonEcaSeaCons["vlsfo"] += parseFloat(
            fuel_cons * nonEcadays * cp_price
          );
        } else if (!nonvlsfo.fuel_cons && nonifo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonifo;
          nonEcaSeaCons["ifo"] += parseFloat(fuel_cons * nonEcadays * cp_price);
        } else if (nonvlsfo.fuel_cons > 0 && !nonifo.fuel_cons) {
          let { cp_price, fuel_cons } = nonvlsfo;
          nonEcaSeaCons["vlsfo"] += parseFloat(
            fuel_cons * nonEcadays * cp_price
          );
        }
      } else if (ecafuelType == 10) {
        if (nonulsfo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonulsfo;
          nonEcaSeaCons["ulsfo"] += parseFloat(
            fuel_cons * nonEcadays * cp_price
          );
        } else if (nonvlsfo.fuel_cons > 0 && nonifo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonvlsfo;
          nonEcaSeaCons["vlsfo"] += parseFloat(
            fuel_cons * nonEcadays * cp_price
          );
        } else if (nonifo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonifo;

          nonEcaSeaCons["ifo"] += parseFloat(fuel_cons * nonEcadays * cp_price);
        }
      } else {
        if (
          nonifo.fuel_cons > 0 &&
          nonmgo.fuel_cons > 0 &&
          nonlsmgo.fuel_cons > 0 &&
          nonvlsfo.fuel_cons > 0 &&
          nonulsfo.fuel_cons > 0
        ) {
          let { cp_price, fuel_cons } = nonvlsfo;

          nonEcaSeaCons["vlsfo"] += parseFloat(fuel_cons * tsd * cp_price);
        } else {
          nonEcaSeaCons["ifo"] += nonifo.fuel_cons
            ? parseFloat(nonifo.fuel_cons * tsd * nonifo.cp_price)
            : 0;
          nonEcaSeaCons["lsmgo"] += nonlsmgo.fuel_cons
            ? parseFloat(nonlsmgo.fuel_cons * tsd * nonlsmgo.cp_price)
            : 0;
          nonEcaSeaCons["mgo"] += nonmgo.fuel_cons
            ? parseFloat(nonmgo.fuel_cons * tsd * nonmgo.cp_price)
            : 0;
          nonEcaSeaCons["vlsfo"] += nonvlsfo.fuel_cons
            ? parseFloat(nonvlsfo.fuel_cons * tsd * nonvlsfo.cp_price)
            : 0;
          nonEcaSeaCons["ulsfo"] += nonulsfo.fuel_cons
            ? parseFloat(nonulsfo.fuel_cons * tsd * nonulsfo.cp_price)
            : 0;
        }
      }
    });
    return nonEcaSeaCons;
  };

  totalEcaSecafuelCons = (consObj) => {
    let fuelValue = Object.values(consObj);
    return fuelValue.reduce((ac, el) => ac + el, 0);
  };

  ecaPortConsCalculation = () => {
    let ecaPortCons = {
      ifo: 0,
      mgo: 0,
      lsmgo: 0,
      vlsfo: 0,
      ulsfo: 0,
    };
    return ecaPortCons;
  };

  checkNaN = (el) => {
    return isNaN(el) ? 0 : el * 1;
  };

  __getEstimatePL = (calData, colName) => {
    let {
      expenses = {},
      revenue = {},
      voyage_result = {},
      othercostsummary = [],
    } = calData?.["estimate"] ?? {};

    const {
      demmurage = 0,
      demmurageCommission = 0,
      desmurage = 0,
      desmurageCommission = 0,
      freight = 0,
      freight_commission = 0,
      gross_revenue = 0,
      mis_revenue = 0,
      net_revenue = 0,
    } = revenue ?? {};

    const {
      bunker_expenses = {},
      co2expense = 0,
      grossexpensee = 0,
      mis_expenses,
      port_expenses,
      tci_add_comm,
      tci_broker_comm,
      total_expenses,
      vessel_expenses,
    } = expenses ?? {};

    const {
      total_eca_port_cons,
      total_eca_sea_cons,
      total_noneca_port_cons,
      total_noneca_sea_cons,
      port_consumption,
    } = bunker_expenses ?? {};

    let totalBunkerexpense = 0;
    let totalseaconsnoneca = this.sumFromObj(total_noneca_sea_cons);
    let totalseaconseca = this.sumFromObj(total_eca_sea_cons);
    let totalportconsnoneca = this.sumFromObj(total_noneca_port_cons);
    let totalportconseca = this.sumFromObj(port_consumption);

    totalBunkerexpense =
      totalseaconsnoneca + totalseaconseca + totalportconseca;
    const {
      CO2_adj_profit,
      avargefright,
      breakeven_and_freight_rate,
      co2_adjusted_tce,
      daily_profit_loss,
      freight_rate,
      gross_tce,
      net_voyage_days,
      off_hire_days,
      profit_loss,
      tce_hire_net_daily,
      total_port_days,
      total_sea_days,
    } = voyage_result;

    const { dollarUSLocale } = this.state;
    let totalSeaConsumption = 0,
      vessel_expense = 0,
      totalPortConsumption = 0,
      vesselExpenses = 0;
    let ballast_bonus = 0;
    let _estimateData = Object.assign([], this.state.estimateData);

    _estimateData[0][colName] = this.dollarFormatedValue(
      net_revenue?.replaceAll(",", "") * 1
    );
    _estimateData[0]["children"][0][colName] = this.dollarFormatedValue(
      freight?.replaceAll(",", "") * 1
    );
    _estimateData[0]["children"][1][colName] = this.dollarFormatedValue(
      freight_commission?.replaceAll(",", "") * 1
    );
    _estimateData[0]["children"][2][colName] = this.dollarFormatedValue(
      mis_revenue?.replaceAll(",", "") * 1
    );
    _estimateData[0]["children"][3][colName] = this.dollarFormatedValue(
      demmurage?.replaceAll(",", "") * 1
    );
    _estimateData[0]["children"][4][colName] = this.dollarFormatedValue(
      demmurageCommission?.replaceAll(",", "") * 1
    );
    _estimateData[0]["children"][5][colName] = this.dollarFormatedValue(
      desmurage?.replaceAll(",", "") * 1
    );
    _estimateData[0]["children"][6][colName] = this.dollarFormatedValue(
      gross_revenue?.replaceAll(",", "") * 1
    );
    _estimateData[0]["children"][7][colName] = this.dollarFormatedValue(
      net_revenue?.replaceAll(",", "") * 1
    );

    _estimateData[1][colName] = this.dollarFormatedValue(
      total_expenses?.replaceAll(",", "") * 1
    );
    _estimateData[1]["children"][0][colName] = this.dollarFormatedValue(
      vessel_expenses["hire_expenses"]?.replaceAll(",", "") * 1
    );
    _estimateData[1]["children"][1][colName] = this.dollarFormatedValue(
      tci_add_comm?.replaceAll(",", "") * 1
    );
    _estimateData[1]["children"][2][colName] = this.dollarFormatedValue(
      tci_broker_comm?.replaceAll(",", "") * 1
    );
    _estimateData[1]["children"][3][colName] = this.dollarFormatedValue(
      port_expenses?.replaceAll(",", "") * 1
    );
    _estimateData[1]["children"][4][colName] = this.dollarFormatedValue(
      co2expense?.replaceAll(",", "") * 1
    );
    _estimateData[1]["children"][5][colName] = this.dollarFormatedValue(
      mis_expenses?.replaceAll(",", "") * 1
    );

    othercostsummary &&
      othercostsummary.length > 0 &&
      othercostsummary?.map((el, ind) => {
        _estimateData[1]["children"][5]["children"][ind] = {
          key: "ex15" + ind,
          description: el.description,
          estimate: this.dollarFormatedValue(el.amount),
        };
      });

    _estimateData[1]["children"][6][colName] =
      this.dollarFormatedValue(totalBunkerexpense);

    _estimateData[1]["children"][6]["children"][0][colName] =
      this.dollarFormatedValue(totalseaconsnoneca);
    _estimateData[1]["children"][6]["children"][0]["children"][0][colName] =
      this.dollarFormatedValue(total_noneca_sea_cons["ifo"]);
    _estimateData[1]["children"][6]["children"][0]["children"][1][colName] =
      this.dollarFormatedValue(total_noneca_sea_cons["mgo"]);
    _estimateData[1]["children"][6]["children"][0]["children"][2][colName] =
      this.dollarFormatedValue(total_noneca_sea_cons["vlsfo"]);
    _estimateData[1]["children"][6]["children"][0]["children"][3][colName] =
      this.dollarFormatedValue(total_noneca_sea_cons["lsmgo"]);
    _estimateData[1]["children"][6]["children"][0]["children"][4][colName] =
      this.dollarFormatedValue(total_noneca_sea_cons["ulsfo"]);

    _estimateData[1]["children"][6]["children"][1][colName] =
      this.dollarFormatedValue(totalseaconseca);
    _estimateData[1]["children"][6]["children"][1]["children"][0][colName] =
      this.dollarFormatedValue(total_eca_sea_cons["ifo"]);
    _estimateData[1]["children"][6]["children"][1]["children"][1][colName] =
      this.dollarFormatedValue(total_eca_sea_cons["mgo"]);
    _estimateData[1]["children"][6]["children"][1]["children"][2][colName] =
      this.dollarFormatedValue(total_eca_sea_cons["vlsfo"]);
    _estimateData[1]["children"][6]["children"][1]["children"][3][colName] =
      this.dollarFormatedValue(total_eca_sea_cons["lsmgo"]);
    _estimateData[1]["children"][6]["children"][1]["children"][4][colName] =
      this.dollarFormatedValue(total_eca_sea_cons["ulsfo"]);

    // console.log("port_consumption",port_consumption)
    _estimateData[1]["children"][6]["children"][2][colName] =
      this.dollarFormatedValue(totalportconseca);
    _estimateData[1]["children"][6]["children"][2]["children"][0][colName] =
      this.dollarFormatedValue(port_consumption["ifo"].replaceAll(",", ""));
    _estimateData[1]["children"][6]["children"][2]["children"][1][colName] =
      this.dollarFormatedValue(port_consumption["mgo"].replaceAll(",", ""));
    _estimateData[1]["children"][6]["children"][2]["children"][2][colName] =
      this.dollarFormatedValue(port_consumption["vlsfo"].replaceAll(",", ""));
    _estimateData[1]["children"][6]["children"][2]["children"][3][colName] =
      this.dollarFormatedValue(port_consumption["lsmgo"].replaceAll(",", ""));
    _estimateData[1]["children"][6]["children"][2]["children"][4][colName] =
      this.dollarFormatedValue(port_consumption["ulsfo"].replaceAll(",", ""));

    _estimateData[1]["children"][7][colName] = this.dollarFormatedValue(
      grossexpensee?.replaceAll(",", "") * 1
    );
    _estimateData[1]["children"][8][colName] = this.dollarFormatedValue(
      total_expenses?.replaceAll(",", "") * 1
    );

    _estimateData[2][colName] = this.dollarFormatedValue(
      profit_loss?.replaceAll(",", "") * 1
    );
    _estimateData[2]["children"][0][colName] = this.dollarFormatedValue(
      profit_loss?.replaceAll(",", "") * 1
    );
    _estimateData[2]["children"][1][colName] = this.dollarFormatedValue(
      daily_profit_loss?.replaceAll(",", "") * 1
    );
    _estimateData[2]["children"][2][colName] = this.dollarFormatedValue(
      tce_hire_net_daily?.replaceAll(",", "") * 1
    );
    _estimateData[2]["children"][3][colName] = this.dollarFormatedValue(
      gross_tce?.replaceAll(",", "") * 1
    );
    _estimateData[2]["children"][4][colName] = this.dollarFormatedValue(
      avargefright?.replaceAll(",", "") * 1
    );
    _estimateData[2]["children"][5][colName] = this.dollarFormatedValue(
      breakeven_and_freight_rate?.replaceAll(",", "") * 1
    );
    _estimateData[2]["children"][6][colName] = this.dollarFormatedValue(
      co2expense?.replaceAll(",", "") * 1
    );
    _estimateData[2]["children"][7][colName] = this.dollarFormatedValue(
      CO2_adj_profit?.replaceAll(",", "") * 1
    );
    _estimateData[2]["children"][8][colName] = this.dollarFormatedValue(
      co2_adjusted_tce?.replaceAll(",", "") * 1
    );
    _estimateData[2]["children"][9][colName] = this.dollarFormatedValue(
      total_sea_days?.replaceAll(",", "") * 1
    );
    _estimateData[2]["children"][10][colName] = this.dollarFormatedValue(
      total_port_days?.replaceAll(",", "") * 1
    );
    _estimateData[2]["children"][11][colName] = this.dollarFormatedValue(
      net_voyage_days?.replaceAll(",", "") * 1
    );
    _estimateData[2]["children"][12][colName] =
      this.dollarFormatedValue("0.00");
    _estimateData[2]["children"][13][colName] = this.dollarFormatedValue(
      net_voyage_days?.replaceAll(",", "") * 1
    );
    return _estimateData;
  };

  __pl = () => {
    let revenue;
    try {
      let {
        estimateData,
        dollarUSLocale,
        formData,
        estimateDatavalue,
        showEstimatePl,
      } = this.state;

      let objdata = { ...this.state.formData };
      if (!objdata.hasOwnProperty("bunkerdetails")) {
        const bunkerconsp = objdata["portitinerary"]?.map((el, ind) => {
          const values = BunkerDetailsCalculation(objdata, ind);
          return values;
        });
        objdata["bunkerdetails"] = bunkerconsp;
      }
      formData = { ...objdata };

      let netExpenses = 0;
      let totalSeaConsumption = 0,
        totalPortConsumption = 0,
        totalArriveConsumption = 0,
        totalDepConsumption = 0,
        totalAdjConsumption = 0;
      let colName = "actual";

      let ecaSeacons = this.EcaSeaconsCalculation(formData);
      let nonEcaSeacons = this.nonEcaSeaconsCalculation(formData);

      let ecaPortCons = this.ecaPortConsCalculation(formData);
      let totalecaSeacons = this.totalEcaSecafuelCons(ecaSeacons);
      let totalnonEcaSeacons = this.totalEcaSecafuelCons(nonEcaSeacons);

      let totalecaPortCons = this.totalEcaSecafuelCons(ecaPortCons);

      if (showEstimatePl === true) {
        estimateData = this.__getEstimatePL(estimateDatavalue, "estimate");
      }

      let totalVoyageDays = formData["total_days"]
        ? isNaN(("" + formData["total_days"])?.replaceAll(",", "") * 1)
          ? 0
          : ("" + formData["total_days"])?.replaceAll(",", "") * 1
        : 0;

      let tsd = 0,
        tpd = 0,
        pi = 0,
        fr = 0,
        mr = 0,
        grossRevenue = 0,
        netRevenue = 0,
        demmurage_amt = 0,
        desmurage_amt = 0,
        lumpsum = 0,
        freightCommission = 0,
        des_com_amt = 0,
        dem_com_amt = 0,
        dispatch = 0,
        grossExpenses = 0,
        cpQty = 0,
        co2_adjusted_profit = 0,
        co2cost = 0,
        co2_adjusted_tce = 0;

      let posted = {};
      let cash = {};

      let {
        frightInvoice_posted,
        frightComm_posted,
        miscRev_posted,
        demAmt_posted,
        demComAmt_posted,
        desAmt_posted,
        desComAmt_posted,
        vesselExpenses_posted,
        hireExpenses_posted,
        tciaddcom_posted,
        tcibcom_posted,
        portExpenses_posted,
        bunkerExpenses_posted,
        miscExpenses_posted,
      } = estimateDatavalue.post;

      let {
        frightInvoice_cash,
        frightComm_cash,
        miscRev_cash,
        demAmt_cash,
        demComAmt_cash,
        desAmt_cash,
        desComAmt_cash,
        vesselExpenses_cash,
        hireExpenses_cash,
        tciaddcom_cash,
        tcibcom_cash,
        portExpenses_cash,
        bunkerExpenses_cash,
        miscExpenses_cash,
      } = estimateDatavalue.cash;

      let emmissionexpense_posted = 0,
        emmissionexpense_cash = 0,
        grossExpenses_posted = 0,
        grossExpenses_cash = 0,
        netExpenses_posted = 0,
        netExpenses_cash = 0,
        seaconsnonecaifo_posted = 0,
        seaconsnonecaifo_cash,
        seaconsnonecamgo_posted = 0,
        seaconsnonecamgo_cash = 0,
        seaconsnoneca_vlsfo_posted = 0,
        seaconsnoneca_vlsfo_cash = 0,
        seaconsnoneca_lsmgo_posted = 0,
        seaconsnoneca_lsmgo_cash = 0,
        seaconsnoneca_ulsfo_cash = 0,
        seaconsnoneca_ulsfo_posted = 0,
        seacons_eca_ifo_posted = 0,
        seacons_eca_ifo_cash,
        seacons_eca_mgo_posted = 0,
        seacons_eca_mgo_cash = 0,
        seacons_eca_vlsfo_posted = 0,
        seacons_eca_vlsfo_cash = 0,
        seacons_eca_lsmgo_posted = 0,
        seacons_eca_lsmgo_cash = 0,
        seacons_eca_ulsfo_cash = 0,
        seacons_eca_ulsfo_posted = 0,
        portcon_eca_ifo_posted = 0,
        portcon_eca_ifo_cash,
        portcon_eca_mgo_posted = 0,
        portcon_eca_mgo_cash = 0,
        portcon_eca_vlsfo_posted = 0,
        portcon_eca_vlsfo_cash = 0,
        portcon_eca_lsmgo_posted = 0,
        portcon_eca_lsmgo_cash = 0,
        portcon_eca_ulsfo_cash = 0,
        portcon_eca_ulsfo_posted = 0,
        portcon_noneca_ifo_posted = 0,
        portcon_noneca_ifo_cash,
        portcon_noneca_mgo_posted = 0,
        portcon_noneca_mgo_cash = 0,
        portcon_noneca_vlsfo_posted = 0,
        portcon_noneca_vlsfo_cash = 0,
        portcon_noneca_lsmgo_posted = 0,
        portcon_noneca_lsmgo_cash = 0,
        portcon_noneca_ulsfo_cash = 0,
        portcon_noneca_ulsfo_posted = 0;
      let gross_revenue_posted = 0,
        gross_revenue_cash = 0,
        net_revenue_posted = 0,
        net_revenue_cash = 0;
      let gross_exp_posted = 0,
        gross_exp_cash = 0,
        net_exp_posted = 0,
        net_exp_cash = 0;

      const FirstVSSecond = (p, c) => {
        let diff = 0;
        if (p && c) {
          diff = (p - c).toFixed(2);
        } else if (p && !c) {
          diff = (p * 1).toFixed(2);
        } else if (!p && c) {
          diff = (c * -1).toFixed(2);
        } else {
          diff = "0.00";
        }
        return isFinite(diff) ? diff : "0.00";
      };

      const perFirstVSSecond = (p, c) => {
        let perdiff = 0;
        if (p && c) {
          perdiff = ((p * 1 - c * 1) / p) * 1 * 100;
        } else if (p && !c) {
          perdiff = 100;
        } else if (!p && c) {
          perdiff = 0;
        }
        return isFinite(perdiff) ? perdiff.toFixed(2) : "0.00";
      };

      const checkNanAndFormat = (value) => {
        if (!value || isNaN(value)) {
          return 0;
        } else if (typeof value == "number") {
          return value;
        } else if (typeof value == "string") {
          const numValue = value?.replaceAll(",", "") * 1;
          return isNaN(numValue) ? 0 : numValue;
        } else {
          return 0;
        }
      };

      let bb = formData["bb"]
        ? isNaN(("" + formData["bb"])?.replaceAll(",", "") * 1)
          ? 0
          : ("" + formData["bb"])?.replaceAll(",", "") * 1
        : 0;
      let misCost = formData["mis_cost"]
        ? isNaN(("" + formData["mis_cost"])?.replaceAll(",", "") * 1)
          ? 0
          : ("" + formData["mis_cost"])?.replaceAll(",", "") * 1
        : 0;

      let otherCost = formData["other_cost"]
        ? isNaN(("" + formData["other_cost"])?.replaceAll(",", "") * 1)
          ? 0
          : ("" + formData["other_cost"])?.replaceAll(",", "") * 1
        : 0;

      if (formData && formData.hasOwnProperty("portitinerary")) {
        let portItinerary = formData["portitinerary"];
        portItinerary?.map((e) => {
          tsd += (e.tsd + "")?.replaceAll(",", "") * 1;
          tpd += (e.t_port_days + "")?.replaceAll(",", "") * 1;
        });
        tsd = tsd * 1;
        tpd = tpd * 1;
      }

      totalVoyageDays =
        tpd + tsd > 0 && tpd + tsd > totalVoyageDays
          ? tpd + tsd
          : totalVoyageDays;

      let hire = formData["tci_d_hire"]
        ? (formData["tci_d_hire"] + "")?.replaceAll(",", "") * 1
        : 0;

      let addPercentage = formData["add_percentage"]
        ? (formData["add_percentage"] + "")?.replaceAll(",", "") * 1
        : 0;

      let amt_add_percentage = hire * totalVoyageDays * addPercentage * 0.01;

      let broPercentage = formData["bro_percentage"]
        ? (formData["bro_percentage"] + "")?.replaceAll(",", "") * 1
        : 0;
      let amt_bro_percentage = hire * totalVoyageDays * broPercentage * 0.01;

      let portExpenses = { ifo: 0, mgo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0 },
        seaExpenses = { ifo: 0, mgo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0 },
        arrivalrev = { ifo: 0, mgo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0 },
        deprev = { ifo: 0, mgo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0 },
        arrivaladjuestmentrev = {
          ifo: 0,
          mgo: 0,
          vlsfo: 0,
          ulsfo: 0,
          lsmgo: 0,
        };

      if (formData && formData.hasOwnProperty("bunkerdetails")) {
        let bunkerDetails = formData["bunkerdetails"];
        let i = 0;
        bunkerDetails?.map((e, i, { length }) => {
          seaExpenses["ifo"] += e["ifo"]
            ? isNaN(("" + e["ifo"])?.replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["ifo"])?.replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;
          seaExpenses["mgo"] += e["mgo"]
            ? isNaN(("" + e["mgo"])?.replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["mgo"])?.replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;
          seaExpenses["vlsfo"] += e["vlsfo"]
            ? isNaN(("" + e["vlsfo"])?.replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["vlsfo"])?.replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;
          seaExpenses["lsmgo"] += e["lsmgo"]
            ? isNaN(("" + e["lsmgo"])?.replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["lsmgo"])?.replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;
          seaExpenses["ulsfo"] += e["ulsfo"]
            ? isNaN(("" + e["ulsfo"])?.replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["ulsfo"])?.replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;

          portExpenses["ifo"] += e["pc_ifo"]
            ? isNaN(("" + e["pc_ifo"])?.replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["pc_ifo"])?.replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;
          portExpenses["mgo"] += e["pc_mgo"]
            ? isNaN(("" + e["pc_mgo"])?.replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["pc_mgo"])?.replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;
          portExpenses["vlsfo"] += e["pc_vlsfo"]
            ? isNaN(("" + e["pc_vlsfo"])?.replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["pc_vlsfo"])?.replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;
          portExpenses["lsmgo"] += e["pc_lsmgo"]
            ? isNaN(("" + e["pc_lsmgo"])?.replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["pc_lsmgo"])?.replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;
          portExpenses["ulsfo"] += e["pc_ulsfo"]
            ? isNaN(("" + e["pc_ulsfo"])?.replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["pc_ulsfo"])?.replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;

          if (i === 0) {
            arrivalrev["ifo"] += e["arob_ifo"]
              ? isNaN(("" + e["arob_ifo"])?.replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_ifo"])?.replaceAll(",", "") * 1
              : 0;
            arrivalrev["mgo"] += e["arob_mgo"]
              ? isNaN(("" + e["arob_mgo"])?.replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_mgo"])?.replaceAll(",", "") * 1
              : 0;
            arrivalrev["vlsfo"] += e["arob_vlsfo"]
              ? isNaN(("" + e["arob_vlsfo"])?.replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_vlsfo"])?.replaceAll(",", "") * 1
              : 0;
            arrivalrev["lsmgo"] += e["arob_lsmgo"]
              ? isNaN(("" + e["arob_lsmgo"])?.replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_lsmgo"])?.replaceAll(",", "") * 1
              : 0;
            arrivalrev["ulsfo"] += e["arob_ulsfo"]
              ? isNaN(("" + e["arob_ulsfo"])?.replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_ulsfo"])?.replaceAll(",", "") * 1
              : 0;
          }

          if (i + 1 === length) {
            deprev["ifo"] += e["dr_ifo"]
              ? isNaN(("" + e["dr_ifo"])?.replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_ifo"])?.replaceAll(",", "") * 1
              : 0;
            deprev["mgo"] += e["dr_mgo"]
              ? isNaN(("" + e["dr_mgo"])?.replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_mgo"])?.replaceAll(",", "") * 1
              : 0;
            deprev["vlsfo"] += e["dr_vlsfo"]
              ? isNaN(("" + e["dr_vlsfo"])?.replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_vlsfo"])?.replaceAll(",", "") * 1
              : 0;
            deprev["lsmgo"] += e["dr_lsmgo"]
              ? isNaN(("" + e["dr_lsmgo"])?.replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_lsmgo"])?.replaceAll(",", "") * 1
              : 0;
            deprev["ulsfo"] += e["dr_ulsfo"]
              ? isNaN(("" + e["dr_ulsfo"])?.replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_ulsfo"])?.replaceAll(",", "") * 1
              : 0;
          }
        });
      }

      if (formData && formData.hasOwnProperty(".")) {
        let cpData = formData["."];
        let price_cp_eco =
          formData.hasOwnProperty("price_cp_eco") && formData["price_cp_eco"]
            ? 1
            : 0;
        cpData?.map((e) => {
          let _price = 0;
          let _pprice = 0;
          if (
            e["cp_price"] &&
            !isNaN(("" + e["cp_price"])?.replaceAll(",", "") * 1)
          ) {
            _price = ("" + e["cp_price"])?.replaceAll(",", "") * 1;
          }
          if (
            e["purchase_price"] &&
            !isNaN(("" + e["purchase_price"])?.replaceAll(",", "") * 1)
          ) {
            _pprice = ("" + e["purchase_price"])?.replaceAll(",", "") * 1;
          }

          switch (e.fuel_code) {
            case "IFO":
              seaExpenses["ifo"] =
                price_cp_eco === 1
                  ? seaExpenses["ifo"] * _pprice
                  : seaExpenses["ifo"] * _price;
              portExpenses["ifo"] =
                price_cp_eco === 1
                  ? portExpenses["ifo"] * _pprice
                  : portExpenses["ifo"] * _price;
              arrivalrev["ifo"] = arrivalrev["ifo"] * _price;
              deprev["ifo"] = deprev["ifo"] * _pprice;
              arrivaladjuestmentrev["ifo"] = deprev["ifo"] - arrivalrev["ifo"];
              break;

            case "MGO":
              seaExpenses["mgo"] =
                price_cp_eco === 1
                  ? seaExpenses["mgo"] * _pprice
                  : seaExpenses["mgo"] * _price;
              portExpenses["mgo"] =
                price_cp_eco === 1
                  ? portExpenses["mgo"] * _pprice
                  : portExpenses["mgo"] * _price;
              arrivalrev["mgo"] = arrivalrev["mgo"] * _price;
              deprev["mgo"] = deprev["mgo"] * _pprice;
              arrivaladjuestmentrev["mgo"] = deprev["mgo"] - arrivalrev["mgo"];
              break;

            case "VLSFO":
              seaExpenses["vlsfo"] =
                price_cp_eco === 1
                  ? seaExpenses["vlsfo"] * _pprice
                  : seaExpenses["vlsfo"] * _price;
              portExpenses["vlsfo"] =
                price_cp_eco === 1
                  ? portExpenses["vlsfo"] * _pprice
                  : portExpenses["vlsfo"] * _price;
              arrivalrev["vlsfo"] = arrivalrev["vlsfo"] * _price;
              deprev["vlsfo"] = deprev["vlsfo"] * _pprice;
              arrivaladjuestmentrev["vlsfo"] =
                deprev["vlsfo"] - arrivalrev["vlsfo"];
              break;

            case "LSMGO":
              seaExpenses["lsmgo"] =
                price_cp_eco === 1
                  ? seaExpenses["lsmgo"] * _pprice
                  : seaExpenses["lsmgo"] * _price;
              portExpenses["lsmgo"] =
                price_cp_eco === 1
                  ? portExpenses["lsmgo"] * _pprice
                  : portExpenses["lsmgo"] * _price;
              arrivalrev["lsmgo"] = arrivalrev["lsmgo"] * _price;
              deprev["lsmgo"] = deprev["lsmgo"] * _pprice;
              arrivaladjuestmentrev["lsmgo"] =
                deprev["lsmgo"] - arrivalrev["lsmgo"];
              break;

            case "ULSFO":
              seaExpenses["ulsfo"] =
                price_cp_eco === 1
                  ? (isNaN(seaExpenses["ulsfo"]) ? 0 : seaExpenses["ulsfo"]) *
                    _pprice
                  : (isNaN(seaExpenses["ulsfo"]) ? 0 : seaExpenses["ulsfo"]) *
                    _price;
              portExpenses["ulsfo"] =
                price_cp_eco === 1
                  ? (isNaN(portExpenses["ulsfo"]) ? 0 : portExpenses["ulsfo"]) *
                    _price
                  : (isNaN(portExpenses["ulsfo"]) ? 0 : portExpenses["ulsfo"]) *
                    _price;
              arrivalrev["ulsfo"] =
                (isNaN(arrivalrev["ulsfo"]) ? 0 : arrivalrev["ulsfo"]) * _price;
              deprev["ulsfo"] =
                (isNaN(deprev["ulsfo"]) ? 0 : deprev["ulsfo"]) * _pprice;
              arrivaladjuestmentrev["ulsfo"] =
                deprev["ulsfo"] - arrivalrev["ulsfo"];
              break;
          }
        });
      }

      co2cost = formData?.["-----"]?.["inc_pnl"]
        ? formData?.["-----"]?.["ttl_co2_cost"]
        : 0;

      Object.keys(seaExpenses)?.map(
        (e) => (totalSeaConsumption += seaExpenses[e])
      );
      Object.keys(portExpenses)?.map(
        (e) => (totalPortConsumption += portExpenses[e])
      );

      let nonecaPortcons = portExpenses;
      let totalnonecaPortcons = this.totalEcaSecafuelCons(nonecaPortcons);
      let bunkerExpense =
        totalecaSeacons +
        totalnonEcaSeacons +
        totalnonecaPortcons +
        totalecaPortCons;

      Object.keys(arrivalrev)?.map(
        (e) => (totalArriveConsumption += arrivalrev[e])
      );
      Object.keys(deprev)?.map((e) => (totalDepConsumption += deprev[e]));
      Object.keys(arrivaladjuestmentrev)?.map(
        (e) => (totalAdjConsumption += arrivaladjuestmentrev[e])
      );

      if (formData && formData.hasOwnProperty("portitinerary")) {
        let portitinerary = formData["portitinerary"];
        portitinerary?.map(
          (e) =>
            (pi += isNaN(("" + e.p_exp)?.replaceAll(",", "") * 1)
              ? 0
              : ("" + e.p_exp)?.replaceAll(",", "") * 1)
        );
      }

      let avgfreightRate = 0,
        avgfreightRateOpt = 0,
        frtAvg = 0,
        cpQtyOpt = 0;
      let calculateDemurrage = 0;
      let calculatedespatch = 0;
      if (formData && formData.hasOwnProperty("portitinerary")) {
        const { demurrage, despatch } = this.DemurrageDespatchCalculation(
          formData.portitinerary
        );
        calculateDemurrage = demurrage;
        calculatedespatch = despatch;
      }
      let opt_per;
      if (formData && formData.hasOwnProperty("cargos")) {
        let cargos = formData["cargos"];
        cargos?.map((e, i) => {
          let frtRate = 0,
            commission = 0,
            frtAmount = 0;
          let frt_rate = e.freight_rate || e.frat_rate || e.f_rate || 0;

          let frttype = e.frt_type || e.frt_type || e.frt_type || 0;
          let lumsum = e.lumsum || e.lumsum || e.lumsum || 0;
          let demmurage = e.dem_rate_pd || e.dem_rate_pd || e.dem_rate_pd || 0;
          let desmurage = e.des_rate_pd || e.des_rate_pd || e.des_rate_pd || 0;

          let cp_qty = e.cp_qty || e.quantity || 0;
          let b_commission = e.b_commission || e.commission || 0;

          opt_per =
            parseFloat(e.option_percentage) ||
            parseFloat(e.opt_type === "286" ? 0 : e.opt_percentage) ||
            0;
          mr += e.extra_rev ? (e.extra_rev + "")?.replaceAll(",", "") * 1 : 0;
          cpQty += cp_qty ? (cp_qty + "")?.replaceAll(",", "") * 1 : 0;
          cpQtyOpt += cp_qty ? (cp_qty + "")?.replaceAll(",", "") * 1 : 0;

          if (opt_per !== 0) {
            let _cpq = cp_qty ? (cp_qty + "")?.replaceAll(",", "") * 1 : 0;
            let _fr = frt_rate ? (frt_rate + "")?.replaceAll(",", "") * 1 : 0;
            opt_per = opt_per ? (opt_per + "").replaceAll(",", "") : 0;
            let cpOptQty = _cpq + (_cpq * opt_per) / 100;
            cpQtyOpt = cpQtyOpt - _cpq + cpOptQty;

            frtAmount = cpOptQty * _fr;
            if (frttype === "104") {
              frtAmount = lumsum ? (lumsum + "")?.replaceAll(",", "") * 1 : 0;
            }

            commission =
              (frtAmount *
                (b_commission
                  ? (b_commission + "")?.replaceAll(",", "") * 1
                  : 0)) /
              100;
            frtRate =
              (cp_qty ? (cp_qty + "")?.replaceAll(",", "") * 1 : 0) *
              (frt_rate ? (frt_rate + "")?.replaceAll(",", "") * 1 : 0);
          } else {
            frtAmount =
              (cp_qty ? (cp_qty + "")?.replaceAll(",", "") * 1 : 0) *
              (frt_rate ? (frt_rate + "")?.replaceAll(",", "") * 1 : 0);
            if (frttype === "104") {
              frtAmount = lumsum ? (lumsum + "")?.replaceAll(",", "") * 1 : 0;
            }
            commission =
              (frtAmount *
                (b_commission
                  ? (b_commission + "")?.replaceAll(",", "") * 1
                  : 0)) /
              100;

            frtRate = frtAmount;
          }

          fr += frtAmount;
          freightCommission += commission;
          frtAvg += frtRate;

          dem_com_amt +=
            (calculateDemurrage *
              (b_commission
                ? (b_commission + "")?.replaceAll(",", "") * 1
                : 0)) /
            100;
          lumpsum += e.lumsum * 1;
        });
        avgfreightRateOpt = fr / cpQtyOpt;
        avgfreightRate = fr / cpQty;
      }

      const calculateMiscRevenue = (otherCost, extraRev) => {
        let otherMiscValue = checkNanAndFormat(otherCost);
        let extraMiscValue = checkNanAndFormat(extraRev);
        if (otherMiscValue != 0 && extraMiscValue != 0) {
          return otherMiscValue + extraMiscValue;
        } else if (otherMiscValue === 0 && extraMiscValue != 0) {
          return extraMiscValue;
        } else if (otherMiscValue != 0 && extraMiscValue === 0) {
          return otherMiscValue;
        } else {
          return 0;
        }
      };

      demmurage_amt = calculateDemurrage;
      desmurage_amt = calculatedespatch;

      let miscrevac = calculateMiscRevenue(formData?.mis_cost, mr);

      grossRevenue =
        this.checkNaN(fr) +
        this.checkNaN(miscrevac) +
        this.checkNaN(desmurage_amt) -
        this.checkNaN(demmurage_amt * -1);
      netRevenue = grossRevenue - freightCommission - dem_com_amt * -1;

      otherCost = otherCost + bb;

      grossExpenses =
        this.checkNaN(totalVoyageDays * hire) +
        this.checkNaN(pi) +
        this.checkNaN(otherCost) +
        this.checkNaN(bunkerExpense) +
        this.checkNaN(co2cost);

      netExpenses = grossExpenses - (amt_add_percentage + amt_bro_percentage);

      fr = fr <= 0 ? lumpsum : fr;

      let profitLoss = netRevenue - netExpenses;
      co2_adjusted_profit = profitLoss - co2cost;
      co2_adjusted_tce =
        totalVoyageDays > 0 ? co2_adjusted_profit / totalVoyageDays : 0;

      net_revenue_posted =
        frightInvoice_posted * 1 + frightComm_posted * 1 + miscRev_posted * 1;
      net_revenue_cash =
        frightInvoice_cash * 1 + frightComm_cash * 1 + miscRev_cash * 1;

      estimateData[0][colName] = this.dollarFormatedValue(netRevenue);
      let reves = estimateData[0]["estimate"]
        ? estimateData[0]["estimate"]?.replaceAll(",", "") * 1
        : 0;
      let revac = estimateData[0]["actual"]
        ? estimateData[0]["actual"]?.replaceAll(",", "") * 1
        : 0;
      estimateData[0]["Diff"] = FirstVSSecond(revac, reves);
      estimateData[0]["perDiff"] = perFirstVSSecond(revac, reves);
      estimateData[0]["posted"] = this.dollarFormatedValue(net_revenue_posted);
      estimateData[0]["cash_in"] = this.dollarFormatedValue(net_revenue_cash);
      estimateData[0]["sec_variance"] = FirstVSSecond(
        net_revenue_posted,
        net_revenue_cash
      );
      estimateData[0]["sec_per"] = perFirstVSSecond(
        net_revenue_posted,
        net_revenue_cash
      );

      estimateData[0]["children"][0][colName] = this.dollarFormatedValue(fr);
      let frtes = estimateData[0]["children"][0]["estimate"]
        ? estimateData[0]["children"][0]["estimate"]?.replaceAll(",", "") * 1
        : 0;
      let frtac = estimateData[0]["children"][0]["actual"]
        ? estimateData[0]["children"][0]["actual"]?.replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][0]["Diff"] = FirstVSSecond(frtac, frtes);
      estimateData[0]["children"][0]["perDiff"] = perFirstVSSecond(
        frtac,
        frtes
      );
      estimateData[0]["children"][0]["posted"] =
        this.dollarFormatedValue(frightInvoice_posted);
      estimateData[0]["children"][0]["cash_in"] =
        this.dollarFormatedValue(frightInvoice_cash);
      estimateData[0]["children"][0]["sec_variance"] = FirstVSSecond(
        frightInvoice_posted,
        frightInvoice_cash
      );
      estimateData[0]["children"][0]["sec_per"] = perFirstVSSecond(
        frightInvoice_posted,
        frightInvoice_cash
      );

      estimateData[0]["children"][1][colName] =
        this.dollarFormatedValue(freightCommission);
      let frtcmsnes = checkNanAndFormat(
        estimateData[0]["children"][1]["estimate"]
      );
      let frtcmsnac = checkNanAndFormat(
        estimateData[0]["children"][1]["actual"]
      );
      estimateData[0]["children"][1]["Diff"] = FirstVSSecond(
        frtcmsnac,
        frtcmsnes
      );
      estimateData[0]["children"][1]["perDiff"] = perFirstVSSecond(
        frtcmsnac,
        frtcmsnes
      );
      estimateData[0]["children"][1]["posted"] =
        this.dollarFormatedValue(frightComm_posted);
      estimateData[0]["children"][1]["cash_in"] =
        this.dollarFormatedValue(frightComm_cash);
      estimateData[0]["children"][1]["sec_variance"] = FirstVSSecond(
        frightComm_posted,
        frightComm_cash
      );
      estimateData[0]["children"][1]["sec_per"] = perFirstVSSecond(
        frightComm_posted,
        frightComm_cash
      );

      estimateData[0]["children"][2][colName] =
        this.dollarFormatedValue(miscrevac);
      let miscreves = estimateData[0]["children"][2]["estimate"]
        ? estimateData[0]["children"][2]["estimate"]?.replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][2]["Diff"] = FirstVSSecond(
        miscrevac,
        miscreves
      );
      estimateData[0]["children"][2]["perDiff"] = perFirstVSSecond(
        miscrevac,
        miscreves
      );
      estimateData[0]["children"][2]["posted"] =
        this.dollarFormatedValue(miscRev_posted);
      estimateData[0]["children"][2]["cash_in"] =
        this.dollarFormatedValue(miscRev_cash);
      estimateData[0]["children"][2]["sec_variance"] = FirstVSSecond(
        miscRev_posted,
        miscRev_cash
      );
      estimateData[0]["children"][2]["sec_per"] = perFirstVSSecond(
        miscRev_posted,
        miscRev_cash
      );

      estimateData[0]["children"][3][colName] = this.dollarFormatedValue(
        demmurage_amt * -1
      );
      let demmuragees = estimateData[0]["children"][3]["estimate"]
        ? estimateData[0]["children"][3]["estimate"]?.replaceAll(",", "") * 1
        : 0;
      let demmurageac = estimateData[0]["children"][3]["actual"]
        ? estimateData[0]["children"][3]["actual"]?.replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][3]["Diff"] = FirstVSSecond(
        demmurageac,
        demmuragees
      );
      estimateData[0]["children"][3]["perDiff"] = perFirstVSSecond(
        demmurageac,
        demmuragees
      );
      estimateData[0]["children"][3]["posted"] =
        this.dollarFormatedValue(demAmt_posted);
      estimateData[0]["children"][3]["cash_in"] =
        this.dollarFormatedValue(demAmt_cash);
      estimateData[0]["children"][3]["sec_variance"] = FirstVSSecond(
        demAmt_posted,
        demAmt_cash
      );
      estimateData[0]["children"][3]["sec_per"] = perFirstVSSecond(
        demAmt_posted,
        demAmt_cash
      );

      estimateData[0]["children"][4][colName] = this.dollarFormatedValue(
        dem_com_amt * -1
      );
      let demcomamtes = estimateData[0]["children"][4]["estimate"]
        ? estimateData[0]["children"][4]["estimate"]?.replaceAll(",", "") * 1
        : 0;
      let demcomamtac = estimateData[0]["children"][4]["actual"]
        ? estimateData[0]["children"][4]["actual"]?.replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][4]["Diff"] = FirstVSSecond(
        demcomamtac,
        demcomamtes
      );
      estimateData[0]["children"][4]["perDiff"] = perFirstVSSecond(
        demcomamtac,
        demcomamtes
      );
      estimateData[0]["children"][4]["posted"] =
        this.dollarFormatedValue(demComAmt_posted);
      estimateData[0]["children"][4]["cash_in"] =
        this.dollarFormatedValue(demComAmt_cash);
      estimateData[0]["children"][4]["sec_variance"] = FirstVSSecond(
        demComAmt_posted,
        demComAmt_cash
      );
      estimateData[0]["children"][4]["sec_per"] = perFirstVSSecond(
        demComAmt_posted,
        demComAmt_cash
      );

      estimateData[0]["children"][5][colName] = this.dollarFormatedValue(
        desmurage_amt.toFixed(2)
      );
      let desamtes = checkNanAndFormat(
        estimateData[0]["children"][5]["estimate"]
      );
      let desamtac = checkNanAndFormat(
        estimateData[0]["children"][5]["actual"]
      );
      estimateData[0]["children"][5]["Diff"] = FirstVSSecond(
        desamtac,
        desamtes
      );
      estimateData[0]["children"][5]["perDiff"] = perFirstVSSecond(
        desamtac,
        desamtes
      );
      estimateData[0]["children"][5]["posted"] =
        this.dollarFormatedValue(desAmt_posted);
      estimateData[0]["children"][5]["cash_in"] =
        this.dollarFormatedValue(desAmt_cash);
      estimateData[0]["children"][5]["sec_variance"] = FirstVSSecond(
        desAmt_posted,
        desAmt_cash
      );
      estimateData[0]["children"][5]["sec_per"] = perFirstVSSecond(
        desAmt_posted,
        desAmt_cash
      );

      estimateData[0]["children"][6][colName] = this.dollarFormatedValue(
        grossRevenue.toFixed(2)
      );
      let grossreves = estimateData[0]["children"][6]["estimate"]
        ? estimateData[0]["children"][6]["estimate"]?.replaceAll(",", "") * 1
        : 0;
      let grossrevac = estimateData[0]["children"][6]["actual"]
        ? estimateData[0]["children"][6]["actual"]?.replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][6]["Diff"] = FirstVSSecond(
        grossrevac,
        grossreves
      );
      estimateData[0]["children"][6]["perDiff"] = perFirstVSSecond(
        grossrevac,
        grossreves
      );
      estimateData[0]["children"][6]["posted"] =
        this.dollarFormatedValue(gross_revenue_posted);
      estimateData[0]["children"][6]["cash_in"] =
        this.dollarFormatedValue(gross_revenue_cash);
      estimateData[0]["children"][6]["sec_variance"] = FirstVSSecond(
        gross_revenue_posted,
        gross_revenue_cash
      );
      estimateData[0]["children"][6]["sec_per"] = perFirstVSSecond(
        gross_revenue_posted,
        gross_revenue_cash
      );

      estimateData[0]["children"][7][colName] = this.dollarFormatedValue(
        netRevenue.toFixed(2)
      );
      let netreves = estimateData[0]["children"][7]["estimate"]
        ? estimateData[0]["children"][7]["estimate"]?.replaceAll(",", "") * 1
        : 0;
      let netrevac = estimateData[0]["children"][7]["actual"]
        ? estimateData[0]["children"][7]["actual"]?.replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][7]["Diff"] = FirstVSSecond(
        netrevac,
        netreves
      );
      estimateData[0]["children"][7]["perDiff"] = perFirstVSSecond(
        netrevac,
        netreves
      );
      estimateData[0]["children"][7]["posted"] =
        this.dollarFormatedValue(net_revenue_posted);
      estimateData[0]["children"][7]["cash_in"] =
        this.dollarFormatedValue(net_revenue_cash);
      estimateData[0]["children"][7]["sec_variance"] = FirstVSSecond(
        net_revenue_posted,
        net_revenue_cash
      );
      estimateData[0]["children"][7]["sec_per"] = perFirstVSSecond(
        net_revenue_posted,
        net_revenue_cash
      );

      let VesselExpense = totalVoyageDays * hire;

      estimateData[1][colName] = this.dollarFormatedValue(
        netExpenses.toFixed(2)
      );
      let expenses = estimateData[1]["estimate"]
        ? estimateData[1]["estimate"]?.replaceAll(",", "") * 1
        : 0;
      let expensesac = estimateData[1]["actual"]
        ? estimateData[1]["actual"]?.replaceAll(",", "") * 1
        : 0;

      net_exp_posted =
        hireExpenses_posted * 1 +
        tciaddcom_posted * 1 +
        tcibcom_posted * 1 +
        portExpenses_posted * 1 +
        emmissionexpense_posted * 1 +
        miscExpenses_posted * 1 +
        bunkerExpenses_posted * 1;
      net_exp_cash =
        hireExpenses_cash * 1 +
        tciaddcom_cash * 1 +
        tcibcom_cash * 1 +
        portExpenses_cash * 1 +
        emmissionexpense_cash * 1 +
        miscExpenses_cash * 1 +
        bunkerExpenses_posted * 1;
      estimateData[1]["Diff"] = FirstVSSecond(expensesac, expenses);
      estimateData[1]["perDiff"] = perFirstVSSecond(expensesac, expenses);
      estimateData[1]["posted"] = this.dollarFormatedValue(net_exp_posted);
      estimateData[1]["cash_in"] = this.dollarFormatedValue(net_exp_cash);
      estimateData[1]["sec_variance"] = FirstVSSecond(
        net_exp_posted,
        net_exp_cash
      );
      estimateData[1]["sec_per"] = perFirstVSSecond(
        net_exp_posted,
        net_exp_cash
      );

      estimateData[1]["children"][0][colName] =
        this.dollarFormatedValue(VesselExpense);
      let veselexpnses = checkNanAndFormat(
        estimateData[1]["children"][0]["estimate"]
      );
      let veselexpnsac = checkNanAndFormat(
        estimateData[1]["children"][0]["actual"]
      );
      estimateData[1]["children"][0]["Diff"] = FirstVSSecond(
        veselexpnsac,
        veselexpnses
      );
      estimateData[1]["children"][0]["perDiff"] = perFirstVSSecond(
        veselexpnsac,
        veselexpnses
      );
      estimateData[1]["children"][0]["posted"] =
        this.dollarFormatedValue(hireExpenses_posted);
      estimateData[1]["children"][0]["cash_in"] =
        this.dollarFormatedValue(hireExpenses_cash);
      estimateData[1]["children"][0]["sec_variance"] = FirstVSSecond(
        hireExpenses_posted,
        hireExpenses_cash
      );
      estimateData[1]["children"][0]["sec_per"] = perFirstVSSecond(
        hireExpenses_posted,
        hireExpenses_cash
      );

      estimateData[1]["children"][1][colName] = this.dollarFormatedValue(
        amt_add_percentage.toFixed(2)
      );
      let tciaddcomes = checkNanAndFormat(
        estimateData[1]["children"][1]["estimate"]
      );
      let tciaddcomac = checkNanAndFormat(
        estimateData[1]["children"][1]["actual"]
      );
      estimateData[1]["children"][1]["Diff"] = FirstVSSecond(
        tciaddcomac,
        tciaddcomes
      );
      estimateData[1]["children"][1]["perDiff"] = perFirstVSSecond(
        tciaddcomac,
        tciaddcomes
      );
      estimateData[1]["children"][1]["posted"] =
        this.dollarFormatedValue(tciaddcom_posted);
      estimateData[1]["children"][1]["cash_in"] =
        this.dollarFormatedValue(tciaddcom_cash);
      estimateData[1]["children"][1]["sec_variance"] = FirstVSSecond(
        tciaddcom_posted,
        tciaddcom_cash
      );
      estimateData[1]["children"][1]["sec_per"] = perFirstVSSecond(
        tciaddcom_posted,
        tciaddcom_cash
      );

      estimateData[1]["children"][2][colName] = this.dollarFormatedValue(
        amt_bro_percentage.toFixed(2)
      );
      let tcibrocomes = checkNanAndFormat(
        estimateData[1]["children"][2]["estimate"]
      );
      let tcibrocomac = checkNanAndFormat(
        estimateData[1]["children"][2]["actual"]
      );
      estimateData[1]["children"][2]["Diff"] = FirstVSSecond(
        tcibrocomac,
        tcibrocomes
      );
      estimateData[1]["children"][2]["perDiff"] = perFirstVSSecond(
        tcibrocomac,
        tcibrocomes
      );
      estimateData[1]["children"][2]["posted"] =
        this.dollarFormatedValue(tcibcom_posted);
      estimateData[1]["children"][2]["cash_in"] =
        this.dollarFormatedValue(tcibcom_cash);
      estimateData[1]["children"][2]["sec_variance"] = FirstVSSecond(
        tcibcom_posted,
        tcibcom_cash
      );
      estimateData[1]["children"][2]["sec_per"] = perFirstVSSecond(
        tcibcom_posted,
        tcibcom_cash
      );

      estimateData[1]["children"][3][colName] = this.dollarFormatedValue(
        pi.toFixed(2)
      );
      let prtexpnses = checkNanAndFormat(
        estimateData[1]["children"][3]["estimate"]
      );
      let prtexpnsac = checkNanAndFormat(
        estimateData[1]["children"][3]["actual"]
      );
      estimateData[1]["children"][3]["Diff"] = FirstVSSecond(
        prtexpnsac,
        prtexpnses
      );
      estimateData[1]["children"][3]["perDiff"] = perFirstVSSecond(
        prtexpnsac,
        prtexpnses
      );
      estimateData[1]["children"][3]["posted"] =
        this.dollarFormatedValue(portExpenses_posted);
      estimateData[1]["children"][3]["cash_in"] =
        this.dollarFormatedValue(portExpenses_cash);
      estimateData[1]["children"][3]["sec_variance"] = FirstVSSecond(
        portExpenses_posted,
        portExpenses_cash
      );
      estimateData[1]["children"][3]["sec_per"] = perFirstVSSecond(
        portExpenses_posted,
        portExpenses_cash
      );

      estimateData[1]["children"][4][colName] =
        this.dollarFormatedValue(co2cost);
      let emmissionexpensees = checkNanAndFormat(
        estimateData[1]["children"][4]["estimate"]
      );
      let emmissionexpenseac = checkNanAndFormat(
        estimateData[1]["children"][4]["actual"]
      );
      estimateData[1]["children"][4]["Diff"] = FirstVSSecond(
        emmissionexpenseac,
        emmissionexpensees
      );
      estimateData[1]["children"][4]["perDiff"] = perFirstVSSecond(
        emmissionexpenseac,
        emmissionexpensees
      );
      estimateData[1]["children"][4]["posted"] = this.dollarFormatedValue(
        emmissionexpense_posted
      );
      estimateData[1]["children"][4]["cash_in"] = this.dollarFormatedValue(
        emmissionexpense_cash
      );
      estimateData[1]["children"][4]["sec_variance"] = FirstVSSecond(
        emmissionexpense_posted,
        emmissionexpense_cash
      );
      estimateData[1]["children"][4]["sec_per"] = perFirstVSSecond(
        emmissionexpense_posted,
        emmissionexpense_cash
      );

      estimateData[1]["children"][5][colName] = this.dollarFormatedValue(
        otherCost.toFixed(2)
      );
      let miscstes = checkNanAndFormat(
        estimateData[1]["children"][5]["estimate"]
      );
      let miscstac = checkNanAndFormat(
        estimateData[1]["children"][5]["actual"]
      );
      estimateData[1]["children"][5]["Diff"] = FirstVSSecond(
        miscstac,
        miscstes
      );
      estimateData[1]["children"][5]["perDiff"] = perFirstVSSecond(
        miscstac,
        miscstes
      );
      estimateData[1]["children"][5]["posted"] =
        this.dollarFormatedValue(miscExpenses_posted);
      estimateData[1]["children"][5]["cash_in"] =
        this.dollarFormatedValue(miscExpenses_cash);
      estimateData[1]["children"][5]["sec_variance"] = FirstVSSecond(
        miscExpenses_posted,
        miscExpenses_cash
      );
      estimateData[1]["children"][5]["sec_per"] = perFirstVSSecond(
        miscExpenses_posted,
        miscExpenses_cash
      );

      formData?.["othercostsummary"] &&
        formData?.["othercostsummary"].length > 0 &&
        formData?.["othercostsummary"]?.map((el, ind) => {
          let est = estimateData[1]["children"][5]["children"][ind]?.[
            "estimate"
          ]
            ? parseFloat(
                estimateData[1]["children"][5]["children"][ind]?.[
                  "estimate"
                ].replaceAll(",", "")
              )
            : 0;
          let act = el.amount;

          estimateData[1]["children"][5]["children"][ind] = {
            ...estimateData[1]["children"][5]["children"][ind],
            key: "ex15" + ind,
            description: el.description,
            actual: this.dollarFormatedValue(el.amount),
            Diff: FirstVSSecond(act, est),
            perDiff: perFirstVSSecond(act, est),
            posted: "0.00",
            cash_in: "0.00",
            sec_variance: "0.00",
            sec_per: "0.00",
          };
        });

      estimateData[1]["children"][6][colName] = dollarUSLocale.format(
        bunkerExpense.toFixed(2)
      );
      let bnkrexpnses = estimateData[1]["children"][6]["estimate"]
        ? estimateData[1]["children"][6]["estimate"]?.replaceAll(",", "") * 1
        : 0;
      let bnkrexpnsac = estimateData[1]["children"][6]["actual"]
        ? estimateData[1]["children"][6]["actual"]?.replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][6]["Diff"] = (
        bnkrexpnsac - bnkrexpnses
      ).toFixed(2);
      estimateData[1]["children"][6]["perDiff"] =
        bnkrexpnses === 0
          ? "0.00"
          : (((bnkrexpnsac - bnkrexpnses) / bnkrexpnses) * 100).toFixed(2);
      estimateData[1]["children"][6]["posted"] = bunkerExpenses_posted
        ? bunkerExpenses_posted
        : "0.00";
      estimateData[1]["children"][6]["cash_in"] = bunkerExpenses_cash
        ? bunkerExpenses_cash
        : "0.00";
      estimateData[1]["children"][6]["sec_variance"] = FirstVSSecond(
        bunkerExpenses_posted,
        bunkerExpenses_cash
      );
      estimateData[1]["children"][6]["sec_per"] = perFirstVSSecond(
        bunkerExpenses_posted,
        bunkerExpenses_cash
      );

      estimateData[1]["children"][6]["children"][0][colName] =
        dollarUSLocale.format(totalnonEcaSeacons.toFixed(2));
      let seacnsmpes = estimateData[1]["children"][6]["children"][0]["estimate"]
        ? estimateData[1]["children"][6]["children"][0]["estimate"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      let seacnsmpac = estimateData[1]["children"][6]["children"][0]["actual"]
        ? estimateData[1]["children"][6]["children"][0]["actual"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      estimateData[1]["children"][6]["children"][0]["Diff"] = (
        seacnsmpac - seacnsmpes
      ).toFixed(2);
      estimateData[1]["children"][6]["children"][0]["perDiff"] =
        seacnsmpes === 0
          ? "0.00"
          : (((seacnsmpac - seacnsmpes) / seacnsmpes) * 100).toFixed(2);
      estimateData[1]["children"][6]["children"][0]["posted"] = "0.00";
      estimateData[1]["children"][6]["children"][0]["cash_in"] = "0.00";
      estimateData[1]["children"][6]["children"][0]["sec_variance"] = "0.00";
      estimateData[1]["children"][6]["children"][0]["sec_per"] = "0.00";

      estimateData[1]["children"][6]["children"][0]["children"][0][colName] =
        this.dollarFormatedValue(nonEcaSeacons["ifo"]);
      let seaconsnonecaifoes = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][0]["children"][0]["estimate"]
      );
      let seaconsnonecaifoac = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][0]["children"][0]["actual"]
      );
      estimateData[1]["children"][6]["children"][0]["children"][0]["Diff"] =
        FirstVSSecond(seaconsnonecaifoac, seaconsnonecaifoes);
      estimateData[1]["children"][6]["children"][0]["children"][0]["perDiff"] =
        perFirstVSSecond(seaconsnonecaifoac, seaconsnonecaifoes);
      estimateData[1]["children"][6]["children"][0]["children"][0]["posted"] =
        this.dollarFormatedValue(seaconsnonecaifo_posted);
      estimateData[1]["children"][6]["children"][0]["children"][0]["cash_in"] =
        this.dollarFormatedValue(seaconsnonecaifo_cash);
      estimateData[1]["children"][6]["children"][0]["children"][0][
        "sec_variance"
      ] = FirstVSSecond(seaconsnonecaifo_posted, seaconsnonecaifo_cash);
      estimateData[1]["children"][6]["children"][0]["children"][0]["sec_per"] =
        perFirstVSSecond(seaconsnonecaifo_posted, seaconsnonecaifo_cash);

      estimateData[1]["children"][6]["children"][0]["children"][1][colName] =
        this.dollarFormatedValue(nonEcaSeacons["mgo"]);
      let seaconsnoneca_mgoes = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][0]["children"][1]["estimate"]
      );
      let seaconsnoneca_mgoac = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][0]["children"][1]["actual"]
      );
      estimateData[1]["children"][6]["children"][0]["children"][1]["Diff"] =
        FirstVSSecond(seaconsnoneca_mgoac, seaconsnoneca_mgoes);
      estimateData[1]["children"][6]["children"][0]["children"][1]["perDiff"] =
        perFirstVSSecond(seaconsnoneca_mgoac, seaconsnoneca_mgoes);
      estimateData[1]["children"][6]["children"][0]["children"][1]["posted"] =
        this.dollarFormatedValue(seaconsnonecamgo_posted);
      estimateData[1]["children"][6]["children"][0]["children"][1]["cash_in"] =
        this.dollarFormatedValue(seaconsnonecamgo_cash);
      estimateData[1]["children"][6]["children"][0]["children"][1][
        "sec_variance"
      ] = FirstVSSecond(seaconsnonecamgo_posted, seaconsnonecamgo_cash);
      estimateData[1]["children"][6]["children"][0]["children"][1]["sec_per"] =
        perFirstVSSecond(seaconsnonecamgo_posted, seaconsnonecamgo_cash);

      estimateData[1]["children"][6]["children"][0]["children"][2][colName] =
        this.dollarFormatedValue(nonEcaSeacons["vlsfo"]);
      let seaconsnoneca_vlsfoes = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][0]["children"][2]["estimate"]
      );
      let seaconsnoneca_vlsfoac = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][0]["children"][2]["actual"]
      );
      estimateData[1]["children"][6]["children"][0]["children"][2]["Diff"] =
        FirstVSSecond(seaconsnoneca_vlsfoac, seaconsnoneca_vlsfoes);
      estimateData[1]["children"][6]["children"][0]["children"][2]["perDiff"] =
        perFirstVSSecond(seaconsnoneca_vlsfoac, seaconsnoneca_vlsfoes);
      estimateData[1]["children"][6]["children"][0]["children"][2]["posted"] =
        this.dollarFormatedValue(seaconsnoneca_vlsfo_posted);
      estimateData[1]["children"][6]["children"][0]["children"][2]["cash_in"] =
        this.dollarFormatedValue(seaconsnoneca_vlsfo_cash);
      estimateData[1]["children"][6]["children"][0]["children"][2][
        "sec_variance"
      ] = FirstVSSecond(seaconsnoneca_vlsfo_posted, seaconsnoneca_vlsfo_cash);
      estimateData[1]["children"][6]["children"][0]["children"][2]["sec_per"] =
        perFirstVSSecond(seaconsnoneca_vlsfo_posted, seaconsnoneca_vlsfo_cash);

      estimateData[1]["children"][6]["children"][0]["children"][3][colName] =
        this.dollarFormatedValue(nonEcaSeacons["lsmgo"]);
      let seaconsnoneca_lsmgoes = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][0]["children"][3]["estimate"]
      );
      let seaconsnoneca_lsmgoac = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][0]["children"][3]["actual"]
      );
      estimateData[1]["children"][6]["children"][0]["children"][3]["Diff"] =
        FirstVSSecond(seaconsnoneca_lsmgoac, seaconsnoneca_lsmgoes);
      estimateData[1]["children"][6]["children"][0]["children"][3]["perDiff"] =
        perFirstVSSecond(seaconsnoneca_lsmgoac, seaconsnoneca_lsmgoes);
      estimateData[1]["children"][6]["children"][0]["children"][3]["posted"] =
        this.dollarFormatedValue(seaconsnoneca_lsmgo_posted);
      estimateData[1]["children"][6]["children"][0]["children"][3]["cash_in"] =
        this.dollarFormatedValue(seaconsnoneca_lsmgo_cash);
      estimateData[1]["children"][6]["children"][0]["children"][3][
        "sec_variance"
      ] = FirstVSSecond(seaconsnoneca_lsmgo_posted, seaconsnoneca_lsmgo_cash);
      estimateData[1]["children"][6]["children"][0]["children"][3]["sec_per"] =
        perFirstVSSecond(seaconsnoneca_lsmgo_posted, seaconsnoneca_lsmgo_cash);

      estimateData[1]["children"][6]["children"][0]["children"][4][colName] =
        this.dollarFormatedValue(nonEcaSeacons["ulsfo"]);
      let seaconsnoneca_ulsfo_es = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][0]["children"][4]["estimate"]
      );
      let seaconsnoneca_ulsfo_ac = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][0]["children"][4]["actual"]
      );
      estimateData[1]["children"][6]["children"][0]["children"][4]["Diff"] =
        FirstVSSecond(seaconsnoneca_ulsfo_ac, seaconsnoneca_ulsfo_es);
      estimateData[1]["children"][6]["children"][0]["children"][4]["perDiff"] =
        perFirstVSSecond(seaconsnoneca_ulsfo_ac, seaconsnoneca_ulsfo_es);
      estimateData[1]["children"][6]["children"][0]["children"][4]["posted"] =
        this.dollarFormatedValue(seaconsnoneca_ulsfo_posted);
      estimateData[1]["children"][6]["children"][0]["children"][4]["cash_in"] =
        this.dollarFormatedValue(seaconsnoneca_ulsfo_cash);
      estimateData[1]["children"][6]["children"][0]["children"][4][
        "sec_variance"
      ] = FirstVSSecond(seaconsnoneca_ulsfo_posted, seaconsnoneca_ulsfo_cash);
      estimateData[1]["children"][6]["children"][0]["children"][4]["sec_per"] =
        perFirstVSSecond(seaconsnoneca_ulsfo_posted, seaconsnoneca_ulsfo_cash);

      estimateData[1]["children"][6]["children"][1][colName] =
        dollarUSLocale.format(totalecaSeacons.toFixed(2));
      let ecaseacnsmpes = estimateData[1]["children"][6]["children"][1][
        "estimate"
      ]
        ? estimateData[1]["children"][6]["children"][1]["estimate"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      let ecaseacnsmpac = estimateData[1]["children"][6]["children"][1][
        "actual"
      ]
        ? estimateData[1]["children"][6]["children"][1]["actual"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      estimateData[1]["children"][6]["children"][1]["Diff"] = (
        ecaseacnsmpac - ecaseacnsmpes
      ).toFixed(2);
      estimateData[1]["children"][6]["children"][1]["perDiff"] =
        ecaseacnsmpes === 0
          ? "0.00"
          : (((ecaseacnsmpac - ecaseacnsmpes) / ecaseacnsmpes) * 100).toFixed(
              2
            );
      estimateData[1]["children"][6]["children"][1]["posted"] = "0.00";
      estimateData[1]["children"][6]["children"][1]["cash_in"] = "0.00";
      estimateData[1]["children"][6]["children"][1]["sec_variance"] = "0.00";
      estimateData[1]["children"][6]["children"][1]["sec_per"] = "0.00";

      estimateData[1]["children"][6]["children"][1]["children"][0][colName] =
        this.dollarFormatedValue(ecaSeacons["ifo"]);
      let seacons_eca_ifo_es = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][1]["children"][0]["estimate"]
      );
      let seacons_eca_ifo_ac = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][1]["children"][0]["actual"]
      );
      estimateData[1]["children"][6]["children"][1]["children"][0]["Diff"] =
        FirstVSSecond(seacons_eca_ifo_ac, seacons_eca_ifo_es);
      estimateData[1]["children"][6]["children"][1]["children"][0]["perDiff"] =
        perFirstVSSecond(seacons_eca_ifo_ac, seacons_eca_ifo_es);
      estimateData[1]["children"][6]["children"][1]["children"][0]["posted"] =
        this.dollarFormatedValue(seacons_eca_ifo_posted);
      estimateData[1]["children"][6]["children"][1]["children"][0]["cash_in"] =
        this.dollarFormatedValue(seacons_eca_ifo_cash);
      estimateData[1]["children"][6]["children"][1]["children"][0][
        "sec_variance"
      ] = FirstVSSecond(seacons_eca_ifo_posted, seacons_eca_ifo_cash);
      estimateData[1]["children"][6]["children"][1]["children"][0]["sec_per"] =
        perFirstVSSecond(seacons_eca_ifo_posted, seacons_eca_ifo_cash);

      estimateData[1]["children"][6]["children"][1]["children"][1][colName] =
        this.dollarFormatedValue(ecaSeacons["mgo"]);
      let seacons_eca_mgo_es = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][1]["children"][1]["estimate"]
      );
      let seacons_eca_mgo_ac = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][1]["children"][1]["actual"]
      );
      estimateData[1]["children"][6]["children"][1]["children"][1]["Diff"] =
        FirstVSSecond(seacons_eca_mgo_ac, seacons_eca_mgo_es);
      estimateData[1]["children"][6]["children"][1]["children"][1]["perDiff"] =
        perFirstVSSecond(seacons_eca_mgo_ac, seacons_eca_mgo_es);
      estimateData[1]["children"][6]["children"][1]["children"][1]["posted"] =
        this.dollarFormatedValue(seacons_eca_mgo_posted);
      estimateData[1]["children"][6]["children"][1]["children"][1]["cash_in"] =
        this.dollarFormatedValue(seacons_eca_mgo_cash);
      estimateData[1]["children"][6]["children"][1]["children"][1][
        "sec_variance"
      ] = FirstVSSecond(seacons_eca_mgo_posted, seacons_eca_mgo_cash);
      estimateData[1]["children"][6]["children"][1]["children"][1]["sec_per"] =
        perFirstVSSecond(seacons_eca_mgo_posted, seacons_eca_mgo_cash);

      estimateData[1]["children"][6]["children"][1]["children"][2][colName] =
        this.dollarFormatedValue(ecaSeacons["vlsfo"]);
      let seacons_eca_vlsfo_es = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][1]["children"][2]["estimate"]
      );
      let seacons_eca_vlsfo_ac = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][1]["children"][2]["actual"]
      );
      estimateData[1]["children"][6]["children"][1]["children"][2]["Diff"] =
        FirstVSSecond(seacons_eca_vlsfo_ac, seacons_eca_vlsfo_es);
      estimateData[1]["children"][6]["children"][1]["children"][2]["perDiff"] =
        perFirstVSSecond(seacons_eca_vlsfo_ac, seacons_eca_vlsfo_es);
      estimateData[1]["children"][6]["children"][1]["children"][2]["posted"] =
        this.dollarFormatedValue(seacons_eca_vlsfo_posted);
      estimateData[1]["children"][6]["children"][1]["children"][2]["cash_in"] =
        this.dollarFormatedValue(seacons_eca_vlsfo_cash);
      estimateData[1]["children"][6]["children"][1]["children"][2][
        "sec_variance"
      ] = FirstVSSecond(seacons_eca_vlsfo_posted, seacons_eca_vlsfo_cash);
      estimateData[1]["children"][6]["children"][1]["children"][2]["sec_per"] =
        perFirstVSSecond(seacons_eca_vlsfo_posted, seacons_eca_vlsfo_cash);

      estimateData[1]["children"][6]["children"][1]["children"][3][colName] =
        this.dollarFormatedValue(ecaSeacons["lsmgo"]);
      let seacons_eca_lsmgo_es = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][1]["children"][3]["estimate"]
      );
      let seacons_eca_lsmgo_ac = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][1]["children"][3]["actual"]
      );
      estimateData[1]["children"][6]["children"][1]["children"][3]["Diff"] =
        FirstVSSecond(seacons_eca_lsmgo_ac, seacons_eca_lsmgo_es);
      estimateData[1]["children"][6]["children"][1]["children"][3]["perDiff"] =
        perFirstVSSecond(seacons_eca_lsmgo_ac, seacons_eca_lsmgo_es);
      estimateData[1]["children"][6]["children"][1]["children"][3]["posted"] =
        this.dollarFormatedValue(seacons_eca_lsmgo_posted);
      estimateData[1]["children"][6]["children"][1]["children"][3]["cash_in"] =
        this.dollarFormatedValue(seacons_eca_lsmgo_cash);
      estimateData[1]["children"][6]["children"][1]["children"][3][
        "sec_variance"
      ] = FirstVSSecond(seacons_eca_lsmgo_posted, seacons_eca_lsmgo_cash);
      estimateData[1]["children"][6]["children"][1]["children"][3]["sec_per"] =
        perFirstVSSecond(seacons_eca_lsmgo_posted, seacons_eca_lsmgo_cash);

      estimateData[1]["children"][6]["children"][1]["children"][4][colName] =
        this.dollarFormatedValue(ecaSeacons["ulsfo"]);
      let seacons_eca_ulsfo_es = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][1]["children"][4]["estimate"]
      );
      let seacons_eca_ulsfo_ac = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][1]["children"][4]["actual"]
      );
      estimateData[1]["children"][6]["children"][1]["children"][4]["Diff"] =
        FirstVSSecond(seacons_eca_ulsfo_ac, seacons_eca_ulsfo_es);
      estimateData[1]["children"][6]["children"][1]["children"][4]["perDiff"] =
        perFirstVSSecond(seacons_eca_ulsfo_ac, seacons_eca_ulsfo_es);
      estimateData[1]["children"][6]["children"][1]["children"][4]["posted"] =
        this.dollarFormatedValue(seacons_eca_ulsfo_posted);
      estimateData[1]["children"][6]["children"][1]["children"][4]["cash_in"] =
        this.dollarFormatedValue(seacons_eca_ulsfo_cash);
      estimateData[1]["children"][6]["children"][1]["children"][4][
        "sec_variance"
      ] = FirstVSSecond(seacons_eca_ulsfo_posted, seacons_eca_ulsfo_cash);
      estimateData[1]["children"][6]["children"][1]["children"][4]["sec_per"] =
        perFirstVSSecond(seacons_eca_ulsfo_posted, seacons_eca_ulsfo_cash);

      estimateData[1]["children"][6]["children"][2][colName] =
        dollarUSLocale.format(totalnonecaPortcons.toFixed(2));
      let portcnsnonecampes = estimateData[1]["children"][6]["children"][2][
        "estimate"
      ]
        ? estimateData[1]["children"][6]["children"][2]["estimate"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      let portcnsnonecampac = estimateData[1]["children"][6]["children"][2][
        "actual"
      ]
        ? estimateData[1]["children"][6]["children"][2]["actual"].replaceAll(
            ",",
            ""
          ) * 1
        : 0;
      estimateData[1]["children"][6]["children"][2]["Diff"] = (
        portcnsnonecampac - portcnsnonecampes
      ).toFixed(2);
      estimateData[1]["children"][6]["children"][2]["perDiff"] =
        portcnsnonecampes === 0
          ? "0.00"
          : (
              ((portcnsnonecampac - portcnsnonecampes) / portcnsnonecampes) *
              100
            ).toFixed(2);
      estimateData[1]["children"][6]["children"][2]["posted"] = "0.00";
      estimateData[1]["children"][6]["children"][2]["cash_in"] = "0.00";
      estimateData[1]["children"][6]["children"][2]["sec_variance"] = "0.00";
      estimateData[1]["children"][6]["children"][2]["sec_per"] = "0.00";

      estimateData[1]["children"][6]["children"][2]["children"][0][colName] =
        this.dollarFormatedValue(nonecaPortcons["ifo"]);
      let portcons_non_ifoes = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][2]["children"][0]["estimate"]
      );
      let portcons_non_ifoac = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][2]["children"][0]["actual"]
      );
      estimateData[1]["children"][6]["children"][2]["children"][0]["Diff"] =
        FirstVSSecond(portcons_non_ifoac, portcons_non_ifoes);
      estimateData[1]["children"][6]["children"][2]["children"][0]["perDiff"] =
        perFirstVSSecond(portcons_non_ifoac, portcons_non_ifoes);
      estimateData[1]["children"][6]["children"][2]["children"][0]["posted"] =
        this.dollarFormatedValue(portcon_noneca_ifo_posted);
      estimateData[1]["children"][6]["children"][2]["children"][0]["cash_in"] =
        this.dollarFormatedValue(portcon_noneca_ifo_cash);
      estimateData[1]["children"][6]["children"][2]["children"][0][
        "sec_variance"
      ] = FirstVSSecond(portcon_noneca_ifo_posted, portcon_noneca_ifo_cash);
      estimateData[1]["children"][6]["children"][2]["children"][0]["sec_per"] =
        perFirstVSSecond(portcon_noneca_ifo_posted, portcon_noneca_ifo_cash);

      estimateData[1]["children"][6]["children"][2]["children"][1][colName] =
        this.dollarFormatedValue(nonecaPortcons["mgo"]);
      let portcon_noneca_mgo_es = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][2]["children"][1]["estimate"]
      );
      let portcon_noneca_mgo_ac = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][2]["children"][1]["actual"]
      );
      estimateData[1]["children"][6]["children"][2]["children"][1]["Diff"] =
        FirstVSSecond(portcon_noneca_mgo_ac, portcon_noneca_mgo_es);
      estimateData[1]["children"][6]["children"][2]["children"][1]["perDiff"] =
        perFirstVSSecond(portcon_noneca_mgo_ac, portcon_noneca_mgo_es);
      estimateData[1]["children"][6]["children"][2]["children"][1]["posted"] =
        this.dollarFormatedValue(portcon_noneca_mgo_posted);
      estimateData[1]["children"][6]["children"][2]["children"][1]["cash_in"] =
        this.dollarFormatedValue(portcon_noneca_mgo_cash);
      estimateData[1]["children"][6]["children"][2]["children"][1][
        "sec_variance"
      ] = FirstVSSecond(portcon_noneca_mgo_posted, portcon_noneca_mgo_cash);
      estimateData[1]["children"][6]["children"][2]["children"][1]["sec_per"] =
        perFirstVSSecond(portcon_noneca_mgo_posted, portcon_noneca_mgo_cash);

      estimateData[1]["children"][6]["children"][2]["children"][2][colName] =
        this.dollarFormatedValue(nonecaPortcons["vlsfo"]);
      let portcon_noneca_vlsfo_es = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][2]["children"][2]["estimate"]
      );
      let portcon_noneca_vlsfo_ac = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][2]["children"][2]["actual"]
      );
      estimateData[1]["children"][6]["children"][2]["children"][2]["Diff"] =
        FirstVSSecond(portcon_noneca_vlsfo_ac, portcon_noneca_vlsfo_es);
      estimateData[1]["children"][6]["children"][2]["children"][2]["perDiff"] =
        perFirstVSSecond(portcon_noneca_vlsfo_ac, portcon_noneca_vlsfo_es);
      estimateData[1]["children"][6]["children"][2]["children"][2]["posted"] =
        this.dollarFormatedValue(portcon_noneca_vlsfo_posted);
      estimateData[1]["children"][6]["children"][2]["children"][2]["cash_in"] =
        this.dollarFormatedValue(portcon_noneca_vlsfo_cash);
      estimateData[1]["children"][6]["children"][2]["children"][2][
        "sec_variance"
      ] = FirstVSSecond(portcon_noneca_vlsfo_posted, portcon_noneca_vlsfo_cash);
      estimateData[1]["children"][6]["children"][2]["children"][2]["sec_per"] =
        perFirstVSSecond(
          portcon_noneca_vlsfo_posted,
          portcon_noneca_vlsfo_cash
        );

      estimateData[1]["children"][6]["children"][2]["children"][3][colName] =
        this.dollarFormatedValue(nonecaPortcons["lsmgo"]);
      let portcon_noneca_lsmgo_es = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][2]["children"][3]["estimate"]
      );
      let portcon_noneca_lsmgo_ac = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][2]["children"][3]["actual"]
      );
      estimateData[1]["children"][6]["children"][2]["children"][3]["Diff"] =
        FirstVSSecond(portcon_noneca_lsmgo_ac, portcon_noneca_lsmgo_es);
      estimateData[1]["children"][6]["children"][2]["children"][3]["perDiff"] =
        perFirstVSSecond(portcon_noneca_lsmgo_ac, portcon_noneca_lsmgo_es);
      estimateData[1]["children"][6]["children"][2]["children"][3]["posted"] =
        this.dollarFormatedValue(portcon_noneca_lsmgo_posted);
      estimateData[1]["children"][6]["children"][2]["children"][3]["cash_in"] =
        this.dollarFormatedValue(portcon_noneca_lsmgo_cash);
      estimateData[1]["children"][6]["children"][2]["children"][3][
        "sec_variance"
      ] = FirstVSSecond(portcon_noneca_lsmgo_posted, portcon_noneca_lsmgo_cash);
      estimateData[1]["children"][6]["children"][2]["children"][3]["sec_per"] =
        perFirstVSSecond(
          portcon_noneca_lsmgo_posted,
          portcon_noneca_lsmgo_cash
        );

      estimateData[1]["children"][6]["children"][2]["children"][4][colName] =
        this.dollarFormatedValue(nonecaPortcons["ulsfo"]);
      let portcon_noneca_ulsfo_es = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][2]["children"][4]["estimate"]
      );
      let portcon_noneca_ulsfo_ac = checkNanAndFormat(
        estimateData[1]["children"][6]["children"][2]["children"][4]["actual"]
      );
      estimateData[1]["children"][6]["children"][2]["children"][4]["Diff"] =
        FirstVSSecond(portcon_noneca_ulsfo_ac, portcon_noneca_ulsfo_es);
      estimateData[1]["children"][6]["children"][2]["children"][4]["perDiff"] =
        perFirstVSSecond(portcon_noneca_ulsfo_ac, portcon_noneca_ulsfo_es);
      estimateData[1]["children"][6]["children"][2]["children"][4]["posted"] =
        this.dollarFormatedValue(portcon_noneca_ulsfo_posted);
      estimateData[1]["children"][6]["children"][2]["children"][4]["cash_in"] =
        this.dollarFormatedValue(portcon_noneca_ulsfo_cash);
      estimateData[1]["children"][6]["children"][2]["children"][4][
        "sec_variance"
      ] = FirstVSSecond(portcon_noneca_ulsfo_posted, portcon_noneca_ulsfo_cash);
      estimateData[1]["children"][6]["children"][2]["children"][4]["sec_per"] =
        perFirstVSSecond(
          portcon_noneca_ulsfo_posted,
          portcon_noneca_ulsfo_cash
        );

      estimateData[1]["children"][6]["children"][3]["estimate"] = "0.00";
      estimateData[1]["children"][6]["children"][3]["actual"] = estimateData[1][
        "children"
      ][6]["children"][3]["actual"]
        ? estimateData[1]["children"][6]["children"][3]["actual"]
        : "0.00";
      estimateData[1]["children"][6]["children"][3]["posted"] = "0.00";
      estimateData[1]["children"][6]["children"][3]["cash_in"] = "0.00";
      estimateData[1]["children"][6]["children"][3]["Diff"] = "0.00";
      estimateData[1]["children"][6]["children"][3]["perDiff"] = "0.00";
      estimateData[1]["children"][6]["children"][3]["sec_variance"] = "0.00";
      estimateData[1]["children"][6]["children"][3]["sec_per"] = "0.00";

      estimateData[1]["children"][6]["children"][3]["children"][0]["estimate"] =
        estimateData[1]["children"][6]["children"][3]["children"][0]["estimate"]
          ? estimateData[1]["children"][6]["children"][3]["children"][0][
              "estimate"
            ]
          : "0.00";
      estimateData[1]["children"][6]["children"][3]["children"][0]["actual"] =
        estimateData[1]["children"][6]["children"][3]["children"][0]["actual"]
          ? estimateData[1]["children"][6]["children"][3]["children"][0][
              "actual"
            ]
          : "0.00";
      estimateData[1]["children"][6]["children"][3]["children"][0]["posted"] =
        estimateData[1]["children"][6]["children"][3]["children"][0]["posted"]
          ? estimateData[1]["children"][6]["children"][3]["children"][0][
              "posted"
            ]
          : "0.00";
      estimateData[1]["children"][6]["children"][3]["children"][0]["cash_in"] =
        estimateData[1]["children"][6]["children"][3]["children"][0]["cash_in"]
          ? estimateData[1]["children"][6]["children"][3]["children"][0][
              "cash_in"
            ]
          : "0.00";
      estimateData[1]["children"][6]["children"][3]["children"][0]["Diff"] =
        estimateData[1]["children"][6]["children"][3]["children"][0]["Diff"]
          ? estimateData[1]["children"][6]["children"][3]["children"][0]["Diff"]
          : "0.00";
      estimateData[1]["children"][6]["children"][3]["children"][0]["perDiff"] =
        estimateData[1]["children"][6]["children"][3]["children"][0]["perDiff"]
          ? estimateData[1]["children"][6]["children"][3]["children"][0][
              "perDiff"
            ]
          : "0.00";
      estimateData[1]["children"][6]["children"][3]["children"][0][
        "sec_variance"
      ] = estimateData[1]["children"][6]["children"][3]["children"][0][
        "sec_variance"
      ]
        ? estimateData[1]["children"][6]["children"][3]["children"][0][
            "sec_variance"
          ]
        : "0.00";
      estimateData[1]["children"][6]["children"][3]["children"][0]["sec_per"] =
        estimateData[1]["children"][6]["children"][3]["children"][0]["sec_per"]
          ? estimateData[1]["children"][6]["children"][3]["children"][0][
              "sec_per"
            ]
          : "0.00";

      estimateData[1]["children"][7][colName] = this.dollarFormatedValue(
        grossExpenses.toFixed(2)
      );
      let grossExpest = estimateData[1]["children"][7]["estimate"]
        ? estimateData[1]["children"][7]["estimate"]?.replaceAll(",", "") * 1
        : 0;
      let grossExpact = estimateData[1]["children"][7]["actual"]
        ? estimateData[1]["children"][7]["actual"]?.replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][7]["Diff"] = FirstVSSecond(
        grossExpact,
        grossExpest
      );
      estimateData[1]["children"][7]["perDiff"] = perFirstVSSecond(
        grossExpact,
        grossExpest
      );
      estimateData[1]["children"][7]["posted"] =
        this.dollarFormatedValue(grossExpenses_posted);
      estimateData[1]["children"][7]["cash_in"] =
        this.dollarFormatedValue(grossExpenses_cash);
      estimateData[1]["children"][7]["sec_variance"] = FirstVSSecond(
        grossExpenses_posted,
        grossExpenses_cash
      );
      estimateData[1]["children"][7]["sec_per"] = perFirstVSSecond(
        grossExpenses_posted,
        grossExpenses_cash
      );

      estimateData[1]["children"][8][colName] = this.dollarFormatedValue(
        netExpenses.toFixed(2)
      );
      let netExpest = estimateData[1]["children"][8]["estimate"]
        ? estimateData[1]["children"][8]["estimate"]?.replaceAll(",", "") * 1
        : 0;
      let netExpact = estimateData[1]["children"][8]["actual"]
        ? estimateData[1]["children"][8]["actual"]?.replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][8]["Diff"] = FirstVSSecond(
        netExpact,
        netExpest
      );
      estimateData[1]["children"][8]["perDiff"] = perFirstVSSecond(
        netExpact,
        netExpest
      );
      estimateData[1]["children"][8]["posted"] =
        this.dollarFormatedValue(netExpenses_posted);
      estimateData[1]["children"][8]["cash_in"] =
        this.dollarFormatedValue(netExpenses_cash);
      estimateData[1]["children"][8]["sec_variance"] = FirstVSSecond(
        netExpenses_posted,
        netExpenses_cash
      );
      estimateData[1]["children"][8]["sec_per"] = perFirstVSSecond(
        netExpenses_posted,
        netExpenses_cash
      );

      let itemValue = netRevenue - netExpenses;
      let tceHire = (
        (netRevenue -
          (grossExpenses -
            (totalVoyageDays * hire + otherCost - amt_add_percentage))) /
        totalVoyageDays
      ).toFixed(2);

      estimateData[2][colName] = itemValue
        ? this.dollarFormatedValue(itemValue)
        : "0.00";
      estimateData[2]["children"][0][colName] = itemValue
        ? this.dollarFormatedValue(itemValue)
        : "0.00";
      let vygreses = estimateData[2]["estimate"]
        ? estimateData[2]["estimate"]?.replaceAll(",", "") * 1
        : 0;
      let vygresac = estimateData[2]["actual"]
        ? estimateData[2]["actual"]?.replaceAll(",", "") * 1
        : 0;
      estimateData[2]["Diff"] = (vygresac - vygreses).toFixed(2);
      estimateData[2]["perDiff"] = (
        ((vygresac - vygreses) / vygreses) *
        100
      ).toFixed(2);
      estimateData[2]["posted"] = "0.00";
      estimateData[2]["cash_in"] = "0.00";
      estimateData[2]["sec_variance"] = "0.00";
      estimateData[2]["sec_per"] = "0.00";

      let profitlses = checkNanAndFormat(
        estimateData[2]["children"][0]["estimate"]
      );
      let profitlsac = checkNanAndFormat(
        estimateData[2]["children"][0]["actual"]
      );
      estimateData[2]["children"][0]["Diff"] = FirstVSSecond(
        profitlsac,
        profitlses
      );
      estimateData[2]["children"][0]["perDiff"] = perFirstVSSecond(
        profitlsac,
        profitlses
      );
      estimateData[2]["children"][0]["posted"] = "0.00";
      estimateData[2]["children"][0]["cash_in"] = "0.00";
      estimateData[2]["children"][0]["sec_variance"] = "0.00";
      estimateData[2]["children"][0]["sec_per"] = "0.00";

      estimateData[2]["children"][1][colName] = this.dollarFormatedValue(
        (itemValue / (totalVoyageDays - 0)).toFixed(2)
      );
      let dlprofitlses = checkNanAndFormat(
        estimateData[2]["children"][1]["estimate"]
      );
      let dlprofitlsac = checkNanAndFormat(
        estimateData[2]["children"][1]["actual"]
      );
      estimateData[2]["children"][1]["Diff"] = FirstVSSecond(
        dlprofitlsac,
        dlprofitlses
      );
      estimateData[2]["children"][1]["perDiff"] = perFirstVSSecond(
        dlprofitlsac,
        dlprofitlses
      );
      estimateData[2]["children"][1]["posted"] = "0.00";
      estimateData[2]["children"][1]["cash_in"] = "0.00";
      estimateData[2]["children"][1]["sec_variance"] = "0.00";
      estimateData[2]["children"][1]["sec_per"] = "0.00";

      estimateData[2]["children"][2][colName] =
        this.dollarFormatedValue(tceHire);
      let tcehirees = checkNanAndFormat(
        estimateData[2]["children"][2]["estimate"]
      );
      let tcehireac = checkNanAndFormat(
        estimateData[2]["children"][2]["actual"]
      );
      estimateData[2]["children"][2]["Diff"] = FirstVSSecond(
        tcehireac,
        tcehirees
      );
      estimateData[2]["children"][2]["perDiff"] = perFirstVSSecond(
        tcehireac,
        tcehirees
      );
      estimateData[2]["children"][2]["posted"] = "0.00";
      estimateData[2]["children"][2]["cash_in"] = "0.00";
      estimateData[2]["children"][2]["sec_variance"] = "0.00";
      estimateData[2]["children"][2]["sec_per"] = "0.00";

      estimateData[2]["children"][3][colName] = this.dollarFormatedValue(
        (addPercentage >= 0 && totalVoyageDays > 0
          ? tceHire / (1 - addPercentage * 0.01)
          : 0
        ).toFixed(2)
      );
      let grstcehirees = checkNanAndFormat(
        estimateData[2]["children"][3]["estimate"]
      );
      let grstcehireac = checkNanAndFormat(
        estimateData[2]["children"][3]["actual"]
      );
      estimateData[2]["children"][3]["Diff"] = FirstVSSecond(
        grstcehireac,
        grstcehirees
      );
      estimateData[2]["children"][3]["perDiff"] = perFirstVSSecond(
        grstcehireac,
        grstcehirees
      );
      estimateData[2]["children"][3]["posted"] = "0.00";
      estimateData[2]["children"][3]["cash_in"] = "0.00";
      estimateData[2]["children"][3]["sec_variance"] = "0.00";
      estimateData[2]["children"][3]["sec_per"] = "0.00";

      estimateData[2]["children"][4][colName] =
        ((0 + netExpenses) / cpQtyOpt).toFixed(2) >= 0
          ? dollarUSLocale.format(((0 + netExpenses) / cpQtyOpt).toFixed(2))
          : "(" +
            dollarUSLocale.format(
              isNaN(((0 + netExpenses) / cpQtyOpt) * 1)
                ? 0
                : ((netExpenses / cpQtyOpt) * 1).toFixed(2)
            ) +
            ")";

      let avfrtes = checkNanAndFormat(
        estimateData[2]["children"][4]["estimate"]
      );
      let avfrtac = checkNanAndFormat(estimateData[2]["children"][4]["actual"]);
      estimateData[2]["children"][4]["Diff"] = FirstVSSecond(avfrtac, avfrtes);
      estimateData[2]["children"][4]["perDiff"] = perFirstVSSecond(
        avfrtac,
        avfrtes
      );
      estimateData[2]["children"][4]["posted"] = "0.00";
      estimateData[2]["children"][4]["cash_in"] = "0.00";
      estimateData[2]["children"][4]["sec_variance"] = "0.00";
      estimateData[2]["children"][4]["sec_per"] = "0.00";

      estimateData[2]["children"][5][colName] =
        ((0 + netExpenses) / cpQtyOpt).toFixed(2) >= 0
          ? dollarUSLocale.format(((0 + netExpenses) / cpQtyOpt).toFixed(2))
          : "(" +
            dollarUSLocale.format(
              isNaN(((0 + netExpenses) / cpQtyOpt) * 1)
                ? 0
                : ((netExpenses / cpQtyOpt) * 1).toFixed(2)
            ) +
            ")";
      let brkevnfrtes = checkNanAndFormat(
        estimateData[2]["children"][5]["estimate"]
      );
      let brkevnfrtac = checkNanAndFormat(
        estimateData[2]["children"][5]["actual"]
      );
      estimateData[2]["children"][5]["Diff"] = FirstVSSecond(
        brkevnfrtac,
        brkevnfrtes
      );
      estimateData[2]["children"][5]["perDiff"] = perFirstVSSecond(
        brkevnfrtac,
        brkevnfrtes
      );
      estimateData[2]["children"][5]["posted"] = "0.00";
      estimateData[2]["children"][5]["cash_in"] = "0.00";
      estimateData[2]["children"][5]["sec_variance"] = "0.00";
      estimateData[2]["children"][5]["sec_per"] = "0.00";

      estimateData[2]["children"][6][colName] = this.dollarFormatedValue(
        co2cost * 1
      );
      let co2costes = checkNanAndFormat(
        estimateData[2]["children"][6]["estimate"]
      );
      let co2costac = checkNanAndFormat(
        estimateData[2]["children"][6]["actual"]
      );
      estimateData[2]["children"][6]["Diff"] = FirstVSSecond(
        co2costac,
        co2costes
      );
      estimateData[2]["children"][6]["perDiff"] = perFirstVSSecond(
        co2costac,
        co2costes
      );
      estimateData[2]["children"][6]["posted"] = "0.00";
      estimateData[2]["children"][6]["cash_in"] = "0.00";
      estimateData[2]["children"][6]["sec_variance"] = "0.00";
      estimateData[2]["children"][6]["sec_per"] = "0.00";

      estimateData[2]["children"][7][colName] = this.dollarFormatedValue(
        co2_adjusted_profit * 1
      );
      let co2costadjpes = checkNanAndFormat(
        estimateData[2]["children"][7]["estimate"]
      );
      let co2costadjpac = checkNanAndFormat(
        estimateData[2]["children"][7]["actual"]
      );
      estimateData[2]["children"][7]["Diff"] = FirstVSSecond(
        co2costadjpac,
        co2costadjpes
      );
      estimateData[2]["children"][7]["perDiff"] = perFirstVSSecond(
        co2costadjpac,
        co2costadjpes
      );
      estimateData[2]["children"][7]["posted"] = "0.00";
      estimateData[2]["children"][7]["cash_in"] = "0.00";
      estimateData[2]["children"][7]["sec_variance"] = "0.00";
      estimateData[2]["children"][7]["sec_per"] = "0.00";

      estimateData[2]["children"][8][colName] = this.dollarFormatedValue(
        co2_adjusted_tce * 1
      );
      let co2costadjtcees = checkNanAndFormat(
        estimateData[2]["children"][8]["estimate"]
      );
      let co2costadjtceac = checkNanAndFormat(
        estimateData[2]["children"][8]["actual"]
      );
      estimateData[2]["children"][8]["Diff"] = FirstVSSecond(
        co2costadjtceac,
        co2costadjtcees
      );
      estimateData[2]["children"][8]["perDiff"] = perFirstVSSecond(
        co2costadjtceac,
        co2costadjtcees
      );
      estimateData[2]["children"][8]["posted"] = "0.00";
      estimateData[2]["children"][8]["cash_in"] = "0.00";
      estimateData[2]["children"][8]["sec_variance"] = "0.00";
      estimateData[2]["children"][8]["sec_per"] = "0.00";

      estimateData[2]["children"][9][colName] = this.dollarFormatedValue(
        tsd * 1
      );
      let tsdes = checkNanAndFormat(estimateData[2]["children"][9]["estimate"]);
      let tsdac = checkNanAndFormat(estimateData[2]["children"][9]["actual"]);
      estimateData[2]["children"][9]["Diff"] = FirstVSSecond(tsdac, tsdes);
      estimateData[2]["children"][9]["perDiff"] = perFirstVSSecond(
        tsdac,
        tsdes
      );
      estimateData[2]["children"][9]["posted"] = "0.00";
      estimateData[2]["children"][9]["cash_in"] = "0.00";
      estimateData[2]["children"][9]["sec_variance"] = "0.00";
      estimateData[2]["children"][9]["sec_per"] = "0.00";

      estimateData[2]["children"][10][colName] = this.dollarFormatedValue(
        tpd * 1
      );
      let tpdes = checkNanAndFormat(
        estimateData[2]["children"][10]["estimate"]
      );
      let tpdac = checkNanAndFormat(estimateData[2]["children"][10]["actual"]);
      estimateData[2]["children"][10]["Diff"] = FirstVSSecond(tpdac, tpdes);
      estimateData[2]["children"][10]["perDiff"] = perFirstVSSecond(
        tpdac,
        tpdes
      );
      estimateData[2]["children"][10]["posted"] = "0.00";
      estimateData[2]["children"][10]["cash_in"] = "0.00";
      estimateData[2]["children"][10]["sec_variance"] = "0.00";
      estimateData[2]["children"][10]["sec_per"] = "0.00";

      estimateData[2]["children"][11][colName] = this.dollarFormatedValue(
        totalVoyageDays * 1
      );
      let netvygdses = checkNanAndFormat(
        estimateData[2]["children"][11]["estimate"]
      );
      let netvygdsac = checkNanAndFormat(
        estimateData[2]["children"][11]["actual"]
      );
      estimateData[2]["children"][11]["Diff"] = FirstVSSecond(
        netvygdsac,
        netvygdses
      );
      estimateData[2]["children"][11]["perDiff"] = perFirstVSSecond(
        netvygdsac,
        netvygdses
      );
      estimateData[2]["children"][11]["posted"] = "0.00";
      estimateData[2]["children"][11]["cash_in"] = "0.00";
      estimateData[2]["children"][11]["sec_variance"] = "0.00";
      estimateData[2]["children"][11]["sec_per"] = "0.00";
      let tci_off_Hire_Days =
        estimateDatavalue?.off_hire_days?.tci_offhire_invoice;
      let tco_off_Hire_Days =
        estimateDatavalue?.off_hire_days?.tco_offhire_invoice;
      let off_Hire_Days = Number(tci_off_Hire_Days) + Number(tco_off_Hire_Days);
      estimateData[2]["children"][12][colName] =
        this.dollarFormatedValue(off_Hire_Days);
      estimateData[2]["children"][12]["Diff"] = "0.00";
      estimateData[2]["children"][12]["perDiff"] = "0.00";
      estimateData[2]["children"][12]["posted"] = "0.00";
      estimateData[2]["children"][12]["cash_in"] = "0.00";
      estimateData[2]["children"][12]["sec_variance"] = "0.00";
      estimateData[2]["children"][12]["sec_per"] = "0.00";

      estimateData[2]["children"][13][colName] = this.dollarFormatedValue(
        totalVoyageDays - off_Hire_Days
      );
      estimateData[2]["children"][13]["Diff"] = "0.00";
      estimateData[2]["children"][13]["perDiff"] = "0.00";
      estimateData[2]["children"][13]["posted"] = "0.00";
      estimateData[2]["children"][13]["cash_in"] = "0.00";
      estimateData[2]["children"][13]["sec_variance"] = "0.00";
      estimateData[2]["children"][13]["sec_per"] = "0.00";

      estimateData[0]["children"][0]["children"] = [
        ...estimateDatavalue["freight_invoices"],
      ];
      estimateData[0]["children"][1]["children"] = [
        ...estimateDatavalue["freight_commission_invoices"],
      ];
      estimateData[0]["children"][2]["children"] = [
        ...estimateDatavalue["other_revenue_invoices"],
      ];
      estimateData[0]["children"][3]["children"] = [
        ...estimateDatavalue["demurrage_amt_claim_invoices"],
      ];
      estimateData[0]["children"][5]["children"] = [
        ...estimateDatavalue["dispatch_amt_claim_invoices"],
      ];
      estimateData[1]["children"][0]["children"] = [
        ...estimateDatavalue["vessel_expenses_invoices"],
      ];
      estimateData[1]["children"][3]["children"] = [
        ...estimateDatavalue["port_expense_invoices"],
      ];
      // estimateData[1]["children"][5]["children"] = [
      //   ...estimateData[1]["children"][5]["children"],
      //   ...estimateDatavalue["other_expense_invoices"],
      // ];

      // estimateData[1]["children"][6]["children"] = [
      //   ...estimateData[1]["children"][6]["children"],
      //   ...estimateDatavalue["bunker_expense_invoices"],
      // ];

      estimateData[1]["children"][5]["children"] = [
        ...estimateData[1]["children"][5]["children"],
        ...estimateDatavalue["other_expense_invoices"].filter(
          (newItem) =>
            !estimateData[1]["children"][5]["children"].some(
              (existingItem) => existingItem.description === newItem.description
            )
        ),
      ];
      estimateData[1]["children"][6]["children"] = [
        ...estimateData[1]["children"][6]["children"],
        ...estimateDatavalue["bunker_expense_invoices"].filter(
          (newItem) =>
            !estimateData[1]["children"][6]["children"].some(
              (existingItem) => existingItem.description === newItem.description
            )
        ),
      ];

      revenue = {
        netRevenue,
        grossRevenue,
        freightCommission,
        dem_com_amt,
        tpd,
        pi,
        fr,
        mr,
        demmurage_amt,
        desmurage_amt,
        lumpsum,
        des_com_amt,
        dispatch,
        grossExpenses,
        cpQty,
        co2_adjusted_profit,
        co2cost,
        co2_adjusted_tce,
        grossExpenses,
        amt_add_percentage,
        amt_bro_percentage,
        netExpenses,
        totalVoyageDays,
        hire,
        VesselExpense,
        addPercentage,
        broPercentage,
        pi,
        ecaPortCons,
        totalecaSeacons,
        totalnonEcaSeacons,
        totalecaPortCons,
        totalnonecaPortcons,
        bunkerExpense,
        itemValue,
        tceHire,
        cpQtyOpt,
        ecaSeacons,
        nonEcaSeacons,
        nonecaPortcons,
        ecaPortCons,
      };

      return { estimateData, revenue };
    } catch (err) {
      console.log("err", err);
      openNotificationWithIcon("error", "Something went wrong", 3);
    }
  };

  handleClick = async () => {
    const element = document.querySelector(
      ".ant-table-row-expand-icon-collapsed"
    );

    if (element) {
      await element.click();
    }
  };

  savePNL = async () => {
    const _id = this.state?.formData?.id;
    this.setState({ ...this.state, saveLoading: true, showPL: false });
    const currentUser = localStorage.getItem("currentUser");
    const token = localStorage.getItem("oceanToken");

    const currentTime = moment(new Date()).format("YYYY-MM-DD,HH:mm:ss");
    const dropdownUser =
      "|" + currentTime + "|" + _id.toString() + "|" + currentUser;

    const savePayload = {
      pnl_data: this.state.formData,
      remark: this.state.remark,
      user: dropdownUser,
      created_at: currentTime,
      voyage_no: _id,
    };

    const myurl = `${URL_WITH_VERSION}/voyage-manager/voyage-pnl/save`;
    try {
      const response = await fetch(myurl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(savePayload),
      });

      const data = await response.json();
      if (data.data) {
        await this.getSavedPNLList(true);
        openNotificationWithIcon("info", data.message);
      } else {
        openNotificationWithIcon("error", data.message);
      }
    } catch (err) {
      console.log("err", err);
      openNotificationWithIcon("error", "Something went wrong", 3);
    }
  };

  // componentDidMount() {
  //   const pl = this.__pl();
  //   this.setState(
  //     (prevState) => ({
  //       ...prevState,
  //       showPL: false,
  //       estimateData: pl?.estimateData,
  //       tooltipData: pl?.revenue,
  //     }),
  //     async () => {
  //       await this.getSavedPNLList();
  //       this.setState((prevState) => ({
  //         ...prevState,
  //         showPL: true,
  //       }));
  //     }
  //   );
  // }

  componentDidUpdate(prevProps, prevState) {
    let reduxData = redux_state.reduxStore?.["voyage_manager_form"];
    const prevFormData = JSON.parse(localStorage.getItem("prevFormData"));
    if (JSON.stringify(prevFormData) !== JSON.stringify(reduxData)) {
      // console.log("hiii787878");
      localStorage?.setItem("prevFormData", JSON.stringify(reduxData));
    }
  }

  checkForUpdates(prevProps, prevState) {
    let reduxData = redux_state.reduxStore?.["voyage_manager_form"];
    const prevFormData = JSON.parse(localStorage?.getItem("prevFormData"));
    if (JSON.stringify(reduxData) !== JSON.stringify(prevFormData)) {
      const pl = this.__pl();
      this.setState(
        (prev) => ({
          ...prev,
          formData: reduxData,
          estimateData: pl?.estimateData,
          tooltipData: pl?.revenue,
        }),
        async () => {
          await this.getSavedPNLList();
          // this.setState((prevState) => ({
          //   ...prevState,
          //   // showPL: true,
          // }));
        }
      );
    }
  }

  componentDidMount() {
    const pl = this.__pl();
    this.setState(
      (prevState) => ({
        ...prevState,
        showPL: false,
        estimateData: pl?.estimateData,
        tooltipData: pl?.revenue,
      }),
      async () => {
        await this.getSavedPNLList();
        this.setState((prevState) => ({
          ...prevState,
          showPL: true,
        }));
      }
    );

    this.updateInterval = setInterval(() => {
      this.checkForUpdates();
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval);
    localStorage.removeItem("prevFormData");
  }

  getSavedPNLList = async (calledAfterSave = false) => {
    const _id = this.props.formData.id;
    try {
      let qParamString = objectToQueryStringFunc({ ae: _id });
      let url = `${URL_WITH_VERSION}/voyage-manager/voyage-pnl/drop-list?l=0&p=1&${qParamString}`;
      const response = await getAPICall(url);
      if (response.data.length > 0) {
        const selectOptionsList = [
          {
            value: "actual",
            label: "Actual",
          },
          ...response?.data?.map((item) => ({
            label: item.pnl_label,
            value: item.id,
          })),
        ];

        if (calledAfterSave) {
          this.setState(
            {
              ...this.state,
              showPL: false,
              saveLoading: false,
              selectOptions: selectOptionsList,
            },
            () => this.setState({ ...this.state, showPL: true })
          );
        } else {
          this.setState({ ...this.state, selectOptions: selectOptionsList });
        }
      }
    } catch (err) {
      console.log("err", err);
      openNotificationWithIcon("error", "Something went wrong", 3);
    }
  };

  handleSelectChange = async (value) => {
    this.setState({ ...this.state, showPL: false });
    let changedFormData = {};
    let disableBtn = true;
    let updatedRemark = "";
    if (value === "actual") {
      changedFormData = this.props.formData;
      disableBtn = false;
    } else {
      const _id = this.props.formData.id;
      try {
        let qParamString = objectToQueryStringFunc({ ae: value });
        let url = `${URL_WITH_VERSION}/voyage-manager/pnl/edit?${qParamString}`;
        const response = await getAPICall(url);
        if (response.data.hasOwnProperty("pnl_data")) {
          changedFormData = response.data.pnl_data;
        }
        if (response.data.hasOwnProperty("remark")) {
          updatedRemark = response.data.remark;
        }
      } catch (err) {
        console.log("err", err);
        openNotificationWithIcon("error", "Something went wrong", 3);
      }
    }

    this.setState(
      {
        ...this.state,
        formData: changedFormData,
        actualSelection: disableBtn,
        selectedOption: value,
        remark: updatedRemark,
      },
      () => {
        this.setState(
          {
            ...this.state,
            estimateData: this.__pl()?.estimateData,
          },
          () => {
            this.setState({ ...this.state, showPL: true });
          }
        );
      }
    );
  };

  remarkHandler = (e) => {
    const remarkValue = e.target.value;
    this.setState({ ...this.state, remark: remarkValue });
  };

  showcalculation = (title) => {
    if (title) {
      return (
        <>
          {VoyageEstimateCalcArr?.map((el) => {
            if (
              el.title.toLowerCase().replace(/[^a-z0-9]+/g, "") ===
              title.toLowerCase().replace(/[^a-z0-9]+/g, "")
            ) {
              return (
                <TcovVmPnl
                  tooltipData={this.state.tooltipData}
                  desc={el.formula}
                  title={el.title}
                  formData={this.state.formData}
                />
              );
            }
          })}
        </>
      );
    }
  };

  handleiconClick = () => {
    this.setState((prev) => {
      const isActualView = prev.viewTabs.includes("Actual &  Operation View");
      const newView = isActualView
        ? ["Estimate View"]
        : ["Actual &  Operation View"];

      localStorage.setItem("tabView", newView[0]);

      return {
        ...prev,
        viewTabs: newView,
      };
    });

    // console.log("sttt", this.state);
  };

  TableBodyCell = (props) => {
    const { title } = props;

    let totaldescarr = [
      "Revenue",
      "Freight",
      "Freight Commission",
      "Misc. Revenue",
      "Demurrage Amt",
      "Demm Comm Amt",
      "Despatch Amt",
      "Gross Revenue",
      "Net Revenue",
      "Expenses",
      "Vessel expenses",
      "TCI Address Commission",
      "TCI Bro Commission",
      "Port Expenses",
      "Emmission Expenses",
      "Misc. Expenses (Other Cost + Ballast Bonus)",
      "Misc. Expenses",
      "Bunker Expenses",
      "Gross Expenses",
      "Net Expenses",
      "Voyage Result",
      "Profit (Loss)",
      "Daily Profit (Loss)",
      "TCE Hire ( Net Daily )",
      "Gross TCE",
      "Average Freight rate ($/T)",
      "Breakeven & Freight rate ($/T)",
      "Co2 Cost",
      "CO2 Adjusted Profit (Loss)",
      "CO2 Adjusted (Net) TCE",
      "Sea Consp Non ECA",
      "Sea Consp ECA",
      "Port Consp",
    ];

    return (
      <>
        {totaldescarr.includes(title) ? (
          <Popover
            content={() => this.showcalculation(title)}
            placement="right"
            destroyTooltipOnHide={true}
            fresh={true}
          >
            <td
              {...props}
              style={{
                ...props.style,
              }}
            />
          </Popover>
        ) : (
          <td
            {...props}
            style={{
              ...props.style,
            }}
          />
        )}
      </>
    );
  };

  render() {
    const {
      estimateData,
      showPL,
      viewTabs,
      saveLoading,
      actualSelection,
      selectedOption,
      selectOptions,
      remark,
      openGraphModal,
      openAICompModal,
    } = this.state;

    const handleOpenAnalytics = () => {
      this.setState((prev) => ({ ...prev, openGraphModal: true }));
    };

    const handleOpenAIComprater = () => {
      this.setState((prev) => ({ ...prev, openAICompModal: true }));
    };
    const defaultExpandedRowKeys = estimateData.map((item) => item.key);

    // console.log("props", this.props);
    return (
      <div style={{ padding: "20px" }}>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <Tooltip
            title={
              this.state.viewTabs.includes("Actual &  Operation View")
                ? "Contract to view Estimate View only"
                : "Expand to view Actual & Operation View"
            }
          >
            <Icon
              onClick={this.handleiconClick}
              icon={
                this.state.viewTabs.includes("Actual &  Operation View")
                  ? "tabler:layout-sidebar-left-expand-filled" // Change to collapsed icon
                  : "tabler:layout-sidebar-right-expand-filled" // Expanded icon
              }
              style={{
                fontSize: "30px",
                cursor: "pointer",
                color: "#ab0d0d",
                marginTop: "5px",
              }}
            />
          </Tooltip>

          <ToolbarUI routeUrl={"pl-main"} callback={(e) => this.callback(e)} />

          <CloseOutlined
            onClick={() => this.props.closeSidePnl()}
            style={{ marginLeft: "100px" }}
          />
        </div>
        <Button
          type="primary"
          shape="round"
          icon={<SaveOutlined />}
          loading={saveLoading}
          size="middle"
          onClick={this.savePNL}
          disabled={actualSelection}
        >
          Save PNL
        </Button>

        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            float: "right",
            marginRight: this.state.viewTabs.includes(
              "Actual &  Operation View"
            )
              ? "9%"
              : "40%",
          }}
        >
          <Select
            defaultValue="Actual"
            value={selectedOption}
            style={{
              width: this.state.viewTabs.includes("Actual &  Operation View")
                ? 250
                : 150,
            }}
            placement="Select saved PNL"
            loading={!showPL}
            disabled={!showPL}
            onChange={this.handleSelectChange}
            options={showPL ? selectOptions : []}
          />
          {this.state.viewTabs.includes("Actual &  Operation View") ? (
            <>
              <div
                onClick={handleOpenAnalytics}
                style={{
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                }}
              >
                <AiButton text="AI Analysis" />
              </div>
              <div
                style={{
                  marginLeft: "0.7rem",
                }}
                onClick={handleOpenAIComprater}
              >
                <AiButton text="AI-Powered P&L Comparator" />
              </div>
            </>
          ) : null}

          {/* </Tag> */}
        </div>

        {openGraphModal ? (
          <Modal
            open={openGraphModal}
            onCancel={() =>
              this.setState((prev) => ({ ...prev, openGraphModal: false }))
            }
            footer={null}
            width={"85vw"}
            title="P&L Analysis"
          >
            <PnlGraph estimateData={this.props.estimateData} />
          </Modal>
        ) : null}

        {openAICompModal ? (
          <Modal
            open={openAICompModal}
            onCancel={() =>
              this.setState((prev) => ({ ...prev, openAICompModal: false }))
            }
            footer={null}
            width={"85vw"}
            title="AI-Powered P&L Comparator"
          >
            {/* <PnlGraph estimateData={this.props.estimateData} /> */}
            <AiPLComparator
              actualPL={this.props.estimateData}
              selectOptions={this?.state?.selectOptions}
            />
          </Modal>
        ) : null}

        {showPL === true ? (
          <div
            ref={this.elementRef}
            style={{
              width: viewTabs.includes("Actual &  Operation View")
                ? "900px"
                : "450px",
              transition: "width 0.3s ease-in-out",
              overflow: "hidden",
            }}
          >
            <Tabs defaultActiveKey="1">
              {viewTabs?.map((e) => {
                if (e === "Actual &  Operation View") {
                  return (
                    <TabPane tab={e} key="ao2" style={{ width: "900px" }}>
                      <Table
                        className="pl-summary-list-view"
                        bordered
                        columns={columns}
                        dataSource={estimateData}
                        components={{
                          body: {
                            cell: this.TableBodyCell,
                          },
                        }}
                        pagination={false}
                        rowClassName={(r, i) =>
                          i % 2 === 0
                            ? r.is_invoiced
                              ? "table-striped-listing colorthepl"
                              : "table-striped-listing"
                            : r.is_invoiced
                            ? "table-striped-listing colorthepl"
                            : "dull-color table-striped-listing"
                        }
                        defaultExpandedRowKeys={defaultExpandedRowKeys}
                      />
                      <Row gutter={16} className="m-t-18">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <FormItem
                            label="Remark"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: "10px 0 0 9px" }}
                          >
                            <div
                              style={{ border: "1px solid blue" }}
                              className="vk"
                            >
                              <TextArea
                                placeholder="Remark"
                                value={remark}
                                onChange={this.remarkHandler}
                                autoSize={{ minRows: 6, maxRows: 6 }}
                              />
                            </div>
                          </FormItem>
                        </Col>
                      </Row>
                    </TabPane>
                  );
                } else if (e === "Estimate View") {
                  return (
                    <TabPane tab={e} key="ev1" style={{ width: "450px" }}>
                      <Table
                        className="pl-summary-list-view"
                        bordered
                        columns={columns2}
                        dataSource={estimateData}
                        pagination={false}
                        rowClassName={(r, i) =>
                          i % 2 === 0
                            ? "table-striped-listing"
                            : "dull-color table-striped-listing"
                        }
                        components={{
                          body: {
                            cell: this.TableBodyCell,
                          },
                        }}
                        defaultExpandedRowKeys={defaultExpandedRowKeys}
                      />
                      <Row gutter={16} className="m-t-18">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <FormItem
                            label="Remark"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: "10px 0 0 9px" }}
                          >
                            <div
                              style={{ border: "1px solid blue" }}
                              className="vk"
                            >
                              <TextArea
                                placeholder="Remark"
                                autoSize={{ minRows: 6, maxRows: 6 }}
                              />
                            </div>
                          </FormItem>
                        </Col>
                      </Row>
                    </TabPane>
                  );
                } else if (e === "Account View") {
                  return (
                    <TabPane tab={e} key="av3">
                      Accounts
                    </TabPane>
                  );
                }
              })}
            </Tabs>
          </div>
        ) : (
          <div className="col col-lg-12">
            <Spin tip="Loading...">
              <Alert message=" " description="Please wait..." type="info" />
            </Spin>
          </div>
        )}
      </div>
    );
  }
}

export default PL;

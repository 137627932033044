export const FREIGHT_RATE = "frera";
export const CARGO_DESCRIPTION = "carde";
export const BEAUFORT_SCALE = "besc";
export const CONSUMPTION_TYPE = "conty";
export const COUNTRY = "couli";
export const CURRENCIES = "curre";
export const DELAY_REASON = "delre";
export const DELAY_TYPES = "delty";
export const FUEL_TYPE = "fulty";
export const FUEL_ZONE = "fuzo";
export const LAYTIME_TO_COMMENCE = "latco";
export const LAYTIME_TYPE = "layty";
export const LOADING_COST = "loaco";
export const LOADLINE_ZONE = "loazo";
export const NOR_TO_TENDER = "notte";
export const PAYMENT_TERM = "payte";
export const PORT_ACTIVITY = "porac";
export const PORT_FUNCTION = "porfu";
export const PORT_TERM = "porte";
export const SEA_FORCE = "seafo";
export const SEA_SWELLS = "seasw";
export const SHIFTING_TERMS = "shite";
export const TIME_USED = "timus";
export const TIME_TO_RENDER = "titen";
export const TRADE = "trade";
export const UNIT_MEASURES = "unime";
export const UNIT_SELECTION = "unise";
export const VESSEL_CLASS_SOCIETY = "vecso";
export const VESSEL_FUEL_TYPE = "vefty";
export const VESSEL_TYPE = "vesty";
export const WHEATHER_WORKING = "weawo";
export const FREIGHT_TERM = "frete";
export const VESSEL_FLEET = "vesfl";
export const FUEL_CONSUMPTION_CATEGORIES = "fucoca";
export const OTHER_LOADING_TERMS = "otlote";
export const CHARTY_PARTY_FORMS = "chpafo";
export const NORMAL_OFFICE_HOURS = "noofho";
export const CURRENCIE_TYPES = "curset";
export const GRADES = "grades";
export const OPA_RATES = "oparat";
export const OPERATION_LEDGER = "opeled";
export const VESSEL_DAILY_COST = "vedaco";
export const CARGO_GROUP = "cargr";
export const CARGO_LIST = "carli";
export const PORT = "port";
export const SUBSCRIPTION = "subs";
export const BANKNAMES = "bankna";

export const FIVE_MINUTES = "FIVE_MINUTES";
export const ONE_MINUTES = "ONE_MINUTES";
export const FUEL_SORT_ORDER = ["IFO", "VLSFO", "ULSFO", "LSMGO", "MGO"];

export const DEFAULT_CONS_PER_DAY = [
    {
      cp_price: "0",
      fuel_code: "IFO",
      fuel_type: 3,
      ballast_value: 0,
      laden_value: 0,
      con_loading: 0,
      con_disch: 0,
      con_ideal_on: 0,
      index: 0,
      id: -9e6,
    },
    {
      cp_price: "0",
      fuel_code: "VLSFO",
      fuel_type: 5,
      ballast_value: 0.00,
      laden_value: 0,
      con_loading: 0,
      con_disch: 0,
      con_ideal_on: 0,
      index: 0,
      id: -9e6 + 1,
    },
    {
      cp_price: "0",
      fuel_code: "ULSFO",
      fuel_type: 10,
      ballast_value: 0,
      laden_value: 0,
      con_loading: 0,
      con_disch: 0,
      con_ideal_on: 0,
      index: 0,
      id: -9e6 + 2,
    },
    {
      cp_price: "0",
      fuel_code: "LSMGO",
      fuel_type: 7,
      ballast_value: 0,
      laden_value: 0,
      con_loading: 0,
      con_disch: 0,
      con_ideal_on: 0,
      index: 0,
      id: -9e6 + 3,
    },
    {
      cp_price: "0",
      fuel_code: "MGO",
      fuel_type: 4,
      ballast_value: 0,
      laden_value: 0,
      con_loading: 0,
      con_disch: 0,
      con_ideal_on: 0,
      index: 0,
      id: -9e6 + 4,
    },
  ]
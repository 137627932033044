import React, { useMemo, useState } from "react";
import { Tooltip, Modal, Dropdown, Input, Button, Checkbox } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import Popover from "antd/lib/popover";
import EmailComments from "./mailRightbar/EmailComments";
import CargoTemplate from "../../components/email-templates/CargoTemplate";
import TonnageTemplate from "../../components/email-templates/TonnageTemplate";
import CargoFirmOffers from "../../components/email-templates/CargoFirmOffers";
import TonnageFirmOffers from "../../components/email-templates/TonnageFirmOffers";
import { openNotificationWithIcon, postAPICall } from "../../shared";
import { useDispatch } from "react-redux";
import { composeNewMail, setVesselName } from "../../actions/emailActions";

import { v4 as uuidv4 } from "uuid";
import { getAttachmentsURL } from "./constants";



const GenerateMenuItems = ({ handleIconClick, currentMailIndex, mail, mailDetails, handlePrint, showIcons, assignToData }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState("");
    const [emailTemplateName, setemailTemplate] = useState('')
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedAssignees, setSelectedAssignees] = useState([]);
    const [showTemplate, setShowTemplate] = useState(false)
    const openModal = (title, content) => {
        setModalTitle(title);
        setModalContent(content);
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setModalContent(null);
        setModalTitle("");
    };

    const menuItemStyle = {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: "0.5rem",
        padding: "0.5rem 1rem",
    };

    const iconStyle = {
        fontSize: "15px",
        marginRight: "10px",
    };
    // console.log(mail)
    const dispatch = useDispatch()

      const attachments = useMemo(
        () =>
          mail?.Attachments?.map(attach => {
            // ToDo: replace this with logged-in user email - Dildar Khan
            // const email = 'brokers@theoceann.com'
            const email = mail?.client
            const { url, fileType } = getAttachmentsURL(email, attach, mail?.GmailMessageId)
            return {
              url,
              fileType,
              fileName: attach?.filename
            }
          }),
        [mail?.Attachments?.length]
    
      )

    const handleDownloadAttachment = async (
        attach
      ) => {
        const response = await fetch(attach?.url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
    
        const blob = await response.blob()
        const url = URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', attach?.fileName)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      }

    const downloadAllAttachments = async (e) => {
        if (attachments?.length) {
          for (const attach of attachments) {
            await handleDownloadAttachment(attach);
          }
        } else {
          console.error('No attachments to download.');
        }
      };
    
    const handleCheck = (item) => {
        // console.log("hello")
        setSelectedAssignees((prev) => {
          const exists = prev.find((assignee) => assignee.id === item.id);
          if (exists) {
            // Uncheck: Remove the object
            return prev.filter((assignee) => assignee.id !== item.id);
          } else {
            // Check: Add the object
            return [...prev, { name: item.first_name, id: item.id }];
          }
        });
      };
      
    //   console.log("selectedassigness",selectedAssignees)
        const assignMail = async () => {
          try {
            const url = `${process.env.REACT_APP_MT_URL}mail/assign-mail`;
            const payload = {
              client: mailDetails?.client,
              ref_id: mailDetails?._id,
              users: selectedAssignees,
            };
            const response = await postAPICall(url, payload, "post");
            // console.log("response9876", response);
            if (response?.status == true) {
              openNotificationWithIcon("success", "Email assigned successfully");
            } else {
              console.log("some error occured");
            }
          } catch (error) {
            console.log(error);
          }
        };
        // console.log("aasigntodata",assignToData)
        const filteredAssignTo = assignToData.filter((item) =>
            item.first_name.toLowerCase().includes(searchTerm.toLowerCase())
          );
        //  const mailview = localStorage.getItem("MailView")
        //  console.log("maillllllllllll",mailview)

        const formatDateTime = (timestamp) => {
            if (!timestamp) return "N/A";
            const date = new Date(timestamp);
            return new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "short",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            })?.format(date);
          };

    const items = [
        {
            key: "1",
            label: (
                <div
                    style={menuItemStyle}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleIconClick(e, currentMailIndex, "markAsRead", mail, mailDetails);
                    }}
                >
                    <Icon
                        style={iconStyle}
                        icon={
                            mail?.isRead
                                ? "material-symbols:mark-email-read-outline-sharp"
                                : "material-symbols:mail-outline"
                        }
                    />
                    {mail?.isRead ? "Mark as unread" : "Mark as read"}
                </div>
            ),
        },
        {
            key: "2",
            label: (
                <div
                    style={menuItemStyle}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleIconClick(e, currentMailIndex, "markImportant", mail, mailDetails);
                    }}
                >
                    <Icon
                        style={{ ...iconStyle, color: "orange" }}
                        icon={mail?.important ? "tabler:star-filled" : "tabler:star"}
                    />
                    {mail?.important ? "Unmark Important" : "Mark Important"}
                </div>
            ),
        },
        {
            key: "3",
            label: (
                <Popover
                    content={<EmailComments currentMail={mail} />}
                    title={null}
                    trigger="click"
                    placement="leftTop"
                >
                    <Tooltip title="Click to view or add comments related to this email or task." placement="left">
                        <div style={menuItemStyle}>
                            <Icon style={iconStyle} icon="ei:comment" />
                            Comment
                        </div>
                    </Tooltip>
                </Popover>
            ),
        },

        {
            key: "11",
            label: showIcons ? (
                <div
                    style={menuItemStyle}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleIconClick(e, currentMailIndex, "delete", mail, mailDetails);
                    }}
                >
                    <DeleteOutlined style={{ ...iconStyle, color: "red" }} />
                    Delete
                </div>
            ) : null,
        },

        {
            key: "12",
            label: (
                <div
                    style={menuItemStyle}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleIconClick(e, currentMailIndex, "archive", mail, mailDetails);
                    }}
                >
                    <Icon style={iconStyle} icon="tabler:square-arrow-down" />
                    Archive
                </div>
            ),
        },
        {
            key: "4",
            label: (
                <div style={menuItemStyle} onClick={(e) => {
                    e.stopPropagation();
                    const currentDate = new Date();
                    const formattedDate = currentDate.toISOString();
                    let newOptions;
                    const userData = localStorage.getItem(
                        "oceanToken"
                    )
                        ? JSON.parse(
                            atob(
                                localStorage
                                    .getItem("oceanToken")
                                    ?.split(".")[1] ?? ""
                            )
                        )
                        : null;
                    if (userData && userData?.inboxes) {
                        newOptions = userData?.inboxes?.map(
                            (item) => ({ value: item })
                        );
                    }
                    let data = {
                        composedData: {
                            Date: formattedDate,
                            to: [],
                            cc: [],
                            bcc: [],
                            body: "",
                            subject: "",
                            attachments: [],
                            //   editorStateKey: EditorState.createEmpty(),
                            HTMLBody: undefined,
                            owner_email: newOptions[0]?.value || "",
                            ThreadID: "",
                            GmailMessageId: "",
                            listOfFile: [],
                            totalSize: 0,
                            uid: uuidv4(),
                            _id: null,
                        },
                        max: false,
                        min: false,
                    }
                    dispatch(composeNewMail(data))
                    handleDropdownVisibleChange(false)

                }}>
                    <Icon style={iconStyle} icon="tabler:mail" />
                    Compose
                </div >
            ),
        },
        // {
        //     key: "11",
        //     label: (
        //         <div style={menuItemStyle}>
        //             <Popover
        //                 placement="bottom"
        //                 trigger="click"
        //                 // title={
        //                 //   <span style={{ color: "#ff5722", fontWeight: "bold" }}>
        //                 //     Assigned To
        //                 //   </span>
        //                 // }
        //                 content={
        //                     <div>
        //                         <Input
        //                             placeholder="Search assignees..."
        //                             onChange={(e) => setSearchTerm(e.target.value)}
        //                             style={{
        //                                 marginBottom: "12px",
        //                                 borderRadius: "8px",
        //                                 padding: "8px",
        //                                 border: "1px solid #ddd",
        //                             }}
        //                         />
        //                         {filteredAssignTo.length > 0 ? (
        //                             <div
        //                                 style={{
        //                                     maxHeight: "200px",
        //                                     overflowY: "auto",
        //                                     marginBottom: "16px",
        //                                     background: "rgb(138 192 242)",
        //                                     borderRadius: "8px",
        //                                     padding: "8px",
        //                                 }}
        //                             >
        //                                 {filteredAssignTo.map((item, index) => (
        //                                     <div
        //                                         key={index}
        //                                         style={{
        //                                             display: "flex",
        //                                             justifyContent: "space-between",
        //                                             alignItems: "center",
        //                                             background: "#ffffff",
        //                                             borderRadius: "6px",
        //                                             padding: "8px",
        //                                             marginBottom: "8px",
        //                                             boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        //                                         }}
        //                                     >
        //                                         <div
        //                                             style={{
        //                                                 display: "flex",
        //                                                 alignItems: "center",
        //                                             }}
        //                                         >
        //                                             <div
        //                                                 style={{
        //                                                     width: "32px",
        //                                                     height: "32px",
        //                                                     borderRadius: "50%",
        //                                                     background: "#12406a",
        //                                                     color: "#fff",
        //                                                     display: "flex",
        //                                                     alignItems: "center",
        //                                                     justifyContent: "center",
        //                                                     fontWeight: "bold",
        //                                                     marginRight: "8px",
        //                                                 }}
        //                                             >
        //                                                 {item?.first_name?.charAt(0).toUpperCase()}
        //                                             </div>
        //                                             <div
        //                                                 style={{
        //                                                     fontWeight: "500",
        //                                                     color: "#333",
        //                                                     fontSize: "14px",
        //                                                 }}
        //                                             >
        //                                                 {item?.first_name}
        //                                             </div>
        //                                         </div>
        //                                         <Checkbox
        //                                             checked={selectedAssignees.some(
        //                                                 (assignee) => assignee.id === item.id
        //                                             )}
        //                                             onChange={() => handleCheck(item)}
        //                                             style={{
        //                                                 transform: "scale(1.2)",
        //                                                 color: "#ff5722",
        //                                             }}
        //                                         />
        //                                     </div>
        //                                 ))}
        //                             </div>
        //                         ) : (
        //                             <div style={{ textAlign: "center", color: "#ff5722" }}>
        //                                 No assignees found.
        //                             </div>
        //                         )}
        //                         <div
        //                             style={{
        //                                 display: "flex",
        //                                 justifyContent: "flex-end",
        //                                 gap: "8px",
        //                                 marginTop: "16px",
        //                             }}
        //                         >
        //                             <Button
        //                                 type="default"
        //                                 size="small"
        //                                 onClick={(e) => {
        //                                     e.stopPropagation();
        //                                     setSelectedAssignees([]);
        //                                 }}
        //                                 style={{
        //                                     background: "#f3e5f5",
        //                                     color: "#9c27b0",
        //                                     fontWeight: "bold",
        //                                     border: "none",
        //                                     borderRadius: "6px",
        //                                 }}
        //                             >
        //                                 Cancel
        //                             </Button>
        //                             <Button
        //                                 type="primary"
        //                                 size="small"
        //                                 onClick={(e) => {
        //                                     e.stopPropagation();
        //                                     assignMail();
        //                                 }}
        //                                 style={{
        //                                     background:
        //                                         "linear-gradient(135deg, rgb(34 184 255), rgb(36 64 61))",
        //                                     border: "none",
        //                                     fontWeight: "bold",
        //                                     borderRadius: "6px",
        //                                 }}
        //                             >
        //                                 Apply
        //                             </Button>
        //                         </div>
        //                     </div>
        //                 }
        //             >
        //                 <Icon style={iconStyle} icon="tabler:template" />
        //                 Assign To
        //             </Popover>

                    
        //         </div>
        //     ),
        // },
        {
            key: "5",
            label: (
                <div style={menuItemStyle}
                    onClick={(e) => {
                        e.stopPropagation(); dispatch(setVesselName({ vesselName: mail?.vessel_name?.[0], isVisible: true }));
                        handleDropdownVisibleChange(false)
                    }}
                >
                    <Icon style={iconStyle} icon="tabler:radar" />
                    Track vessel
                </div>
            ),
        },
        {
            key: "6",
            label: (
                <div style={menuItemStyle} onClick={(e) => { e.stopPropagation(); handlePrint(0, mail) }}>
                    <Icon style={iconStyle} icon="tabler:printer" />
                    Print
                </div>
            ),
        },
        {
            key: "7",
            label: (
                <div style={menuItemStyle}
                    onClick={(e) => { e.stopPropagation(); openModal("Tonnage Template:", <TonnageFirmOffers />,) }}

                >
                    <Icon style={iconStyle} icon="tabler:certificate" />
                    Create Tonnage Firm Offer
                </div>
            ),
        },
        {
            key: "8",
            label: (
                <div style={menuItemStyle}
                    onClick={() => openModal("Cargo Template:", <CargoFirmOffers />,)}
                >
                    <Icon style={iconStyle} icon="tabler:certificate" />
                    Create Cargo Firm Offer
                </div>
            ),
        },
        {
            key: "9",
            label: (
                <div style={menuItemStyle}
                    onClick={() => openModal("Cargo Template:", <TonnageTemplate />,)}
                >
                    <Icon style={iconStyle} icon="tabler:phone-incoming" />
                    Create Tonnage Enquiry
                </div>
            ),
        },
        {
            key: "9",
            label: (
                <div style={menuItemStyle}
                    onClick={(e) => { e.stopPropagation(); openModal("Cargo Template:", <CargoTemplate />,) }}
                >
                    <Icon style={iconStyle} icon="tabler:phone-incoming" />
                    Create Cargo Enquiry
                </div>
            ),
        },
        {
            key: "10",
            label: (
                <div style={menuItemStyle}
                    onClick={(e) => { e.stopPropagation(); setShowTemplate(true) }}>
                    <Icon style={iconStyle} icon="tabler:template" />
                    Save as Template
                </div>
            ),
        },
        {
            key: "12",
            label: (
                <div
                    style={menuItemStyle}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleIconClick(e, currentMailIndex, "bestMatch", mail, mailDetails);
                    }}
                >
                    <Icon style={iconStyle} icon={"ri:shake-hands-line"} />
                    Click to show best match
                </div>
            ),
        },

        {
            key: "13",
            label: (
                <div
                    style={menuItemStyle}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleIconClick(e, currentMailIndex, "delete", mail, mailDetails);
                    }}
                >
                    <Icon style={iconStyle}  icon={"tabler:trash"} />
                    Delete
                </div>
            ),
        },
        {
            key: "13",
            label: (
                <div
                    style={menuItemStyle}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleIconClick(e, currentMailIndex, "applyTags", mail, mailDetails);
                    }}
                >
                    <Icon style={iconStyle}  icon={"tabler:bookmark"} />
                    Apply Tags
                </div>
            ),
        },
        {
            key: "14",
            label: (
                <div
                    style={menuItemStyle}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleIconClick(e, currentMailIndex, "archive", mail, mailDetails);
                    }}
                >
                    <Icon style={iconStyle}  icon={"tabler:square-arrow-down"} />
                    Archive
                </div>
            ),
        },
        {
            key: "15",
            label: (
                <div
                    style={menuItemStyle}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleIconClick(e, currentMailIndex, "spam", mail, mailDetails);
                    }}
                >
                    <Icon style={iconStyle}  icon={"tabler:alert-octagon"} />
                    Spam
                </div>
            ),
        },
        mail?.Attachments?.length > 0  ? {
            key: "17",
            label: (
                <div
                style={menuItemStyle}
                onClick={(e) => { e.stopPropagation(); downloadAllAttachments(e); }}
            >
                <Icon style={iconStyle}  icon="material-symbols:download-sharp"  />
                Download
            </div>
            ),
          } : null,  
          

        {
            key: "18",
            label: (
                <div
                    style={menuItemStyle}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Popover
                        placement="left"
                        trigger="click"
                        content={
                            <div>
                                <Input
                                    placeholder="Search assignees..."
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    style={{
                                        marginBottom: "12px",
                                        borderRadius: "8px",
                                        padding: "8px",
                                        border: "1px solid #ddd",
                                    }}
                                />
                                {filteredAssignTo.length > 0 ? (
                                    <div
                                        style={{
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                            marginBottom: "16px",
                                            background: "rgb(138 192 242)",
                                            borderRadius: "8px",
                                            padding: "8px",
                                        }}
                                    >
                                        {filteredAssignTo.map((item, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    background: "#ffffff",
                                                    borderRadius: "6px",
                                                    padding: "8px",
                                                    marginBottom: "8px",
                                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: "32px",
                                                            height: "32px",
                                                            borderRadius: "50%",
                                                            background: "#12406a",
                                                            color: "#fff",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            fontWeight: "bold",
                                                            marginRight: "8px",
                                                        }}
                                                    >
                                                        {item?.first_name?.charAt(0).toUpperCase()}
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontWeight: "500",
                                                            color: "#333",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {item?.first_name}
                                                    </div>
                                                </div>
                                                <Checkbox
                                                    checked={selectedAssignees.some(
                                                        (assignee) => assignee.id === item.id
                                                    )}
                                                    onChange={() => handleCheck(item)}
                                                    style={{
                                                        transform: "scale(1.2)",
                                                        color: "#ff5722",
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div style={{ textAlign: "center", color: "#ff5722" }}>
                                        No assignees found.
                                    </div>
                                )}
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        gap: "8px",
                                        marginTop: "16px",
                                    }}
                                >
                                    <Button
                                        type="default"
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setSelectedAssignees([]);
                                        }}
                                        style={{
                                            background: "#f3e5f5",
                                            color: "#9c27b0",
                                            fontWeight: "bold",
                                            border: "none",
                                            borderRadius: "6px",
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            assignMail();
                                        }}
                                        style={{
                                            marginRight: "1rem",
                                            background: "linear-gradient(135deg, rgb(34 184 255), rgb(36 64 61))",
                                            border: "none",
                                            fontWeight: "bold",
                                            borderRadius: "6px",
                                        }}
                                    >
                                        Apply
                                    </Button>
                                </div>
                            </div>
                        }
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                borderRadius: "0.5rem",
                                // padding: "0.5rem 1rem",
                            }}
                        >   <Icon style={iconStyle} icon="bitcoin-icons:message-outline" />
                            Assign To
                        </div>
                    </Popover>
                </div>
            ),
        },

        {
            key: "19",
            label: (
                <div
                    style={menuItemStyle}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Popover
                        overlayStyle={{ marginTop: "1rem" }}
                        placement="left"
                        trigger="click"
                        title={<div>Who viewed this mail.</div>}
                        content={
                            <div onClick={(e) => e.stopPropagation()}>
                                {Array.isArray(mail?.readBy) ? (
                                    mail.readBy.length > 0 ? (
                                        mail.readBy.map((data, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <span style={{ margin: "5px" }}>
                                                    {index + 1}. {data}
                                                </span>
                                                <span
                                                    style={{ marginLeft: "10px", color: "#1480c4" }}
                                                >
                                                    {formatDateTime(mail.readTime)}
                                                </span>
                                            </div>
                                        ))
                                    ) : (
                                        mail?.client
                                    )
                                ) : (
                                    <>
                                        {mail?.readBy || mail?.client}{" "}
                                        <span style={{ marginLeft: "10px", color: "#1480c4" }}>
                                            {formatDateTime(mail.readTime)}
                                        </span>
                                    </>
                                )}
                            </div>
                        }
                    >
                        <div
                            style={{
                                display: "flex",
                                gap: "0.6rem",
                                alignItems: "center",
                                cursor: "pointer",
                                borderRadius: "0.5rem",
                            }}
                        >
                            <Icon icon="tabler:message-filled" style={{ iconStyle }} />
                            Viewed By
                        </div>
                    </Popover>
                </div>
            ),
        },

    ];


    const handlesaveAstemplate = async () => {

        try {
            const payload = {
                send_to: '',
                cc: mail.CC,
                bcc: mail.BCC,
                send_from: '',
                subject: mail.Subject,
                template_name: emailTemplateName,
                body: mail.Body
            }
            const url = `${process.env.REACT_APP_MT_URL}accounts/add-email-template`;
            let response = await postAPICall(url, payload, "post");
            setShowTemplate(false);
            openNotificationWithIcon('info', response?.msg)

            //   const response = await postApiService({ url, isToken, payload, showMessage: true })
        } catch (err) {
            console.log('something went wrong', err)
        }
    }
    const handleDropdownVisibleChange = (visible) => {
        setIsDropdownOpen(visible);
    };

    // return items;
    return (
        <>
            {/* {items} */}
            <Dropdown
                menu={{
                    items: items.map((item) => ({
                        ...item,
                        onClick: (e) => {
                            e.domEvent.stopPropagation(); // Stop event propagation
                            item.onClick && item.onClick(); // Call the original onClick if it exists
                        },
                    })),
                    style: { maxHeight: "500px", overflowY: "auto" },

                }}
                placement="bottomRight"
                arrow
                trigger={["click"]}
                onClick={(e) => e.stopPropagation()}
                open={isDropdownOpen}
                onOpenChange={handleDropdownVisibleChange}
            >
                <Tooltip title="More options">
                    <div onClick={(e) => e.stopPropagation()}>
                        <Icon
                            className="current_mail_icons"
                            icon={"tabler:dots-vertical"}
                        />
                    </div>
                </Tooltip>
            </Dropdown>
            <Modal
                title={modalTitle}
                visible={isModalVisible}
                onCancel={closeModal}
                footer={null}
                width={1500}



            >

                {modalContent}
            </Modal>
            {showTemplate &&
                <Modal
                    title="Save Email Template"
                    open={showTemplate}
                    onCancel={() => setShowTemplate(false)}
                    footer={[
                        <Button key="close" onClick={() => setShowTemplate(false)}>
                            Close
                        </Button>,
                        <Button
                            key="save"
                            type="primary"
                            onClick={() => handlesaveAstemplate()}
                        >
                            Save
                        </Button>,
                    ]}
                >
                    <Input
                        placeholder="Enter Template Name"
                        value={emailTemplateName}
                        onChange={(e) => setemailTemplate(e.target.value)}
                    />
                </Modal>}
        </>
    );
};

export default GenerateMenuItems;

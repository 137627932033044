import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Collapse,
  Drawer,
  Dropdown,
  Flex,
  Input,
  Menu,
  message,
  Modal,
  Popover,
  Tag,
  Tooltip,
  Typography,
  Popconfirm,
  Checkbox,
} from "antd";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  chipStyles,
  colorArrayCustomTag,
  EMAILS_PER_PAGE,
  events,
  formatNumber,
  formatTimestamp,
  getAttachmentsURL,
  getInitial,
  tagColourIcon,
  tagdata,
  timeHandler,
} from "./constants";
import ComposeReply from "../../components/emails/ComposeReply";
import {
  extractEmail,
  parseSender,
  sendermaillReturn,
  toMailReturn,
} from "../../services/email_utils";
import Swal from "sweetalert2";
import {
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
  postAPICallService,
} from "../../shared";
import LabelManager from "./LabelManager";
import { renderToString } from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmailReplyOriginalBody,
  setEmailReplySuggestions,
  setVesselName,
} from "../../actions/emailActions";
import ApplyTagsModal from "./ApplyTagsModal";
import ShadowContainer from "./ShadowContainer";
import EmailHeader from "./EmailHeader";
import {
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FormOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import BestMatchDrawerContent from "./BestMatchDrawerContent";
import SelectText from "./mailRightbar/selectTextPopover";
import EmailComments from "./mailRightbar/EmailComments";
// import generateMenuItems from "./menuIteamForThreeDot";
import GenerateMenuItems from "./menuIteamForThreeDot";
import MailRightbar from "./mailRightbar/MailRightbar";

let showItem = true;

// class ShadowContainer extends HTMLElement {
//   constructor() {
//     super();
//     this.attachShadow({ mode: "open" });
//   }

//   /**
//    * @param {string} value
//    */
//   set content(value) {
//     this.shadowRoot.innerHTML = value;
//   }
// }

// customElements.define("shadow-container", ShadowContainer);

const Tags = ({ mail, handleSocketEvent }) => {
  let newTags = useSelector((state) => state.email.showtagdata);
  const [newcustomtag, setnewcustomtag] = useState([]);

  // let newcustomtag = newTags ? newTags : []

  // const [newcustomtag, setNewCustomtag] = useState([])
  useEffect(() => {
    setnewcustomtag(newTags ? newTags : []);
    // newcustomtag = newTags ? newTags : []
  }, [newTags]);
  const [taglist, setTagList] = useState([]);
  let tagdata1 = tagdata;
  const showTags = (userInpu1, withCustomtag = true) => {
    const filteredResponse = [];
    if (userInpu1) {
      tagdata1.forEach((key) => {
        if (
          userInpu1.hasOwnProperty(key) &&
          userInpu1[key] !== null &&
          userInpu1[key] !== undefined &&
          userInpu1[key].length !== 0 &&
          userInpu1[key]
        ) {
          const formattedKey = key
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          if (key === "discharge_port" || key === "load_port") {
            if (userInpu1[key].port !== null) {
              filteredResponse.push({
                key: formattedKey,
                value: [userInpu1[key].port],
                realKey: key,
              });
            }
          } else {
            if (Array.isArray(userInpu1[key]) && userInpu1[key][1] !== 0) {
              const capitalizedValues = userInpu1[key].map((word) => {
                if (typeof word === "string") {
                  return word
                    .split(" ")
                    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
                    .join(" ");
                } else {
                  return word;
                }
              });
              filteredResponse.push({
                key: formattedKey,
                value: capitalizedValues,
                realKey: key,
              });
            } else if (userInpu1[key].length !== 0 && userInpu1[key][1] !== 0) {
              filteredResponse.push({
                key: formattedKey,
                value: [userInpu1[key]],
                realKey: key,
              });
            }
          }
        }
      });
    }
    if (withCustomtag) {
      if (userInpu1?.tags && userInpu1?.tags?.length > 0) {
        filteredResponse.push({
          key: "Tags",
          value: userInpu1.tags,
          realKey: "tags",
        });
      }
    }
    return filteredResponse;
  };

  useEffect(() => {
    const tags = showTags(mail, false);
    if (tags.length > 0) {
      setTagList(tags);
    } else {
      setTagList([]);
    }
  }, [mail]);

  const handledeletetag = (tag) => {
    let remtaglist = [...mail.tags].filter((el) => el != tag);
    const set2 = new Set(mail?.tags); // Convert second array to a Set for fast lookups
    const matchingArray = newcustomtag.filter((item) => set2.has(item));
    // console.log({ matchingArray })
    const payload = {
      context: mail?.client,
      _id: mail?._id,
      event: "applyTag",
      tags: [...remtaglist],
    };

    handleSocketEvent(payload);

    // let _currentMail = { ...currentMail }
    // let _id = mail?._id
    // let context = mail?.client
    // const payload = {
    //   context,
    //   _id,
    //   event: 'applyTag',
    //   tags: [...remtaglist]
    // }
    // events.applytags(payload)

    // _currentMail['tags'] = remtaglist

    // dispatch(setCurrentMail(_currentMail))
    // const indexOfCurrentMail = currentPageMails.findIndex(data => {
    //   return data._id === _id
    // })
    // if (indexOfCurrentMail !== -1) {
    //   let temp = [...currentPageMails]
    //   temp[indexOfCurrentMail] = _currentMail
    //   dispatch(setCurrentPageMails(temp))
    // }
  };

  return (
    <div
      className="current_mail_header_labels"
      style={{
        // marginLeft: handleCurrentMailClose ? "49px" : "0px",
        display: "flex",
        flexWrap: "wrap",
        marginBottom: "5px",
        alignItems: "baseline",
        rowGap: "5px",
      }}
    >
      {mail?.tags &&
        mail?.tags?.length > 0 &&
        mail?.tags?.map((tag, index) => {
          let tagname = newcustomtag[0]?.map((tag) => tag.tags.toLowerCase());
          let isDeletable = tagname?.includes(tag.toLowerCase());
          // console.log(tagname?.includes(tag), newcustomtag, ";hsdfghjkl", tagname)

          return (
            <Tooltip title="Tag">
              {" "}
              <Tag
                style={{
                  marginBottom: "5px",
                  backgroundColor:
                    colorArrayCustomTag[index % colorArrayCustomTag.length],
                  color: "#10311a",
                  height: "1.5rem",
                  fontSize: "14px",
                  padding: "1 10px",
                  borderRadius: "16px",
                  display: "flex",
                  alignItems: "center",
                  boxSizing: "border-box",
                  cursor: isDeletable ? "pointer" : "default",
                }}
                // closeIcon={isDeletable ? <DeleteOutlined style={{ fontSize: '12px' }} /> : null}
                closable={isDeletable}
                onClose={() => handledeletetag(tag)}
                closeIcon={
                  isDeletable ? (
                    <CloseOutlined style={{ fontSize: "12px" }} />
                  ) : null
                }
              >
                {tag}
              </Tag>
            </Tooltip>
          );
        })}
      <div
        style={{ paddingTop: "20px", display: "flex", flexWrap: "wrap" }}
        className="first"
      >
        {taglist.map(({ key, value, realKey }, index) => (
          <div key={index} style={{ marginBottom: "5px" }} className="second">
            <Tooltip title={key}>
              <Tag
                style={{
                  marginBottom: "5px",
                  backgroundColor: tagColourIcon[realKey]?.backgroundColor,
                  color: tagColourIcon[realKey]?.color,
                  height: "1.5rem",
                  fontSize: "14px",
                  padding: "0 8px",
                  borderRadius: "16px",
                  display: "flex",
                  alignItems: "center",
                  boxSizing: "border-box",
                }}
                icon={
                  <Icon
                    icon={tagColourIcon[realKey]?.icon}
                    style={{
                      color: tagColourIcon[realKey]?.color,
                      marginRight: "4px",
                    }}
                  />
                }
              >
                {value?.join(", ")}
              </Tag>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};

const Label = ({
  mail,
  dropitems,
  handleIconClick,
  currentMailIndex,
  mailDetails,
  handleSocketEvent,
  setCurrentMail,
  handlePrint,
  showBestMatch,
  showIcons,
}) => {
  const [isLabelOpen, setIsLabelOpen] = useState(false);
  const [isLabelModalVisible, setIsLabelModalVisible] = useState(false);
  const resizing = useSelector((state) => state.email.resizeMail);
  const popoverRef = useRef(null);
  const [searchLang, setSearchLang] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [filteredLanguages, setFilteredLanguages] = useState([]);
  const [translateMenu, setTranslateMenu] = useState(false);
  const [assignTo, setAssignTo] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    searchLanguages(searchLang);
    getAssignedMailOptions();
  }, [searchLang]);

  const filteredAssignTo = assignTo.filter((item) =>
    item.first_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getAssignedMailOptions = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}accounts/get-users`;
      const response = await getAPICall(url);
      // console.log("response", response);
      if (response && Array.isArray(response)) {
        setAssignTo(response || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const translate = async (language) => {
    let url = "https://aiml.azurewebsites.net/translate";
    if (mail.hasOwnProperty("Threads")) {
      try {
        // Loop over each mail in currentMailThreads and send the translation request
        const updatedThreads = await Promise.all(
          mail?.Threads?.map(async (mail, index) => {
            let payload = {
              text: mail?.Body,
              language,
            };

            try {
              const response = await postAPICallService({ url, payload });

              if (response) {
                // If translation is successful, update the mail body
                const translatedMail = {
                  ...mail,
                  Body: response?.text,
                };
                // setCurrentMail(translatedMail)
                return translatedMail; // Return the updated mail
              }
            } catch (error) {
              console.error(`Error translating thread ${index}:`, error);
              return mail; // Return the original mail if error occurs
            }
          })
        );

        // If you need to update the main mail as well (in case it should also be translated)
        let updatedMainMail = { ...mail };

        // Optionally, translate the body of the main mail (currentMail.Body) if needed
        if (mail.Body) {
          let payload = {
            text: mail.Body,
            language,
          };
          try {
            const response = await postAPICallService({ url, payload });

            if (response) {
              updatedMainMail = {
                ...updatedMainMail,
                Body: response?.text, // Update the body of the main mail
              };
            }
            setCurrentMail(updatedMainMail);
            setTranslateMenu(false);
          } catch (error) {
            console.error("Error translating main mail:", error);
          }
        }

        // Update the entire currentMail object with the translated threads
        const updatedMail = {
          ...updatedMainMail,
          Threads: updatedThreads,
        };

        // Dispatch the updated mail with translated threads and main mail body
        // dispatch(setCurrentMail(updatedMail));
        setCurrentMail(updatedMail);
        // handleCloseMenulan(); // Close the language menu
        // setSpinner(false); // Stop spinner
        setTranslateMenu(false);
      } catch (error) {
        // setSpinner(false)
        console.error("Error during translation of threads:", error);
      }
    } else {
      // If no threads, only translate the main mail
      let payload = {
        text: mail?.Body,
        language,
      };

      try {
        // setSpinner(true);

        const response = await postAPICallService({ url, payload });
        if (response) {
          // If translation is successful, update the mail body

          let responseMail = {
            ...mail,
            Body: response?.text,
          };

          if (response?.text?.length > 0) {
            // dispatch(setCurrentMail(responseMail)); // Dispatch the translated mail
            setCurrentMail(responseMail);
          }

          // handleCloseMenulan(); // Close the language menu
        }
        // setSpinner(false); // Stop spinner
      } catch (error) {
        // setSpinner(false)
        console.error("Error during translation:", error);
      }
    }
  };
  const languagesInNativeForm = {
    English: "English",
    "Mandarin Chinese": "Mandarin Chinese (中文)",
    Spanish: "Spanish (Español)",
    Hindi: "Hindi (हिन्दी)",
    Arabic: "Arabic (العربية)",
    Bengali: "Bengali (বাংলা)",
    Portuguese: "Portuguese (Português)",
    Russian: "Russian (Русский)",
    Japanese: "Japanese (日本語)",
    Punjabi: "Punjabi (ਪੰਜਾਬੀ)",
    German: "German (Deutsch)",
    French: "French (Français)",
    Turkish: "Turkish (Türkçe)",
    Korean: "Korean (한국어)",
    Italian: "Italian (Italiano)",
    Vietnamese: "Vietnamese (Tiếng Việt)",
    Urdu: "Urdu (اردو)",
    Javanese: "Javanese (ꦧꦱꦗꦮ)",
    Telugu: "Telugu (తెలుగు)",
    Tamil: "Tamil (தமிழ்)",
    Persian: "Persian (فارسی)",
    Thai: "Thai (ไทย)",
    Gujarati: "Gujarati (ગુજરાતી)",
    Polish: "Polish (Polski)",
    Ukrainian: "Ukrainian (Українська)",
    Dutch: "Dutch (Nederlands)",
    Romanian: "Romanian (Română)",
    Greek: "Greek (Ελληνικά)",
    Malay: "Malay (Bahasa Melayu)",
    Swahili: "Swahili (Kiswahili)",
    Hebrew: "Hebrew (עברית)",
    Burmese: "Burmese (ဗမာစာ)",
    Czech: "Czech (Čeština)",
    Amharic: "Amharic (አማርኛ)",
    Kannada: "Kannada (ಕನ್ನಡ)",
    Somali: "Somali (Af-Soomaali)",
    Lao: "Lao (ລາວ)",
    Sinhala: "Sinhala (සිංහල)",
    Azerbaijani: "Azerbaijani (Azərbaycan)",
    Serbian: "Serbian (Српски)",
    Hungarian: "Hungarian (Magyar)",
    Pashto: "Pashto (پښتو)",
    Kurdish: "Kurdish (کوردی)",
    Nepali: "Nepali (नेपाली)",
    Finnish: "Finnish (Suomi)",
    Catalan: "Catalan (Català)",
    Armenian: "Armenian (Հայերեն)",
    Madagascan: "Madagascan (Malagasy)",
    Khmer: "Khmer (ភាសាខ្មែរ)",
    Odia: "Odia (ଓଡ଼ିଆ)",
    Malayalam: "Malayalam (മലയാളം)",
    Assamese: "Assamese (অসমীয়া)",
    Tibetan: "Tibetan (བོད་ཡིག)",
    Mongolian: "Mongolian (Монгол)",
    Zulu: "Zulu (IsiZulu)",
    Xhosa: "Xhosa (IsiXhosa)",
    Hausa: "Hausa (Harshen Hausa)",
    Yoruba: "Yoruba (Yoruba)",
  };
  const searchLanguages = (searchTerm) => {
    if (!searchTerm) {
      // If searchTerm is empty, show the first five languages by default
      const firstFiveLanguages = Object.entries(languagesInNativeForm).map(
        ([key, value]) => ({ key, value })
      );
      setFilteredLanguages(firstFiveLanguages);
      return;
    }

    // If searchTerm is not empty, perform the search
    const lowercasedSearchTerm = searchTerm.toLowerCase();

    const results = Object.entries(languagesInNativeForm).filter(
      ([key, value]) => {
        return (
          key.toLowerCase().includes(lowercasedSearchTerm) ||
          value.toLowerCase().includes(lowercasedSearchTerm)
        );
      }
    );

    const filteredLanguages = results.map(([key, value]) => ({ key, value }));
    setFilteredLanguages(filteredLanguages);
  };
  const attachments = useMemo(
    () =>
      mail?.Attachments?.map(attach => {
        // ToDo: replace this with logged-in user email - Dildar Khan
        // const email = 'brokers@theoceann.com'
        const email = mail?.client
        const { url, fileType } = getAttachmentsURL(email, attach, mail?.GmailMessageId)
        return {
          url,
          fileType,
          fileName: attach?.filename
        }
      }),
    [mail?.Attachments?.length]

  )
  
  const handleDownloadAttachment = async (
    attach
  ) => {
    const response = await fetch(attach?.url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    const blob = await response.blob()
    const url = URL.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', attach?.fileName)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }
  const downloadAllAttachments = async (e) => {
    if (attachments?.length) {
      for (const attach of attachments) {
        await handleDownloadAttachment(attach);
      }
    } else {
      console.error('No attachments to download.');
    }
  };

  const handleLanguageClick = (language) => {
    setSearchLang(language); // Set the clicked language as the search term
    setSelectedLanguage(language); // You can track the selected language if needed
    // console.log(`Translating to: ${language}`);

    setTranslateMenu(false);
  };

  const handleSearchLangSubmit = (event, value) => {
    if (!value) {
      return;
    } else {
      translate(value); // Trigger translation with the English name (key)
      // setOpen(false) // Close the popover
      setSearchLang("");
      setTranslateMenu(false);
    }
  };
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        !isLabelModalVisible
      ) {
        setIsLabelOpen(false);
      }
    };

    if (isLabelOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isLabelOpen, isLabelModalVisible]);

  const [selectedAssignees, setSelectedAssignees] = useState([]);

  const handleCheck = (item) => {
    setSelectedAssignees((prev) => {
      const exists = prev.find((assignee) => assignee.id === item.id);
      if (exists) {
        // Uncheck: Remove the object
        return prev.filter((assignee) => assignee.id !== item.id);
      } else {
        // Check: Add the object
        return [...prev, { name: item.first_name, id: item.id }];
      }
    });
  };

  // console.log("selectedassignees", selectedAssignees);

  const assignMail = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}mail/assign-mail`;
      const payload = {
        client: mailDetails?.client,
        ref_id: mailDetails?._id,
        users: selectedAssignees,
      };
      const response = await postAPICall(url, payload, "post");
      // console.log("response9876", response);
      if (response?.status == true) {
        openNotificationWithIcon("success", "Email assigned successfully");
      } else {
        console.log("some error occured");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatDateTime = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    })?.format(date);
  };
  

  return (
    <div className="current_mail_header currentMailHeader_backgroung">
      <div className="user_mail_profile">
        {getInitial(
          mail?.Sender ||
            mail?.sender?.emailAddress?.address ||
            mail?.owner_email
        )}
      </div>
      <div className="mail_cc_bcc">
        <div>
          {mail?.Sender ||
            mail?.sender?.emailAddress?.address ||
            mail?.owner_email}
        </div>
        <Dropdown
          overlay={
            <Menu style={{ maxWidth: "400px" }}>
              {/* {<div><span>From :</span> {mail?.To}</div>}
              {<p>To : {mail?.To}</p>}
              {mail?.CC && <p>CC : {mail?.CC}</p>}
              {mail?.BCC && <p>BCC : {mail?.BCC}</p>} */}
              <EmailHeader currentMail={mailDetails} />
            </Menu>
          }
          placement="bottomLeft"
          arrow
          trigger={"click"}
        >
          <button onClick={(e) => e.stopPropagation()}>&#11167;</button>
        </Dropdown>
      </div>
      <div className="mail_feature_icons">
        <div className="mail_header_icons">
          <Tooltip title="Click to Reply">
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleIconClick(
                  e,
                  currentMailIndex,
                  "reply",
                  mail,
                  mailDetails
                );
              }}
            >
              <Icon
                className="current_mail_icons"
                icon={"tabler:corner-up-left"}
                style={{ color: "#b90b31" }}
              />
            </div>
          </Tooltip>
        </div>

        <div className="mail_header_icons">
          <Tooltip title="Click to Forward">
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleIconClick(
                  e,
                  currentMailIndex,
                  "forward",
                  mail,
                  mailDetails
                );
              }}
            >
              <Icon
                className="current_mail_icons"
                icon={"tabler:corner-up-right"}
                style={{ color: "#b90b31" }}
              />
            </div>
          </Tooltip>
        </div>

        <div className="mail_header_icons">
          <Tooltip title="Click to Reply All">
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleIconClick(
                  e,
                  currentMailIndex,
                  "replyAll",
                  mail,
                  mailDetails
                );
              }}
            >
              <Icon
                className="current_mail_icons"
                icon={"tabler:corner-up-left-double"}
                style={{ color: "#7e7007" }}
              />
            </div>
          </Tooltip>
        </div>

        {currentMailIndex === 0 && resizing < 40 && (
          <div className="mail_header_icons">
            <Tooltip title="Click to translate mail into your preferred language.">
              <Popover
                content={
                  <div style={{ width: 300 }}>
                    {/* Autocomplete for searching and selecting languages */}
                    <Input
                      size="small"
                      placeholder="Search languages"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => {
                        e.stopPropagation();
                        const value = e.target.value;
                        setSearchLang(value);
                        searchLanguages(value);
                      }}
                      value={searchLang}
                      style={{
                        width: "100%",
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                      }}
                    />
                    {/* Display first 5 languages below the search bar */}
                    <div style={{ marginBottom: 8 }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 8,
                          maxHeight: 220,
                          overflowY: "auto",
                        }}
                      >
                        {filteredLanguages.map((language, index) => (
                          <Button
                            key={index}
                            style={{ width: "100%" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleLanguageClick(language.key);
                              handleSearchLangSubmit(null, language.key);
                            }}
                          >
                            {language.value}
                          </Button>
                        ))}
                      </div>
                    </div>
                  </div>
                }
                title="Translate"
                trigger="click"
                open={translateMenu}
              >
                <div style={{ cursor: "pointer" }}>
                  <Icon
                    className="current_mail_icons"
                    style={{ color: "#F00000" }}
                    icon={"material-symbols:translate"}
                    onClick={(e) => {
                      e.stopPropagation();
                      setTranslateMenu((prev) => !prev);
                    }}
                  />
                </div>
              </Popover>
            </Tooltip>
          </div>
        )}

         <div className="mail_header_icons">
          <Tooltip title="Click to add Labels">
            <Popover
              placement="bottom"
              title=""
              content={
                <LabelManager
                  setIsLabelOpen={setIsLabelOpen}
                  popoverRef={popoverRef}
                  isLabelModalVisible={isLabelModalVisible}
                  setIsLabelModalVisible={setIsLabelModalVisible}
                  mail={mail}
                  mailDetails={mailDetails}
                  handleIconClick={handleIconClick}
                  currentMailIndex={currentMailIndex}
                  handleSocketEvent={handleSocketEvent}
                />
              }
              trigger="click"
              open={isLabelOpen}
            >
              <div
                ref={popoverRef}
                onClick={(e) => {
                  e.stopPropagation();
                  // handleIconClick(
                  //   e,
                  //   currentMailIndex,
                  //   "addLabel",
                  //   mail,
                  //   mailDetails
                  // );
                  setIsLabelOpen(true);
                }}
              >
                <Icon
                  className="current_mail_icons"
                  icon={"tabler:tag"}
                  style={{ color: "#39a106" }}
                />
              </div>
            </Popover>
          </Tooltip>
        </div>
       

        {localStorage.getItem("MailView") === "list" && (
        <>

        {/* best match  function */}
        {(mail?.MailType === "cargo" ||
          (mail?.MailType == "tonnage" && showBestMatch)) && (
          <div className="mail_header_icons">
            <Tooltip title="Click to show best match">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleIconClick(
                    e,
                    currentMailIndex,
                    "bestMatch",
                    mail,
                    mailDetails
                  );
                }}
              >
                <Icon
                  className="current_mail_icons"
                  icon={"ri:shake-hands-line"}
                  style={{ color: "rgb(28 147 182)" }}
                />
              </div>
            </Tooltip>            
          </div>
        )}
        {mail?.Attachments?.length>0 && currentMailIndex === 0 && resizing < 40  && (
          <div className="mail_header_icons">
            <Tooltip title="Click to download all the attachments.">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  downloadAllAttachments(e);
                }}
              >
                <Icon
                  className="current_mail_icons"
                  icon={"icon-park-solid:folder-download"}
                  style={{ color: "#006A67" }}
                  fontSize={24}
                />
              </div>
            </Tooltip>
          </div>
        )}
        {resizing < 40 && (
          <div className="mail_header_icons">
            <Tooltip title="Click to Print">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleIconClick(
                    e,
                    currentMailIndex,
                    "print",
                    mail,
                    mailDetails
                  );
                }}
              >
                <Icon
                  className="current_mail_icons"
                  icon={"prime:print"}
                  style={{ color: "#07147e" }}
                />
              </div>
            </Tooltip>
          </div>
        )}


        {currentMailIndex === 0 && resizing < 40 && showItem && (
          <div className="mail_header_icons">
            <Tooltip title="Click to Delete">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleIconClick(
                    e,
                    currentMailIndex,
                    "delete",
                    mail,
                    mailDetails
                  );
                }}
              >
                <Icon
                  className="current_mail_icons"
                  icon={"tabler:trash"}
                  style={{ color: "#e11616" }}
                />
              </div>
            </Tooltip>
          </div>
        )}
        {currentMailIndex === 0 && resizing < 40 && (
          <div className="mail_header_icons">
            <Tooltip title="Click to add Tags">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleIconClick(
                    e,
                    currentMailIndex,
                    "applyTags",
                    mail,
                    mailDetails
                  );
                }}
              >
                <Icon
                  className="current_mail_icons"
                  icon={"tabler:bookmark"}
                  style={{ color: "#ffa905" }}
                />
              </div>
            </Tooltip>
          </div>
        )}
        {currentMailIndex === 0 && resizing < 40 && (
          <div className="mail_header_icons">
            <Tooltip title="Click to Archive">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleIconClick(
                    e,
                    currentMailIndex,
                    "archive",
                    mail,
                    mailDetails
                  );
                }}
              >
                <Icon
                  className="current_mail_icons"
                  icon={"tabler:square-arrow-down"}
                  style={{ color: "#ff0599" }}
                />
              </div>
            </Tooltip>
          </div>
        )}
        {currentMailIndex === 0 && resizing < 40 && (
          <div className="mail_header_icons">
            <Tooltip title="Click to mark this as Spam">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {/* <Icon
                  className="current_mail_icons"
                  style={{ color: "#000000" }}
                  icon={"tabler:alert-octagon"}
                /> */}
                <Popconfirm
                  title="Report as Spam or Unsubscribe"
                  onConfirm={(e) => {
                    e.stopPropagation();
                    handleIconClick(
                      e,
                      currentMailIndex,
                      "spam",
                      mail,
                      mailDetails
                    );
                  }}
                  okText="OK"
                  cancelText="No"
                >
                  <Icon
                    className="current_mail_icons"
                    style={{ color: "#000000" }}
                    icon={"tabler:alert-octagon"}
                  />
                </Popconfirm>
              </div>
            </Tooltip>
          </div>
        )}
        {currentMailIndex === 0 && (
          <>
            <div
              className="mail_header_icons"
              onClick={(e) => e.stopPropagation()}
            >
              <Popover
                placement="bottom"
                trigger="click"
                // title={
                //   <span style={{ color: "#ff5722", fontWeight: "bold" }}>
                //     Assigned To
                //   </span>
                // }
                content={
                  <div>
                    <Input
                      placeholder="Search assignees..."
                      onChange={(e) => setSearchTerm(e.target.value)}
                      style={{
                        marginBottom: "12px",
                        borderRadius: "8px",
                        padding: "8px",
                        border: "1px solid #ddd",
                      }}
                    />
                    {filteredAssignTo.length > 0 ? (
                      <div
                        style={{
                          maxHeight: "200px",
                          overflowY: "auto",
                          marginBottom: "16px",
                          background: "rgb(138 192 242)",
                          borderRadius: "8px",
                          padding: "8px",
                        }}
                      >
                        {filteredAssignTo.map((item, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              background: "#ffffff",
                              borderRadius: "6px",
                              padding: "8px",
                              marginBottom: "8px",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  borderRadius: "50%",
                                  background: "#12406a",
                                  color: "#fff",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontWeight: "bold",
                                  marginRight: "8px",
                                }}
                              >
                                {item?.first_name?.charAt(0).toUpperCase()}
                              </div>
                              <div
                                style={{
                                  fontWeight: "500",
                                  color: "#333",
                                  fontSize: "14px",
                                }}
                              >
                                {item?.first_name}
                              </div>
                            </div>
                            <Checkbox
                              checked={selectedAssignees.some(
                                (assignee) => assignee.id === item.id
                              )}
                              onChange={() => handleCheck(item)}
                              style={{
                                transform: "scale(1.2)",
                                color: "#ff5722",
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div style={{ textAlign: "center", color: "#ff5722" }}>
                        No assignees found.
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "8px",
                        marginTop: "16px",
                      }}
                    >
                      <Button
                        type="default"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedAssignees([]);
                        }}
                        style={{
                          background: "#f3e5f5",
                          color: "#9c27b0",
                          fontWeight: "bold",
                          border: "none",
                          borderRadius: "6px",
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          assignMail();
                        }}
                        style={{
                          background:
                            "linear-gradient(135deg, rgb(34 184 255), rgb(36 64 61))",
                          border: "none",
                          fontWeight: "bold",
                          borderRadius: "6px",
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                }
              >
                <Tooltip title="Assign To">
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      color: "#fff",
                      background: "linear-gradient(135deg, #1976d2, #64b5f6)",
                      padding: "4px 12px",
                      borderRadius: "10px",
                      fontWeight: "600",
                      cursor: "pointer",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    Assign To
                  </div>
                </Tooltip>
              </Popover>
            </div>

            <div
              className="mail_header_icons"
              onClick={(e) => e.stopPropagation()}
            >
              <Popover
                placement="bottom"
                trigger="click"
                title={<div>Who viewed this mail.</div>}
                content={
                  <div onClick={(e) => e.stopPropagation()}>
                    {Array.isArray(mail?.readBy) ? (
                      mail.readBy.length > 0 ? (
                        mail.readBy.map((data, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ margin: "5px" }}>
                              {index + 1}. {data}
                            </span>
                            <span
                              style={{ marginLeft: "10px", color: "#1480c4" }}
                            >
                              {formatDateTime(mail.readTime)}
                            </span>
                          </div>
                        ))
                      ) : (
                        mail?.client
                      )
                    ) : (
                      <>
                        {mail?.readBy || mail?.client}{" "}
                        <span style={{ marginLeft: "10px", color: "#1480c4" }}>
                          {formatDateTime(mail.readTime)}
                        </span>
                      </>
                    )}
                  </div>
                }
              >
                <Tooltip title="Click to see who viewed this email">
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      color: "#fff",
                      background: "linear-gradient(135deg, #1976d2, #64b5f6)",
                      padding: "4px 12px",
                      borderRadius: "10px",
                      fontWeight: "600",
                      cursor: "pointer",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    Viewed By
                  </div>
                </Tooltip>
              </Popover>
            </div>
          </>
        )}
        </>
      )}
      </div>
      <div
        style={{
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {currentMailIndex === 0
          ? mail.Formatted_Date || mail.TIMESTAMP
            ? formatTimestamp(mail?.Formatted_Date || mail?.TIMESTAMP)
            : timeHandler(mail)
          : mail?.Threads[currentMailIndex - 1]?.Formatted_Date ||
            mail?.Threads[currentMailIndex - 1]?.TIMESTAMP
          ? formatTimestamp(
              mail?.Threads[currentMailIndex - 1]?.Formatted_Date ||
                mail?.Threads[currentMailIndex - 1]?.TIMESTAMP
            )
          : timeHandler(mail?.Threads[currentMailIndex - 1])}
      </div>
      <div className="mail_header_icons">
        {/* <Dropdown overlay={<div>Hello</div> } trigger={["click"]}>
          <Tooltip title="More options">
            <Icon
              className="current_mail_icons"
              icon={"tabler:dots-vertical"}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Tooltip>
        </Dropdown> */}
        <GenerateMenuItems
          handleIconClick={handleIconClick}
          currentMailIndex={currentMailIndex}
          mail={mail}
          mailDetails={mailDetails}
          handlePrint={handlePrint}
          showIcons={showIcons}
          assignToData={assignTo}
        />
        {/* <Dropdown
          menu={{
            items: menuItems,
            style: { maxHeight: '500px', overflowY: 'auto' },
          }}
          placement="bottomRight"
          arrow
          trigger={["click"]}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Tooltip title="More options">
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Icon
                className="current_mail_icons"
                icon={"tabler:dots-vertical"}
              />
            </div>
          </Tooltip>
        </Dropdown> */}
      </div>
    </div>
  );
};

const Children = ({
  body,
  replyMailIndex,
  currentMailIndex,
  mail,
  handleReplyClose,
  showComposeModal,
  setShowComposeModal,
  replyComposeData,
  setReplyComposeData,
  mailType,
  handleSocketEvent,
  setReplyMailIndex,
  showCustomTag,
}) => {
  const [processedHtml, setProcessedHtml] = useState(body);
  const dispatch = useDispatch();

  function searchVessel(inputString, keyword) {
    const regex = new RegExp(`\\b(${keyword})\\b`, "gi"); // Regular expression to match the keyword in a case-insensitive manner
    const matches = inputString.match(regex); // Find all matches

    return matches ? matches : []; // Return matches if found, otherwise return an empty array
  }
  const fieldObject = {
    vessel_name: {
      color: "#4B0082",
      backgroundColor: "#E6E6FA",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          height="17"
          width="17"
        >
          <path d="M4 10.4V4C4 3.44772 4.44772 3 5 3H10V1H14V3H19C19.5523 3 20 3.44772 20 4V10.4L21.0857 10.7257C21.5974 10.8792 21.8981 11.4078 21.7685 11.9261L20.2516 17.9938C20.1682 17.9979 20.0844 18 20 18C19.4218 18 18.8665 17.9019 18.3499 17.7213L19.6 12.37L12 10L4.4 12.37L5.65008 17.7213C5.13348 17.9019 4.5782 18 4 18C3.91564 18 3.83178 17.9979 3.74845 17.9938L2.23152 11.9261C2.10195 11.4078 2.40262 10.8792 2.91431 10.7257L4 10.4ZM6 9.8L12 8L18 9.8V5H6V9.8ZM4 20C5.53671 20 6.93849 19.4223 8 18.4722C9.06151 19.4223 10.4633 20 12 20C13.5367 20 14.9385 19.4223 16 18.4722C17.0615 19.4223 18.4633 20 20 20H22V22H20C18.5429 22 17.1767 21.6104 16 20.9297C14.8233 21.6104 13.4571 22 12 22C10.5429 22 9.17669 21.6104 8 20.9297C6.82331 21.6104 5.45715 22 4 22H2V20H4Z"></path>
        </svg>
      ),
    },
    dwt: { color: "#448aff", icon: "" },
    build_year: { color: "#ff6e40", icon: "" },
    open_area: { color: "#ff4081", icon: "" },
    open_date: { color: "#40c4ff", icon: "" },
    imo: { color: "#2B5179", icon: "" },
    cranes: { color: "#bcaaa4", icon: "" },
    loa: { color: "#e040fb", icon: "" },
    beam: { color: "#ffd740", icon: "" },
    port_cons: { color: "#b0bec5", icon: "" },
    sea_cons: { color: "#536dfe", icon: "" },
    bod: { color: "#ffab40", icon: "" },
    cargo: { color: "#40c4ff", icon: "" },
    cargo_size: { color: "#b0bec5", icon: "" },
    discharge_port: { color: "#536dfe", icon: "" },
    load_port: { color: "#40c4ff", icon: "" },
    load_term: { color: "#ff4081", icon: "" },
    laycan: { color: "#ff6e40", icon: "" },
  };

  const MapIconSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        fill="#165fd4"
        fillRule="evenodd"
        d="m17.861 3.163l.16.054l1.202.4c.463.155.87.29 1.191.44c.348.162.667.37.911.709c.244.338.341.707.385 1.088c.04.353.04.781.04 1.27v8.212c0 .698 0 1.287-.054 1.753c-.056.484-.182.962-.535 1.348a2.25 2.25 0 0 1-.746.538c-.478.212-.971.18-1.448.081c-.46-.096-1.018-.282-1.68-.503l-.043-.014c-1.12-.374-1.505-.49-1.877-.477a2.251 2.251 0 0 0-.441.059c-.363.085-.703.299-1.686.954l-1.382.922l-.14.093c-1.062.709-1.8 1.201-2.664 1.317c-.863.116-1.705-.165-2.915-.57l-.16-.053l-1.202-.4c-.463-.155-.87-.29-1.191-.44c-.348-.162-.667-.37-.911-.71c-.244-.338-.341-.706-.385-1.088c-.04-.353-.04-.78-.04-1.269V8.665c0-.699 0-1.288.054-1.753c.056-.484.182-.962.535-1.348a2.25 2.25 0 0 1 .746-.538c.478-.213.972-.181 1.448-.081c.46.095 1.018.282 1.68.503l.043.014c1.12.373 1.505.49 1.878.477a2.25 2.25 0 0 0 .44-.059c.363-.086.703-.3 1.686-.954l1.382-.922l.14-.094c1.062-.708 1.8-1.2 2.663-1.316c.864-.116 1.706.165 2.916.57m-2.111.943V16.58c.536.058 1.1.246 1.843.494l.125.042c.717.239 1.192.396 1.555.472c.356.074.477.04.532.016a.75.75 0 0 0 .249-.179c.04-.044.11-.149.152-.51c.043-.368.044-.869.044-1.624V7.163c0-.54-.001-.88-.03-1.138c-.028-.239-.072-.328-.112-.382c-.039-.054-.109-.125-.326-.226c-.236-.11-.56-.218-1.07-.389l-1.165-.388c-.887-.296-1.413-.464-1.797-.534m-1.5 12.654V4.434c-.311.18-.71.441-1.276.818l-1.382.922l-.11.073c-.688.46-1.201.802-1.732.994v12.326c.311-.18.71-.442 1.276-.819l1.382-.921l.11-.073c.688-.46 1.201-.802 1.732-.994m-6 3.135V7.42c-.536-.058-1.1-.246-1.843-.494l-.125-.042c-.717-.239-1.192-.396-1.556-.472c-.355-.074-.476-.041-.53-.017a.75.75 0 0 0-.25.18c-.04.043-.11.148-.152.509c-.043.368-.044.87-.044 1.625v8.128c0 .54.001.88.03 1.138c.028.239.072.327.112.382c.039.054.109.125.326.226c.236.11.56.218 1.07.389l1.165.388c.887.295 1.412.463 1.797.534"
        clipRule="evenodd"
      />
    </svg>
  );

  window.handleClick = (keyword) => {
    dispatch(setVesselName({ vesselName: keyword, isVisible: true }));
  };

  function highlightKeywords(userInput) {
    try {
      let { html, field } = userInput;
      if (mail[field] && Array.isArray(mail[field])) {
        mail[field]?.forEach((keyword) => {
          const escapedKeyword = searchVessel(mail.Body, keyword);
          const regex = new RegExp(`\\b${escapedKeyword}\\b`, "gi");
          // const icon = fieldObject[field as keyof typeof fieldObject].icon
          const icon = renderToString(fieldObject[field].icon);
          const MapIcon = renderToString(MapIconSvg);
          if (icon && field === "vessel_name") {
            dispatch(setVesselName({ vesselName: keyword, isVisible: false }));
            escapedKeyword.map((data) => {});
            html = html.replace(
              escapedKeyword[0],
              `<span title="${field.replace(/_/g, " ").toUpperCase()}">
              <div style="color:#9400D3; display: inline;">
                <span onclick="handleClick('${keyword}')">${icon}</span>
                <span>${keyword.toUpperCase()}</span>
                <span>
                <button onclick="handleClick('${keyword}')" style="margin: 2px; color: #86549D;">${MapIcon}</button></span>
              </div>
            </span>`
            );

            // return html
          } else {
            html = html.replace(
              keyword,
              `<span  title="${field
                .replace(/_/g, " ")
                .toUpperCase()}" style="display:inline;color:${
                fieldObject[field].color
              };padding:0px 4px;border-radius:5px;font-weight:900" class='tests'>
                <span>${keyword}</span> 
            </span>`
            );
          }
        });
        return html;
      } else {
        if (mail[field] && mail[field].length !== 0) {
          let keywords = mail[field];
          html = html.replace(
            keywords,
            `<span  title="${field
              .replace(/_/g, " ")
              .toUpperCase()}" style="display:inline;color:${
              fieldObject[field].color
            };padding:0px 4px;border-radius:5px;font-weight:900" class='tests'>
              <span>${keywords}</span> 
            </span>`
          );
        }
      }
      return html;
    } catch (error) {
      console.log(error);
    }
  }
  const divRef = useRef(null);
  const [anchorElForSelectText, setAnchorElForSelectText] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  useEffect(() => {
    if (body) {
      let updatedHtml = body;
      Object.keys(fieldObject).forEach((field) => {
        updatedHtml = highlightKeywords({ html: updatedHtml, field });
      });

      // if (updatedHtml !== processedHtml) {
      setProcessedHtml(updatedHtml);
      // setCurrentMail({ ...mail, Body: updatedHtml });
      // }
    }
  }, [body]);

  const handleTextSelect = () => {
    const selection = window.getSelection();

    if (selection && selection.toString().length > 2) {
      const selectedText = selection.toString();
      const range = selection.getRangeAt(0).getBoundingClientRect();
      // const { x, y, height } = range;
      const { x, y, height } = range;
      // Debugging logs
      // console.log("Selected Text:", height, x, y, range);
      // console.log("Last Rect:", lastRect);
      setSelectedText(selectedText);
      const dummyAnchor = document.createElement("div");
      dummyAnchor.style.position = "absolute";
      dummyAnchor.style.left = `${x}px`;
      dummyAnchor.style.top = `${y + height}px`; // Position below the text
      document.body.appendChild(dummyAnchor);

      setAnchorElForSelectText(dummyAnchor);
      setShowPopup(true);
    } else {
      setShowPopup(false);
      if (anchorElForSelectText) {
        document.body.removeChild(anchorElForSelectText);
        setAnchorElForSelectText(null);
      }
    }
  };

  const handlecloseTextSelectPopUp = (dataFromChild) => {
    // console.log(dataFromChild);
    let payLoad = { event: "setMail", data: dataFromChild };
    handleSocketEvent(payLoad);
    // setSpinner(dataFromChild.spinner)
    // if (dataFromChild.popUpclose) {
    //   setShowPopup(false)
    //   setAnchorElForSelectText(null)
    // }
  };

  // useEffect(() => {
  //   if (divRef.current && processedHtml) {
  //     divRef.current.innerHTML = '' // Clear any existing content
  //     let shadowRoot = divRef.current.shadowRoot
  //     if (!shadowRoot) {
  //       shadowRoot = divRef.current.attachShadow({ mode: 'open' })
  //     } else {
  //       shadowRoot.innerHTML = ''
  //     }
  //     const container = document.createElement('div')
  //     container.innerHTML = processedHtml
  //     shadowRoot.appendChild(container)
  //     shadowRoot.addEventListener('mouseup', handleTextSelect(false, 1))
  //     return () => {
  //       if (divRef.current) {
  //         divRef.current.removeEventListener('mouseup', handleTextSelect(false, 1))
  //       }
  //     }
  //   }
  // }, [body])
  const attachments = useMemo(
    () =>
      mail?.Attachments?.map(attach => {
        // console.log(attach)
        // ToDo: replace this with logged-in user email - Dildar Khan
        // const email = 'brokers@theoceann.com'
        const email = mail?.client
        const { url, fileType } = getAttachmentsURL(email, attach, mail?.GmailMessageId)
        return {
          url,
          fileType,
          fileName: attach?.filename
        }
      }),
    [mail?.Attachments?.length]

  )
  
  const handleDownloadAttachment = async (
    attach
  ) => {    
    const response = await fetch(attach?.url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    const blob = await response.blob()
    const url = URL?.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', attach?.fileName)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }
  const downloadAllAttachments = async (e) => {
    if (attachments?.length) {
      for (const attach of attachments) {
        await handleDownloadAttachment(attach);
      }
    } else {
      console.error('No attachments to download.');
    }
  };

  return (
    <div className="current_mail_body_box">
      <div className="current_mail_body mail_scrolls">
        {mail?.CUSTOM_LABELS && mail?.CUSTOM_LABELS?.length > 0 && (
          <>
            <div style={{ display: "flex" }}>
              <div className="mr-2 fw-bold">Labels: </div>
              <div style={{ display: "flex", flexWrap: "wrap", rowGap: "5px" }}>
                {mail?.CUSTOM_LABELS?.map((item, index) => (
                  <Tag
                    style={{
                      color: "rgba(0, 62, 120, 0.7)",
                      borderColor: "rgba(0, 62, 120, 0.7)",
                      fontSize: "13px",
                      display: "inline-flex",
                      padding: "1px 8px",
                      fontWeight: "600",
                    }}
                    closeIcon={
                      <CloseCircleOutlined
                        style={{ color: "rgba(0, 62, 120, 0.7)" }}
                      />
                    }
                    onClose={() => {
                      const payLoad = {
                        _id: mail?._id,
                        event: "applyLabel",
                        labels:
                          mail?.CUSTOM_LABELS?.length > 0
                            ? mail?.CUSTOM_LABELS?.filter(
                                (label) => label !== item
                              )
                            : [],
                        context: mail?.client,
                        isLabelRemoved: true,
                      };

                      handleSocketEvent(payLoad);
                    }}
                  >
                    {item}
                  </Tag>
                ))}
              </div>
            </div>
            <hr className="my-1" />
          </>
        )}
        {/* <shadow-container ref={ref}></shadow-container> */}
        {(mail?.vessel_name?.length > 0 ||
          mail?.tags?.length > 0 ||
          mail?.MailType) && (
          <Tags mail={mail} handleSocketEvent={handleSocketEvent} />
        )}
        <ShadowContainer>
          <div
            ref={divRef}
            onMouseUp={() => handleTextSelect()}
            dangerouslySetInnerHTML={{ __html: processedHtml }}
          />
          {showPopup && anchorElForSelectText && (
            <div
              style={{
                position: "absolute",
                top: anchorElForSelectText.style.top,
                left: anchorElForSelectText.style.left,
                background: "#E9E9E7",
                border: "1px solid #ccc",
                padding: "8px",
                zIndex: 1000,
              }}
            >
              <SelectText
                selectedText={selectedText}
                mail={mail}
                handlecloseTextSelectPopUp={handlecloseTextSelectPopUp}
              />
            </div>
          )}
        </ShadowContainer>
        {currentMailIndex === 0 && mail?.Attachments?.length > 0 &&
          (<div style={{display:'flex', flexDirection:'row',alignItems:'center', justifyContent:'flex-start', gap:'16px'}}>
            
            <div style={{ fontSize:'18px', fontWeight:'bold', color:'#00000078'}}>{mail?.Attachments?.length === 1 ? 'One Attachment' : mail?.Attachments?.length + ' Attachments'}</div>
            <div
              name='attachment'
              onClick={(e) => {
                e.stopPropagation();
                downloadAllAttachments(e)
                // handleBestMatch(e)
                // dispatch(setshowBestMatch(true))
              }}
              style={{display:'flex', flexDirection:'row',alignItems:'center', cursor:'pointer', fontSize:'18px', justifyContent:'flex-start', gap:4, color:'#003e78'}}
            >
              <div>Download all</div>
              <Icon icon='icon-park-solid:folder-download' color='#006A67' fontSize={32}/>
            </div>
        <div style={{ height: '48px', display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center', marginTop:'20px' }}>
          {attachments?.map((attach, index) => (
            <a
              component='a'
              href={attach?.url}
              target='_blank'
              rel='noreferrer'
              title={attach?.fileName}
              className='att-container'
              key={index}
              style={{
                width: '4rem',
                height: '4rem',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textDecoration: 'none',
                overflow: 'hidden',
                borderRadius: '4px',
                backgroundColor: '#f5f5f5',
              }}
            >
              <div className='att-overlay' style={{ position: 'absolute', inset: 0, background: 'rgba(0,0,0,0.2)' }} />
              
              <div className='att-icon-container' style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                bottom: '4px',
                left: '50%',
                transform: 'translateX(-50%)',
              }}>
                {/* <Typography
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: 10,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: '3',
                    WebkitBoxOrient: 'vertical',
                    textAlign: 'center',
                    maxWidth: '100%',
                  }}
                >
                  {attach.fileName.replaceAll('_', ' ').replaceAll('-', ' ')}
                </Typography> */}
                
                <div
                  style={{
                    backgroundColor: 'grey',
                    borderRadius: '4px',
                    padding: '4px',
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleDownloadAttachment(attach)}}
                >
                  <Icon icon='tabler:download' fontSize={16} style={{ color: 'white' }} />
                </div>
              </div>
              
              {attach?.fileType === 'image' ? (
                <img src={attach?.url} alt='' className='att-image' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              ) : (
                <div style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#eeeeee',
                }}>
                  <Icon
                    icon={
                      attach.fileType === 'word' ? 'mdi:file-word-box' :
                      attach.fileType === 'excel' ? 'mdi:file-excel-box' :
                      attach.fileType === 'pdf' ? 'mdi:file-pdf-box' : ''
                    }
                    style={{
                      color:
                        attach.fileType === 'word' ? '#2196f3' :
                        attach.fileType === 'excel' ? '#009688' :
                        attach.fileType === 'pdf' ? '#ff5722' : 'black',
                      width: '70%',
                      height: '70%',
                    }}
                  />
                </div>
              )}
            </a>
          ))}
        </div>
          </div>
        )}

        {replyMailIndex == currentMailIndex && !showComposeModal && (
          <div
            style={{
              marginTop: "10px",
              border: "1px solid #e3d3d3",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <ComposeReply
              mail={mail}
              handleReplyClose={handleReplyClose}
              showComposeModal={showComposeModal}
              setShowComposeModal={setShowComposeModal}
              replyComposeData={replyComposeData}
              setReplyComposeData={setReplyComposeData}
              mailType={mailType}
              setReplyMailIndex={setReplyMailIndex}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const initializeRefs = (length) => {
  return Array.from({ length }).reduce((acc, _, index) => {
    acc[index] = React.createRef();
    return acc;
  }, {});
};

const CurrentMail = ({
  showRightBar,
  countShow = true,
  mail,
  handleCurrentMailClose = () => {},
  threads,
  threadIndex = null,
  currentMailIndex,
  threadData,
  allMails,
  setMailData,
  handleDeleteMail,
  setCurrentMail,
  openCurrentMail = false,
  totalMailCount,
  page,
  paginationHandler,
  setReadUnreadCount,
  readUnreadCount,
  showIcons,
}) => {
  const [threadList, setThreadList] = useState([]);
  const [replyMailIndex, setReplyMailIndex] = useState(null);
  const [composeData, setComposeData] = useState(null);
  const [showComposeModal, setShowComposeModal] = useState(false);
  const [replyComposeData, setReplyComposeData] = useState(null);
  const [mailType, setMailType] = useState("");
  const [showApplyTagsModal, setShowApplyTagsModal] = useState(false);
  const [refs, setRefs] = useState(() => initializeRefs(null));
  const collapseRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const restrictionData = useSelector((state) => state.email.restrictionData);
  // const tabContentRefs = useRef({});
  showItem = countShow;
  const tabContentRefs = useRef({});
  const dispatch = useDispatch();
  const [isBestMatch, setIsBestMatch] = useState(false);
  const [currentBestMatch, setCurrentBestMatch] = useState({});
  const [showCustomTag, setShowCustomtag] = useState([]);

  // const handlePrint = () => {
  //   window.print();
  // };

  // const handlePrint = (tabKey,mail) => {
  //   const contentToPrint = tabContentRefs.current[tabKey];
  //   console.log({mail})
  //   if (contentToPrint) {
  //     const printWindow = window.open("", "_blank");
  //     printWindow.document.write(
  //       // "<html><head><title>Print</title></head><body>"
  //       `<html><head><title>Print</title></head><body><h1>Hello</h1></body></html>`
  //     );
  //     // printWindow.document.write(contentToPrint.innerHTML);
  //     // printWindow.document.write("</body></html>");
  //     printWindow.document.close();
  //     printWindow.print();
  //     setTimeout(() => {
  //       printWindow.close();
  //     }, 1000);
  //   }
  // };

  useEffect(() => {
    let index = allMails?.findIndex((email) => email?._id === mail?._id);
    if (index >= 0) {
      setCurrentIndex(index);
    }
  }, [allMails, mail]);

  function searchVessel(inputString, keyword) {
    const regex = new RegExp(`\\b(${keyword})\\b`, "gi"); // Regular expression to match the keyword in a case-insensitive manner
    const matches = inputString.match(regex); // Find all matches

    return matches ? matches : []; // Return matches if found, otherwise return an empty array
  }
  const fieldObject = {
    vessel_name: {
      color: "#4B0082",
      backgroundColor: "#E6E6FA",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          height="17"
          width="17"
        >
          <path d="M4 10.4V4C4 3.44772 4.44772 3 5 3H10V1H14V3H19C19.5523 3 20 3.44772 20 4V10.4L21.0857 10.7257C21.5974 10.8792 21.8981 11.4078 21.7685 11.9261L20.2516 17.9938C20.1682 17.9979 20.0844 18 20 18C19.4218 18 18.8665 17.9019 18.3499 17.7213L19.6 12.37L12 10L4.4 12.37L5.65008 17.7213C5.13348 17.9019 4.5782 18 4 18C3.91564 18 3.83178 17.9979 3.74845 17.9938L2.23152 11.9261C2.10195 11.4078 2.40262 10.8792 2.91431 10.7257L4 10.4ZM6 9.8L12 8L18 9.8V5H6V9.8ZM4 20C5.53671 20 6.93849 19.4223 8 18.4722C9.06151 19.4223 10.4633 20 12 20C13.5367 20 14.9385 19.4223 16 18.4722C17.0615 19.4223 18.4633 20 20 20H22V22H20C18.5429 22 17.1767 21.6104 16 20.9297C14.8233 21.6104 13.4571 22 12 22C10.5429 22 9.17669 21.6104 8 20.9297C6.82331 21.6104 5.45715 22 4 22H2V20H4Z"></path>
        </svg>
      ),
    },
    dwt: { color: "#448aff", icon: "" },
    build_year: { color: "#ff6e40", icon: "" },
    open_area: { color: "#ff4081", icon: "" },
    open_date: { color: "#40c4ff", icon: "" },
    imo: { color: "#2B5179", icon: "" },
    cranes: { color: "#bcaaa4", icon: "" },
    loa: { color: "#e040fb", icon: "" },
    beam: { color: "#ffd740", icon: "" },
    port_cons: { color: "#b0bec5", icon: "" },
    sea_cons: { color: "#536dfe", icon: "" },
    bod: { color: "#ffab40", icon: "" },
    cargo: { color: "#40c4ff", icon: "" },
    cargo_size: { color: "#b0bec5", icon: "" },
    discharge_port: { color: "#536dfe", icon: "" },
    load_port: { color: "#40c4ff", icon: "" },
    load_term: { color: "#ff4081", icon: "" },
    laycan: { color: "#ff6e40", icon: "" },
  };

  const MapIconSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        fill="#165fd4"
        fillRule="evenodd"
        d="m17.861 3.163l.16.054l1.202.4c.463.155.87.29 1.191.44c.348.162.667.37.911.709c.244.338.341.707.385 1.088c.04.353.04.781.04 1.27v8.212c0 .698 0 1.287-.054 1.753c-.056.484-.182.962-.535 1.348a2.25 2.25 0 0 1-.746.538c-.478.212-.971.18-1.448.081c-.46-.096-1.018-.282-1.68-.503l-.043-.014c-1.12-.374-1.505-.49-1.877-.477a2.251 2.251 0 0 0-.441.059c-.363.085-.703.299-1.686.954l-1.382.922l-.14.093c-1.062.709-1.8 1.201-2.664 1.317c-.863.116-1.705-.165-2.915-.57l-.16-.053l-1.202-.4c-.463-.155-.87-.29-1.191-.44c-.348-.162-.667-.37-.911-.71c-.244-.338-.341-.706-.385-1.088c-.04-.353-.04-.78-.04-1.269V8.665c0-.699 0-1.288.054-1.753c.056-.484.182-.962.535-1.348a2.25 2.25 0 0 1 .746-.538c.478-.213.972-.181 1.448-.081c.46.095 1.018.282 1.68.503l.043.014c1.12.373 1.505.49 1.878.477a2.25 2.25 0 0 0 .44-.059c.363-.086.703-.3 1.686-.954l1.382-.922l.14-.094c1.062-.708 1.8-1.2 2.663-1.316c.864-.116 1.706.165 2.916.57m-2.111.943V16.58c.536.058 1.1.246 1.843.494l.125.042c.717.239 1.192.396 1.555.472c.356.074.477.04.532.016a.75.75 0 0 0 .249-.179c.04-.044.11-.149.152-.51c.043-.368.044-.869.044-1.624V7.163c0-.54-.001-.88-.03-1.138c-.028-.239-.072-.328-.112-.382c-.039-.054-.109-.125-.326-.226c-.236-.11-.56-.218-1.07-.389l-1.165-.388c-.887-.296-1.413-.464-1.797-.534m-1.5 12.654V4.434c-.311.18-.71.441-1.276.818l-1.382.922l-.11.073c-.688.46-1.201.802-1.732.994v12.326c.311-.18.71-.442 1.276-.819l1.382-.921l.11-.073c.688-.46 1.201-.802 1.732-.994m-6 3.135V7.42c-.536-.058-1.1-.246-1.843-.494l-.125-.042c-.717-.239-1.192-.396-1.556-.472c-.355-.074-.476-.041-.53-.017a.75.75 0 0 0-.25.18c-.04.043-.11.148-.152.509c-.043.368-.044.87-.044 1.625v8.128c0 .54.001.88.03 1.138c.028.239.072.327.112.382c.039.054.109.125.326.226c.236.11.56.218 1.07.389l1.165.388c.887.295 1.412.463 1.797.534"
        clipRule="evenodd"
      />
    </svg>
  );

  function highlightKeywords(userInput) {
    try {
      let { html, field } = userInput;
      if (mail[field] && Array.isArray(mail[field])) {
        mail[field]?.forEach((keyword) => {
          const escapedKeyword = searchVessel(mail.Body, keyword);
          const regex = new RegExp(`\\b${escapedKeyword}\\b`, "gi");
          // const icon = fieldObject[field as keyof typeof fieldObject].icon
          const icon = renderToString(fieldObject[field].icon);
          const MapIcon = renderToString(MapIconSvg);
          if (icon && field === "vessel_name") {
            dispatch(setVesselName({ vesselName: keyword, isVisible: false }));
            escapedKeyword.map((data) => {});
            html = html.replace(
              escapedKeyword[0],
              `<span title="${field.replace(/_/g, " ").toUpperCase()}">
              <div style="color:#9400D3; display: inline;">
                <span onclick="handleClick('${keyword}')">${icon}</span>
                <span>${keyword.toUpperCase()}</span>
                <span>
                <button onclick="handleClick('${keyword}')" style="margin: 2px; color: #86549D;">${MapIcon}</button></span>
              </div>
            </span>`
            );

            // return html
          } else {
            html = html.replace(
              keyword,
              `<span  title="${field
                .replace(/_/g, " ")
                .toUpperCase()}" style="display:inline;color:${
                fieldObject[field].color
              };padding:0px 4px;border-radius:5px;font-weight:900" class='tests'>
                <span>${keyword}</span> 
            </span>`
            );
          }
        });
        return html;
      } else {
        if (mail[field] && mail[field].length !== 0) {
          let keywords = mail[field];
          html = html.replace(
            keywords,
            `<span  title="${field
              .replace(/_/g, " ")
              .toUpperCase()}" style="display:inline;color:${
              fieldObject[field].color
            };padding:0px 4px;border-radius:5px;font-weight:900" class='tests'>
              <span>${keywords}</span> 
            </span>`
          );
        }
      }
      return html;
    } catch (error) {
      console.log(error);
    }
  }

  const renderRecipients = (mail) => {
    const recipients = toMailReturn(mail);

    if (typeof recipients !== "string" && !Array.isArray(recipients)) {
      return "";
    }

    const recipientList =
      typeof recipients === "string" ? recipients.split(",") : recipients;

    return recipientList
      .map((item, index) => {
        const { name, email } = parseSender(item);
        return `
          <span key="${index}" class="sender-email" style="color: gray; font-size: 0.9em;">&lt;${email}&gt;</span>
      `;
      })
      .join("");
  };

  const handlePrint = (tabKey, mail) => {
    // console.log({tabKey,mail})
    const contentToPrint = tabContentRefs.current[tabKey];

    let updatedHtml = mail?.Body;
    if (mail?.Body) {
      Object.keys(fieldObject).forEach((field) => {
        updatedHtml = highlightKeywords({ html: updatedHtml, field });
      });

      // if (updatedHtml !== processedHtml) {
      // setProcessedHtml(updatedHtml);
      // setCurrentMail({ ...mail, Body: updatedHtml });
      // }
    }

    if (contentToPrint) {
      const iframe = document.createElement("iframe");
      iframe.style.position = "absolute";
      iframe.style.width = "0px";
      iframe.style.height = "0px";
      iframe.style.border = "none";

      document.body.appendChild(iframe);

      const iframeDocument = iframe.contentWindow.document;

      const { Subject, subject, Body, Threads } = mail;

      // Create the HTML content for the email printout
      let printContent = `
        <html>
        <head>
          <title>Print</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
            }
            .subject {
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 20px;
              padding: 10px 0;
              border-top:1px solid;
              border-bottom:1px solid;
            }
            .body {
              margin-bottom: 40px;
            }
            .thread {
              margin-top: 20px;
              padding-top: 20px;
            }
            .thread .subject {
              font-size: 20px;
            }
            .mail-type {
            display: inline;
            background-color:#cedad4;
            margin-right: 4px;
            border-radius: 4px;
            padding: 5px 8px;
            border: 1px solid;
            font-size: 13px;
            font-weight: 600;
            color: black;
            text-transform:"capitalize";
          }
            .vessel {
            display: inline;
            background-color: #32CD32;
            margin-right: 4px;
            border-radius: 4px;
            padding: 5px 8px;
            border: 1px solid;
            font-size: 13px;
            font-weight: 600;
            color: white;
            text-transform:"capitalize";
          }
            
          </style>
        </head>
        <body>
          <div class="subject" >${Subject || subject}</div>
          `;

      let fromHtml = "";
      let toHtml = "";
      if (mail?.mailClient === "outlook") {
        fromHtml = `<div style="color: gray; font-size: 0.9em;">
        ${mail?.from?.emailAddress?.name ?? ""}  
        &lt;${mail?.from?.emailAddress?.address ?? ""}&gt;</div>`;
        let toData = mail?.toRecipients?.map(
          (el, index) =>
            // <span key={index} sx={{ fontWeight: "500", marginLeft: "10px" }}>
            el?.emailAddress?.address ?? ""
          // </span>
        );
        toData?.length > 0 &&
          toData?.map((item, index) => {
            toHtml += ` <span class="to_email" key="${index}">
                &lt;${item}&gt;
              </span>`;
          });
      } else {
        let fromData = sendermaillReturn(mail);
        fromHtml = `<div style="color: gray; font-size: 0.9em;">${fromData
          ?.replace(/</g, "&lt;")
          ?.replace(/>/g, "&gt;")}</div>`;
        let toData = renderRecipients(mail);
        toHtml = `<span>${toData}</span>`;
      }

      printContent += `<div style="margin-bottom:10px; ">
          ${fromHtml}
          <div>To: ${toHtml}</div>
        </div>`;

      let tagHtml = "";
      if (mail?.MailType) {
        tagHtml += ` <div class="mail-type">
                ${mail?.MailType}
              </div>`;
      }

      if (mail?.vessel_name && mail?.vessel_name?.length > 0) {
        mail?.vessel_name.map((vessel, index) => {
          tagHtml += ` <div class="" style="
            display: inline;
            backgroundColor: ${
              chipStyles[index % chipStyles.length].backgroundColor
            };
            color: ${chipStyles[index % chipStyles.length].color};
            border-color: ${chipStyles[index % chipStyles.length].color};
            margin-right: 4px;
            border-radius: 4px;
            padding: 5px 8px;
            border: 1px solid;
            font-size: 13px;
            font-weight: 600;
            text-transform:"capitalize";
          " 
          key="${index}">
                ${vessel}
              </div>`;
        });
      }
      if (mail?.tags && mail?.tags?.length > 0) {
        mail?.tags?.map((tag, index) => {
          tagHtml += ` <div style="
                backgroundColor: ${
                  chipStyles[index % chipStyles.length].backgroundColor
                };
                color: ${chipStyles[index % chipStyles.length].color};
                border-color: ${chipStyles[index % chipStyles.length].color};
                margin-Right: 4px;
                border-radius: 4px;
                padding: 5px 8px;
                border: 1px solid;
                font-size: 13px;
                font-weight: 600;
                text-transform:"capitalize";
                " 
                key="${index}">
                ${tag}
              </div>`;
        });
      }

      printContent += `
          <div class="body">
          <div style="display:flex; flex-wrap: wrap; gap:5px; margin-bottom: 30px;" >${tagHtml}</div>
          ${updatedHtml}
          </div>`;

      if (Threads && Threads.length > 0) {
        Threads.forEach((thread) => {
          printContent += `
          <hr>
            <div class="thread">
              <div class="body">${thread?.Body}</div>
            </div>`;
        });
      }

      printContent += `
        </body>
        </html>`;

      iframeDocument.open();
      iframeDocument.write(printContent);
      iframeDocument.close();

      iframe.contentWindow.focus();
      iframe.contentWindow.print();

      // setTimeout(() => {
      //   printWindow.close();
      // }, 1000);
    }
  };

  useEffect(() => {
    if (mail) {
      setComposeDataFromMail(mail);
    }
  }, [mail]);

  const setComposeDataFromMail = (mail) => {
    if (!mail) return;
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();
    if (mail) {
      let {
        Sender,
        Body,
        Subject,
        GmailMessageId,
        CC,
        BCC,
        ThreadID,
        id,
        to,
        To,
        from,
        From,
        ccRecipients,
        bccRecipients,
        client,
        Attachments,
        _id,
        Threads,
        subject,
      } = mail;
      let customId = "";
      let parentcurrentMail = { ...mail };
      let mailId = mail.mailClient === "outlook" ? id : GmailMessageId;
      if (mail?.mailClient == "custom") {
        mailId = mail?.message_id;
        customId = mail.id;
      }
      let Tomail =
        mail.mailClient === "outlook"
          ? from?.emailAddress?.address
          : mail?.labelIds?.includes("SENT")
          ? mail.Recipient
          : From
          ? From
          : sendermaillReturn(mail);

      let newClient = mail.client ? mail.client : mail.Client;

      let ccmail;
      if (mail.mailClient === "outlook") {
        if (Array.isArray(ccRecipients)) {
          if (ccRecipients.length !== 0) {
            ccmail = [
              ccRecipients.map((cc) => cc.emailAddress?.address).join(", "),
            ];
          } else {
            ccmail = ccRecipients;
          }
        } else {
          ccmail = [];
        }
      } else {
        if (ccmail) {
          ccmail = Array.isArray(CC) ? CC : CC.length > 0 ? CC.split(",") : [];
        } else {
          ccmail = [];
        }
      }

      let bccmail;
      if (mail.mailClient === "outlook") {
        if (Array.isArray(bccRecipients)) {
          if (bccRecipients.length !== 0) {
            bccmail = [
              bccRecipients.map((bcc) => bcc.emailAddress?.address).join(", "),
            ];
          } else {
            ccmail = bccRecipients;
          }
        } else {
          bccmail = [];
        }
      } else {
        bccmail = Array.isArray(BCC)
          ? BCC
          : BCC?.length > 0
          ? BCC?.split(",")
          : [];
      }
      // extractEmail(mail?.From)
      if (mail.mailClient === "custom") {
        Tomail = parseSender(mail?.From[0]).email;
        customId = mail?.id;
      }
      let payload = {
        to: [extractEmail(Tomail)],
        cc: ccmail,
        bcc: bccmail,
        // subject: Subject?.includes(":")
        //   ? `Re: ${Subject?.split(":")[1]}`
        //   : `Re: ${Subject}`,
        subject: Subject || subject,
        // subject:{mailType == "forward" ? `Fwd: ${subject}` : `Re: ${subject}`};
        body: `<br/><br/><br/><p></p> <p></p>${Body}`,
        message_id: mailId,
        owner_email: newClient,
        // mailType: "reply",
        attachments: Attachments,
        // attachments: [],
        _id:
          _id?.length > 0
            ? _id
            : parentcurrentMail?._id
            ? parentcurrentMail?._id
            : id,
      };
      setReplyComposeData(payload);
    }
    let obj = {
      Date: formattedDate,
      to: [],
      cc: [],
      bcc: [],
      body: "",
      subject: "",
      attachments: [],
      //   editorStateKey: EditorState.createEmpty(),
      HTMLBody: undefined,
      owner_email: mail?.Client,
      ThreadID: "",
      GmailMessageId: "",
      listOfFile: [],
      totalSize: 0,
      // uid: uuidv4(),
      _id: null,
    };
  };

  // useEffect(() => {
  //   if (replyMailIndex !== null && refs[replyMailIndex].current) {
  //     refs[replyMailIndex].current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "end",
  //     });
  //   }
  // }, [replyMailIndex, refs]);

  const ref = useRef(null);

  useEffect(() => {
    if (collapseRef?.current && replyMailIndex !== -1) {
      setTimeout(()=>{

        collapseRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 200)
    }
  }, [replyMailIndex]);

  useEffect(() => {
    if (ref.current) {
      ref.current.content =
        mail?.Body || mail?.body || mail?.snippet || "No Email Body found!!";
    }
    setReplyMailIndex(null);
  }, [mail]);

  useEffect(() => {
    if (mailType) {
      let payload = { ...replyComposeData };
      if (mailType == "reply") {
        payload["cc"] = [];
        payload["bcc"] = [];
        payload["subject"] = `Re: ${payload["subject"]}`;
        payload["mailType"] = "reply";
      } else if (mailType === "forward") {
        payload["to"] = [];
        payload["cc"] = [];
        payload["bcc"] = [];
        payload["subject"] = `Fwd: ${payload["subject"]}`;
        payload["mailType"] = "forward";
      } else if (mailType == "replyAll") {
        payload[mailType] = "replyAll";
      }
      setReplyComposeData(payload);
    }
  }, [mailType]);

  const [activeKey, setActiveKey] = useState(["0"]);

  // Handle user interaction with the collapse component
  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  const dropitems = (
    <Menu>
      {mail?.To && <p>To : {mail?.To}</p>}
      {mail?.CC && <p>CC : {mail?.CC}</p>}
      {mail?.BCC && <p>BCC : {mail?.BCC}</p>}
    </Menu>
  );

  const handleSocketEvent = async (payLoad) => {
    try {
      if (payLoad.event == "setMail") {
        setCurrentMail(payLoad?.data);
        return;
      }
      // Check if webSocketManager is available and WebSocket is open
      const webSocketManager = window?.webSocketManager;
      if (
        webSocketManager &&
        webSocketManager.socket.readyState === WebSocket.OPEN
      ) {
        // Create a unique responseId to track this request
        const responseId = Date.now();
        // Create a promise to handle the delete response
        const responsePromise = new Promise((resolve, reject) => {
          // Define a temporary event handler
          const handleResponse = (response) => {
            if (response?.content?.AcknowledgmentID) {
              if (payLoad?.event === "spamMail") {
                payLoad?.Spam
                  ? openNotificationWithIcon(
                      "success",
                      "Mail successfully marked as spam."
                    )
                  : openNotificationWithIcon(
                      "success",
                      "Email successfully unmarked as spam."
                    );
                setCurrentMail({});
                handleCurrentMailClose();
                const filteredMails = allMails?.filter(
                  (mail) => mail?._id !== payLoad?._id
                );
                setMailData(filteredMails);
              } else if (payLoad?.event === "archivedMail") {
                payLoad?.Archive
                  ? openNotificationWithIcon(
                      "success",
                      "Mail successfully archived."
                    )
                  : openNotificationWithIcon(
                      "success",
                      "Email successfully removed from archive."
                    );
                setCurrentMail({});
                handleCurrentMailClose();
                const filteredMails = allMails?.filter(
                  (mail) => mail?._id !== payLoad?._id
                );
                setMailData(filteredMails);
              } else if (payLoad?.event === "markImportant") {
                payLoad?.important
                  ? openNotificationWithIcon(
                      "success",
                      "Email marked as important."
                    )
                  : openNotificationWithIcon(
                      "success",
                      "Email marked as unimportant."
                    );
                // setCurrentMail({});
                // handleCurrentMailClose();
                const filteredMails = allMails?.map((mail) => {
                  let newMail = { ...mail };
                  if (newMail?._id == payLoad?._id) {
                    if (newMail.hasOwnProperty("important")) {
                      // const newMail={...mail}
                      newMail.important = !newMail.important;
                    } else {
                      newMail = { ...newMail, important: true };
                    }
                    setCurrentMail(newMail);
                    return newMail;
                  } else {
                    return newMail;
                  }
                });
                if (countShow) {
                  setMailData(filteredMails);
                } else {
                  //  in tonnage cargo view mail.
                  if (!countShow) {
                    // console.log(mail, "mail")
                    let tonnagemail = mail;
                    if (tonnagemail.hasOwnProperty("important")) {
                      // const newMail={...mail}
                      tonnagemail.important = !tonnagemail.important;
                    } else {
                      tonnagemail = { ...tonnagemail, important: true };
                    }
                    setCurrentMail(tonnagemail);
                  }
                }

                // setMailData(filteredMails);
              } else if (payLoad?.event === "markRead") {
                payLoad?.isRead
                  ? openNotificationWithIcon("success", "Email marked as read.")
                  : openNotificationWithIcon(
                      "success",
                      "Email unmarked as read."
                    );

                if (payLoad?.isRead) {
                  let mailCount = {
                    markedAsRead: readUnreadCount?.markedAsRead + 1,
                    markedAsUnread: readUnreadCount?.markedAsUnread - 1,
                    status: true,
                  };
                  setReadUnreadCount(mailCount);
                } else {
                  let mailCount = {
                    markedAsRead: readUnreadCount?.markedAsRead - 1,
                    markedAsUnread: readUnreadCount?.markedAsUnread + 1,
                    status: true,
                  };
                  setReadUnreadCount(mailCount);
                }

                // setCurrentMail({});
                // handleCurrentMailClose();
                const filteredMails = allMails?.map((mail) => {
                  let newMail = { ...mail };
                  if (newMail?._id == payLoad?._id) {
                    if (newMail.hasOwnProperty("isRead")) {
                      // const newMail={...mail}
                      newMail.isRead = !newMail.isRead;
                    } else {
                      newMail = { ...newMail, isRead: true };
                    }
                    setCurrentMail(newMail);
                    return newMail;
                  } else {
                    return newMail;
                  }
                });
                // setMailData(filteredMails);

                if (countShow) {
                  setMailData(filteredMails);
                } else {
                  //  in tonnage cargo view mail.
                  if (!countShow) {
                    // console.log(mail, "mail")
                    let tonnagemail = mail;
                    if (tonnagemail.hasOwnProperty("isRead")) {
                      // const newMail={...mail}
                      tonnagemail.isRead = !tonnagemail.isRead;
                    } else {
                      tonnagemail = { ...tonnagemail, isRead: true };
                    }
                    setCurrentMail(tonnagemail);
                  }
                }
              } else if (payLoad?.event === "applyLabel") {
                openNotificationWithIcon(
                  "success",
                  payLoad?.isLabelRemoved
                    ? "Label removed successfully"
                    : "Label updated successfully"
                );
                const filteredMails = allMails?.map((mail) => {
                  let newMail = { ...mail };
                  if (newMail?._id == payLoad?._id) {
                    if (newMail.hasOwnProperty("CUSTOM_LABELS")) {
                      // const newMail={...mail}
                      newMail.CUSTOM_LABELS = payLoad?.labels;
                    } else {
                      newMail = { ...newMail, CUSTOM_LABELS: payLoad?.labels };
                    }
                    setCurrentMail(newMail);
                    return newMail;
                  } else {
                    return newMail;
                  }
                });
                if (countShow) {
                  setMailData(filteredMails);
                } else {
                  //  in tonnage cargo view mail.
                  if (!countShow) {
                    let tonnagemail = mail;
                    if (tonnagemail.hasOwnProperty("CUSTOM_LABELS")) {
                      // const newMail={...mail}
                      tonnagemail.CUSTOM_LABELS = payLoad?.labels;
                    } else {
                      tonnagemail = {
                        ...tonnagemail,
                        CUSTOM_LABELS: payLoad?.labels,
                      };
                    }
                    setCurrentMail(tonnagemail);
                  }
                }
              } else if (payLoad?.event === "applyTag") {
                if (response?.content?.AcknowledgmentID) {
                  openNotificationWithIcon(
                    "success",
                    "Tags removed successfully."
                  );
                  let _currentMail = { ...mail };
                  _currentMail["tags"] = [...(payLoad?.tags || [])];
                  // setCurrentMail(_currentMail);
                  setCurrentMail({ ...mail, tags: payLoad?.tags });
                  const updatedAllMails = allMails?.map((item) => {
                    if (item._id === mail?._id) {
                      return {
                        ...item,
                        tags: payLoad?.tags,
                      };
                    } else {
                      return item;
                    }
                  });
                  setMailData(updatedAllMails);
                }
              }
            } else {
              message.error("Please try again.");
            }
            resolve(response);
            // Remove the event listener after handling the response
            webSocketManager.off(payLoad?.event, handleResponse);
          };

          // Register the temporary event handler
          webSocketManager.on(payLoad?.event, handleResponse);
        });

        // Send payload with the unique responseId
        await webSocketManager.send({ ...payLoad, responseId });

        // Await the response promise
        await responsePromise;
      } else {
        message.error("Please try again.");
        console.error("WebSocket is not open or not initialized.");
      }
    } catch (error) {
      console.error("WebSocket error:", error);
      message.error("WebSocket error");
    }
  };

  // const handleSocket = async (payLoad) => {
  //   let response = await handleSocketEvent(payLoad);
  //   console.log("response-->",response)
  //   if (response) {
  //     if (payLoad?.event === "spamMail") {
  //       payLoad?.Spam
  //         ? openNotificationWithIcon(
  //             "success",
  //             "Mail successfully marked as spam."
  //           )
  //         : openNotificationWithIcon(
  //             "success",
  //             "Email successfully unmarked as spam."
  //           );
  //     } else if (payLoad?.event === "archivedMail") {
  //       payLoad?.Archive
  //         ? openNotificationWithIcon("success", "Mail successfully archived.")
  //         : openNotificationWithIcon(
  //             "success",
  //             "Email successfully removed from archive."
  //           );
  //     }
  //     setCurrentMail({});
  //     handleCurrentMailClose();
  //     const filteredMails = allMails?.filter(
  //       (mail) => mail?._id !== payLoad?._id
  //     );
  //     setMailData(filteredMails);
  //   }
  // };

  const getReplySuggestion = async () => {
    try {
      dispatch(setEmailReplySuggestions([]));
      const url = `${process.env.REACT_APP_MT_URL}marine/suggestion-list`;
      let payload = {
        body: replyComposeData?.body,
        subject: replyComposeData?.subject,
      };
      let response = await postAPICall(url, payload, "post");
      if (response?.status) {
        if (response?.suggested_replies?.length > 0) {
          // setReplySuggestion(response?.suggested_replies || []);
          dispatch(setEmailReplySuggestions(response?.suggested_replies));
        } else {
          dispatch(setEmailReplySuggestions([]));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  function extractBodyContent(htmlString) {
    // Create a new DOMParser instance
    const parser = new DOMParser();

    // Parse the HTML string into a DOM document
    const doc = parser.parseFromString(htmlString, "text/html");

    // Extract the content of the body element
    const bodyContent = doc.body.innerHTML;

    return bodyContent;
  }

  const checkRestrictedTask = (email, type) => {
    // console.log("restrictiondataa",restrictionData)
    const user = restrictionData?.filter(
      (ele) => ele.emailAccount?.import_email === email
    );
    // console.log("isallowed", user)
    // console.log("type", type)
    if (type == "delete" && user?.[0]?.deleteEmail == false) {
      Swal.fire({
        title: "User doesn't have access to delete mails",
        text: "Please contact the administrator",
      });
      return false;
    } else {
      return true;
    }
  };

  const handleIconClick = async (event, index, action, mail, mailDetails) => {
    // event.stopPropagation();
    setComposeDataFromMail(mail);
    if (action === "reply") {
      // Temporarily set replyMailIndex to null to force re-render
      setReplyMailIndex(null);
      setMailType("reply");
      getReplySuggestion();
      dispatch(
        setEmailReplyOriginalBody(extractBodyContent(mail?.Body || mail?.body))
      );
      setTimeout(() => {
        setReplyMailIndex(index); // Set the reply mail index to the intended value
      }, 0);
    } else if (action === "replyAll") {
      setReplyMailIndex(null);
      setMailType("replyAll");
      getReplySuggestion();
      setTimeout(() => {
        setReplyMailIndex(index); // Set the reply mail index to the intended value
      }, 0);
    } else if (action === "forward") {
      setReplyMailIndex(null);
      setMailType("forward");
      getReplySuggestion();
      setTimeout(() => {
        setReplyMailIndex(index); // Set the reply mail index to the intended value
      }, 0);
    } else if (action === "delete") {
      if (!checkRestrictedTask(mailDetails?.client, "delete")) {
        return;
      }
      if (mailDetails?.["_id"]) {
        handleDeleteMail({
          GmailMessageId: mailDetails?.GmailMessageId,
          _id: mailDetails?._id,
          client: mailDetails?.client,
        });
      }
    } else if (action === "spam") {
      const payLoad = {
        GmailMessageId: mailDetails?.GmailMessageId,
        _id: mailDetails?._id,
        context: mailDetails?.client,
        Spam: mailDetails?.Spam ? false : true,
        event: "spamMail",
      };
      handleSocketEvent(payLoad);
      // handleSocket(payLoad);
    } else if (action === "archive") {
      const payLoad = {
        GmailMessageId: mailDetails?.GmailMessageId,
        _id: mailDetails?._id,
        context: mailDetails?.client,
        Archive: mailDetails?.Archive ? false : true,
        event: "archivedMail",
      };
      handleSocketEvent(payLoad);
      // handleSocket(payLoad);
    } else if (action === "print") {
      handlePrint(index, mail);
    } else if (action === "bestMatch") {
      setCurrentBestMatch(mail);
      setIsBestMatch(true);
    } else if (action === "applyLabel") {
    } else if (action === "applyTags") {
      setShowApplyTagsModal(true);
    } else if (action === "markAsRead") {
      const payLoad = {
        GmailMessageId: mailDetails?.GmailMessageId,
        _id: mailDetails?._id,
        context: mailDetails?.client,
        isRead: !mail?.isRead,
        event: "markRead",
      };
      handleSocketEvent(payLoad);
      // handleSocket(payLoad);
    } else if (action === "markImportant") {
      const payLoad = {
        GmailMessageId: mailDetails?.GmailMessageId,
        _id: mailDetails?._id,
        context: mailDetails?.client,
        important: !mail?.important, // Toggle the important value
        event: "markImportant",
      };

      handleSocketEvent(payLoad);
      // handleSocket(payLoad);
    }

    setActiveKey(() => [...activeKey, `${index}`]);
    if (collapseRef?.current && replyMailIndex !== -1) {
      collapseRef?.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };

  const applyTagsEvent = () => {};

  useEffect(() => {
    if (threadData?.length > 0) {
      setActiveKey([`${threadData?.length}`]);
      // setThreadList(threadData);
      setRefs(initializeRefs(threadData?.length + 1));
    } else {
      setActiveKey(["0"]);
      setThreadList([]);
      setRefs(initializeRefs(1));
    }
    // console.log({ threadss });
    // }
  }, [threadData]);

  const saveDraft = async (data) => {
    const url = `${process.env.REACT_APP_MT_URL}mail/draft`;
    try {
      // Make the API call to save the draft
      const response = await postAPICall(url, data, "post");

      // Swal.fire({
      //   title: "Saved!",
      //   text: "Your draft has been saved.",
      //   icon: "success",
      //   confirmButtonColor: "#3085d6",
      // });
      if (response?.msg == "Draft successfully updated ") {
        const updatedAllMails = allMails?.map((item) => {
          if (item._id === data?._id) {
            return { ...item, labelIds: [...(item?.labelIds || []), "DRAFT"] };
          } else {
            return item;
          }
        });
        setMailData(updatedAllMails);
      }

      return response; // Return the response if needed
    } catch (error) {
      console.error("Error saving draft:", error);
      Swal.fire({
        title: "Error!",
        text: "There was an error saving your draft.",
        icon: "error",
        confirmButtonColor: "#3085d6",
      });
      // Optionally, you can rethrow the error or handle it accordingly
      throw error;
    }
  };

  const handleReplyClose = (replyComposeData, mail) => {
    Swal.fire({
      title: "",
      text: "Do you want to save as draft?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Don't Save",
      confirmButtonText: "Save",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // setReplyMailIndex(null);
        // setShowComposeModal(false);
        const draftPayload = {
          ...mail,
          labelIds: [...(mail?.labelIds || []), "DRAFT"],
          Threads: [{ ...replyComposeData, labelIds: ["DRAFT"] }],
        };
        const response = await saveDraft(draftPayload);
        if (response?.msg == "Draft successfully updated ") {
          setReplyMailIndex(null);
          setShowComposeModal(false);
          // setCurrentMail({});
          Swal.fire({
            title: "Saved!",
            text: "Your draft has been saved.",
            icon: "success",
            confirmButtonColor: "#3085d6",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "There was an error saving your draft.",
            icon: "error",
            confirmButtonColor: "#3085d6",
          });
        }
      } else if (result.isDismissed) {
        setReplyMailIndex(null);
        setShowComposeModal(false);
      }
    });
  };

  return (
    <>
      {mail["_id"] || mail["id"] || mail?.GmailMessageId ? (
        <>
          <div
            className="current_mail_collapse"
            style={{ padding: handleCurrentMailClose ? "0px" : "0px" }}
          >
            <div
              className="current_mail_subject_header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {openCurrentMail && (
                  <div
                    style={{ marginRight: "14px", cursor: "pointer" }}
                    onClick={() => {
                      handleCurrentMailClose();
                      setReplyMailIndex(null);
                    }}
                  >
                    <Icon
                      icon="tabler:circle-arrow-left"
                      fontWeight={"500"}
                      fontSize={"35"}
                    />
                  </div>
                )}
                {mail?.Subject && (
                  <h4 style={{ margin: 0 }}>
                    {/* {mail?.Subject} */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: mail?.Subject,
                      }}
                    ></div>
                  </h4>
                )}
              </div>

              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {mail?.MailType && (
                  <Tooltip title="Mail Type">
                    <div
                      style={{
                        display: "inline-block",
                        backgroundColor: "#E6E6FA",
                        color: "#4B0082",
                        marginRight: "10px",
                        borderRadius: "15px",
                        padding: "2px 12px",
                        border: "1px solid",
                        fontSize: "15px",
                        fontWeight: "600",
                        textTransform: "capitalize",
                      }}
                    >
                      <span>{mail?.MailType}</span>
                    </div>
                  </Tooltip>
                )}

                {localStorage.getItem("MailView") !== "split" && countShow && (
                  <div
                    style={{
                      maxWidth: "200px",
                      minWidth: "125px",
                      display: "flex",
                      flexWrap: "wrap",
                      fontWeight: "bolder",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: "1.2rem" }}>
                      {(page - 1) * EMAILS_PER_PAGE + 1 + currentIndex} of{" "}
                      {formatNumber(totalMailCount || 0)}
                    </span>
                    <span style={{ display: "flex", gap: "5px" }}>
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          let currentEmailNumber =
                            (page - 1) * EMAILS_PER_PAGE + 1 + currentIndex;
                          if (
                            currentEmailNumber >
                            Math.min(
                              (page - 1) * EMAILS_PER_PAGE + 1,
                              totalMailCount
                            )
                          ) {
                            setCurrentIndex(currentIndex - 1);
                            setCurrentMail(allMails[currentIndex - 1]);
                          } else {
                            if (
                              (page - 1) * EMAILS_PER_PAGE +
                                1 +
                                currentIndex ===
                              1
                            ) {
                              return;
                            }
                            setCurrentIndex(allMails.length - 1);
                            paginationHandler("prev");
                            setTimeout(() => {
                              setCurrentMail(allMails[allMails.length - 1]);
                            }, 1000);
                          }
                        }}
                      >
                        <Icon
                          style={{ color: "#12406a", fontSize: "26px" }}
                          icon={"ion:chevron-back-circle"}
                        />
                      </span>
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          let currentEmailNumber =
                            (page - 1) * EMAILS_PER_PAGE + 1 + currentIndex;
                          if (
                            currentEmailNumber <
                            Math.min(page * EMAILS_PER_PAGE, totalMailCount)
                          ) {
                            setCurrentIndex(currentIndex + 1);
                            setCurrentMail(allMails[currentIndex + 1]);
                          } else {
                            setCurrentIndex(0);
                            paginationHandler("next");
                            setTimeout(() => {
                              setCurrentMail(allMails[0]);
                            }, 1000);
                          }
                        }}
                      >
                        <Icon
                          style={{ color: "#12406a", fontSize: "26px" }}
                          icon={"fluent:chevron-circle-right-48-filled"}
                        />
                      </span>
                    </span>
                  </div>
                )}
              </div>
            </div>
            {/* Remaining code */}
            <Collapse
              bordered={false}
              ghost={true}
              // defaultActiveKey={[threadList?.length?threadList?.length?.toString():'0']}
              activeKey={activeKey}
              onChange={onChange}
              // ref={collapseRef}
              // accordion
            >
              <Collapse.Panel
                ref={refs[0]}
                header={
                  <Label
                    mail={mail}
                    dropitems={dropitems}
                    handleIconClick={handleIconClick}
                    currentMailIndex={0}
                    mailDetails={mail}
                    handleSocketEvent={handleSocketEvent}
                    setCurrentMail={setCurrentMail}
                    handlePrint={handlePrint}
                    showBestMatch={true}
                    showIcons={showIcons}
                  />
                }
                key="0"
              >
                {/* {items?.children} */}
                <div ref={(el) => (tabContentRefs.current[0] = el)}>
                  <Children
                    body={
                      mail?.Body ||
                      mail?.body ||
                      mail?.snippet ||
                      "No Email Body found!!"
                    }
                    currentMailIndex={0}
                    replyMailIndex={replyMailIndex}
                    mail={mail}
                    handleReplyClose={handleReplyClose}
                    showComposeModal={showComposeModal}
                    setShowComposeModal={setShowComposeModal}
                    replyComposeData={replyComposeData}
                    setReplyComposeData={setReplyComposeData}
                    mailType={mailType}
                    handleSocketEvent={handleSocketEvent}
                    setReplyMailIndex={setReplyMailIndex}
                    showCustomTag={showCustomTag}
                  />
                </div>
                {/* {replyMailIndex == 0 && (
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      border: "1px solid black",
                    }}
                  >
                    <ComposeReply mail={mail} />
                  </div>
                )} */}
              </Collapse.Panel>
              {mail?.Threads?.length > 0 &&
                mail?.Threads?.map((item, index) => {
                  if (
                    item?.labelIds?.includes("INBOX") &&
                    !item?.labelIds?.includes("DRAFT")
                  ) {
                    return (
                      <Collapse.Panel
                        ref={refs[index]}
                        header={
                          <Label
                            mail={mail}
                            dropitems={dropitems}
                            handleIconClick={handleIconClick}
                            currentMailIndex={index + 1}
                            mailDetails={item}
                            handleSocketEvent={handleSocketEvent}
                            handlePrint={handlePrint}
                            showBestMatch={false}
                          />
                        }
                        key={index + 1}
                      >
                        <div
                          ref={(el) => (tabContentRefs.current[index + 1] = el)}
                        >
                          <Children
                            body={item?.Body || item?.body}
                            currentMailIndex={index + 1}
                            replyMailIndex={replyMailIndex}
                            mail={mail}
                            handleReplyClose={handleReplyClose}
                            showComposeModal={showComposeModal}
                            setShowComposeModal={setShowComposeModal}
                            replyComposeData={replyComposeData}
                            setReplyComposeData={setReplyComposeData}
                            mailType={mailType}
                            handleSocketEvent={handleSocketEvent}
                            setReplyMailIndex={setReplyMailIndex}
                            showCustomTag={showCustomTag}
                          />
                        </div>
                      </Collapse.Panel>
                    );
                  }
                })}
            </Collapse>
            {showComposeModal && (
              <Modal
                centered
                // open={maximizedPopup}
                open={showComposeModal}
                closable={false}
                footer={null}
                width={"85vw"}
                wrapClassName="composeModal"
                style={{
                  padding: 0,
                  margin: 0,
                  zIndex: showComposeModal ? 1301 : 1300,
                }}
              >
                <ComposeReply
                  mail={mail}
                  handleReplyClose={handleReplyClose}
                  showComposeModal={showComposeModal}
                  setShowComposeModal={setShowComposeModal}
                  replyComposeData={replyComposeData}
                  setReplyComposeData={setReplyComposeData}
                  mailType={mailType}
                  setReplyMailIndex={setReplyMailIndex}
                />
              </Modal>
            )}
          </div>
        </>
      ) : (
        <div className="no_current_mail">
          <img src="/images/mailSVG.gif" width={100} />
          <h4>Welcome to OceannMail</h4>
        </div>
      )}
      <ApplyTagsModal
        showApplyTagsModal={showApplyTagsModal}
        setShowApplyTagsModal={setShowApplyTagsModal}
        mail={mail}
        setCurrentMail={setCurrentMail}
        allMails={allMails}
        setMailData={setMailData}
      />
      {isBestMatch ? (
        <Drawer
          open={isBestMatch}
          className="bestmatch-drawer"
          style={{
            height: "100vh",
            overflowY: "hidden",
            minWidth: "600px",
            maxWidth: "600px",
            marginLeft: "auto",
          }}
          onClose={() => setIsBestMatch(false)}
          title={
            <>
              <span style={{ paddingRight: "8px" }}>Best Match</span>
              <Icon icon={"ri:shake-hands-line"} />
            </>
          }
        >
          <BestMatchDrawerContent
            currentMatch={currentBestMatch}
            cargo={currentBestMatch?.MailType == "cargo"}
          />
          {/* < BestMatchDrawerContent */}
        </Drawer>
      ) : null}

      {showRightBar && (
        <div
          style={{ top: "4.5rem" }}
          className="current_mail_extra_features_tab"
        >
          <MailRightbar currentMail={mail} />
        </div>
      )}
    </>
  );
};

export default CurrentMail;

import { PrinterOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";
import React, { forwardRef, useRef } from "react";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";

const ComponentToPrint = forwardRef(({ data }, ref) => {
  const fuelTypes = ["IFO", "VLSFO", "LSMGO", "MGO", "ULSFO"];

  let maxRows = 0;
  const structuredData = fuelTypes.map((type) => {
    const filtered = data?.fuels?.filter((fuel) => fuel.code === type) || [];
    maxRows = Math.max(maxRows, filtered.length);
    return filtered;
  });

  return (
    <div style={styles.bunkerReport} id="divToPrint" ref={ref}>
      <p style={styles.vesselInfo}>
        <span>Vessel Name:</span>{" "}
        <span style={styles.boldText}>{data?.vessel_name || "N/A"}</span>
      </p>
      <h3>
        <b>Bunker Lifting Details</b>
      </h3>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.headerCell}>Category</th>
            {fuelTypes.map((type) => (
              <th key={type} colSpan="3" style={styles.fuelHeader}>
                {type}
              </th>
            ))}
          </tr>
          <tr>
            <th style={styles.subHeader}></th>
            {fuelTypes.map(() => (
              <>
                <th style={styles.subHeader}>Qty (MT)</th>
                <th style={styles.subHeader}>Price (USD)</th>
                <th style={styles.subHeader}>Value (USD)</th>
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: maxRows }).map((_, rowIndex) => (
            <tr key={rowIndex}>
              <td style={styles.categoryCell}>
                {rowIndex === 0 ? "Lifted" : ""}
              </td>
              {structuredData.map((fuelArray) => {
                const fuel = fuelArray[rowIndex] || {};
                return (
                  <>
                    <td style={styles.dataCell}>{fuel.qty || "-"}</td>
                    <td style={styles.dataCell}>
                      {fuel.price ? `$${fuel.price}` : "-"}
                    </td>
                    <td style={styles.dataCell}>
                      {fuel.value ? `$${fuel.value}` : "-"}
                    </td>
                  </>
                );
              })}
            </tr>
          ))}
          <tr style={styles.totalRow}>
            <td style={styles.totalData}>
              <b>Total</b>
            </td>
            {fuelTypes.map((type) => (
              <>
                <td style={styles.totalData}>
                  {data.grand_total?.[type]?.qty || "-"}
                </td>
                <td style={styles.totalData}></td>
                <td style={styles.totalData}>
                  {data.grand_total?.[type]?.value
                    ? `$${data.grand_total[type].value}`
                    : "-"}
                </td>
              </>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
});

const BunkerLiftingReport = ({ data }) => {
  const componentRef = useRef();

  const printDocument = () => {
    html2canvas(document.getElementById("divToPrint"), {
      useCORS: true,
      allowTaint: true,
    }).then((canvas) => {
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(canvas);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(canvas, "PNG", 0, 20, pdfWidth, pdfHeight);
      pdf.save("BunkerReport.pdf");
    });
  };

  return (
       <div className="body-wrapper modalWrapper">
         <article className="article toolbaruiWrapper">
           <div className="box box-default">
             <div className="box-body">
               <div className="toolbar-ui-wrapper">
                 <div className="leftsection"></div>
                 <div className="rightsection">
                   <span className="wrap-bar-menu">
                     <ul className="wrap-bar-ul">
                       <li onClick={printDocument}>Download</li>
                       <li>
                         <ReactToPrint
                           trigger={() => (
                             <span className="text-bt">
                               <PrinterOutlined /> Print
                             </span>
                           )}
                           content={() => componentRef.current}
                         />
                       </li>
                     </ul>
                   </span>
                 </div>
               </div>
             </div>
           </div>
         </article>
   
         <article className="article">
           <div className="box box-default">
             <div className="box-body">
               <ComponentToPrint ref={componentRef} data={data} />
             </div>
           </div>
         </article>
  
       </div>
  );
};

const styles = {
  bunkerReport: { padding: "20px", fontFamily: "Arial, sans-serif" },
  vesselInfo: { fontSize: "16px", marginBottom: "10px" },
  boldText: { fontWeight: "600" },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "10px",
    border: "1px solid #ccc",
  },
  headerCell: {
    background: "#ddd",
    padding: "8px",
    fontWeight: "bold",
    textAlign: "center",
  },
  fuelHeader: {
    background: "#e8e8e8",
    padding: "5px",
    textAlign: "center",
    fontWeight: "bold",
  },
  subHeader: {
    background: "#f5f5f5",
    padding: "5px",
    textAlign: "center",
    fontWeight: "bold",
  },
  categoryCell: {
    background: "#ddd",
    padding: "8px",
    fontWeight: "bold",
    textAlign: "center",
  },
  dataCell: {
    padding: "8px",
    textAlign: "center",
    borderBottom: "1px solid #ddd",
  },
  totalRow: { background: "#f5f5f5", fontWeight: "bold", textAlign: "center" },
  totalData: {
    background: "#f5f5f5",
    fontWeight: "bold",
    textAlign: "center",
    padding: "8px",
  },
};

export default BunkerLiftingReport;

import React, { useRef, useState, useCallback, useEffect } from "react";
import { AutoComplete, Spin, Tooltip } from "antd";
import { getAPICall, IMAGE_PATH, openNotificationWithIcon } from "../../shared";
import debounce from "lodash/debounce";
import { SearchOutlined } from "@ant-design/icons";

const SearchVesselInput = ({
  state,
  setState,
  setVesselData,
  setIsLoading,
  isgetdata=false,
  getMarineData,
  isLock,
  showVesselMap,
  isShowMap=false
}) => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    // Reset search input when a new estimate is added
    setValue("");
  }, []);
  
  const handleSearch = useCallback(
    debounce(async (newValue) => {
      if (newValue.length < 3) {
        setOptions([]);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await getAPICall(
          `${process.env.REACT_APP_MT_URL}marine/get-vessels-name/${newValue}`
        );
        if (response && Array.isArray(response) && response.length > 0) {
          const newOptions = response.map((item) => ({
            value: item.SHIPNAME,
            label: (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={`${IMAGE_PATH}icons/ship.png`}
                  alt="ship"
                  style={{ width: 30, marginRight: 8 }}
                />
                <div>
                  <strong>{item.SHIPNAME}</strong>
                  <br />
                  MMSI: {item.MMSI}
                  <br />
                  IMO: {item.IMO}
                </div>
              </div>
            ),
            details: item,
          }));
          setOptions(newOptions);
        } 
        // console.log("respone",response.length);
        
        else if (response.length === 0) {
          openNotificationWithIcon("error", "Vessel data not found.",1);
        } 
        // else 
        // {
        //   openNotificationWithIcon("error", "Vessel data not found.");
        // }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }, 300),
    []
  );

  const handleChange = (newValue) => {
    setValue(newValue);
    handleSearch(newValue);
  };

  const handleSelect = async (value, option) => {
    let details = option.details;
    if (isShowMap) {
      showVesselMap(value)
    }
    // console.log("formData-->", state);
    function notifyStatus(frmName, statusKey, type) {
      const status = state?.formData?.[statusKey];
      if (status && status != "0") {
        const statusText =
          status == "1" ? "Fixed" : status == "2" ? "Scheduled" : "";
        openNotificationWithIcon(
          "info",
          `This ${type} is already ${statusText}.`
        );
        return true;
      }
      return false;
    }

    if (
      (state?.frmName === "tcov_full_estimate_form" &&
        notifyStatus("tcov_full_estimate_form", "tcov_status", "Estimate")) ||
      (state?.frmName === "tcto_full_estimate_form" &&
        notifyStatus("tcto_full_estimate_form", "tcto_status", "Estimate")) ||
      (state?.frmName === "VOYAGE_RELET" &&
        notifyStatus("VOYAGE_RELET", "vivo_status", "Voy Relet"))
    ) {
      return;
    }

    try {
      setIsLoading(true);

      const addFleetResponse = await getAPICall(
        `${process.env.REACT_APP_MT_URL}marine/add-fleet/${details?.IMO}/${details?.SHIP_ID}`
      );
      if (addFleetResponse?.data !== "fleet added") {
        // openNotificationWithIcon('info', addFleetResponse?.error || "Failed to add fleet");
        // setIsLoading(false);
        // return;
      }
      const vesselDetailsResponse = await getAPICall(
        `${process.env.REACT_APP_MT_URL}marine/get-vessel-details/${details?.MMSI}/${details?.SHIP_ID}/${details?.SHIPNAME}/${details?.IMO}`
      );
      if (!vesselDetailsResponse?.data) {
        openNotificationWithIcon(
          "error",
          vesselDetailsResponse?.error || "Failed to get vessel details"
        );
        setIsLoading(false);
        return;
      }
      let reqData = { ...vesselDetailsResponse?.data };
      if (isgetdata) {
        getMarineData(reqData)
      }
      await setVesselData(reqData,details);
       setValue(""); // Clear search bar after selecting a vessel
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div style={{ position: "relative", lineHeight: "1" }}>
      <Tooltip title="Enter vessel name or IMO number">
        {/* <Tooltip title="Search & add vessel from Marine Traffic"> */}
        <AutoComplete
        className="baby_potato"
          ref={autoCompleteRef}
          value={value}
          options={loading ? [] : options}
          style={{
            minWidth: 330,
            borderRadius: "10px",
            overflow: "hidden",
            border: "3px solid #12406a",
          }}
          onSelect={handleSelect}
          disabled={isLock}
          onSearch={handleChange}
          onChange={handleChange}
          placeholder="Search & add vessel from Marine Traffic"
          // placeholder="Enter vessel name or IMO number"
          dropdownStyle={{ maxHeight: 300, overflow: "auto" }}
          notFoundContent={
            loading ? (
              <div
                style={{
                  textAlign: "center;",
                  padding: "8px",
                  width: "50px",
                  margin: "0 auto",
                }}
              >
                <Spin size="small" />
              </div>
            ) : null
          }
        />
      </Tooltip>
      <div
        style={{
          position: "absolute",
          right: "0.1rem",
          top: "45%",
          transform: "translateY(-50%)",
          color: "black",
          lineHeight: "1",
        }}
      >
        <SearchOutlined />
      </div>
    </div>
  );
};

export default SearchVesselInput;

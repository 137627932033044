import React from 'react';
import { Row, Col, Menu, Dropdown } from 'antd';
import CONFIGURATION from '../../constants/configuration';
import { DownloadOutlined, MoreOutlined } from '@ant-design/icons';





class ToolbarUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routeUrl: null,
      isLeft: { xs: 24, sm: 24, md: 6, lg: 6, xl: 6 },
      isRight: { xs: 24, sm: 24, md: 18, lg: 18, xl: 18 },
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props && props.routeUrl && !state.routeUrl) {
      return {
        routeUrl: props.routeUrl,
      };
    }
    return null;
  }

  onIconClick = (key, index) => {
    // console.log("show key", key);
  };

  onDropdownClick = enevt => {
    // console.log("show key dropdown", enevt);
  };

  openModalPopup = obj => {
    if (this.props.callback) this.props.callback(obj);
  };

  generateMenus = menus => {
    return (
      <Menu>
        {menus
          ? menus.map((menu, i) => {
            return (
              <Menu.Item key={i}>
                <a
                  rel="noopener noreferrer"
                  href={menu.href}
                  onClick={!menu.href ? () => this.openModalPopup(menu.modalKey) : null}
                >
                  <>
                    {menu.icon ? menu.icon : null}
                    {menu.label ? menu.label : null}
                  </>
                </a>
              </Menu.Item>
            );
          })
          : null}
      </Menu>
    );
  };

  render() {
    const { routeUrl } = this.state;
    let isLeft = Object.assign({}, this.state.isLeft);
    let isRight = Object.assign({}, this.state.isRight);
    let configurationArray =
      CONFIGURATION && CONFIGURATION[routeUrl] ? CONFIGURATION[routeUrl] : null;

    // console.log({configurationArray,routeUrl})

    if (
      configurationArray &&
      configurationArray.isLeftBtn &&
      configurationArray.isLeftBtn.length > 0 &&
      configurationArray.isRightBtn &&
      configurationArray.isRightBtn.length === 0
    ) {
      isRight = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };
      isLeft = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 };
    } else if (
      configurationArray &&
      configurationArray.isLeftBtn &&
      configurationArray.isLeftBtn.length === 0 &&
      configurationArray.isRightBtn &&
      configurationArray.isRightBtn.length > 0
    ) {
      isLeft = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };
      isRight = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 };
    }

    return (
      <>
        <Row>
          <Col xs={isLeft.xs} sm={isLeft.sm} md={isLeft.md} lg={isLeft.lg} xl={isLeft.xl}>
            {configurationArray
              ? configurationArray.isLeftBtn.map((el, i) => {
                if (configurationArray.isLeftBtn.length > 0) {
                  return (
                    <span key={(i + 1).toString()} className="wrap-bar-menu">
                      <ul className="wrap-bar-ul">
                        {el.isSets.map((set, j) => {
                          return (
                            <li key={j} onClick={e => this.openModalPopup(set.key, j)}>
                              {set.type}
                              <span style={{ display: this.props?.routeUrl == "pl-main" ? "flex" : "", textWrap: this.props?.routeUrl == "pl-main" ? "nowrap" : "" }} className="text-bt">{set.withText}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </span>
                  );
                } else {
                  return null;
                }
              })
              : null}
          </Col>

          <Col xs={isRight.xs} sm={isRight.sm} md={isRight.md} lg={isRight.lg} xl={isRight.xl}>
            {configurationArray ? (
              <Row type="flex" justify="end">
                {configurationArray.isRightBtn.map((el, i) => {
                  if (configurationArray.isRightBtn.length > 0) {
                    return (
                      <span key={(i + 1).toString()} className="wrap-bar-menu">
                        <ul className="wrap-bar-ul">
                          {el.isSets.map((set, j) => {
                            return (
                              <li key={j}>
                                {set.isDropdown === 0 ? (
                                  <span
                                    className="text-bt"
                                    onClick={e => this.onIconClick(set.key, j)}
                                  >
                                    {set.withText}
                                  </span>
                                ) : (
                                  <Dropdown
                                    overlay={() => this.generateMenus(set.menus)}
                                    onVisibleChange={e => this.onDropdownClick(e)}
                                    trigger={['click']}
                                  >
                                    <span className="text-bt">
                                      {set.withText} <DownloadOutlined />
                                    </span>
                                  </Dropdown>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </span>
                    );
                  } else {
                    return null;
                  }
                })}
                <div>
                  {configurationArray.isMoreoprtion ? (
                    <span className="wrap-bar-menu more-icon">
                      <MoreOutlined />
                    </span>
                  ) : null}
                </div>
              </Row>
            ) : null}
          </Col>
        </Row>
      </>
    );
  }
}

export default ToolbarUI;

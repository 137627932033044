import React, { useEffect, useState, useRef } from "react";
import mapboxgl from "mapbox-gl";
import AiLoader from "../../../../shared/components/PL/tcov/AiLoader";
import { Select, Table } from "antd";
import URL_WITH_VERSION, { getAPICall, postAPICall } from "../../../../shared";
import "../tcov/AiTracker.css";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const HistoricalData = () => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [portList, setPortList] = useState([]);
  const [selectedPort, setSelectedPort] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    getVessel();
  }, []);

  useEffect(() => {
    // Ensure mapRef.current is valid and safely call remove if available
    if (mapRef?.current) {
      try {
        if (typeof mapRef.current.remove === "function") {
          mapRef.current.remove();
        }
      } catch (error) {
        console.warn("Error while removing the map instance:", error);
      }
    }
    if (tableData.length > 0) {
      initializeMap();
    }
  }, [tableData]);
  
  

  const getVessel = async () => {
    try {
      const url = `${URL_WITH_VERSION}/vessel/list?l=0`;
      const resp = await getAPICall(url);
      if (resp?.data) {
        setPortList(resp?.data);
        setLoading(false);
      } else {
        console.error("Error fetching vessels");
      }
    } catch (error) {
      console.error("Error fetching vessels:", error);
    }
  };

  const getTableData = async (vessel) => {
    setTableLoading(true);
    try {
      const payload = { vessel_name: vessel };
      const url = `${process.env.REACT_APP_MT_URL}marine/vessel-history`;
      const resp = await postAPICall(url, payload, "post");
      if (resp?.success) {
        setTableData(resp?.data || []);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTableLoading(false);
    }
  };

  const handleSelectChange = (value) => {
    setSelectedPort(value);
    if (value) {
      getTableData(value);
    }
  };

  const initializeMap = () => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [tableData[0]?.LON || 0, tableData[0]?.LAT || 0],
      zoom: 3,
    });

    // Add markers for each point
    tableData.forEach((data) => {
      const marker = new mapboxgl.Marker({ color: "red" })
        .setLngLat([data.LON, data.LAT])
        .addTo(mapRef.current);

      // If this is the most recently updated, add a label on top of the marker
      if (data.LAST_UPDATED === tableData[0].LAST_UPDATED) {
        const label = document.createElement("div");
        label.style.fontSize = "12px";
        label.style.fontWeight = "bold";
        label.style.color = "black";
        label.style.backgroundColor = "white";
        label.style.borderRadius = "5px";
        label.style.padding = "0.2rem";
        label.style.position = "absolute";
        label.style.top = "-45px"; // Position it above the marker
        label.textContent = data.VESSEL_NAME;
        label.style.zIndex = "999";

        // Create a custom marker with a label
        new mapboxgl.Marker({ element: label })
          .setLngLat([data.LON, data.LAT])
          .addTo(mapRef.current);
      }
    });

    // Create a GeoJSON line feature
    const lineCoordinates = tableData.map((data) => [data.LON, data.LAT]);

    const geojson = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: lineCoordinates,
          },
        },
      ],
    };

    // Add the GeoJSON source for the line
    mapRef.current.on("load", () => {
      mapRef.current.addSource("vessel-path", {
        type: "geojson",
        data: geojson,
      });

      // Add the line layer
      // mapRef.current.addLayer({
      //   id: "vessel-path-layer",
      //   type: "line",
      //   source: "vessel-path",
      //   layout: {
      //     "line-join": "round",
      //     "line-cap": "round",
      //   },
      //   paint: {
      //     "line-color": "#888",
      //     "line-width": 4,
      //   },
      // });
    });
  };

  const columns = [
    {
      key: "CURRENT_PORT",
      dataIndex: "CURRENT_PORT",
      title: "Current Port",
    },
    {
      key: "NEXT_PORT",
      dataIndex: "NEXT_PORT",
      title: "Next Port Visited",
    },
    {
      key: "LAT",
      dataIndex: "LAT",
      title: "Latitude",
    },
    {
      key: "LON",
      dataIndex: "LON",
      title: "Longitude",
    },
    {
      key:"LAST_UPDATED",
      dataIndex:"LAST_UPDATED",
      title:"Updated At",
    },
  ];

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            maxHeight: "50vh",
          }}
        >
          <AiLoader text={"AI Tracking..."} />
        </div>
      ) : (
        <div
          style={{
            margin: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div style={{ marginBottom: "16px" }}>
            <label
              htmlFor="port-select"
              style={{ display: "block", color: "#a52323" }}
            >
              Select vessel for historical data:
            </label>
            <Select
              id="port-select"
              placeholder="Select vessel"
              style={{ width: "100%" }}
              allowClear={true}
              onChange={handleSelectChange}
              options={portList.map((vessel) => ({
                value: vessel?.vessel_name,
                label: vessel?.vessel_name,
              }))}
              showSearch={true}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            {selectedPort ? (
              <div
                ref={mapContainerRef}
                style={{
                  flex: 1,
                  height: "400px",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              ></div>
            ) : (
              <div
                style={{
                  flex: 1,
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px dashed #ccc",
                  borderRadius: "10px",
                  backgroundColor: "#f9f9f9",
                  color: "#999",
                  fontSize: "16px",
                }}
              >
                Select a vessel to view the map
              </div>
            )}

            <div style={{ flex: 1 }}>
              <Table
                className="ai-table"
                columns={columns}
                dataSource={tableData}
                loading={tableLoading}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HistoricalData;

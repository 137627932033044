//===============================================================================

import React from "react";
import { lazy, Suspense } from "react";
import { createHashRouter, Navigate, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import LoadingComponent from "../components/Loading";
import { AppContentRoute } from "./Layout/Content";

import "../styles/antd.less";
import "../styles/bootstrap/bootstrap.scss";
// custom
import "../styles/layout.scss";
import "../styles/theme.scss";
import "../styles/ui.scss";
import "../styles/vendors.scss";
import "../styles/context-menu.scss";
import "../styles/custom.scss";
import "../styles/cardServices.css";
import "./Layout/Tooltip/Tooltip.css";
import { URL_WITHOUT_VERSION } from "../shared/index.js";
import RippleComponent from "../routes/NotFoundPage/index.js";

const cookies = new Cookies();

let AsyncAppLayout = lazy(() => import("../components/Layout/AppLayout/"));
let AsyncException = lazy(() => import("../routes/exception/"));
let AsyncAccount = lazy(() => import("../routes/user/"));
let AsyncSharedLink = lazy(() => import("../routes/docs/"));
let SharedDocuments = lazy(() =>
  import("../routes/docs/routes/SharedDocuments.js")
);
let AsynLogin = lazy(() => import("../routes/user/routes/Login.js"));

let AsyncNoonReportForm = lazy(() =>
  import("../routes/vessel-activate-list/Noon-report-form.js")
);

let AsyncDepartureReport = lazy(() =>
  import("../routes/vessel-activate-list/Departure-report.js")
);

let AsyncBunkerHandlingReport = lazy(() =>
  import("../routes/vessel-activate-list/Bunker-handling-report.js")
);

let AsyncArrivalReport = lazy(() =>
  import("../routes/vessel-activate-list/Arrival-report.js")
);



let AsyncDelayStopReport = lazy(() =>
  import("../routes/vessel-activate-list/Delay-stop-report.js")
);

// function NotFoundPage() {
//   return window.history.back();
// }

function isLoggedIn() {
  const token = localStorage.getItem("oceanToken");
  return !!token; // Using the double negation to convert to boolean
}

function PrivateRoute({ element }) {
  const navigate = useNavigate();

  window.myNavigator = navigate;
  // window.clearHistory=()=>{
  //   console.log("history");
  //   history.clear()
  // }
  const isLogin = isLoggedIn();
  // console.log(isLogin, typeof isLogin);
  return isLogin === true ? (
    element
  ) : (
    <Navigate to="/user/login" replace={true} />
  );
}

export const Router = createHashRouter([
  {
    path: "/",
    element: (
      <PrivateRoute
        element={
          <Suspense fallback={<LoadingComponent  roushan='true'/>}>
            <AsyncAppLayout />
          </Suspense>
        }
      />
    ),
    errorElement: <RippleComponent />,
    children: AppContentRoute,
  },

  {
    path: "/noon-report-form",
    element: (
      <PrivateRoute
        element={
          <Suspense fallback={<LoadingComponent />}>
            <AsyncNoonReportForm />
          </Suspense>
        }
      />
    ),
    //  errorElement: <NotFoundPage />,
  },
  {
    path: "/departure-report-form",
    element: (
      <PrivateRoute
        element={
          <Suspense fallback={<LoadingComponent />}>
            <AsyncDepartureReport />
          </Suspense>
        }
      />
    ),
    //  errorElement: <NotFoundPage />,
  },

  {
    path: "/bunker-handling-report-form",
    element: (
      <PrivateRoute
        element={
          <Suspense fallback={<LoadingComponent />}>
            <AsyncBunkerHandlingReport />
          </Suspense>
        }
      />
    ),
    //  errorElement: <NotFoundPage />,
  },

  {
    path: "/arrival-report-form",
    element: (
      <PrivateRoute
        element={
          <Suspense fallback={<LoadingComponent />}>
            <AsyncArrivalReport />
          </Suspense>
        }
      />
    ),
    //  errorElement: <NotFoundPage />,
  },
  {
    path: "/sea-delay-stop-report-form",
    element: (
      <PrivateRoute
        element={
          <Suspense fallback={<LoadingComponent />}>
            <AsyncDelayStopReport />
          </Suspense>
        }
      />
    ),
    //  errorElement: <NotFoundPage />,
  },

  {
    path: "user/login",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsynLogin />
      </Suspense>
    ),
    //  errorElement: <NotFoundPage />
  },
  {
    path: "exception",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncException />
      </Suspense>
    ),
    //  errorElement: <NotFoundPage />,
  },
  {
    path: "user",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <AsyncAccount />
      </Suspense>
    ),
    //  errorElement: <NotFoundPage />,
  },
  {
    path: "docs/shared/:id",
    element: (
    <PrivateRoute
      element={
      <Suspense fallback={<LoadingComponent />}>
        <SharedDocuments />
      </Suspense>
      }
      />
    ),
    //  errorElement: <NotFoundPage />,
  },
]);
